import { apiUrl, env, londonLink } from "@talentpair/env";
import { sentry } from "@talentpair/sentry";
import { loadInspectlet, mixpanelHelper } from "@talentpair/tracking";
import { UserDataT } from "@talentpair/types/userService";
import { getQueryParam } from "@talentpair/utils";
import lodash from "lodash";
import { apiRequest, ResponseDataT } from "./apiRequest";
import { userService } from "./user";

export type UserTypeT = "candidate" | "employer";

function getNextParam(): string {
  const param = getQueryParam("next") || "";
  return decodeURIComponent(param);
}

const getCSRF = (): Promise<string> =>
  apiRequest.getMemoized<{ csrf: string }>("users/login/").then(({ data: { csrf } }) => {
    apiRequest.setToken(csrf);
    return csrf;
  });

// Only register once per user
export const register = lodash.memoize(
  () => {
    sentry.register(userService.data);
    mixpanelHelper.register({
      "User Type": userService.userType(),
      Organization: userService.data?.organization?.name || "Candidate",
    });
    loadInspectlet();

    // Register service worker for recruiters only (for now). Will use function in util at root when we're ready to use for all users
    if (
      "serviceWorker" in navigator &&
      navigator.serviceWorker &&
      userService.hasGettysburgAccess()
    )
      navigator.serviceWorker.register("/service-worker.js").catch(sentry.error);
  },
  // Resolve the cache with the user id
  () => userService.id(),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PassthroughT = Record<string, any>;

type UserSessionResultT<P = PassthroughT> = [UserDataT, string, null | ResponseDataT<P>];
function getUserSession<P = PassthroughT>(
  passthrough: null | ResponseDataT<P> = null,
): Promise<UserSessionResultT> {
  return Promise.all([
    // load the user data into the userService
    userService.loadData(),
    // get the CSRF token we need for talking to certain API endpoints
    getCSRF(),
  ])
    .catch((errs) => {
      // if we errorer then quite likely this is an anonymous user
      // we should still attempt to register
      register();
      throw errs;
    })
    .then(([userData, csrf]) => {
      // register the user with mixpanel and other services
      register();
      // Return the original response
      return [userData, csrf, passthrough];
    });
}

function isApiAppRedirect(next: string): boolean {
  const isAdminApp = next.startsWith("/admin/") || next.startsWith("/tp-");
  return !!userService.data && userService.data.is_talentpair_data_scientist && isAdminApp;
}

const redirectToApp = (arg?: UserDataT | UserSessionResultT<{ redirect_to?: string }>): void => {
  if (Array.isArray(arg) && arg[2] && arg[2].data && arg[2].data.redirect_to)
    location.replace(arg[2].data.redirect_to);
  else {
    const next = getNextParam();
    if (!next && userService.hasGettysburgAccess()) {
      location.replace(env.gettysburgUrl());
    } else if (next.startsWith(env.gettysburgUrl())) {
      location.replace(userService.hasGettysburgAccess() ? next : env.londonUrl());
    } else if (isApiAppRedirect(next)) {
      location.replace(apiUrl(next.slice(1)));
    } else if (next.startsWith("http")) {
      location.replace(next);
    } else {
      location.replace(env.londonUrl(next));
    }
  }
};

const login = ({
  username,
  password,
  ...extraData
}: {
  username: string;
  password: string;
  user_type: UserTypeT;
  job?: number;
  org_slug?: string;
}): Promise<void> =>
  getCSRF()
    .then((csrfmiddlewaretoken) =>
      apiRequest.post<Record<string, unknown>>("users/login/", {
        username: lodash.toString(username),
        password: lodash.toString(password),
        csrfmiddlewaretoken,
        ...extraData,
      }),
    )
    .then(getUserSession)
    .then(redirectToApp);

const signup = ({
  email,
  user_type,
  ...extraData
}: {
  email: string;
  user_type: UserTypeT;
  job?: number;
  org_slug?: string;
}): Promise<ResponseDataT<unknown>> =>
  getCSRF().then((csrfmiddlewaretoken) =>
    apiRequest.post(`users/${user_type === "employer" ? "contact" : "candidate"}-signup/`, {
      email: lodash.toString(email),
      csrfmiddlewaretoken,
      ...extraData,
    }),
  );

const forgot = ({
  email,
  user_type,
}: {
  email: string;
  user_type: UserTypeT;
}): Promise<ResponseDataT<unknown>> =>
  getCSRF().then((csrfmiddlewaretoken) =>
    apiRequest.post("users/api/forgot/", {
      email: lodash.toString(email),
      user_type,
      csrfmiddlewaretoken,
    }),
  );

interface UserT {
  email: string;
  first_name: string;
}

export type InviteUserT = {
  email: string;
  first_name: string;
  last_recruiter_invite: null | string;
  user_type: "candidate" | "employer";
  last_tos_accepted: null | string;
};

const checkReset = (token: string): Promise<ResponseDataT<{ user: UserT; validlink: boolean }>> =>
  apiRequest.get(`users/api/forgot/${token}/`);

const checkInviteToken = (token: string): Promise<ResponseDataT<{ user: InviteUserT }>> =>
  apiRequest.get(`users/api/invite/signup/${token}/`);

const reset = ({
  new_password1,
  new_password2,
  token,
}: {
  new_password1: string;
  new_password2: string;
  token: string;
}): Promise<void> =>
  getCSRF().then((csrfmiddlewaretoken) =>
    apiRequest
      .post(`users/api/forgot/${token}/`, {
        new_password1: lodash.toString(new_password1),
        new_password2: lodash.toString(new_password2),
        csrfmiddlewaretoken,
      })
      .then(getUserSession)
      .then(redirectToApp),
  );

const inviteSignup = ({
  new_password,
  token,
  job,
  org_slug,
}: {
  new_password: string;
  token: string;
  job?: number;
  org_slug?: undefined | string;
}): Promise<UserSessionResultT> =>
  getCSRF().then((csrfmiddlewaretoken) =>
    apiRequest
      .post(`users/api/invite/signup/${token}/`, {
        new_password: lodash.toString(new_password),
        csrfmiddlewaretoken,
        ...(job ? { job, org_slug } : null),
      })
      .then(getUserSession),
  );

const loadSessionData = (skipRedirect?: (url: string) => boolean): Promise<UserDataT> =>
  getUserSession().then(([userData]) => {
    if (skipRedirect && skipRedirect(location.pathname)) return userData;

    // Redirect unfinished contact signups
    if (!userData.candidate_id) {
      if (!userService.getTapSignupPage()) return userData;
      // Rejecting this so that AuthRoute doesn't start rendering the page
      location.replace(env.londonUrl(londonLink.tapCreateProfile()));
      return Promise.reject(Error("SENTRY IGNORE: redirect handled"));
    }

    // Handle unfinished candidate signups
    return apiRequest
      .getMemoized<{ cap_signup_flow_complete: boolean }>(
        `candidates-cap/${userData.candidate_id}/`,
      )
      .then(({ data: { cap_signup_flow_complete } }) => {
        if (cap_signup_flow_complete || !userService.getCapSignupPage()) return userData;
        // Rejecting this so that AuthRoute doesn't start rendering the page
        location.replace(env.londonUrl(londonLink.capCreateProfile()));
        return Promise.reject(Error("SENTRY IGNORE: redirect handled"));
      });
  });

let _loadSessionPromise: Promise<UserDataT> | null = null;

const isLoggedin = (skipRedirect?: (url: string) => boolean): Promise<UserDataT> => {
  if (userService.loggedin && userService.data) return Promise.resolve(userService.data);
  if (!_loadSessionPromise) {
    _loadSessionPromise = loadSessionData(skipRedirect);
  }
  return _loadSessionPromise;
};

const logout = (shouldRedirect = true): Promise<void> =>
  apiRequest
    .get("users/logout/")
    .then(() => {
      const redirect = env.londonUrl(
        userService.isCandidate()
          ? londonLink.authCandidateSignin()
          : londonLink.authEmployerSignin(),
      );
      userService.clear();
      if (shouldRedirect) location.assign(redirect);
      else location.reload();
    })
    .catch(sentry.error);

export const auth = {
  checkInviteToken,
  checkReset,
  signup,
  forgot,
  getCSRF,
  getNextParam,
  inviteSignup,
  isApiAppRedirect,
  isLoggedin,
  loadSessionData,
  login,
  logout,
  redirectToApp,
  reset,
};
