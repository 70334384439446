import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { MyJobsMeta } from "./containers/MyJobsHeader";
import { SideNavigation } from "../components/Navigation/SideNavigation";

export const MyJobs = React.lazy(() => import("./containers/MyJobsPage"));

export const MyJobsPage = page({
  // @ts-expect-error - it's okay
  desktop: MyJobs,
  // @ts-expect-error - it's okay
  mobile: MyJobs,
  // @ts-expect-error - it's okay
  desktopSidenav: SideNavigation,
  header: TapHeader,
  meta: MyJobsMeta,
});
