import { sentry } from "@talentpair/sentry";
import { isStorageAvailable } from "./storage";

// ignore keys are keys that are not wrapped in the Timestamped json object
const ignoreKeys = ["FLYOUT_VIEW"];
const placeholder: Timestamped = { time: 1, value: null };

interface Timestamped<V = unknown> {
  time: number;
  value: V;
}

// eslint-disable-next-line @typescript-eslint/no-use-before-define
const isAvailable = isStorageAvailable(window.localStorage);

const localStorageService = {
  isAvailable,

  getKeys(filter?: (key: string) => boolean): string[] {
    const keys = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && (!filter || filter(key))) keys.push(key);
    }
    return keys;
  },

  getItem<V = unknown>(key: string, defaultVal: V): V {
    if (!isAvailable) return defaultVal;
    try {
      const val = window.localStorage.getItem(key);
      if (val == null) return defaultVal;
      // @ts-expect-error - 2322 - mismatch between string and generic V
      if (ignoreKeys.includes(key)) return val;

      try {
        const wrapper: Timestamped<V> = JSON.parse(val);
        return wrapper.value;
      } catch (e) {
        // @ts-expect-error - 2322 - mismatch between string and generic V
        return val;
      }
    } catch (e) {
      return defaultVal;
    }
  },

  setItem<V = unknown>(
    key: string,
    value: V,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onError: null | ((e: DOMException) => void) = defaultErrorHandler,
  ): boolean {
    if (!isAvailable) return false;
    try {
      const timeStampedValue = {
        time: Date.now(),
        value,
      };
      window.localStorage.setItem(key, JSON.stringify(timeStampedValue));
      return true;
    } catch (e) {
      if (onError) {
        onError(e as DOMException);
        return this.setItem(key, value, null);
      }
    }
    return false;
  },

  removeItem(key: string): void {
    if (!isAvailable) return;
    try {
      window.localStorage.removeItem(key);
    } catch (e) {} // eslint-disable-line no-empty
  },
};

export default localStorageService;

// if localStorage is full then attempt to delete the oldest 25% of key values
function defaultErrorHandler(e: DOMException): void {
  if (e.name === "QuotaExceededError" || e.name === "NS_ERROR_DOM_QUOTA_REACHED") {
    // get all timestamped keys from storage
    const keys = localStorageService.getKeys((k) => !ignoreKeys.includes(k));
    keys
      // map keys into [key, value] tuples
      .map((k) => {
        const v = localStorageService.getItem(k, null);
        return [k, v || placeholder] as [string, Timestamped];
      })
      // sort oldest values to front of array
      .sort(([, a], [, b]) => a.time - b.time)
      // remap back down to keys now that we've sorted them
      .map(([k]) => k)
      // grab the oldest 25% of keys and delete them
      .slice(0, Math.floor(keys.length * 0.25))
      .forEach(localStorageService.removeItem);
  } else {
    sentry.error(e);
  }
}

export const _test = { defaultErrorHandler };
