import { apiRequest } from "./apiRequest";

type Flags = Record<string, true>;

const ONE_HOUR = 3600000; // 1000 ms * 60 s * 60 m

export const FeatureFlags = {
  SHOW_TALENT_SOURCING_EXTENSION: "show_talent_sourcing_extension",
  RECOMMENDED_GP_MODAL: "recommended_gp_modal",
  USE_MIXPANEL: "use_mixpanel",
  ANON_MIXPANEL: "anon_mixpanel",
  USE_JOB_TIMEZONE_FILTER: "use_job_timezone_filter",
  USE_MYDESK: "use_mydesk",
  USE_ORG_DESK: "use_org_desk",
  USE_ORG_DASHBOARD: "use_org_dashboard",
  OPENAI_INTEGRATION: "openai_integration",
  TAP_GENERATE_INTERVIEW_QUESTIONS: "tap_generate_interview_questions",
  COMMISSION_SPLIT: "commission_split",
  GENERATE_CANDIDATE_SIZZLE_NOTES: "generate_candidate_sizzle_notes", // TODO: Unused, remove FE and BE
  TAP_JOB_BOARD_MANAGEMENT: "tap_job_board_management", // TODO: Unused, remove FE and BE
  EX_UI_SERVER: "ex_ui_server",
  PERRY_AI_JOB_CREATE: "perry_ai_job_create",
  TAP_MYDESK_CANDIDATES: "tap_mydesk_candidates",
  TAP_NAV_SEARCH: "tap_nav_search", // TODO: Unused, remove FE and BE
  TAP_REPORTS_JOB_PIPELINES: "tap_reports_job_pipelines",
  TAP_PAYMENTS: "tap_payments",
  PAIR_SHARED_FEED: "pair_shared_feed", // TODO: Unused, remove FE and BE
  JOB_MATCHES_PREVIEW: "job_matches_preview",
  TAP_JOB_SUGGESTIONS: "tap_job_suggestions",
  PERRY_AUTO_RANK_PAIRS: "perry_auto_rank_pairs",
} as const;

export type FeatureFlagT = typeof FeatureFlags[keyof typeof FeatureFlags];

async function fetchFeatureFlags(userId?: number): Promise<Flags> {
  const { data } = await apiRequest.getMemoized<string[]>(
    userId ? `users/${userId}/feature-flags/` : "users/api/feature-flags/",
  );
  return data.length
    ? data.reduce((flags: Flags, flag) => {
        flags[flag] = true;
        return flags;
      }, {})
    : {};
}

class FeatureFlagsManager {
  flags: Flags = {};
  last_fetched = 0;

  async fetchFeatureFlags(): Promise<Flags> {
    this.flags = await fetchFeatureFlags();
    this.last_fetched = Date.now();
    return this.flags;
  }

  async getFeatureFlags(): Promise<Flags> {
    return Date.now() - this.last_fetched > ONE_HOUR ? this.fetchFeatureFlags() : this.flags;
  }

  async hasFeatureFlag(flag: FeatureFlagT): Promise<boolean> {
    const flags = await this.getFeatureFlags();
    return !!flags[flag];
  }
}

export const featureFlags = new FeatureFlagsManager();

// Usage of this function is currently only supported when the current user is a recruiter
// CAP and TAP users cannot use this function
export async function userHasFeatureFlag(userId: number, flag: FeatureFlagT): Promise<boolean> {
  const flags = await fetchFeatureFlags(userId);
  return !!flags[flag];
}
