import { userService } from "@talentpair/api";
import { UIPreferencesT } from "@talentpair/types/userService";
import * as React from "react";

type CapTourT = Extract<
  keyof UIPreferencesT,
  "onboarding_cap_profile" | "onboarding_cap_pair_list" | "onboarding_cap_pair_actions"
>;
type TapTourT = Extract<
  keyof UIPreferencesT,
  "onboarding_tap_profile" | "onboarding_tap_candidate_pass" | "onboarding_tap_navigation_ui"
>;

export type TourT = CapTourT | TapTourT;

export const BG_COLOR = {
  PINK: "#f3179a",
  YELLOW: "#f5a623",
  PURPLE: "#7f5991",
  BLUE: "#468ee5",
  GREEN: "#40b97e",
  YELLOW_2: "#ffb302",
  GREEN_2: "#02ad69",
  BLUE_2: "#366bf4",
};

export interface SlideT {
  background: typeof BG_COLOR[keyof typeof BG_COLOR];
  children?: React.ReactNode;
  img: string;
  text: React.ReactNode;
}

// Provide the order a user should see tours. This really only matters if more than one can show up
// on a page, but it's helpful to think what order they would appear in the happy path.
// i.e. if more than one of these tours could appear on a page, which should they see first?
const script: Record<string, TourT[]> = {
  Candidate: ["onboarding_cap_profile", "onboarding_cap_pair_actions", "onboarding_cap_pair_list"],
  Employer: [
    "onboarding_tap_profile",
    "onboarding_tap_navigation_ui",
    "onboarding_tap_candidate_pass",
  ],
};

export function getNextTour(availableTours: TourT[]): TourT | undefined {
  // Get tours for the type of user they are.
  const userType = userService.userType();
  const tours = userType ? script[userType] || [] : [];
  // Return first item they haven't seen and is available on the current page.
  return tours.find(
    (tour) => !userService.settings.ui_preferences[tour] && availableTours.includes(tour),
  );
}
