import * as React from "react";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import lodash from "lodash";
import ReactAutosuggest, { AutosuggestPropsSingleSection, InputProps } from "react-autosuggest";
import { Popper, Input } from "@mui/material";
import { SearchIconAdornment, CloseButtonAdornment, colors } from "@talentpair/quantic";
import { preventDefault, stopPropagation } from "../../utils/events";
import { getViewportHeight, isBottomHalfOfViewport } from "../../utils/misc";
import {
  defaultCreateSuggestionValue,
  defaultGetSuggestionValue,
  getQueryFromValue,
  Suggestion as DefaultSuggestion,
} from "./helpers";
import {
  CreateSuggestionValueT,
  FetchRequestedT,
  FetchT,
  GetSuggestionValueT,
  OnKeyDownT,
  OnQueryChangeT,
  OnSuggestionSelectedT,
  RenderInputContainerT,
  SuggestionPropsT,
  RenderSuggestionT,
} from "./types";

const RoundInput = withStyles((theme) => ({
  root: {
    borderRadius: 30,
  },
  focused: {
    borderRadius: 30,
    boxShadow: theme.shadows[1],
  },
}))(Input);

const AppbarInput = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: colors.green[200],
    border: "none",
  },
  focused: {
    borderRadius: 0,
    boxShadow: theme.shadows[1],
    backgroundColor: "white",
    border: "none",
  },
}))(Input);

export interface AutocompletePropsT<V>
  extends Omit<
    AutosuggestPropsSingleSection<V>,
    "inputProps" | "onSuggestionsFetchRequested" | "onSuggestionSelected"
  > {
  alwaysRenderSuggestions?: boolean;
  autoFocus?: boolean;
  canCreate?: boolean | ((query: string) => string);
  classes: {
    [key: string]: string;
  };
  clearSuggestions: () => void;
  createSuggestionValue: CreateSuggestionValueT<V>;
  disabled?: boolean;
  enterSubmit?: boolean;
  fullHeight?: boolean;
  getSuggestionValue: GetSuggestionValueT<V>;
  id: string;
  inputProps?: {
    onKeyDown?: OnKeyDownT;
    classes?: Record<string, unknown>;
    startAdornment?: React.ReactNode;
  } | null;
  inputRef?: ((el: HTMLInputElement) => void) | null;
  name: string;
  onBlur?: (event: React.FocusEvent) => void;
  // `onChange` is called to propagate the control's new value to a parent component, while `onQueryChange` updates the
  // autocomplete's internal `state.value`.
  onChange?: (value: (V | null) | string) => void | null;
  onInvalidSelection?: (value: string) => void | null;
  onQueryCleared?: null | (() => void);
  onSuggestionSelected?: (suggestion: V) => void | null;
  onSuggestionsFetchRequested: FetchRequestedT;
  placeholder?: string | null;
  renderInputContainer?: RenderInputContainerT | null;
  selectedValues?: V[];
  selectOnTab?: boolean;
  showErrors?: boolean;
  Suggestion: React.ComponentType<SuggestionPropsT<V>>;
  suggestions: V[];
  value?: V | string | null;
  viewport?: HTMLElement | null;
  variant?: "default" | "round" | "appbar";
  showSearchIcon?: boolean;
  showClearButton?: boolean;
}

interface StateT<V> {
  query: string;
  suggestions: V[];
  suggestionsVisible: boolean;
  hasFetchedSuggestions: boolean;
  forceOpen: boolean;
  props: AutocompletePropsT<V>;
}

class Autocomplete<V> extends React.Component<AutocompletePropsT<V>, StateT<V>> {
  inputEl: HTMLInputElement | null = null;
  inputContainer: HTMLElement | null = null;
  highlightedSuggestion: V | null = null;
  lastSelectedSuggestion: V | null = null;
  finalSuggestions: V[] = [];
  hasRenderedSuggestions = false;
  hasQueryChanges = false;
  positionClass = "";
  needsRefreshSuggestions = false;
  createVal: string | null = null;

  static defaultProps = {
    alwaysRenderSuggestions: false,
    showErrors: true,
    fullHeight: false,
    placeholder: null,
    canCreate: false,
    Suggestion: DefaultSuggestion,
    getSuggestionValue: defaultGetSuggestionValue,
    inputProps: null,
    createSuggestionValue: defaultCreateSuggestionValue,
    renderInputContainer: null,
    onChange: null,
    onBlur: null,
    onInvalidSelection: null,
    onSuggestionSelected: null,
    shouldRenderSuggestions: lodash.identity,
    selectOnTab: true,
    autoFocus: false,
    disabled: false,
    suggestions: [],
    value: "",
    handlers: {},
    viewport: null,
    inputRef: null,
    onQueryCleared: null,
    selectedValues: null,
    enterSubmit: true,
    variant: "default",
    showSearchIcon: false,
    showClearButton: false,
  };

  constructor(props: AutocompletePropsT<V>) {
    super(props);

    const { value, getSuggestionValue, suggestions } = props;
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      query: getQueryFromValue(value || null, getSuggestionValue),
      suggestions, // Kept in state only to enable `forceOpen` functionality. See that method.
      suggestionsVisible: true, // doesn't make sense to ever initialize to false
      hasFetchedSuggestions: false,
      forceOpen: false,
      props,
    };
  }

  static getDerivedStateFromProps(
    nextProps: AutocompletePropsT<unknown>,
    prevState: StateT<unknown>,
  ): Partial<StateT<unknown>> {
    let stateObj: Partial<StateT<unknown>> = { props: nextProps };
    if (nextProps?.value !== prevState.props.value) {
      if (typeof nextProps.value !== "string") {
        stateObj.query = getQueryFromValue(nextProps.value || null, nextProps.getSuggestionValue);
      }
    }
    if (
      !lodash.isEqual(nextProps.suggestions, prevState.props.suggestions) ||
      prevState.forceOpen
    ) {
      stateObj = {
        ...stateObj,
        suggestions: nextProps.suggestions,
        suggestionsVisible: true,
        forceOpen: false,
      };
    }
    return stateObj;
  }

  onQueryChange: OnQueryChangeT = (e, { newValue, method }) => {
    if (method !== "type") return;

    this.hasQueryChanges = true;
    this.setState({ query: newValue });
    if (!newValue) {
      const { onQueryCleared } = this.props;
      if (onQueryCleared) onQueryCleared();
      this.clearSuggestions();
    }
  };

  onSuggestionsFetchRequested = ({ value, reason }: FetchT): void => {
    // Only fetch on focus if suggestions haven't been fetched before. Fetching again is redundant since they would
    // have been fetched the last time the query changed.
    if (reason === "input-changed" || reason === "input-focused") {
      this.needsRefreshSuggestions = false;
      this.props.onSuggestionsFetchRequested(value).then(() => {
        this.setState({ hasFetchedSuggestions: true });
      });
    }
  };

  onInvalidSelection(query: string): void {
    const { onChange, onInvalidSelection, showErrors } = this.props;
    if (!showErrors) return;
    const fn = onInvalidSelection || onChange;
    if (fn) fn(query);
  }

  // See note in `onKeyDown`
  onSuggestionHighlighted = ({ suggestion }: { suggestion: V }): void => {
    this.highlightedSuggestion = suggestion;
  };

  onSuggestionSelected: OnSuggestionSelectedT<V> = (e, selection) => {
    const {
      getSuggestionValue,
      createSuggestionValue,
      value,
      onChange,
      onSuggestionSelected,
      onQueryCleared,
    } = this.props;
    this.hasQueryChanges = false;
    const createVal = !selection.suggestionIndex ? this.createVal : null;
    const { suggestion } = selection;
    const normalizedSuggestion = createVal ? createSuggestionValue(createVal) : suggestion;

    // Autocomplete Multi
    if (onSuggestionSelected) {
      onSuggestionSelected(normalizedSuggestion);
      this.setState({ query: "" });
      if (onQueryCleared) onQueryCleared();
      return;
    }

    // Autocomplete Single
    this.lastSelectedSuggestion = suggestion;
    const suggestionVal = getSuggestionValue(normalizedSuggestion);

    this.setState({
      query: suggestionVal,
      suggestionsVisible: false,
    });
    // Only call onChange if the new suggestion value is different than the current value of the field
    if (
      onChange &&
      (!value || typeof value === "string" || suggestionVal !== getSuggestionValue(value))
    ) {
      // Since the query has changed to the full text of the selected suggestion, the suggestions are now obsolete.
      // Rather than fetching immediately (which would potentially be wasteful, since the user may never need to see
      // the suggestions again), we flag them as needing to be refreshed should the user take an action that requires
      // us to show them again but that wouldn't usually trigger a suggestion refresh (currently, this is just when the
      // user uses the up/down arrows. The other way of getting suggestions to show is to start typing, but that would
      // naturally trigger a suggestion refetch anyway since the query is changing).
      this.needsRefreshSuggestions = true;
      onChange(normalizedSuggestion);
    }
  };

  onBlur = (e: React.FocusEvent, { highlightedSuggestion }: { highlightedSuggestion: V }): void => {
    const { onChange, selectOnTab, onBlur, value } = this.props;
    if (onBlur) onBlur(e);
    if (!this.hasQueryChanges || !selectOnTab) return;

    if (this.state.query) {
      if (highlightedSuggestion && (this.props.canCreate || this.isQueryEqualToFirstSuggestion())) {
        this.onSuggestionSelected(e, { suggestion: highlightedSuggestion });
      } else {
        this.onInvalidSelection(this.state.query);
      }
    } else {
      this.clearSuggestions();
      // Only propagate null if value isn't already null
      if (onChange && value != null && value !== "") onChange(null);
    }
  };

  onKeyDownCapture = (e: React.KeyboardEvent): void => {
    const { suggestions, suggestionsVisible, query } = this.state;
    const { onSuggestionSelected, onChange, getSuggestionValue, selectOnTab, enterSubmit } =
      this.props;
    // ArrowUp/Down shows suggestions if they aren't visible and allows cycling from bottom to top
    // of list and vice versa. It doesn't look like React Autosuggest wants to implement this
    // cycling behavior themselves: https://github.com/moroshko/react-autowhatever/issues/32.
    switch (e.key) {
      case "ArrowUp":
      case "ArrowDown":
        e.preventDefault();

        if (suggestionsVisible) {
          const lastSuggestion =
            e.key === "ArrowUp" ? this.finalSuggestions[0] : lodash.last(this.finalSuggestions);
          if (lastSuggestion === this.highlightedSuggestion) e.stopPropagation();
        } else {
          e.stopPropagation();
          if (this.needsRefreshSuggestions) {
            this.onSuggestionsFetchRequested({ value: this.state.query, reason: "input-changed" });
          } else {
            this.setState({ suggestionsVisible: true });
          }
        }

        break;

      case "Escape": {
        this.setState({ suggestionsVisible: false });
        // If query is empty, reset it to the original value
        if (!query && !onSuggestionSelected && this.lastSelectedSuggestion) {
          this.hasQueryChanges = false;
          this.setState({ query: getSuggestionValue(this.lastSelectedSuggestion) });
        }
        break;
      }

      case "Enter":
        // If suggestions aren't visible, user can expect to submit on enter like a normal input field
        if ((suggestionsVisible && suggestions.length) || !enterSubmit) e.preventDefault(); // `Enter` suggests that the user wants to select the currently highlighted suggestion,
        // hence the `isSelectionValid()` check rather than an `isQueryEqualToFirstSuggestion()` check

        if (this.isSelectionValid()) break;

        if (query.trim()) {
          e.preventDefault(); // Always block submit on enter here
          e.stopPropagation();
          const queryLower = query.toLowerCase();
          const suggestionExists = suggestions.some(
            (s) => getSuggestionValue(s).toLowerCase() === queryLower,
          );
          if (!suggestionExists) this.onInvalidSelection(query);
        } else if (onChange) {
          e.preventDefault(); // Always block submit on enter here
          onChange(null);
        }

        break;

      case "Tab":
        // A tab key press is considered a stronger indication of a desire to select the currently
        // highlighted suggestion. Therefore, it will succeed in selecting the item if
        // `this.isSelectionValid()` -- basically, if there are suggestions and the suggestions are
        // visible (the final `this.highlightedSuggestion` check below is just to appease flow).
        // This is more permissive than `onBlur`, which requires the query to equal the full text of the
        // first suggestion: https://bit.ly/2Y46uQG
        if (selectOnTab && query && this.isSelectionValid() && this.highlightedSuggestion) {
          this.onSuggestionSelected(e, { suggestion: this.highlightedSuggestion });
        }

        break;

      default:
    }
  };

  forceOpen = (): void => {
    // This is a hack for forcing the autocomplete to open back up on click. In fact, it's currently the only
    // reason this maintains its own internal `suggestions` state.
    // Hopefully this will become unnecessary: https://goo.gl/jBZkDg
    // Update 2020: TROLOLOLOLOLOL this will never be fixed. The maintainer of react-autosuggest is not receptive to this issue.
    this.setState((prevState) => ({
      suggestions: prevState.suggestions.map((s) => ({ ...s })),
      forceOpen: true,
    }));
  };

  clearSuggestions(): void {
    this.setState({ hasFetchedSuggestions: false });
    this.props.clearSuggestions();
  }

  isQueryEqualToFirstSuggestion(): boolean {
    const { suggestions } = this.state;
    const { getSuggestionValue } = this.props;
    return suggestions.length
      ? this.state.query.toLowerCase() === getSuggestionValue(suggestions[0]).toLowerCase()
      : false;
  }

  isSelectionValid(): boolean {
    const { query, suggestions, suggestionsVisible } = this.state;
    const { getSuggestionValue } = this.props;
    if (this.props.canCreate) return true;
    if (suggestionsVisible && suggestions.length) {
      // verifying that the currently highlighted suggestion matches query to handle instance where
      // user types a garbage query that doesn't match any suggestion and presses "enter" or "tab"
      // such that onKeyDownCapture is called before the suggestions are cleared out
      if (
        this.highlightedSuggestion &&
        getSuggestionValue(this.highlightedSuggestion).toLowerCase().includes(query.toLowerCase())
      )
        return true;
      return false;
    }
    // Even if the suggestions aren't visible, if the text exactly matches the first suggestion,
    // select it.
    return this.isQueryEqualToFirstSuggestion();
  }

  renderSuggestionsContainer = ({
    containerProps,
    children,
  }: {
    containerProps: Record<string, unknown>;
    children: React.ReactNode;
  }): React.ReactNode => {
    const { suggestions, suggestionsVisible, query, hasFetchedSuggestions } = this.state;
    const { fullHeight, classes, viewport, Suggestion, variant, canCreate, createSuggestionValue } =
      this.props;

    if (
      !children &&
      this.hasQueryChanges &&
      query &&
      hasFetchedSuggestions &&
      suggestionsVisible &&
      document.activeElement === this.inputEl
    ) {
      // MEGAHACK
      children =
        typeof canCreate === "function" ? (
          this.renderSuggestion(createSuggestionValue(canCreate(query), query), {
            query,
            isHighlighted: false,
          })
        ) : (
          <Suggestion
            onMouseLeave={stopPropagation}
            suggestionValue={<span className="grey-600">No options</span>}
            className={variant === "round" ? "px3 py0" : undefined}
            isHighlighted={false}
            query=""
          />
        );
    } else if (
      !suggestionsVisible ||
      !children || // Prevent the `Create...` suggestion from appearing when simply clicking back into the input field when it has text.
      // For example, create a new skill in CAP profile and save it. Then click edit pencil and click into the skill autocomplete.
      // Without the following line, "Create..." will immediately appear.
      (!suggestions.length && !this.hasQueryChanges) || // No need to render suggestions if the only option matches what we already have in the text field
      (suggestions.length === 1 && this.isQueryEqualToFirstSuggestion() && !this.hasQueryChanges)
    ) {
      this.hasRenderedSuggestions = false;
      return null;
    }

    // Only recompute `positionClass` if the suggestions weren't previously visible. This prevents them from
    // potentially "flipping" from top to bottom as the user interacts with them.
    if (!this.hasRenderedSuggestions) {
      this.hasRenderedSuggestions = true;
      this.positionClass = isBottomHalfOfViewport(this.inputContainer, viewport)
        ? "bottom-100"
        : "top-100";
    }

    // On mobile it's easy for this to be too big, and in a modal that needs scrolling to we
    // can't allow it to overflow
    const maxHeightBasedOnViewport = Math.floor(getViewportHeight(viewport) / 2.5);
    const maxHeight = 400; // Standard suggestions are 40px tall
    return (
      <Popper
        anchorEl={this.inputContainer}
        open={!!children}
        placement={this.positionClass === "bottom-100" ? "top" : "bottom"}
        style={{
          zIndex: 2000,
          width: this.inputContainer ? this.inputContainer.clientWidth : 0,
        }}
      >
        <div
          {...containerProps}
          className={clsx(
            "col-12 bg-white overflow-y-auto box-shadow1",
            variant === "appbar" && "br0 lt-grey-border-top",
            classes.suggestionsContainer,
            !fullHeight
              ? ["absolute", this.positionClass, classes.suggestionsContainerShadow]
              : null,
          )}
          style={
            !fullHeight
              ? {
                  ...(variant !== "default"
                    ? {
                        borderRadius: 20,
                        overflow: "hidden",
                        // offset suggestions 10px from input (either above or below)
                        transform: `translateY(${
                          this.positionClass === "bottom-100" ? -10 : 10
                        }px)`,
                      }
                    : undefined),
                  zIndex: 1200,
                  maxHeight: Number.isNaN(maxHeightBasedOnViewport)
                    ? maxHeight
                    : Math.min(maxHeightBasedOnViewport, maxHeight),
                }
              : undefined
          }
        >
          {children}
        </div>
      </Popper>
    );
  };

  renderSuggestion = (suggestion: V, renderProps: RenderSuggestionT): React.ReactNode => {
    const { Suggestion, getSuggestionValue, selectedValues, variant } = this.props;
    return (
      <Suggestion
        onMouseLeave={stopPropagation}
        suggestion={suggestion}
        suggestionValue={getSuggestionValue(suggestion)}
        selected={
          selectedValues
            ? selectedValues.some((v) => getSuggestionValue(v) === getSuggestionValue(suggestion))
            : false
        }
        {...renderProps}
        className={variant !== "default" ? "px3 py0" : undefined}
        onMouseDown={preventDefault} // Not sure why this is necessary, but without it the first suggestion gets highlighted onMouseDown
      />
    );
  };

  renderInputComponent = ({
    size, // pulling off 'size' as it doesn't match the mui props (probably will never be passed really)
    ...props
  }: Omit<InputProps<V>, "color" | "onChange"> & {
    onChange: (event: React.ChangeEvent) => void;
  }): React.ReactNode => {
    const {
      id,
      name,
      placeholder,
      classes,
      variant,
      renderInputContainer,
      inputRef,
      showSearchIcon,
      showClearButton,
    } = this.props;
    const Cmp = variant === "round" ? RoundInput : variant === "appbar" ? AppbarInput : Input;
    const input = (
      <Cmp
        id={id}
        name={name}
        fullWidth
        placeholder={placeholder || undefined}
        inputRef={(el) => {
          this.inputEl = el;
          if (inputRef) inputRef(el);
        }}
        data-testid={`${name}Autocomplete`}
        {...props}
        disableUnderline={variant !== "default"}
        inputProps={{
          autoComplete: "new-password",
          // @ts-expect-error - I dunno
          className: clsx(props.inputProps?.className, variant === "appbar" && "dark-placeholder"),
        }}
        // Use capture phase to avoid overwriting bubbling phase methods that already exist on `input`
        onKeyDownCapture={this.onKeyDownCapture}
        onClick={this.forceOpen}
        {...(showSearchIcon ? { startAdornment: <SearchIconAdornment /> } : null)}
        {...(showClearButton
          ? {
              endAdornment: (
                <CloseButtonAdornment
                  disabled={!this.state.query}
                  className={!this.state.query ? "visibility-hidden" : undefined}
                  aria-label="clear search"
                  onClick={(e) => this.onQueryChange(e, { newValue: "", method: "type" })}
                  data-testid="clearSearchBtn"
                />
              ),
            }
          : null)}
      />
    );
    return (
      <div
        ref={(el) => {
          this.inputContainer = el;
        }}
        className={clsx("flex", classes.inputContainer)}
      >
        {renderInputContainer ? renderInputContainer(input) : input}
      </div>
    );
  };

  render(): React.ReactNode {
    const { query, suggestions, hasFetchedSuggestions } = this.state;
    const { canCreate, autoFocus, disabled, fullHeight, alwaysRenderSuggestions, inputProps } =
      this.props;
    const onKeyDown = inputProps ? inputProps.onKeyDown : null;

    // Potentially show a `Create ...` suggestion if the current text doesn't match a suggestion
    // `hasFetchedSuggestions` prevents briefly rendering `Create ...` before any suggestions have
    // come back
    const trimmedQuery = query.trim();
    if (
      canCreate &&
      trimmedQuery &&
      hasFetchedSuggestions &&
      !this.isQueryEqualToFirstSuggestion()
    ) {
      const label =
        typeof canCreate === "function" ? canCreate(trimmedQuery) : `Create "${trimmedQuery}"`;
      if (label) {
        const newSuggestion = this.props.createSuggestionValue(label, trimmedQuery);
        this.createVal = trimmedQuery;
        this.finalSuggestions = [newSuggestion, ...suggestions];
      } else {
        this.createVal = null;
        this.finalSuggestions = suggestions;
      }
    } else {
      this.createVal = null;
      this.finalSuggestions = suggestions;
    }

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div className={!fullHeight ? "relative" : undefined} onKeyDown={stopPropagation}>
        {/* @ts-expect-error - material-ui InputProps are incompatible with React-Autosuggest InputProps */}
        <ReactAutosuggest
          suggestions={this.finalSuggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={lodash.noop}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.props.getSuggestionValue}
          shouldRenderSuggestions={this.props.shouldRenderSuggestions}
          renderSuggestion={this.renderSuggestion}
          inputProps={{
            value: query,
            onChange: this.onQueryChange,
            onBlur: this.onBlur,
            autoFocus,
            disabled,
            ...inputProps,
            onKeyDown: onKeyDown ? (e: React.KeyboardEvent) => onKeyDown(e, query) : undefined,
          }}
          renderInputComponent={this.renderInputComponent}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          onSuggestionHighlighted={this.onSuggestionHighlighted}
          alwaysRenderSuggestions={alwaysRenderSuggestions}
          highlightFirstSuggestion
        />
      </div>
    );
  }
}

export default withStyles((theme) => ({
  suggestionsContainer: {
    "& ul": {
      margin: 0,
      padding: 0,
      listStyle: "none",
      cursor: "pointer",
    },
  },
  suggestionsContainerShadow: {
    boxShadow: theme.shadows[3],
  },
  inputContainer: {}, // Expose class name for theming by wrapping components
}))(Autocomplete);

export const _test = { Autocomplete };
