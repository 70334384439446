import * as React from "react";
import { ListItemAvatar } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { ComposeProps, MappedInterface } from "@talentpair/types/utils";
import { GreenIconAvatar } from "kyoto/components/ui/Avatars";
import {
  ListItem,
  ListItemPropsT,
  ListItemText,
  ListItemTextPropsT,
} from "kyoto/components/ui/List";

export type DataListItemProps<D extends React.ElementType = "li"> = ComposeProps<
  MappedInterface<ListItemTextPropsT>,
  "primary" | "secondary",
  "ListItemTextProps"
> &
  ListItemPropsT<D> & {
    title?: string;
    avatar?: React.ReactElement;
    dense?: boolean;
  };

export default function DataListItem({
  primary,
  secondary,
  ListItemTextProps,
  avatar,
  title,
  children,
  dense = false,
  ...props
}: DataListItemProps): React.ReactElement {
  return (
    <ListItem {...props}>
      <ListItemAvatar>{avatar || <GreenIconAvatar icon={DescriptionIcon} />}</ListItemAvatar>
      <ListItemText
        classes={
          dense ? { primary: "line-height-initial", secondary: "line-height-initial" } : undefined
        }
        primary={
          <>
            {title && <h5 className="grey-500 mt0 mb1">{title}</h5>}
            <div>{primary}</div>
          </>
        }
        secondary={secondary}
        {...ListItemTextProps}
      />
      {children}
    </ListItem>
  );
}
