import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  Fab,
  FabProps,
  IconButton as MuiIconButton,
  IconButtonProps,
  SvgIcon,
  SvgIconProps,
  Tooltip,
  Theme,
} from "@mui/material";
import lodash from "lodash";
import { withStyles } from "@mui/styles";
import { darken } from "@mui/material/styles";
import { getLinkedInUrl, getGoogleLoginUrl } from "@talentpair/env";
import { ElementType, HTMLAttributes, ReactElement, ReactNode } from "react";
import clsx from "clsx";
import { Button, ButtonProps } from "./atoms/Button";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function customRaisedStyles(backgroundColor: string, color: string, variant: string) {
  return withStyles(
    // Merging with ButtonStyles so that all keys are still available for 'classes' prop
    (theme: Theme) =>
      lodash.merge(
        { root: {} },
        {
          [variant]: {
            backgroundColor,
            // `color` allows for an easy override, otherwise use the default contrast calculation
            color: color || theme.palette.getContrastText(backgroundColor),
            "&:hover": {
              // Replicates what ultimately gets set as `dark` value in Material UI themes
              backgroundColor: darken(backgroundColor, 0.3),
              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": { backgroundColor },
            },
          },
        },
      ),
  );
}
interface StyleT {
  color: string;
  backgroundColor: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const customFabStyles = ({ backgroundColor, color }: StyleT) =>
  customRaisedStyles(backgroundColor, color, "root")(Fab);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const customButtonStyles = ({ backgroundColor, color }: StyleT) =>
  customRaisedStyles(backgroundColor, color, "contained")(MuiButton);

export type PrimaryButtonProps<D extends ElementType> = MuiButtonProps<D, { component?: D }>;

export const PrimaryButton = <D extends ElementType>(
  props: PrimaryButtonProps<D>,
): ReactElement => <MuiButton color="primary" variant="contained" {...props} />;

export const FlatButton = ({ className, ...props }: ButtonProps): ReactElement => (
  <Button {...props} className={clsx("uppercase", className)} />
);

export type BusyButtonProps<D extends ElementType> = Omit<
  MuiButtonProps<D, { component?: D }>,
  "disabled"
> & {
  isBusy?: boolean;
};
export const BusyButton = <D extends ElementType>({
  isBusy = false,
  className,
  ...props
}: BusyButtonProps<D>): ReactElement => (
  <div className={className} style={{ display: "table" }}>
    <div className="relative flex justify-center items-center">
      <PrimaryButton {...props} disabled={isBusy} />
      {isBusy && <CircularProgress size={24} className="absolute" />}
    </div>
  </div>
);

export const PrimaryFab = (props: FabProps): ReactElement => <Fab color="primary" {...props} />;

export const IconButton = ({
  label,
  tooltip = false,
  ...props
}: IconButtonProps & { label: string; tooltip?: boolean }): ReactElement => {
  const btn = <MuiIconButton aria-label={label} color="primary" {...props} />;
  return tooltip ? (
    <Tooltip title={label}>
      <span>{btn}</span>
    </Tooltip>
  ) : (
    btn
  );
};

export interface TextButtonPropsT extends HTMLAttributes<HTMLButtonElement> {
  className?: string;
  inheritFontSize?: boolean;
}
export const TextButton = ({
  className = "green-700",
  inheritFontSize = true,
  ...props
}: TextButtonPropsT): ReactElement => (
  <button
    className={clsx("bg-transparent border-none p0 pointer", className, {
      "font-size-inherit": inheritFontSize,
    })}
    type="button"
    {...props}
  />
);

export const SubmitButton = <D extends ElementType>({
  children = "Submit",
  type = "submit",
  ...props
}: PrimaryButtonProps<D>): ReactElement => (
  <PrimaryButton type={type} data-testid="submitBtn" tabIndex={0} {...props}>
    {children}
  </PrimaryButton>
);

export const CancelButton = ({
  children = "Cancel",
  type = "button",
  ...props
}: MuiButtonProps): ReactElement => (
  <MuiButton type={type} data-testid="cancelBtn" color="inherit" tabIndex={0} {...props}>
    {children}
  </MuiButton>
);

export const LinkedInLogo = (props: SvgIconProps): ReactElement => (
  <SvgIcon fontSize="inherit" titleAccess="LinkedIn Icon" {...props}>
    <defs>
      <rect id="b" width="348" height="40" rx="2" />
      <filter
        id="a"
        width="101.1%"
        height="112.5%"
        x="-.6%"
        y="-5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation=".5" />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-40 -309)">
      <path fill="#FFF" d="M0 0H412V732H0z" />
      <g transform="translate(32 300.5)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#0077B5" xlinkHref="#b" />
      </g>
      <rect width="38" height="38" x="33" y="301.5" fill="#FFF" rx="1" />
      <path
        fill="#0077B5"
        fillRule="nonzero"
        d="M60.447 329.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667h-3.554V318h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286h-.001zm-15.11-13.019a2.062 2.062 0 0 1-2.063-2.065 2.064 2.064 0 1 1 2.063 2.065zm1.782 13.019h-3.564V318h3.564v11.452zM62.225 309H41.771c-.979 0-1.771.774-1.771 1.729v20.542c0 .956.792 1.729 1.771 1.729h20.451c.978 0 1.778-.773 1.778-1.729v-20.542c0-.955-.8-1.729-1.778-1.729h.003z"
      />
    </g>
  </SvgIcon>
);

export const GoogleLogo = (props: SvgIconProps): ReactElement => (
  <SvgIcon fontSize="inherit" titleAccess="Google Icon" {...props}>
    <defs>
      <rect id="b" width="348" height="40" rx="2" />
      <filter
        id="a"
        width="101.1%"
        height="112.5%"
        x="-.6%"
        y="-5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation=".5" />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-40 -252)">
      <path fill="#FFF" d="M0 0H412V732H0z" />
      <g transform="translate(32 244)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#4285F4" xlinkHref="#b" />
      </g>
      <rect width="38" height="38" x="33" y="245" fill="#FFF" rx="1" />
      <g transform="translate(43 255)">
        <path
          fill="#4285F4"
          d="M17.64 9.205c0-.639-.057-1.252-.164-1.841H9v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z"
        />
        <path
          fill="#34A853"
          d="M9 18c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332A8.997 8.997 0 0 0 9 18z"
        />
        <path
          fill="#FBBC05"
          d="M3.964 10.71A5.41 5.41 0 0 1 3.682 9c0-.593.102-1.17.282-1.71V4.958H.957A8.996 8.996 0 0 0 0 9c0 1.452.348 2.827.957 4.042l3.007-2.332z"
        />
        <path
          fill="#EA4335"
          d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0A8.997 8.997 0 0 0 .957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58z"
        />
        <path d="M0 0L18 0 18 18 0 18z" />
      </g>
      <g transform="translate(40 252)">
        <path
          fill="#4285F4"
          d="M20.64 12.205c0-.639-.057-1.252-.164-1.841H12v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z"
        />
        <path
          fill="#34A853"
          d="M12 21c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H3.957v2.332A8.997 8.997 0 0 0 12 21z"
        />
        <path
          fill="#FBBC05"
          d="M6.964 13.71A5.41 5.41 0 0 1 6.682 12c0-.593.102-1.17.282-1.71V7.958H3.957A8.996 8.996 0 0 0 3 12c0 1.452.348 2.827.957 4.042l3.007-2.332z"
        />
        <path
          fill="#EA4335"
          d="M12 6.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C16.463 3.891 14.426 3 12 3a8.997 8.997 0 0 0-8.043 4.958l3.007 2.332C7.672 8.163 9.656 6.58 12 6.58z"
        />
        <path d="M0 0L24 0 24 24 0 24z" />
      </g>
    </g>
  </SvgIcon>
);

const sharedLabelStyle = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
};

const withGoogleStyle = withStyles({
  root: {
    backgroundColor: "#4285f4",
    color: "white",
    height: 40,
    padding: "1px",
    "&.Mui-focused": {
      backgroundColor: "#3d74e8",
    },
    "&:hover": {
      backgroundColor: "#3d74e8",
    },
    "&.Mui-active": {
      backgroundColor: "#3367d6",
    },
  },
  label: sharedLabelStyle,
});

const withLinkedInStyle = withStyles({
  root: {
    backgroundColor: "#0077b5",
    color: "white",
    height: 40,
    padding: "1px",
    "&.Mui-focused": {
      backgroundColor: "#0369a0",
    },
    "&:hover": {
      backgroundColor: "#0369a0",
    },
    "&.Mui-active": {
      backgroundColor: "#006093",
    },
  },
  label: sharedLabelStyle,
});

const iconWrapperStyle = {
  flexGrow: 0,
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  height: 38,
  width: 38,
  borderRadius: 4,
  marginRight: "1em",
};

interface OauthButtonT extends MuiButtonProps {
  icon: ReactNode;
}
export const OauthButton = ({
  classes = {},
  className = "",
  children,
  href = "",
  icon = null,
  ...props
}: OauthButtonT): ReactElement => (
  <MuiButton
    {...props}
    variant="contained"
    classes={{ ...classes, root: `${classes.root || ""} ${className}` }}
    href={href}
  >
    <div style={iconWrapperStyle}>{icon}</div>
    <div className="flex-grow flex justify-center bold pr3">{children}</div>
  </MuiButton>
);

const BaseLinkedInButton = withLinkedInStyle(OauthButton);

export interface LinkedInButtonProps extends Omit<OauthButtonT, "icon"> {
  login?: boolean;
  passAlongState?: string | null;
}
export const LinkedInButton = ({
  href = "",
  login = false,
  passAlongState = null,
  ...props
}: LinkedInButtonProps): ReactElement => (
  <BaseLinkedInButton
    {...props}
    href={
      href ||
      getLinkedInUrl(login, {
        state: passAlongState ? `${process.env.HOST};${passAlongState}` : process.env.HOST,
      })
    }
    icon={<LinkedInLogo />}
  />
);

const BaseGoogleButton = withGoogleStyle(OauthButton);

interface GoogleButtonProps extends Omit<OauthButtonT, "icon"> {
  passAlongState?: string | null;
}
export const GoogleButton = ({
  href = "",
  passAlongState = null,
  ...props
}: GoogleButtonProps): ReactElement => (
  <BaseGoogleButton
    {...props}
    href={
      href ||
      getGoogleLoginUrl({
        state: passAlongState ? `${process.env.HOST};${passAlongState}` : process.env.HOST,
      })
    }
    icon={<GoogleLogo />}
  />
);
