// @flow
import { userService } from "@talentpair/api";
import lodash from "lodash";
import { createSelector, defaultMemoize } from "@talentpair/redux";
import { TAP_STATUS } from "@talentpair/entities/job";
import type { TapMicroUserT } from "@talentpair/types/tap/entities/user";
import type { MicroUserWithRoleT } from "@talentpair/types/entities/user";
import type { TapJobReduxT } from "@talentpair/types/tap/entities/job";
import type { StateT } from "../../store";
import type { JobsReduxT } from "./jobReducers";
import { getOrgJobs, getOrgUserIds } from "./orgSelectors";

export const getJob = ({ tap }: StateT, id: number): ?TapJobReduxT => tap.jobs[id];
export const isJobActive = ({ tap }: StateT, id: number): boolean =>
  !!tap.jobs[id] && tap.jobs[id].tap_status.id !== TAP_STATUS.CLOSED;

const getJobsById = ({ tap }: StateT): JobsReduxT => tap.jobs;

export const getJobs = createSelector<StateT, number, TapJobReduxT[], JobsReduxT, ?(number[])>(
  [getJobsById, getOrgJobs],
  (jobs, jobIds) =>
    jobIds ? jobIds.reduce((acc, id) => (jobs[id] != null ? [...acc, jobs[id]] : acc), []) : null,
);
export const getActiveJobs = createSelector<StateT, number, TapJobReduxT[], _>(
  [getJobs],
  (jobs) => (jobs && jobs.filter((job) => job.tap_status.id !== TAP_STATUS.CLOSED)) || [],
);

export const getHiringManager = createSelector<StateT, number, ?TapMicroUserT, _>([getJob], (job) =>
  job && job.hiring_manager ? job.hiring_manager : null,
);
export const getHiringTeam = createSelector<StateT, number, TapMicroUserT[], _>([getJob], (job) =>
  job && job.hiring_team ? job.hiring_team : [],
);
export const getGrowthPartners = createSelector<StateT, number, MicroUserWithRoleT[], _>(
  [getJob],
  (job) => (job && job.owners ? job.owners : []),
);

export const getHiringTeamMember = createSelector<StateT, number, (number) => ?TapMicroUserT, _, _>(
  [getHiringManager, getHiringTeam],
  (manager, team) =>
    lodash.memoize((member: number) =>
      [manager, ...team].filter(Boolean).find(({ id }) => id === member),
    ),
);

const getJobUserIds = createSelector<StateT, number, number[], _, _>(
  [getHiringManager, getHiringTeam],
  (hm, team) => [hm && hm.id, ...team.map(({ id }) => id)].filter(Boolean),
);

export const getAvailableOrgUserIds = defaultMemoize(
  (state: StateT, orgId: number, jobId: number): number[] => {
    const orgUsers = getOrgUserIds(state, orgId);
    const jobUsers = getJobUserIds(state, jobId);
    return orgUsers.filter((id) => !jobUsers.includes(id));
  },
);

export const isAdmin = createSelector<StateT, number, (number) => boolean, _, _>(
  [getHiringManager, getHiringTeamMember],
  (hm, getMember: (number) => ?TapMicroUserT): ((number) => boolean) =>
    lodash.memoize((id: number): boolean => {
      const member = getMember(id);
      return (
        (!!member && (member === hm || member.is_admin)) ||
        userService.isOrgOwner() ||
        userService.isRecruiter()
      );
    }),
);
