import lodash from "lodash";
import { CapCandidateDetailReduxT } from "@talentpair/types/cap/entities/candidate";
import { CapPairReduxT } from "@talentpair/types/cap/entities/pair";
import { EducationT } from "@talentpair/types/entities/candidate";
import { OrderedChoiceT, OrderedOmniT } from "@talentpair/types/entities/misc";
import { TapCandidateDetailT } from "@talentpair/types/tap/entities/candidate";
import { TapPairReduxT } from "@talentpair/types/tap/entities/pair";
import { sortJobHistories } from "kyoto/utils/entities/candidate";

export const capPairExists = ({ pair }: { pair?: CapPairReduxT | null }): boolean => !!pair;
export const tapPairExists = ({ pair }: { pair?: TapPairReduxT | null }): boolean => !!pair;

// Convert dates into integers for quick sorting
function convertTimestampToInt(field: string): (item: Record<string, string | null>) => number {
  // null represents "Present" for these fields, so want it at the top
  return (item) =>
    item[field] === null ? Number.MAX_VALUE : +(item[field] || "").replace(/-/g, "");
}

export const sortCandidateData = <V extends CapCandidateDetailReduxT | TapCandidateDetailT>(
  candidate: V,
): V => {
  const sorted = {
    ...candidate,
    job_histories: sortJobHistories(candidate.job_histories),
    educations: lodash.orderBy<EducationT[]>(
      candidate.educations,
      ["graduated"].map(convertTimestampToInt),
      ["desc"],
    ),
    omnis: lodash.sortBy<OrderedOmniT>(candidate.omnis, "order"),
    interested_verticals: lodash.sortBy<OrderedChoiceT>(candidate.interested_verticals, "order"),
  };
  return sorted;
};

export const _test = { convertTimestampToInt };
