import { sentry } from "@talentpair/sentry";

/*

`assertNever()` and `expectNever()` are typescript utility functions that assert their arg is of type "never" (which means it's unreachable/impossible)

These are useful for purposes of exhaustive switch checking without squashing lint errors around default case statements

*/
// use this if you *want* the error to be thrown and possibly bubble up to the UI
export function assertNever(arg: never): never {
  throw new Error(`Reached 'never' type assertion with value: ${arg as string}`);
}
// use this verion if you never want to show this error to the user, but you still want the type errors for development purposes
export function expectNever(arg: never): null {
  try {
    assertNever(arg);
  } catch (e) {
    sentry.error(e as Error);
  }
  return null;
}
