import { Tab } from "@mui/material";
import { londonLink } from "@talentpair/env";
import { SuspenseWrapper } from "@talentpair/sentry";
import * as React from "react";
import { connect, DispatchT } from "@talentpair/redux";
import { CapCandidateDetailReduxT } from "@talentpair/types/cap/entities/candidate";
import { RouteComponentProps } from "@talentpair/routing";
import AccountOwnerListItem from "../../components/AccountOwnerListItem";
import { PageTabs, PageTabsWrapper, fixedPanePosition } from "../../components/layout";
import { getRoutePath } from "../../routeConfig";
import { StateT } from "../../store";
import { getCandidate } from "../store/candidateSelectors";
import PairFilterAction, { PairFilterT } from "../store/pairFilterActions";
import { PairFilterReduxT } from "../store/pairFilterReducers";
import { getPairFilter } from "../store/pairFilterSelectors";
import { UserActivityReduxT } from "../../shared-apps/ActivityFeed/store/userActivityReducers";
import { getUserActivity } from "../../shared-apps/ActivityFeed/store/userActivitySelectors";

type PairListTabParams = { candidate: string; pair?: string };

type StateToPropsT = {
  pairFilter: PairFilterReduxT;
  candidate: undefined | null | CapCandidateDetailReduxT;
  userActivity: undefined | null | UserActivityReduxT;
};
type DispatchToPropsT = { pipelineLink: (filter: PairFilterT) => void };
type PropsT = RouteComponentProps<PairListTabParams> & StateToPropsT & DispatchToPropsT;

class PairListTabs extends React.Component<PropsT> {
  link = (event: React.SyntheticEvent, value: PairFilterReduxT): void => {
    this.props.pipelineLink(value);
  };

  render(): React.ReactNode {
    const { candidate } = this.props;
    if (!candidate) return null;

    return (
      <PageTabs pane="sidebar1" onChange={this.link} value={this.props.pairFilter}>
        <Tab label="Review" value="REVIEW" data-testid="pairsListTabReview" />
        <Tab label="Active" value="ACTIVE" data-testid="pairsListTabActive" />
        <Tab label="Inactive" value="INACTIVE" data-testid="pairsListTabInactive" />
      </PageTabs>
    );
  }
}

const mapStateToProps = (
  state: StateT,
  { match: { params } }: RouteComponentProps<PairListTabParams>,
): StateToPropsT => {
  const candidate = getCandidate(state, +params.candidate);
  return {
    pairFilter: getPairFilter(state),
    candidate,
    userActivity: candidate ? getUserActivity(state, candidate.user.id) : null,
  };
};

const mapDispatchToProps = (dispatch: DispatchT): DispatchToPropsT => ({
  pipelineLink: (filter: PairFilterT) => dispatch(PairFilterAction.filter(filter)),
});

type WrapperPropsT = PropsT;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default (Cmp: React.ComponentType<RouteComponentProps<PairListTabParams>>) =>
  connect<StateToPropsT, DispatchToPropsT, PropsT, StateT>(
    mapStateToProps,
    mapDispatchToProps,
  )(
    ({
      candidate,
      pairFilter,
      pipelineLink,
      userActivity,
      history,
      location,
      match,
      staticContext,
      ...props
    }: WrapperPropsT) => (
      <PageTabsWrapper style={candidate && candidate.owner ? { paddingBottom: 69 } : undefined}>
        <PairListTabs
          candidate={candidate}
          pairFilter={pairFilter}
          pipelineLink={pipelineLink}
          userActivity={userActivity}
          history={history}
          location={location}
          match={match}
          staticContext={staticContext}
          {...props}
        />
        <SuspenseWrapper>
          <Cmp
            history={history}
            location={location}
            match={match}
            staticContext={staticContext}
            {...props}
          />
        </SuspenseWrapper>
        {candidate && candidate.owner && (
          <AccountOwnerListItem
            owner={candidate.owner}
            data-testid="growthPartnerListItem"
            to={londonLink.capUserActivity(candidate.id)}
            className={`bottom-0 border-top border-grey-300 ${fixedPanePosition("sidebar1")}`}
            selected={match.path === getRoutePath("CAP/UserActivity")}
            timestamp={userActivity ? userActivity.latestActivity : ""}
            notificationCount={userActivity ? userActivity.notificationCount : 0}
            userActivityId={candidate.user.id}
          />
        )}
      </PageTabsWrapper>
    ),
  );

export const _test = { PairListTabs, mapStateToProps, mapDispatchToProps };
