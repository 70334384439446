import * as React from "react";
import { useSelector } from "@talentpair/redux";
import { differenceInCalendarDays, parseISO } from "@talentpair/datetime";
import { CapCandidateDetailReduxT } from "@talentpair/types/cap/entities/candidate";
import { userService } from "@talentpair/api";
import { StateT } from "../../store";
import { getCandidate } from "../store/candidateSelectors";
import LinkedinPixel from "../../components/LinkedinPixel";

function shouldTrackUser(): boolean {
  return (
    !!userService.data &&
    userService.isCandidate() &&
    !userService.settings.ui_preferences.tracked_sign_up &&
    Math.abs(differenceInCalendarDays(new Date(), parseISO(userService.data.created))) <= 14
  );
}

const defaultValues = { is_provisional_mpc: false, ready_to_send: false };

export function MpcLinkedinPixel(): React.ReactElement | null {
  const candidate = useSelector<StateT, CapCandidateDetailReduxT | null>((state) =>
    getCandidate(state, userService.data?.candidate_id || 0),
  );
  const { is_provisional_mpc, ready_to_send } = candidate || defaultValues;
  const should_track = React.useMemo(() => !!ready_to_send && shouldTrackUser(), [ready_to_send]);
  const [should_render, setShouldRender] = React.useState(should_track && is_provisional_mpc);

  React.useEffect(() => {
    if (should_track) {
      if (is_provisional_mpc) setShouldRender(true);
      else userService.updateUIPreferences({ tracked_sign_up: true });
    }
  }, [should_track, is_provisional_mpc]);

  const onPixelDidLoad = (): void => {
    setShouldRender(false);
    userService.updateUIPreferences({ tracked_sign_up: true });
  };

  return should_render ? <LinkedinPixel type="mpc_sign_up" onLoad={onPixelDidLoad} /> : null;
}

export const _test = { shouldTrackUser };
