import lodash from "lodash";
import { userService } from "@talentpair/api";
import { createSelector } from "@talentpair/redux";
import { getActivity } from "./activitySelectors";
import type { UserActivityReduxT } from "./userActivityReducers";
import { initialState } from "./sharedActivityReducers";
import type { StateT } from "../../../store";

export const getUserActivity = (
  { userActivity }: StateT,
  user: number,
): UserActivityReduxT | null | undefined => userActivity[user];

export const getUserActivityFeed = createSelector(
  [getUserActivity, getActivity],
  (userActivity, activity) =>
    userActivity
      ? lodash.sortBy(
          userActivity.items.map((id) => activity[id]),
          "created",
        )
      : [],
);

export const newUserActivityItems = createSelector(
  [getUserActivity],
  (userActivity) =>
    !!userActivity &&
    !!userActivity.lastFetched &&
    userActivity.lastFetched !== userActivity.latestActivity,
);

export const getUserActivityPagination = createSelector([getUserActivity], (userActivity) =>
  userActivity ? userActivity.pagination : initialState.pagination,
);

export const getEarliestUserActivityTimestamp = createSelector(
  [getUserActivityFeed, getUserActivity],
  (activityFeed, userActivity) =>
    activityFeed.length && userActivity?.pagination.page_size && !userActivity?.pagination.next
      ? activityFeed[0].created
      : (userActivity?.lastFetched && userService.data?.first_login) || null,
);
