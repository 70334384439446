import { Business } from "@mui/icons-material";
import { RingedAvatar, RingedAvatarProps } from "@talentpair/quantic";

export type OrgAvatarOrgT = {
  name?: null | string;
  logo_url?: null | string;
  is_on_platform?: boolean;
  [key: string]: unknown;
};

export type OrgAvatarProps = Pick<RingedAvatarProps, "size" | "ringed" | "background"> & {
  ringColor?: RingedAvatarProps["ringColor"];
  org?: undefined | null | OrgAvatarOrgT; // if no org passed in fallback to default org avatar
};

export function OrgAvatar({
  org,
  ringColor,
  background = "primary.400",
  ...props
}: OrgAvatarProps): React.ReactElement {
  const sharedProps: Omit<RingedAvatarProps, "fill"> = {
    ...props,
    shape: "rounded",
    color: "common.white",
    background,
    ringColor: ringColor || (org?.is_on_platform ? "primary" : "transparent"),
  };

  if (org?.logo_url)
    return (
      <RingedAvatar
        {...sharedProps}
        fill="image"
        src={org.logo_url}
        alt="Org's company logo"
        // icon fallback
        Icon={Business}
      />
    );

  return <RingedAvatar {...sharedProps} fill="icon" Icon={Business} />;
}
