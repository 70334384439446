import * as React from "react";
import { Link } from "@talentpair/routing";
import { Badge, Tooltip } from "@mui/material";
import { useSelector } from "@talentpair/redux";
import { londonLink } from "@talentpair/env";
import { userService } from "@talentpair/api";
import { UserAvatar } from "@talentpair/quantic";
import { displayFullName } from "@talentpair/entities/user";
// @ts-expect-error - REMOVE ME
import { getOrg } from "../../store/orgSelectors";
import NotificationCountWatcher from "../../../shared-apps/Notifications/containers/NotificationCountWatcher";
import ActivityAction from "../../../shared-apps/ActivityFeed/store/activityActions";
import UserActivityAction from "../../../shared-apps/ActivityFeed/store/userActivityActions";
import { getUserActivity } from "../../../shared-apps/ActivityFeed/store/userActivitySelectors";
import { getRoutePath } from "../../../routeConfig";
import { DispatchT } from "../../../store";
import { NavLinkBox } from "./NavLink";

export function AccountOwnerNavLink({
  path,
  orgId,
}: {
  path: string;
  orgId: number;
}): React.ReactElement | null {
  const { contact_owner, primary_owner, notifications_count } = useSelector((state) => {
    const org = getOrg(state, orgId);
    return {
      contact_owner: org?.contact_owner,
      primary_owner: org?.primary_owner,
      notifications_count:
        // @ts-expect-error - dealwithit
        (org?.contact_owner && getUserActivity(state, org?.contact_owner.id)?.notificationCount) ||
        0,
    };
  });

  if (!userService.isOrgAdmin() || !primary_owner || !contact_owner) return null;

  return (
    <Tooltip
      title={`${displayFullName(primary_owner)} @ Talentpair`}
      placement="right"
      PopperProps={{
        popperOptions: { strategy: "fixed" },
      }}
    >
      <div>
        <NavLinkBox
          data-testid="growthPartnerBtn"
          active={path === getRoutePath("TAP/UserActivity")}
          className="border-top border-grey-300 p2"
        >
          <Link className="color-inherit" to={londonLink.tapUserActivity(orgId)}>
            <Badge overlap="circular" max={9} badgeContent={notifications_count} color="error">
              <UserAvatar user={primary_owner} ringed={false} />
            </Badge>
          </Link>
          <NotificationCountWatcher
            fetchAction={() => (dispatch: DispatchT) => {
              dispatch(UserActivityAction.fetchNotificationCount(contact_owner.id));
              dispatch(ActivityAction.fetchLatestActivity("user", contact_owner.id));
            }}
          />
        </NavLinkBox>
      </div>
    </Tooltip>
  );
}
