import { apiRequest } from "@talentpair/api";
import { timeDeltaInSeconds } from "@talentpair/datetime";
import { createSlice, PayloadAction, DispatchT } from "@talentpair/redux";
import { UpcomingInterviewT } from "@talentpair/types/entities/pipeline";

export type UpcomingInterviewsState = {
  user: number;
  list: UpcomingInterviewT[];
  lastLoaded: string | null;
  loading: boolean;
};

// HACK: If we upgrade redux-toolkit we can delete this outer stare business
type OuterState = {
  tap: { upcomingInterviews: UpcomingInterviewsState };
};

type GetState = () => OuterState;

function intialInterviewsState(user: number): UpcomingInterviewsState {
  return {
    user,
    list: [],
    lastLoaded: null,
    loading: false,
  };
}

const { actions, reducer } = createSlice({
  name: "upcomingInterviews",
  initialState: intialInterviewsState(0),
  reducers: {
    setUser(state, action: PayloadAction<number>) {
      if (state.user !== action.payload) {
        state.list = [];
        state.lastLoaded = null;
      }
      state.user = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    receiveList(state, action: PayloadAction<UpcomingInterviewT[]>) {
      state.list = action.payload;
      state.lastLoaded = new Date().toISOString();
      state.loading = false;
    },
  },
  // TODO: Could be using nice slice selectors like this from v2 (upgrade redux-toolkit, possible other deps)
  // selectors: {
  //   getCalendar: (state) => state,
  //   getUser: (state) => state.user,
  // },
});

export function getCalendar(state: OuterState): UpcomingInterviewsState {
  return state.tap.upcomingInterviews;
}

export function getInterview(state: OuterState, id: number): UpcomingInterviewT | undefined {
  return getCalendar(state).list.find((i) => i.id === id);
}

const fetchInterviews =
  (userId?: number) =>
  (dispatch: DispatchT, getState: GetState): Promise<null | UpcomingInterviewT[]> => {
    const { user, lastLoaded, loading } = getCalendar(getState());
    if (
      userId &&
      userId === user &&
      (loading || (lastLoaded && timeDeltaInSeconds(lastLoaded) < 60))
    ) {
      return Promise.resolve(null);
    }
    if (userId !== user) dispatch(actions.setLoading(true));
    return apiRequest
      .get<UpcomingInterviewT[]>(`users/${user}/upcoming-interviews/`)
      .then(({ data }) => {
        dispatch(actions.receiveList(data));
        return data;
      });
  };

const setUser =
  (userId: number) =>
  (dispatch: DispatchT): Promise<unknown> => {
    dispatch(actions.setUser(userId));
    return dispatch(fetchInterviews(userId));
  };

export const UpcomingInterviewsReducer = reducer;
export const UpcomingInterviewsActions = {
  ...actions,
  fetchInterviews,
  setUser,
};
