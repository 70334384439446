import { parseISO, differenceInMinutes } from "date-fns";
import { getShortTimeZone, shiftDateToTimeZone, shiftDateFromTimeZone } from "./timeZone";
import { dateFormat } from "./dateFormat";

export type TimeSlotT = {
  start_time: string;
  end_time: string;
};

export const isFreshTimeSlot = (slot: TimeSlotT): boolean =>
  differenceInMinutes(parseISO(slot.start_time), new Date()) >= 0;

export const getFreshTimeSlot = (slot: TimeSlotT): TimeSlotT | null => {
  // if the entire time slot is in the future, then it's fresh!
  if (isFreshTimeSlot(slot)) return slot;
  const now = new Date();
  // if there's not at least 2 hours left in the time slot: it's stale
  if (differenceInMinutes(parseISO(slot.end_time), now) < 180) return null;
  // part of time slot is stale, so let's trim off the stale time
  // generate a new start time at the top of the next hour
  now.setHours(now.getHours() + 1, 0, 0, 0);
  const start_time = now.toISOString();
  return {
    start_time,
    end_time: slot.end_time,
  };
};

export const displayTimeSlot = (
  { start_time, end_time }: TimeSlotT,
  timeZone: string | boolean = false,
  timeOnly = false,
): string =>
  `${
    timeOnly ? dateFormat.t12pm(start_time) : dateFormat.fullTimestamp(start_time)
  } – ${dateFormat.t12pm(end_time)}${
    timeZone ? ` (${timeZone === true ? getShortTimeZone() : timeZone})` : ""
  }`;

export function shiftTimeSlotToTimeZone(slot: TimeSlotT, timeZone: string): TimeSlotT {
  return {
    start_time: shiftDateToTimeZone(slot.start_time, timeZone).toISOString(),
    end_time: shiftDateToTimeZone(slot.end_time, timeZone).toISOString(),
  };
}

export function shiftTimeSlotFromTimeZone(slot: TimeSlotT, timeZone: string): TimeSlotT {
  return {
    start_time: shiftDateFromTimeZone(slot.start_time, timeZone).toISOString(),
    end_time: shiftDateFromTimeZone(slot.end_time, timeZone).toISOString(),
  };
}
