import * as React from "react";
import { withStyles, StyleRules } from "@mui/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, Card, CardProps, CardContent, CardMedia, MobileStepper } from "@mui/material";
import { Typography, PrimaryButton } from "@talentpair/quantic";
import { SlideT, TourT } from "../utils";

const styles: StyleRules = {
  root: {
    boxShadow:
      "0 0 5px -3px rgba(0, 0, 0, 0.2), 0 0 10px 1px rgba(0, 0, 0, 0.14), 0 0 14px 2px rgba(0, 0, 0, 0.12)",
    minHeight: 0,
    maxWidth: 312,
    width: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: 120,
    paddingBottom: 0,
  },
  media: { paddingBottom: "calc(242 / 312 * 100%)" },
  mediaWrapper: { position: "relative", width: "100%" },
  text: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    maxWidth: "15rem",
  },
  singleButton: {
    margin: "0 auto 1rem",
    maxWidth: "15rem",
    display: "block",
  },
};

export interface CardConfigPropsT {
  tour: TourT;
  doneText: string;
  slides: SlideT[];
  initialStep: number;
  setTourStep: (step: number) => void;
}
export interface TourCardProps extends CardConfigPropsT, CardProps {
  children?: React.ReactNode;
  classes: Record<string, string>;
  onBackgroundChange?: null | ((color: string) => void);
  onClose: (event: React.SyntheticEvent) => void;
  cardRef?: React.ForwardedRef<HTMLDivElement>;
}
interface TourCardState {
  activeStep: number;
}

class TourCard extends React.Component<TourCardProps, TourCardState> {
  static defaultProps = { children: null, onBackgroundChange: null, initialStep: 0 };

  state = { activeStep: this.props.initialStep };

  handleBack = (): void => this.changeSlide(-1);
  handleNext = (): void => this.changeSlide(1);

  handleBackgroundChange = (): void => {
    const { slides, onBackgroundChange, setTourStep } = this.props;
    const { activeStep } = this.state;
    setTourStep(activeStep);
    if (onBackgroundChange) onBackgroundChange(slides[activeStep].background);
  };

  changeSlide(change: number): void {
    this.setState(
      ({ activeStep }) => ({ activeStep: activeStep + change }),
      this.handleBackgroundChange,
    );
  }

  render(): React.ReactNode {
    const { doneText, classes, onBackgroundChange, onClose, slides, tour, cardRef, ...props } =
      this.props;
    const { activeStep } = this.state;
    const { children, img, text, background } = slides[activeStep];
    return (
      <Card data-testid={tour} classes={{ root: classes.root }} ref={cardRef} {...props}>
        <div className={classes.mediaWrapper} style={{ backgroundColor: background }}>
          {/*
          // @ts-expect-error - component prop is okay */}
          <CardMedia className={classes.media} image={img} title={text} />
          {children}
        </div>
        <CardContent className={classes.content}>
          <Typography align="center" className={classes.text} variant="body3">
            {text}
          </Typography>
        </CardContent>
        {slides.length === 1 ? (
          <PrimaryButton className={classes.singleButton} data-testid="doneBtn" onClick={onClose}>
            {doneText}
          </PrimaryButton>
        ) : (
          <MobileStepper
            variant="dots"
            steps={slides.length}
            position="static"
            activeStep={activeStep}
            backButton={
              <Button
                data-testid="prevBtn"
                size="small"
                color="inherit"
                onClick={this.handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeftIcon />
                Back
              </Button>
            }
            nextButton={
              activeStep === slides.length - 1 ? (
                <PrimaryButton data-testid="doneBtn" size="small" onClick={onClose}>
                  {doneText}
                </PrimaryButton>
              ) : (
                <Button
                  data-testid="nextBtn"
                  size="small"
                  color="inherit"
                  onClick={this.handleNext}
                >
                  Next
                  <KeyboardArrowRightIcon />
                </Button>
              )
            }
          />
        )}
      </Card>
    );
  }
}

const StyledTourCard = withStyles(styles)(TourCard);

export default React.forwardRef<HTMLDivElement, Omit<TourCardProps, "classes">>((props, ref) => (
  <StyledTourCard {...props} cardRef={ref} />
));
