import * as React from "react";
import { Badge } from "./Badge";

interface OrgT {
  id: number;
  out_of_business?: boolean;
  is_on_platform?: boolean;
  pipeline_state?: string | { name: string };
}

export type OrgPipelineBadgeProps = {
  org: OrgT;
  noLink?: boolean;
};

const orgPipelineStateColorMap = {
  COLD: {},
  WARM: { color: "bg-amber-500", text: "WARM" },
  "AGREEMENT SENT": { color: "bg-blue-400", text: "AGREEMENT SENT" },
  SIGNED: { color: "bg-green-500", text: "SIGNED" },
  PAUSED: { color: "bg-amber-700", text: "PAUSED" },
  LOST: { color: "bg-red-500", text: "LOST" },
} as const;

export function OrgPipelineBadge({
  org,
  noLink = false,
  ...props
}: OrgPipelineBadgeProps): React.ReactElement | null {
  const pipeline_state = (
    typeof org.pipeline_state !== "string" ? org.pipeline_state?.name : org.pipeline_state
  )?.toUpperCase() as keyof typeof orgPipelineStateColorMap;

  if (pipeline_state && pipeline_state !== "COLD")
    return (
      <Badge
        text={orgPipelineStateColorMap[pipeline_state].text}
        data-testid="org-pipeline-status"
        bgColor={orgPipelineStateColorMap[pipeline_state].color}
        href={noLink ? "" : null}
        key="org-pipeline-state-badge"
        {...props}
      />
    );
  return null;
}
