import * as React from "react";
import { auth, userService } from "@talentpair/api";
import { sentry } from "@talentpair/sentry";
import { withRouter, RouteComponentProps, locationFullUrl, RoutesT } from "@talentpair/routing";
import { mixpanelHelper } from "./mixpanelHelper";
import { getUtmParams, sendUtmParams, saveUtmParams } from "./utm";
import { navigationData } from "./navigationData";

export type NavigationAnalyticsProps = RouteComponentProps & {
  routes: RoutesT;
  skipSignupRedirect?: () => boolean;
};

class BaseNavigationAnalytics extends React.Component<NavigationAnalyticsProps> {
  componentDidMount(): void {
    mixpanelHelper.resetNavCachedTime();
    this.track("Navigation", this.props.location);
    window.onunload = () => this.track("Page View", this.props.location);
  }

  componentDidUpdate(nextProps: RouteComponentProps): void {
    if (locationFullUrl(nextProps.location) !== locationFullUrl(this.props.location)) {
      this.track("Page View", this.props.location);
      this.track("Navigation", nextProps.location);
    }
  }

  track = (event: "Navigation" | "Page View", location: RouteComponentProps["location"]): void => {
    if (event === "Navigation") {
      // Register UTM Params (if any) before tracking. It sets super properties,
      // so we want them to be present first.
      const utmParams = getUtmParams(location.search);
      if (utmParams) {
        mixpanelHelper.registerUtmParams(utmParams);
        auth
          .isLoggedin(this.props.skipSignupRedirect) // catch sendUtmParams directly so we don't inadvertently catch a network error later
          .then(({ id }) => sendUtmParams(id, utmParams).catch(sentry.error))
          .catch(({ message }) => {
            // user is not logged in
            if (!message.includes("redirect handled")) saveUtmParams(utmParams);
            // user exists, but was redirected to finish sign-up flow, so still send the params to the BE
            else if (userService.id()) sendUtmParams(userService.id(), utmParams);
          });
      }
    }

    const mixpanelData: Record<string, string> = {
      ...navigationData(this.props.routes, location.pathname, location.search),
      Page: locationFullUrl(location),
    };
    if (location.state) mixpanelData["App State"] = JSON.stringify(location.state);

    if (mixpanelData["App Type"] !== "Redirect") {
      if (event === "Page View") mixpanelHelper.trackPageView(mixpanelData);
      else mixpanelHelper.track("Navigation", mixpanelData);
    }
  };

  render(): null {
    return null;
  }
}

export const NavigationAnalytics = withRouter(BaseNavigationAnalytics);
