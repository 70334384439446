import * as React from "react";
import withStyles from "@mui/styles/withStyles";
import { AppBar, Toolbar, Grid } from "@mui/material";
import { RouteComponentProps } from "@talentpair/routing";
import { Loader } from "@talentpair/quantic";
import { ErrorBoundary } from "@talentpair/sentry";
import AuthButtons from "kyoto/components/AuthButtons";
import logo from "@talentpair/assets/images/logo.png";

export const Content = withStyles({
  item: {
    display: "flex",
    minHeight: "calc(100vh - 56px)",
    marginTop: 56,
    "@media (min-width:0px) and (orientation: landscape)": {
      minHeight: "calc(100vh - 48px)",
      marginTop: 48,
    },
    "@media (min-width:600px)": {
      minHeight: "calc(100vh - 64px)",
      marginTop: 64,
    },
  },
})(Grid);

export type ReferralParams = { referral_code?: string };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ReferralPageProps extends RouteComponentProps<ReferralParams> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  page: React.LazyExoticComponent<any>;
}

export function ReferralPage({ page: Page, ...props }: ReferralPageProps): React.ReactElement {
  return (
    <Grid>
      <AppBar elevation={0} className="bg-white">
        <Toolbar
          className="flex justify-between items-center col-12 mx-auto"
          style={{ maxWidth: 938 /* Set to match width of pricing cards */ }}
        >
          <img src={logo} alt="Talentpair" style={{ marginBottom: -5, width: 100 }} />
          <AuthButtons variant="green" />
        </Toolbar>
      </AppBar>
      <Content item direction="column" container>
        <ErrorBoundary>
          <React.Suspense fallback={<Loader absolute />}>
            <Page {...props} />
          </React.Suspense>
        </ErrorBoundary>
      </Content>
    </Grid>
  );
}
