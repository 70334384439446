import * as React from "react";
import { Fade, Modal as MuiModal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MakeStyleProps } from "@talentpair/types/material-ui";
import type { PortalPropsT } from "@talentpair/portal";
import Card, { CardConfigPropsT } from "./Card";

// Copied from `Dialog`. Mostly the same with simplifications.
export const useStyles = makeStyles(
  {
    root: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      display: "flex",
      flex: "0 1 auto",
      flexDirection: "column",
      margin: 32,
      maxHeight: "90vh",
      maxWidth: 312,
      overflowY: "auto", // Fix IE11 issue, to remove at some point.
      position: "relative",
      width: "100%",
      "&.Mui-focused, &:focus-within": { outline: "none" },
    },
  },
  { name: "OnboardingModal" },
);

interface ModalProps extends PortalPropsT, CardConfigPropsT, MakeStyleProps<typeof useStyles> {
  classes: {
    root: string;
    paper: string;
  };
  target: HTMLElement;
  transitionDuration: {
    enter: number;
    exit: number;
  };
}

export default function Modal({
  onClose,
  onExited,
  open,
  target,
  transitionDuration = { enter: 225, exit: 195 },
  ...props
}: ModalProps): React.ReactElement {
  const classes = useStyles(props);
  const handleClose = (e: unknown, reason: "escapeKeyDown" | "backdropClick"): void => {
    if (reason === "backdropClick") return;
    onClose();
  };
  return (
    <MuiModal
      className={classes.root}
      BackdropProps={{ transitionDuration }}
      disableEscapeKeyDown
      onClose={handleClose}
      open={open}
      role="dialog"
    >
      <Fade appear in={open} timeout={transitionDuration} onExited={onExited}>
        <Card className={classes.paper} elevation={24} onClose={onClose} {...props} />
      </Fade>
    </MuiModal>
  );
}
