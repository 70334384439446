import * as React from "react";
import { PrimaryButton } from "@talentpair/quantic";
import { Modal, ModalPropsT, PortalPropsT } from "@talentpair/portal";
import FeedbackForm, { FeedbackFormProps } from "./FeedbackForm";

type BaseFeedbackModalProps = ModalPropsT & {
  submittedHeader: React.ReactNode;
  submittedBody: React.ReactNode;
  unsubmittedHeader: React.ReactNode;
  feedbackFormProps?: FeedbackFormProps;
};

export function BaseFeedbackModal({
  submittedHeader,
  submittedBody,
  unsubmittedHeader,
  feedbackFormProps,
  ...props
}: BaseFeedbackModalProps): React.ReactElement {
  const [submitted, setSubmitted] = React.useState(false);
  return (
    <Modal fullWidth responsive {...props}>
      <div className="center">
        {submitted ? (
          <>
            <h2>{submittedHeader}</h2>
            <div className="my5">{submittedBody}</div>
            <PrimaryButton onClick={props.onClose}>Close</PrimaryButton>
          </>
        ) : (
          <>
            <h2 className="mb5">{unsubmittedHeader}</h2>
            <FeedbackForm
              {...feedbackFormProps}
              onSubmitSuccess={() => {
                setSubmitted(true);
                feedbackFormProps?.onSubmitSuccess?.();
              }}
            />
          </>
        )}
      </div>
    </Modal>
  );
}

export default function FeedbackModal(props: PortalPropsT): React.ReactElement {
  return (
    <BaseFeedbackModal
      title="Feedback"
      data-testid="feedbackModal"
      submittedHeader="Thanks for your feedback."
      submittedBody={
        <span aria-label="clapping hands" role="img" style={{ fontSize: "10em" }}>
          👏
        </span>
      }
      unsubmittedHeader={
        <>
          We&apos;d
          <span aria-label="red-500 heart" className="px2" role="img">
            ❤️
          </span>
          to hear from you.
        </>
      }
      feedbackFormProps={{ autoFocus: true }}
      {...props}
    />
  );
}
