import { ProcessTypeT } from "@talentpair/types/entities/misc";
import { apiRequest, ResponseDataT } from "./apiRequest";
import { CancelablePromiseT } from "./async";
import { ping, PingConfigT } from "./ping";

export type ProcessT = {
  progress: number;
  status_code: number;
  status_message: string | null;
  success: boolean;
  type: ProcessTypeT;
  subtype: string; // currently only used for teleprompter tasks
  uuid: string;
};

export type ProcessPingConfigT = Omit<
  PingConfigT<ResponseDataT<ProcessT>>,
  "request" | "isComplete"
> &
  Partial<Pick<PingConfigT<ResponseDataT<ProcessT>>, "isComplete">>;

export type ProcessResponseT = ResponseDataT<ProcessT>;

export const processPing = (
  uuid: string,
  config?: ProcessPingConfigT,
  url = `processes-uuid/${uuid}/`,
): CancelablePromiseT<ProcessResponseT> =>
  ping({
    request: () => apiRequest.get<ProcessT>(url),
    isComplete: ({ data }) => data.progress === 100,
    // If we've sent at least one response, and progress increased
    madeProgress: (rsp: ProcessResponseT, prevRsp: ProcessResponseT | null) =>
      !!prevRsp && rsp.data.progress > prevRsp.data.progress,
    immediate: false,
    timeoutMsg: ({ data: { type } }) => type,
    ...config,
  });
