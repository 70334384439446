import isEqual from "react-fast-compare";
import focusService from "../services/focus";

export function preventFocusOverwrite<E>(changes: Partial<E>): Partial<E> {
  // prevent overwritting of a currently focused text field

  const focused = focusService.getCurrentFocus();
  if (
    focused &&
    focused in changes &&
    // @ts-expect-error - eslint upgrade
    typeof changes[focused] === "string"
  )
    // @ts-expect-error - eslint upgrade
    delete changes[focused];
  return changes;
}

export function diffObjects<O extends Record<string, unknown>>(prev: O, next: O): Partial<O> {
  return Object.keys(next).reduce((diff, key: keyof O) => {
    if (!isEqual(prev[key], next[key])) diff[key] = next[key];
    return diff;
  }, {} as Partial<O>);
}
