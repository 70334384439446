import lodash from "lodash";
import { createSelector } from "@talentpair/redux";
import { CapPairReduxT } from "@talentpair/types/cap/entities/pair";
import { toTruthy } from "kyoto/utils/array";
import { StateT } from "../../store";
import { CandidatePairsT, defaultCandidatePairs } from "./candidatePairReducers";
import { CapPairsReduxT } from "./pairReducers";
import { getPairFilter } from "./pairFilterSelectors";

// Detail
export const getPair = ({ cap }: StateT, id: number): CapPairReduxT | null | undefined =>
  cap.pairs[id];

export const getCandidateState = createSelector([getPair], (pair) =>
  pair ? pair.candidate_state : "PR",
);

// List
export const getPairs = ({ cap }: StateT): CapPairsReduxT => cap.pairs;

export const getCandidatePairs = ({ cap }: StateT, candidate: number): CandidatePairsT =>
  cap.candidatePairs[candidate] || defaultCandidatePairs();

export const getFilteredPairs = createSelector(
  [getPairs, getCandidatePairs, getPairFilter],
  (allPairs: CapPairsReduxT, pairs: CandidatePairsT, filter) =>
    lodash.orderBy(
      pairs[filter].map((id) => allPairs[id]).filter(toTruthy),
      "last_cap_activity",
      "desc",
    ),
);

export const getFilteredPairIds = createSelector([getFilteredPairs], (pairs: CapPairReduxT[]) =>
  pairs.map(({ id }) => id),
);

export const getGrowthPartner = (state: StateT, id: number): CapPairReduxT["owner"] | null => {
  const pair = getPair(state, id);
  return pair ? pair.owner : null;
};
