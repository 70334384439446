import { apiRequest, ResponseDataT, userService } from "@talentpair/api";
import { sentry } from "@talentpair/sentry";
import perry from "@talentpair/assets/images/perry-192.png";
import { NotificationT, NotificationResultsT } from "@talentpair/types/notifications";
import { displayFullName } from "@talentpair/entities/user";
import { body, caption } from "../utils/activity";
import { desktopNotificationLink, subtitle } from "../utils/notifications";
import { stripTags } from "../utils/string";

const defaultSubject = {
  id: 0,
  first_name: "Talentpair",
  last_name: "",
  profile_picture_medium: perry,
};

// Handle both callback and promise api
const askPermission = (): Promise<NotificationPermission> =>
  new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result);
    });

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (permissionResult) permissionResult.then(resolve, reject);
  });

// Can only pass through serializable data to notifications.
async function showNotification(title: string, options?: NotificationOptions): Promise<void> {
  // Break if browser doesn't support it.
  if ("Notification" in window && "serviceWorker" in navigator) {
    // If permission hasn't been set yet, ask for it.
    const permission =
      Notification.permission === "default" ? await askPermission() : Notification.permission;
    // Don't do anything if they've denied it.
    if (permission === "granted" && navigator.serviceWorker) {
      await navigator.serviceWorker.ready.then(async (registration) => {
        if ("showNotification" in registration) await registration.showNotification(title, options);
      });
    }
  }
}

async function showDesktop(notification: NotificationT): Promise<void> {
  const subject = notification.activity.subject || defaultSubject;
  const options = {
    notification: true,
    isCap: userService.isCandidate(),
    isRecruiter: userService.isRecruiter(),
  };
  const itemSubtitle = subtitle(notification, options);
  const itemBody = body(notification.activity, options);
  const itemCaption = caption(notification.activity, options);
  return showNotification(
    `${displayFullName(subject)}${itemSubtitle ? ` | ${itemSubtitle}` : ""}`,
    {
      body: stripTags(`${itemBody}${itemBody && itemCaption ? "\n" : ""}${itemCaption}`, ".*?"),
      data: {
        url: desktopNotificationLink(notification, options),
      },
      icon: subject.profile_picture_medium || perry,
      tag: `${notification.id}`,
    },
  );
}

async function getDesktopNotifications(): Promise<ResponseDataT<NotificationT>[] | void> {
  try {
    const { data } = await apiRequest.get<NotificationResultsT>("notifications/desktop/");
    return await Promise.all(
      data.results
        .reverse() // Show oldest first
        .map((notification) =>
          showDesktop(notification).then(() =>
            apiRequest.patch<NotificationT>(`notifications/${notification.id}/`, {
              desktoped: true,
            }),
          ),
        ),
    );
  } catch (err) {
    sentry.error(err as Error);
    return Promise.resolve();
  }
}

export default { getDesktopNotifications, showDesktop };
