import * as React from "react";
import { env } from "@talentpair/env";
import { SentryRoute } from "@talentpair/sentry";
import { RouteProps } from "@talentpair/routing";

export function BaseRoute(props: RouteProps): React.ReactElement | null {
  // Prevent non-local iframes from being hosted outside of a *.talentpair.com domain
  if (
    !env.isLocal() &&
    window.self !== window.top &&
    (!document.referrer || !new URL(document.referrer).origin.endsWith(".talentpair.com"))
  )
    return null;
  // Only render this route if we're in a local context
  return <SentryRoute {...props} />;
}
