import * as React from "react";
import { Badge } from "./Badge";

const badgeProps = {
  1: { text: "CONTINGENCY", bgColor: "bg-grey-500" },
  2: { text: "PARTNERSHIP", bgColor: "bg-green-300" },
  3: { text: "ENGAGED", bgColor: "bg-red-300" },
  4: { text: "ACCESS", bgColor: "bg-blue-300" },
  5: { text: "PLATFORM", bgColor: "bg-green-300" },
  6: { text: "CUSTOM", bgColor: "bg-amber-300" },
  7: { text: "SOURCING", bgColor: "bg-purple-300" },
} as const;

export type PlanBadgeTypeT = keyof typeof badgeProps;

export type PlanBadgeProps = {
  plan?: null | keyof typeof badgeProps;
  className?: string;
};
export const PlanBadge = ({ plan, ...props }: PlanBadgeProps): React.ReactElement | null =>
  plan && badgeProps[plan] ? <Badge {...props} {...badgeProps[plan]} /> : null;
