/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "../../atoms/Box";
import { useScrollIntoView } from "../../hooks";
import { Timestamp, TimestampProps } from "../Timestamp";
import { Indicator, IndicatorProps } from "../Indicator";

export type ListItemProps = {
  primaryContent: React.ReactNode;
  secondaryContent?: React.ReactNode;
  secondaryControls?: React.ReactNode;

  // manage checked state, onChecked must be defined to show the checkbox
  checked?: boolean;
  onChecked?: (isChecked: boolean) => unknown;

  disabled?: boolean;

  // manage selection state
  selected?: boolean;
  onSelected?: () => unknown;
  scrollIntoView?: ScrollLogicalPosition;

  // for when avatar spacing required
  avatar?: boolean;

  // control vertical padding (dense = true means zero top/bottom padding)
  dense?: boolean;

  // timestamp props
  date?: TimestampProps["date"];
  timestamp?: TimestampProps["variant"];

  // indicator props
  count?: IndicatorProps["count"];
  light?: IndicatorProps["light"];

  "data-testid"?: string;
  className?: string;
  id?: string;
};

export function ListItem({
  disabled = false,
  primaryContent,
  secondaryControls,
  secondaryContent,
  checked,
  onChecked,
  selected,
  onSelected,
  avatar,
  dense,
  date,
  timestamp,
  count,
  light,
  scrollIntoView,
  className,
  ...props
}: ListItemProps): React.ReactElement {
  const ref = useScrollIntoView(selected, scrollIntoView);
  const contents = (
    <Box
      component={onSelected ? "div" : "li"}
      variant="column"
      layout="stretch-start"
      background={selected ? "misc.activeListItem" : checked ? "primary.50" : undefined}
      className={clsx(
        "animated-bg full-width",
        dense ? "py0" : "py2",
        onChecked || avatar ? "px2" : "pl3 pr2",
        onSelected && !selected && "list-item-hover",
        onSelected ? undefined : className,
      )}
      flex={onSelected ? "auto" : undefined}
      onClick={onSelected}
      {...props}
    >
      <Box variant="row" layout="center-start" space={1} className="full-width">
        {onChecked ? (
          <Checkbox
            disabled={disabled}
            checked={!!checked}
            onChange={(e, state) => {
              onChecked(state);
            }}
            onClick={(e) => e.stopPropagation()} // prevents selecting the item
            onMouseDown={(e) => e.stopPropagation()} // prevents ripple effect
          />
        ) : null}
        <Box flex="auto" className="overflow-hidden">
          {primaryContent}
        </Box>
        <Box variant="column" layout="end-start" flex="none">
          {date ? <Timestamp variant={timestamp || "default"} date={date} /> : null}
          <Box variant="row" layout="center-start">
            <Box
              variant="row"
              layout="center-start"
              onClick={(e) => e.stopPropagation()} // prevents selecting the item
              onMouseDown={(e) => e.stopPropagation()} // prevents ripple effect
            >
              {secondaryControls}
            </Box>
            {count != null || light ? <Indicator count={count} light={light} /> : null}
          </Box>
        </Box>
      </Box>
      {secondaryContent}
    </Box>
  );
  return onSelected ? (
    <ButtonBase
      component="li"
      className={clsx("items-stretch", className)}
      aria-selected={!!selected}
      // @ts-expect-error - it's okay
      ref={ref}
    >
      {contents}
    </ButtonBase>
  ) : (
    contents
  );
}
