import lodash from "lodash";
import { LocationT } from "@talentpair/types/entities/location";
import {
  AllCandidateT,
  CandidateVisibilityT,
  EducationT,
  JobHistoryT,
  WorkAuthCandidateT,
} from "@talentpair/types/entities/candidate";
import { OrderedOmniT } from "@talentpair/types/entities/misc";
import { dateFormat } from "@talentpair/datetime";
import { toTruthy } from "../array";
import { displayLocation } from "./location";

export const getCurrentCompany = (candidate: AllCandidateT): string | null =>
  lodash.get(candidate, ["user", "company", "name"]);
export const getCurrentRole = (candidate: AllCandidateT): string | null =>
  lodash.get(candidate, ["user", "role", "name"]);
export const getCurrentLocation = (candidate: AllCandidateT): LocationT | null =>
  lodash.get(candidate, ["user", "location"]);
export const getStatus = (candidate: AllCandidateT): string | null =>
  lodash.get(candidate, ["status", "name"]);

export function displayCurrentLocation(candidate: AllCandidateT): string {
  const location: LocationT | null = getCurrentLocation(candidate);
  return location ? displayLocation(location) : "";
}

export function displayVisibility<C extends { visibility: CandidateVisibilityT }>({
  visibility,
}: C): string {
  return visibility === "PU" ? "Promote Me" : "Stealth";
}

export const displayJobHistoryDateRange = ({
  start_date,
  end_date,
  current,
}: JobHistoryT): string => {
  if (!start_date) return "";
  const endStr = current ? "Present" : end_date ? dateFormat.january2018(end_date) : "";
  return `${dateFormat.january2018(start_date)}${endStr ? " - " : ""}${endStr}`;
};

export const displayDegree = ({ degree, field_of_study }: EducationT): string =>
  [degree && degree.name, field_of_study && field_of_study.name].filter(toTruthy).join(", ");

export const AuthLabel = {
  AUTHORIZED: "Authorized to work in the US for any employer.",
  SPONSORSHIP: "Requires sponsorship to work in the US.",
};

export const getAuthLabel = ({ us_work_authorized }: WorkAuthCandidateT): string =>
  us_work_authorized == null
    ? ""
    : us_work_authorized
    ? AuthLabel.AUTHORIZED
    : AuthLabel.SPONSORSHIP;

export const displayCompanyTypes = (candidate: AllCandidateT): string =>
  [
    "early_startup" in candidate && candidate.early_startup && "Seed/Angel",
    "mid_startup" in candidate && candidate.mid_startup && "VC Backed",
    "growth_startup" in candidate && candidate.growth_startup && "High Growth",
    "public_company" in candidate && candidate.public_company && "Public",
  ]
    .filter(toTruthy)
    .join(", ");

export const sortJobHistories = (histories: JobHistoryT[]): JobHistoryT[] =>
  [...histories].sort((a, b) => {
    const aStart = a.start_date || "";
    const bStart = b.start_date || "";

    if (aStart && !bStart) return -1;
    if (!aStart && bStart) return 1;
    if (!aStart && !bStart) return 0;

    // start dates exist
    if (a.current && (!b.current || aStart > bStart)) return -1;
    if (b.current && (!a.current || bStart > aStart)) return 1;

    // Neither is `current`
    const aEnd = a.end_date || aStart;
    const bEnd = b.end_date || bStart;

    if (aEnd > bEnd) return -1;
    if (aEnd < bEnd) return 1;

    // End dates are equal
    if (aStart === bStart) return 0;
    return aStart > bStart ? -1 : 1;
  });

export const jobSearchStatusValue = (status: ("AC" | "CA" | "NO" | "") | null): string => {
  switch (status) {
    case "AC":
      return "Actively looking";
    case "CA":
      return "Casually looking";
    case "NO":
      return "Not looking";
    default:
      return "";
  }
};

export const jobSearchStatusChoices = [
  { name: "Actively looking", id: "AC" },
  { name: "Casually looking", id: "CA" },
  { name: "Not looking", id: "NO" },
];

export type BenefitsValuesT = {
  retirement_401k: boolean;
  health_and_dental: boolean;
  vacation: boolean;
  equity: boolean;
};
export const displayDesiredBenefits = <C extends BenefitsValuesT>({
  retirement_401k,
  health_and_dental,
  vacation,
  equity,
}: C): string =>
  [
    retirement_401k && "401k",
    health_and_dental && "Health/Dental",
    vacation && "Vacation",
    equity && "Equity",
  ]
    .filter(toTruthy)
    .join(", ");

export type DesiredGrowthValuesT = {
  management_track: boolean;
  c_level: boolean;
  technical_track: boolean;
};
export const displayDesiredGrowth = <C extends DesiredGrowthValuesT>({
  management_track,
  c_level,
  technical_track,
}: C): string =>
  [technical_track && "Individual Contributor", management_track && "Manager", c_level && "C-Level"]
    .filter(toTruthy)
    .join(", ");

export type DesiredCompanySizeValuesT = {
  early_startup: boolean;
  growth_startup: boolean;
  mid_startup: boolean;
  public_company: boolean;
};
export const displayDesiredCompanySize = <C extends DesiredCompanySizeValuesT>({
  early_startup,
  growth_startup,
  mid_startup,
  public_company,
}: C): string =>
  [
    early_startup && "Seed/angel startup",
    mid_startup && "VC backed startup",
    growth_startup && "High growth startup",
    public_company && "Public company",
  ]
    .filter(toTruthy)
    .join(", ");

export function sortSkills(
  skills: OrderedOmniT[],
  prioritize: OrderedOmniT[] = [],
): OrderedOmniT[] {
  if (!prioritize.length) return skills;
  const [mustHaves, otherSkills] = lodash.partition(skills, (i) =>
    prioritize.some((o) => o.canonical_id === i.canonical_id),
  );
  return [...mustHaves, ...otherSkills];
}
