import {
  gettysburgHost,
  londonQgHost,
  londonTpHost,
  queenstownHost,
  pricingHost,
  referralHost,
  s3Host,
} from "./envHostnames";
import { londonLink } from "./londonLink";

// CONFIG
type HostObjT = {
  local: string;
  gojira: string;
  gojira2: string;
  staging: string;
  production: string;
};

// LOOKUPS

export function siteUrl(hostObj: HostObjT, frag: string, forceEncrypt?: boolean): string {
  const host = process.env.HOST;
  const slashFrag = frag.length > 0 && !frag.startsWith("/") ? `/${frag}` : frag;
  switch (host) {
    case "local":
    case "gojira":
    case "gojira2":
    case "staging":
    case "production":
      return `http${host !== "local" || forceEncrypt ? "s" : ""}://${hostObj[host]}${slashFrag}`;
    default:
      throw new Error("No redirect mapping for current env");
  }
}

const londonUrl = (frag = ""): string => siteUrl(londonTpHost, frag);

// Need to be able to always redirect to QgLogin for recruiters and growth partners
const recruitLogin = (next = ""): string =>
  siteUrl(
    londonQgHost,
    `${londonLink.authSignin()}${next ? `?next=${encodeURIComponent(next)}` : ""}`,
  );

const gettysburgUrl = (frag = ""): string => siteUrl(gettysburgHost, frag);
const queenstownUrl = (frag = ""): string => siteUrl(queenstownHost, frag);
const referralUrl = (frag = ""): string => siteUrl(referralHost, frag);
const pricingUrl = (): string => siteUrl(pricingHost, "/index.html");

const s3Url = (frag = ""): string => siteUrl(s3Host, frag, true);

const isEnv = (hostObj: HostObjT): boolean =>
  Object.values(hostObj).includes(location.hostname) ||
  hostObj.local === `${location.hostname}:${location.port}`;

const isLondon = (): boolean => isEnv(londonTpHost);
const isLondonQg = (): boolean => isEnv(londonQgHost);
const isGettysburg = (): boolean => isEnv(gettysburgHost);

const isLocal = (): boolean => process.env.HOST === "local";
const isGojira = (): boolean => ["gojira", "gojira2"].includes(process.env.HOST);
const isLocalOrGojira = (): boolean => isGojira() || isLocal();
const isStaging = (): boolean => process.env.HOST === "staging";
const isNotProduction = (): boolean => process.env.HOST !== "production";
const isProduction = (): boolean => process.env.HOST === "production";

export const env = {
  gettysburgUrl,
  londonUrl,
  queenstownUrl,
  pricingUrl,
  referralUrl,
  s3Url,
  recruitLogin,
  isLondon,
  isLondonQg,
  isGettysburg,
  isLocal,
  isGojira,
  isLocalOrGojira,
  isStaging,
  isNotProduction,
  isProduction,
};
