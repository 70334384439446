import * as React from "react";
import hotkeyService, {
  EventType,
  KeyHandler,
  EventKey,
  ModifierKey,
  ignoreWithoutModifiers,
} from "../services/hotkey";

interface HotkeyProps {
  event?: EventType;
  on: EventKey;
  handler: KeyHandler;
  modifiers?: ModifierKey | ModifierKey[];
  shouldIgnore?: KeyHandler<boolean>;
}

export default class Hotkey extends React.Component<HotkeyProps> {
  // timeout: null | NodeJS.Timeout = null;
  componentDidMount(): void {
    const { on, event, modifiers, shouldIgnore } = this.props;

    const ignoreFn = modifiers
      ? ignoreWithoutModifiers(typeof modifiers === "string" ? [modifiers] : modifiers)
      : shouldIgnore;
    // defer calls to register a new handler so that unregister events have run and cleared
    // otherwise a race condition between register and unregister calls for the same key can clobber each other
    // this.timeout = setTimeout(
    //   () => {
    hotkeyService.register(event || "keyup", on, this.handleEvent, ignoreFn);
    //     this.timeout = null;
    //   },
    //   5,
    // );
  }

  componentWillUnmount(): void {
    // if (this.timeout) {
    //   clearTimeout(this.timeout);
    // } else {
    const { on, event } = this.props;
    hotkeyService.unregister(event || "keyup", on, this.handleEvent);
    // }
  }

  handleEvent: KeyHandler = (e: KeyboardEvent) => this.props.handler(e);

  render(): null {
    return null;
  }
}
