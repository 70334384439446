import { darken } from "@mui/material/styles";
import { getContrastingTextColor } from "./getContrastingTextColor";

export function dynamicDarken(color: string): string {
  // This assumes the color here is to be displayed on a white or mostly white background
  let i = 0;
  let darkened = color;
  while (i < 1 && getContrastingTextColor(darkened, "#000", "#fff") !== "#fff") {
    i += 0.1;
    darkened = darken(color, i);
  }
  return darkened;
}
