import lodash from "lodash";
import { Theme } from "@mui/material";
import { TalentpairPaletteColors, TalentpairTheme } from "./types";

export type VarColorT = `var(--${string})`;
export type HexColorT = `#${string}`;

export type ColorPropT = TalentpairPaletteColors | VarColorT | HexColorT | "transparent";

export function colorProp(color: ColorPropT, theme: TalentpairTheme | Theme): string {
  if (
    color === "inherit" ||
    color.startsWith("var(--") ||
    color.startsWith("#") ||
    color === "transparent"
  )
    return color;
  const finalColor = lodash.get(theme.palette, color, "inherit");
  return typeof finalColor === "string" ? finalColor : finalColor.main || finalColor[500];
}
