import lodash from "lodash";
import { apiRequest, userService, s3 } from "@talentpair/api";
import { env } from "@talentpair/env";
import { portal } from "@talentpair/portal";
import { arrayDifference } from "kyoto/utils/array";
import { ValueT } from "kyoto/components/Profiles/editing/types";
import { listFieldUrl } from "kyoto/utils/misc";
import type { ResponseDataT } from "@talentpair/api";
import type { DispatchT, GetStateT } from "@talentpair/redux";
import type { ReferralCodeT } from "@talentpair/types/entities/misc";
import type {
  CapCandidateDetailT,
  CapCandidateDetailReduxT,
  CapCandidateListFieldsT,
} from "@talentpair/types/cap/entities/candidate";
import type { CapUserT } from "@talentpair/types/cap/entities/user";
import "@talentpair/types/cap/entities/user";
import type { StateT } from "../../store";
import { getCandidate } from "./candidateSelectors";
import track from "../../services/track";

type MaybeNewListItemT = {
  id?: number | null | undefined;
  name?: string;
};
export const CANDIDATE_RECEIVE_DETAIL = "CANDIDATE_RECEIVE_DETAIL";
export type CandidateReceiveDetailT = {
  type: "CANDIDATE_RECEIVE_DETAIL";
  candidateId: number;
  candidate: CapCandidateDetailT;
};

export const RECEIVED_CANDIDATE_REFERRAL_CODE = "RECEIVED_CANDIDATE_REFERRAL_CODE";
export type RecievedCandidateReferralCodeActionT = {
  type: "RECEIVED_CANDIDATE_REFERRAL_CODE";
  candidateId: number;
  referral_code: string;
};

export const CANDIDATE_UPDATE_SUCCESS = "CANDIDATE_UPDATE_SUCCESS";
export type CandidateUpdateSuccessActionT = {
  type: "CANDIDATE_UPDATE_SUCCESS";
  candidateId: number;
  candidate: CapCandidateDetailT;
};
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export type UserUpdateSuccessActionT = {
  type: "USER_UPDATE_SUCCESS";
  candidateId: number;
  user: CapUserT;
};
export const CANDIDATE_LIST_ITEM_UPDATE_SUCCESS = "CANDIDATE_LIST_ITEM_UPDATE_SUCCESS";
export type CandidateListItemUpdateSuccessActionT = {
  type: "CANDIDATE_LIST_ITEM_UPDATE_SUCCESS";
  candidateId: number;
  field: CapCandidateListFieldsT;
  item: ValueT;
};
export const CANDIDATE_LIST_ITEM_DELETE_SUCCESS = "CANDIDATE_LIST_ITEM_DELETE_SUCCESS";
export type CandidateListItemDeleteSuccessActionT = {
  type: "CANDIDATE_LIST_ITEM_DELETE_SUCCESS";
  candidateId: number;
  field: CapCandidateListFieldsT;
  item: ValueT;
};
export type CandidateActionT =
  | CandidateReceiveDetailT
  | CandidateUpdateSuccessActionT
  | CandidateListItemUpdateSuccessActionT
  | CandidateListItemDeleteSuccessActionT
  | UserUpdateSuccessActionT
  | RecievedCandidateReferralCodeActionT;

const candidateUpdateSuccess = <K extends keyof CapCandidateDetailT>(
  candidateId: number,
  candidate: Pick<CapCandidateDetailT, K>,
): CandidateUpdateSuccessActionT => ({
  type: CANDIDATE_UPDATE_SUCCESS,
  candidateId,
  candidate: lodash.omit(candidate, "omnis") as CapCandidateDetailReduxT,
});

const receiveDetail = (
  candidateId: number,
  candidate: CapCandidateDetailT,
): CandidateReceiveDetailT => ({
  type: CANDIDATE_RECEIVE_DETAIL,
  candidateId,
  candidate,
});

const fetchDetail =
  (candidateId: number) =>
  (dispatch: DispatchT): Promise<CapCandidateDetailReduxT> =>
    apiRequest
      .getMemoized<CapCandidateDetailReduxT>(`candidates-cap/${candidateId}/`)
      .then(({ data }) => {
        dispatch(receiveDetail(candidateId, data));
        return data;
      });

// Used for routes like PairDetail where we just need to know if the candidate detail has ever been loaded or not for
// calculating whether or not they can be sent. If it has, for these types of routes we can assume it's up to date.
const fetchDetailIfMissing =
  (candidateId: number) =>
  (dispatch: DispatchT, getState: GetStateT<StateT>): Promise<CapCandidateDetailReduxT> => {
    const candidate = getCandidate(getState(), candidateId);
    if (candidate) return Promise.resolve(candidate);
    return dispatch(fetchDetail(candidateId)) as unknown as Promise<CapCandidateDetailReduxT>;
  };

const updateCandidateFields =
  <K extends keyof CapCandidateDetailT>(candidateId: number, data: Record<K, unknown>) =>
  (dispatch: DispatchT<CandidateUpdateSuccessActionT>): Promise<void> =>
    apiRequest
      .patch<CapCandidateDetailT>(`candidates-cap/${candidateId}/`, data)
      .then(({ data: candidate }) => {
        dispatch(candidateUpdateSuccess(candidateId, candidate));
      });

type NameT = {
  name: (number | string) | null | undefined;
};
type PkT = {
  pk: (number | string) | null | undefined;
};
type TransformT = (arg0: MaybeNewListItemT) => MaybeNewListItemT | NameT | PkT;

const nameProp: TransformT = ({ id, name, ...other }): NameT => ({
  name: id || name,
  ...other,
});

const pkId: TransformT = ({ id }): PkT => ({
  pk: id,
});

const pkIdOrName: TransformT = ({ id, name, ...other }): PkT => ({
  pk: id || name,
  ...other,
});

// This handles which methods the BE supports and transforms items for BE if necessary
const listFieldConfig: Record<
  string,
  {
    post: TransformT;
    patch?: TransformT;
  }
> = {
  interested_verticals: {
    patch: pkIdOrName,
    post: pkIdOrName,
  },
  desired_omnis: {
    post: nameProp,
  },
  desired_verticals: {
    post: pkIdOrName,
  },
  educations: {
    patch: lodash.identity,
    post: lodash.identity,
  },
  ideal_roles: {
    post: pkIdOrName,
  },
  job_histories: {
    patch: lodash.identity,
    post: lodash.identity,
  },
  links: {
    patch: lodash.identity,
    post: lodash.identity,
  },
  omnis: {
    patch: lodash.identity,
    post: nameProp,
  },
  classifications: {
    patch: lodash.identity,
    post: pkId,
  },
  platforms: {
    patch: lodash.identity,
    post: pkIdOrName,
  },
  features: {
    patch: lodash.identity,
    post: pkIdOrName,
  },
  relocate_locations: {
    post: pkId,
  },
  commute_areas: {
    post: lodash.identity,
  },
};

const updateCandidateListItemRequest = <I>(
  candidateId: number,
  field: CapCandidateListFieldsT,
  item: MaybeNewListItemT,
  prevItem?: MaybeNewListItemT | null | undefined,
): Promise<ResponseDataT<I>> => {
  const transform = listFieldConfig[field];
  const endpoint = `candidates-cap/${candidateId}/${listFieldUrl(field)}/`;
  // Need to handle delete on your own if you can't patch in certain cases (such as omnis)
  return prevItem && transform.patch
    ? apiRequest.patch(`${endpoint}${item.id || ""}/`, transform.patch(item))
    : apiRequest.post(endpoint, transform.post(item));
};

type CandidateClassificationsResponseT = {
  activity: unknown;
  instance: {
    id: number;
    name: string;
    root_type: "job classification";
  };
};
export type ClassificationItemT = {
  id: number;
  name: string;
  root_type?: string;
};

const updateCandidateListItemSuccess = (
  candidateId: number,
  field: CapCandidateListFieldsT,
  item: ValueT,
): CandidateListItemUpdateSuccessActionT => ({
  type: CANDIDATE_LIST_ITEM_UPDATE_SUCCESS,
  candidateId,
  field,
  item,
});

const addCandidateClassifications =
  (candidateId: number, item: ClassificationItemT) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest
      .post<CandidateClassificationsResponseT>(`candidates/${candidateId}/classifications/`, {
        pk: item.id,
      })
      .then(({ data: { instance } }) =>
        dispatch(updateCandidateListItemSuccess(candidateId, "classifications", instance)),
      );

const deleteCandidateClassifications =
  (candidateId: number, item: ClassificationItemT) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest
      .delete(`candidates/${candidateId}/classifications/${item.id}/`)
      .then(() =>
        dispatch({
          type: CANDIDATE_LIST_ITEM_DELETE_SUCCESS,
          candidateId,
          field: "classifications",
          item,
        }),
      )
      .catch((e) => {
        portal.snackbar({
          message: "Something went wrong. Unable to save new order.",
          type: "error",
        });
        return Promise.reject(e);
      });

const addAndRemoveCandidateClassifications =
  (candidateId: number, newItems: ClassificationItemT[], prevItems: ClassificationItemT[]) =>
  (dispatch: DispatchT): Promise<unknown> => {
    const { add, remove } = arrayDifference(newItems, prevItems, "id");
    return Promise.all([
      ...add.map((item) => dispatch(addCandidateClassifications(candidateId, item))),
      ...remove.map((item) => dispatch(deleteCandidateClassifications(candidateId, item))),
    ]);
  };

const updateCandidateClassification =
  (candidateId: number, item: ClassificationItemT) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest
      .patch<CandidateClassificationsResponseT>(
        `candidates/${candidateId}/classifications/${item.id}/`,
        item,
      )
      .then(({ data: { instance } }) =>
        dispatch(updateCandidateListItemSuccess(candidateId, "classifications", instance)),
      );

const updateCandidateListItem =
  <I>(
    candidateId: number,
    field: CapCandidateListFieldsT,
    item: MaybeNewListItemT,
    prevItem?: MaybeNewListItemT | null | undefined,
  ) =>
  (dispatch: DispatchT): Promise<I> =>
    updateCandidateListItemRequest<I>(candidateId, field, item, prevItem).then(({ data }) => {
      dispatch(updateCandidateListItemSuccess(candidateId, field, data));
      return data;
    });

const optimisticUpdateCandidateListItem =
  (
    candidateId: number,
    field: CapCandidateListFieldsT,
    item: MaybeNewListItemT,
    prevItem?: MaybeNewListItemT | null | undefined,
  ) =>
  (dispatch: DispatchT, getState: GetStateT<StateT>): Promise<unknown> => {
    const candidate = getCandidate(getState(), candidateId);
    if (!candidate) return Promise.reject();
    dispatch(updateCandidateListItemSuccess(candidateId, field, item as ValueT));
    return updateCandidateListItemRequest(candidateId, field, item, prevItem).catch((e) => {
      // @ts-expect-error - we'll deal with you later
      const oldItem = candidate[field]?.find((i: ValueT) => i.id === item.id);
      dispatch(updateCandidateListItemSuccess(candidateId, field, oldItem));
      portal.snackbar({
        message: "Something went wrong. Unable to save new order.",
        type: "error",
      });
      return Promise.reject(e);
    });
  };

const deleteCandidateListItemRequest = (
  candidateId: number,
  field: CapCandidateListFieldsT,
  item: ValueT,
): Promise<unknown> =>
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  apiRequest.delete(`candidates-cap/${candidateId}/${listFieldUrl(field)}/${item.id}/`);

const deleteCandidateListItem =
  (candidateId: number, field: CapCandidateListFieldsT, item: ValueT) =>
  (dispatch: DispatchT): Promise<unknown> =>
    deleteCandidateListItemRequest(candidateId, field, item).then(() =>
      dispatch({
        type: CANDIDATE_LIST_ITEM_DELETE_SUCCESS,
        candidateId,
        field,
        item,
      }),
    );

const optimisticDeleteCandidateListItem =
  (candidateId: number, field: CapCandidateListFieldsT, item: ValueT) =>
  (dispatch: DispatchT): Promise<unknown> => {
    dispatch({
      type: CANDIDATE_LIST_ITEM_DELETE_SUCCESS,
      candidateId,
      field,
      item,
    });
    return deleteCandidateListItemRequest(candidateId, field, item).catch((e) => {
      dispatch(updateCandidateListItemSuccess(candidateId, field, item));
      return Promise.reject(e);
    });
  };

const IdFields = ["commute_areas", "classifications"];

const addAndRemoveCandidateListItems =
  (candidateId: number, field: CapCandidateListFieldsT, newItems: ValueT[], prevItems: ValueT[]) =>
  (dispatch: DispatchT): Promise<unknown> => {
    const { add, remove } = IdFields.includes(field)
      ? arrayDifference(newItems, prevItems, "id")
      : arrayDifference(newItems, prevItems);
    return Promise.all([
      ...add.map((item) => dispatch(updateCandidateListItem(candidateId, field, item))),
      ...remove.map((item) => dispatch(deleteCandidateListItem(candidateId, field, item))),
    ]);
  };

const updateUserFields =
  <K extends keyof CapUserT>(candidateId: number, userId: number, data: Record<K, unknown>) =>
  (dispatch: DispatchT): Promise<void> => {
    // @ts-expect-error - gotta do it
    if (data.phone === null) data.phone = "";
    return apiRequest.patch<CapUserT>(`users-cap/${userId}/`, data).then(({ data: user }) => {
      dispatch({
        type: USER_UPDATE_SUCCESS,
        candidateId,
        user,
      });
    });
  };

const getCandidateReferralCode =
  (candidateId: number) =>
  async (dispatch: DispatchT): Promise<string> =>
    apiRequest
      .getMemoized<ReferralCodeT>("users-platform/referral-code/")
      .then(({ data: { code: referral_code } }) => {
        dispatch({
          type: RECEIVED_CANDIDATE_REFERRAL_CODE,
          candidateId,
          referral_code,
        });
        return referral_code;
      });

const fetchCandidateWithReferralCode =
  (candidateId: number) =>
  async (dispatch: DispatchT): Promise<string> => {
    await dispatch(fetchDetail(candidateId));
    return dispatch(getCandidateReferralCode(candidateId));
  };

const deactivateAccount =
  (userId: number, candidateId: number) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest.post(`users-cap/${userId}/deactivate/`).then(() => {
      track.accountSettings({ action: "Deactivates Account" });
      portal.snackbar({
        message: "Your account has been deactivated.",
      });
      return Promise.allSettled([dispatch(fetchDetail(candidateId)), userService.getInfo()]);
    });

const reactivateAccount =
  (userId: number, candidateId: number) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest.post(`users-cap/${userId}/reactivate/`).then(() => {
      track.accountSettings({ action: "Reactivates Account" });
      portal.snackbar({
        message: "Success - your account is now Active!",
      });
      return Promise.allSettled([dispatch(fetchDetail(candidateId)), userService.getInfo()]);
    });

const uploadProfilePhoto =
  (file: File, candidateId: number) =>
  (dispatch: DispatchT, getState: GetStateT<StateT>): Promise<unknown> => {
    const candidate = getCandidate(getState(), candidateId);
    if (!candidate) return Promise.reject();
    return s3.uploadPhoto(file, candidate.user.id).then((url) => {
      track.profile({ action: "Uploaded Profile Photo" });
      const user = {
        ...candidate?.user,
        profile_picture_medium: env.s3Url(`/media/profile_pictures/${url}`),
      };
      dispatch({
        type: USER_UPDATE_SUCCESS,
        candidateId,
        user,
      });
    });
  };

export default {
  fetchDetail,
  fetchDetailIfMissing,
  receiveDetail,
  updateCandidateFields,
  updateCandidateListItem,
  optimisticUpdateCandidateListItem,
  deleteCandidateListItem,
  updateCandidateListItemSuccess,
  optimisticDeleteCandidateListItem,
  addAndRemoveCandidateListItems,
  updateUserFields,
  fetchCandidateWithReferralCode,
  getCandidateReferralCode,
  deactivateAccount,
  reactivateAccount,
  uploadProfilePhoto,
  // the following are candidate actions but only for recruiters and admins
  addCandidateClassifications,
  updateCandidateClassification,
  deleteCandidateClassifications,
  addAndRemoveCandidateClassifications,
  candidateUpdateSuccess,
};
export const _test = {
  updateCandidateListItemRequest,
  deleteCandidateListItemRequest,
  nameProp,
  pkId,
  pkIdOrName,
};
