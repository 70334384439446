import lodash from "lodash";
import { apiUrl } from "@talentpair/env";
import { MicroUserT } from "@talentpair/types/entities/user";
import { AllJobT } from "@talentpair/types/entities/job";
import { ChoiceT } from "@talentpair/types/entities/misc";
import { displayFullName } from "./user";

export const TAP_STATUS = {
  ACTIVE: 1,
  PAUSED: 2,
  CLOSED: 3,
  OFF: 4,
};

export const JOB_BOARD_STATUS = {
  ACTIVE: 2,
  STALE: 3,
  OPPORTUNISTIC: 1,
  CLOSED: 4,
};

export const SUBMISSION_PREFERENCE_METHODS = {
  EMAIL: 1,
  TAP: 2,
  ATS: 3,
  SLACK: 4,
};

export const SUBMISSION_PREFERENCES = {
  ANGELLIST: 1,
  BAMBOOHR: 2,
  BREEZY: 3,
  COMEET: 5,
  GREENHOUSE: 6,
  LEVER: 7,
  HIREBRIDGE: 8,
  JOBVITE: 9,
  WORKDAY: 10,
  GREENHOUSE_MANUAL: 11,
};

export type JobWithRoleT = {
  id: number;
  role: {
    name: string;

    [key: string]: unknown;
  } | null;
};

export type JobWithRoleAndOrgT = JobWithRoleT & {
  organization: {
    id: number;
    name: string;

    [key: string]: unknown;
  };
};

export const getHiringManager = (job: AllJobT): MicroUserT | null =>
  lodash.get(job, "hiring_manager");

export const getRole = <J extends JobWithRoleT>(
  job: J,
  defaultVal: string | null = "Untitled Job",
): string => lodash.get(job, ["role", "name"], defaultVal);

export function displayHiringManager(job: AllJobT): string {
  const hm: MicroUserT | null = getHiringManager(job);
  return hm ? displayFullName(hm) : "";
}

export function jobDescPreviewUrl(job: AllJobT): string {
  return apiUrl(`jobs/${job.id}/html-description/`);
}

export function getSalaryText(min?: number | null, max?: number | null): string | null {
  const minAmount: string = min != null ? `$${min.toLocaleString()}` : "";
  const maxAmount: string = max != null ? `$${max.toLocaleString()}` : "";

  if (!min && !max) return null;
  if (!min) return maxAmount;
  if (!max) return minAmount;

  return `${minAmount} - ${maxAmount}`;
}

export const displayJobAndOrg = (job: JobWithRoleAndOrgT): string =>
  `${getRole(job)} at ${job.organization.name}`;

export const displayRelocateOption = (val: number | string | boolean): string => {
  switch (+val) {
    case 0:
      return "Unwilling to relocate new hires.";
    case 1:
      return "Willing to relocate new hires.";
    default:
      return "";
  }
};

export const RELOCATE = {
  NO: false,
  YES: true,
};

type AtsJob = {
  submission_preference_method?: ChoiceT | null;
  submission_preference_link: string | null;
};
export type AtsIntegrationT = {
  type: "Greenhouse";
  id: number;
};
export function getAtsIntegration<J extends AtsJob>(job: J): AtsIntegrationT | null {
  if (
    job.submission_preference_method?.id === SUBMISSION_PREFERENCE_METHODS.ATS &&
    job.submission_preference_link
  )
    return {
      type: "Greenhouse",
      id: job.submission_preference_method.id,
    };
  return null;
}
