import * as React from "react";
import clsx from "clsx";
import lodash from "lodash";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
import { MailToLink } from "@talentpair/quantic";
import { stripProtocol } from "../utils/string";
import ExternalLink from "./ui/ExternalLink";

const Url = ({ text }: { text: string }): React.ReactElement => {
  const url = stripProtocol(text);
  return (
    <ExternalLink
      href={text.startsWith("http") ? text : `http://${text}`}
      text={
        url.length > 48 ? (
          <span>
            {url.slice(0, 48)}
            &hellip;
          </span>
        ) : (
          url
        )
      }
    />
  );
};

const spanRegexp = /<\/?span>/gi;
const mentionRegexp = /<span>(.+?)<\/span>/;

const RenderText = ({
  text,
  truncate,
  noLinks,
  className,
}: {
  text: string;
  truncate: boolean;
  noLinks: boolean;
  className?: string;
}): React.ReactElement => (
  <span className={clsx(className, "user-select-text", { "wrap-all pre-wrap": !truncate })}>
    {
      // Using lodash.unescape to replace some html entities.
      // Since these are still strings to React, we aren't allowing DOM nodes to be created
      lodash
        .unescape(text) // String.split with capturing group in regexp also includes the matched items so we can preserve original whitespace
        // "check this out\n google.com".split(/(\s)/) == ["check", " ", "this", " ", "out", "↵", "", " ", "google.com"]
        // Spec here: https://goo.gl/yUwbqC
        .split(/(\s)/)
        .reduce((acc: React.ReactNode[], word: string, i): React.ReactNode[] => {
          // Wrap links if not in notification feed
          if (!truncate && !noLinks) {
            if (isEmail(word)) {
              return [
                ...acc,
                <MailToLink key={i} email={word} color="" className="hover-underline" />, // eslint-disable-line react/no-array-index-key
              ];
            }
            if (isURL(word)) {
              return [...acc, <Url key={i} text={word} />]; // eslint-disable-line react/no-array-index-key
            }
          }
          if (!truncate && !noLinks && isURL(word) && !isEmail(word)) {
            return [...acc, <Url key={i} text={word} />]; // eslint-disable-line react/no-array-index-key
          }
          // Handle our @mentions
          if (mentionRegexp.test(word)) {
            return [
              ...acc, // eslint-disable-next-line react/no-array-index-key
              <span key={i} className="blue-800">
                {word.replace(spanRegexp, "")}
              </span>,
            ];
          }

          const lastEl = acc[acc.length - 1];
          return typeof lastEl === "string"
            ? [...acc.slice(0, -1), `${lastEl}${word}`]
            : [...acc, word];
        }, [])
    }
  </span>
);
RenderText.defaultProps = { truncate: false, noLinks: false };

export default RenderText;
export const _test = { Url };
