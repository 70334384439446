import * as React from "react";

export type FetchT<D = void> = () => Promise<D>;
interface UseFetcherState<D> {
  loading: boolean;
  rsp: D | null;
  err: Error | null;
}

export interface UseFetcher<D> extends UseFetcherState<D> {
  refetch: () => void;
}

const initialState = {
  loading: true,
  rsp: null,
  err: null,
};

export function useFetch<D>(fetch: FetchT<D>): UseFetcher<D> {
  const [state, setState] = React.useState<UseFetcherState<D>>({
    ...initialState,
  });
  const promise = React.useRef<null | Promise<D>>(null);
  const refetch = React.useCallback(
    (reset = false) => {
      setState((current) =>
        reset
          ? { ...initialState }
          : {
              ...current,
              loading: true,
            },
      );
      const currentPromise = fetch();
      promise.current = currentPromise;
      currentPromise
        .then((rsp) => {
          if (currentPromise === promise.current) setState({ loading: false, rsp, err: null });
        })
        .catch((err) => {
          if (currentPromise === promise.current) setState({ loading: false, rsp: null, err });
        });
    },
    [fetch],
  );
  React.useEffect(() => {
    refetch(true);
    return () => {
      promise.current = null;
    };
  }, [refetch]);
  return { ...state, refetch };
}

// simple wrapper around useFetch that uses a key to trigger refetches
export function useFetcher<D>(fetch: FetchT<D>, key: string | number = "static"): UseFetcher<D> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetcher = React.useCallback(() => fetch(), [key]);
  return useFetch(fetcher);
}
