/**
 * Makes a promise "cancelable" so that its `then` and `catch` handlers can be prevented while also allowing for
 * garbage collection. Taken from https://github.com/facebook/react/issues/5465#issuecomment-339544623
 */
export type CancelablePromiseT<R> = Promise<R> & { cancel: () => void };

export function makeCancelable<R>(promise: Promise<R>): CancelablePromiseT<R> {
  let cancel = (): void => {};

  const wrappedPromise = new Promise<R>((resolve, reject) => {
    let res: ((value: R | PromiseLike<R>) => void) | null = resolve;
    let rej: ((reason?: unknown) => void) | null = reject;
    cancel = (): void => {
      res = null;
      rej = null;
    };

    promise.then(
      (val) => {
        if (res) res(val);
      },
      (error) => {
        if (rej) rej(error);
      },
    );
  });

  // @ts-expect-error - eslint upgrade
  wrappedPromise.cancel = cancel;
  // @ts-expect-error - eslint upgrade
  return wrappedPromise;
}
