import { apiRequest } from "@talentpair/api";
import * as React from "react";
import promptAppRefresh from "./promptAppRefresh";
import Ping from "./Ping";

const invalidHosts = ["local", "gojira2"];

const AppVersionPing = ({
  app,
}: {
  app: "london" | "queenstown" | "perryai";
}): React.ReactElement | null =>
  invalidHosts.includes(process.env.HOST) ? null : (
    <Ping
      interval={300000} // ping every 5 minutes
      pingIfLoggedOut
      request={() =>
        apiRequest
          .get("notifications/frontendversion/", {
            app,
            version: process.env.TAG,
          })
          .then(({ status }) => (status === 205 ? promptAppRefresh() : null))
      }
    />
  );

export default AppVersionPing;
