import { NotificationCountStore, userService } from "@talentpair/api";
import { connect } from "@talentpair/redux";
import NotificationPing from "kyoto/components/NotificationPing";
import type { UrgentNotificationT } from "kyoto/components/NotificationPing";

type DispatchToPropsT = {
  action: (notification: UrgentNotificationT) => unknown;
};
const mapDispatchToProps = (): DispatchToPropsT => ({
  action: ({ has_new_notifications, notification_count, chore_count = 0 }) =>
    NotificationCountStore.setCount(
      userService.isRecruiter() ? chore_count : notification_count,
      has_new_notifications,
    ),
});

export default connect(null, mapDispatchToProps)(NotificationPing);
export const _test = { mapDispatchToProps };
