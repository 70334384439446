// @flow
import * as React from "react";
import { page } from "../../components/layout/responsive";
import ProfileTabs from "../components/ProfileTabs";
import { CapProfileHeader, CapProfileMeta } from "../containers/ProfileHeader";

const CapProfile = ProfileTabs(React.lazy(() => import("./containers/ProfilePage")));

export const CapProfilePage = page({
  mobile: CapProfile,
  header: CapProfileHeader,
  meta: CapProfileMeta,
});
