import { ChoiceT, OrderedOmniT, SoftWarningT } from "@talentpair/types/entities/misc";
import { InterviewConflictT } from "@talentpair/types/entities/pipeline";
import { displayTimeSlot } from "@talentpair/datetime";
import { displayFullName } from "@talentpair/entities/user";

export const getImage = (entity: {
  logo_url?: string | null;
  profile_picture_medium?: string | null;
}): string | null => entity.logo_url || entity.profile_picture_medium || null;

export const Remote = {
  NO: 0,
  OPEN: 1,
  ONLY: 2,
  HYBRID: 3,
} as const;

const RemoteLabel = {
  NO_REMOTE: "100% onsite jobs",
  OPEN_TO_REMOTE: "Open to remote jobs",
  ONLY_REMOTE: "100% remote jobs",
};

const TapRemoteLabel = {
  NO_REMOTE: "100% onsite",
  OPEN_TO_REMOTE: "Open to remote",
  ONLY_REMOTE: "100% remote",
  HYBRID_REMOTE: "Hybrid remote",
};

export const displayRemoteOption = (val: number | string, isCap?: boolean): string => {
  switch (+val) {
    case 0:
      return RemoteLabel.NO_REMOTE;
    case 1:
      return isCap ? "Open to remote and hybrid jobs" : RemoteLabel.OPEN_TO_REMOTE;
    case 2:
      return RemoteLabel.ONLY_REMOTE;
    default:
      return "";
  }
};

export const displayTapRemoteOption = (val: number | string): string => {
  switch (+val) {
    case 0:
      return TapRemoteLabel.NO_REMOTE;
    case 1:
      return TapRemoteLabel.OPEN_TO_REMOTE;
    case 2:
      return TapRemoteLabel.ONLY_REMOTE;
    case 3:
      return TapRemoteLabel.HYBRID_REMOTE;
    default:
      return "";
  }
};

export const displayCapJobRemoteOption = (val: number | string): string => {
  switch (+val) {
    case 0:
      return "Onsite";
    case 1:
      return "Remote available";
    case 2:
      return "100% remote";
    case 3:
      return "Hybrid remote";
    default:
      return "";
  }
};

export const displaySeniority = (score: number): string => {
  switch (score) {
    case 10:
      return "Intern";
    case 20:
      return "Junior";
    case 40:
      return "Mid-Level";
    case 60:
      return "Senior";
    case 70:
      return "Director";
    case 80:
      return "Vice President";
    case 100:
      return "C-Level";
    default:
      return "";
  }
};

export const displayMinimumEducation = (score: number | null | undefined): string => {
  switch (score) {
    case 10:
      return "Bachelor's degree";
    case 20:
      return "Master's degree";
    case 30:
      return "Doctorate";
    case 1:
    default:
      return "No preference";
  }
};

export const interviewRoundChoices = [
  { id: 1, name: "1st" },
  { id: 2, name: "2nd" },
  { id: 3, name: "3rd" },
  { id: 4, name: "4th" },
];

export const interviewTypeChoices = [
  { id: 1, name: "Onsite" },
  { id: 2, name: "Phone" },
  { id: 3, name: "Homework Assignment" },
  { id: 4, name: "Test" },
  { id: 6, name: "Video Conference" },
];

// Convert a conflicting interview into a soft warning
export function conflictToSoftWarning({
  interview: { candidate, interviewer, job, start_time, end_time },
  type,
}: InterviewConflictT): SoftWarningT {
  const message = `Conflict: ${displayFullName(
    type === "candidate_conflict" ? candidate.user : interviewer,
  )} has an interview with ${
    type === "candidate_conflict" ? job.organization.name : displayFullName(candidate.user)
  } ${displayTimeSlot({ start_time, end_time }, true, true)}`;
  return { level: "soft", message, type };
}

export const remoteChoices: ChoiceT[] = [
  { name: RemoteLabel.ONLY_REMOTE, id: Remote.ONLY },
  { name: RemoteLabel.OPEN_TO_REMOTE, id: Remote.OPEN },
  { name: RemoteLabel.NO_REMOTE, id: Remote.NO },
];

export const remoteChoicesForCap: ChoiceT[] = [
  { name: RemoteLabel.ONLY_REMOTE, id: Remote.ONLY },
  { name: "Open to remote and hybrid jobs", id: Remote.OPEN },
  { name: RemoteLabel.NO_REMOTE, id: Remote.NO },
];

export const tapRemoteChoices: ChoiceT[] = [
  { name: TapRemoteLabel.ONLY_REMOTE, id: Remote.ONLY },
  { name: TapRemoteLabel.OPEN_TO_REMOTE, id: Remote.OPEN },
  { name: TapRemoteLabel.NO_REMOTE, id: Remote.NO },
  { name: TapRemoteLabel.HYBRID_REMOTE, id: Remote.HYBRID },
];

export const tapRemoteChoicesWithHires: ChoiceT[] = [
  { name: `${TapRemoteLabel.ONLY_REMOTE} hires`, id: Remote.ONLY },
  { name: `${TapRemoteLabel.OPEN_TO_REMOTE} hires`, id: Remote.OPEN },
  { name: `${TapRemoteLabel.NO_REMOTE} hires`, id: Remote.NO },
  { name: `${TapRemoteLabel.HYBRID_REMOTE} hires`, id: Remote.HYBRID },
];

export const Relo = {
  NO: 0,
  OPEN: 1,
  ONLY: 2,
};

export const ReloLabel = {
  ONLY_RELOS: "Relocation only",
  OPEN_TO_RELOS: "Open to relocation positions",
  NO_RELOS: "Not open to relocation positions",
};

export const reloChoices = [
  { id: Relo.ONLY, name: ReloLabel.ONLY_RELOS },
  { id: Relo.OPEN, name: ReloLabel.OPEN_TO_RELOS },
  { id: Relo.NO, name: ReloLabel.NO_RELOS },
];

export const displayReloOption = (val: number | string): string => {
  switch (+val) {
    case 0:
      return ReloLabel.NO_RELOS;
    case 1:
      return ReloLabel.OPEN_TO_RELOS;
    case 2:
      return ReloLabel.ONLY_RELOS;
    default:
      return "";
  }
};

export const ReportType = {
  MANUAL: "MA",
  EMAIL_SIGNUP: "ES",
  NOT_LOOKING: "NL",
  QUALITY_CHECK: "QC",
  ACCOUNT_DEACTIVATED: "AD",
};

export const InterviewType = {
  ONSITE: 1,
  PHONE: 2,
  HOMEWORK: 3,
  TEST: 4,
  VIDEO: 6,
};

export const PricingPlanType = {
  CONTINGENCY: 1,
  PARTNERSHIP: 2,
  ENGAGED_SEARCH: 3,
  ACCESS: 4,
  PLATFORM: 5,
  CUSTOM: 6,
  SOURCING: 7,
};

export const PricingPlanLabels = [
  "", // 0 not used
  "Contingency",
  "Partnership",
  "Engaged Search",
  "Access",
  "Platform",
  "Custom Stripe plan",
  "Sourcing",
];

export const InvoiceFeeType = {
  OTHER: 0,
  JOB_ACTIVATION: 1,
  PLACEMENT: 2,
};

export function isStripePlan(plan?: null | { type: ChoiceT } | number): boolean {
  return (
    [
      PricingPlanType.SOURCING,
      PricingPlanType.ACCESS,
      PricingPlanType.PLATFORM,
      PricingPlanType.CUSTOM,
    ] as Array<unknown>
  ).includes(typeof plan === "number" ? plan : plan?.type.id);
}

export const PartnershipFeeLevel = {
  INDIVIDUAL_CONTRIBUTOR: 1,
  MANAGER_OR_DIRECTOR: 2,
  EXECUTIVE_OR_CSUITE: 3,
};

export const FeeAgreementTemplateForPlanType = {
  [PricingPlanType.CONTINGENCY]: "TP Contingency",
  [PricingPlanType.PARTNERSHIP]: "TP Partnership",
  [PricingPlanType.ENGAGED_SEARCH]: "TP Retained Search",
  [PricingPlanType.ACCESS]: "TP Unified",
  [PricingPlanType.PLATFORM]: "TP Unified",
  [PricingPlanType.CUSTOM]: "TP Unified",
  [PricingPlanType.SOURCING]: "TP Unified",
};

/*

  ORDERED OMNI HELPER UTILS

*/
export function sortItemsByOrder<I extends { order: number }>(a: I, b: I): number {
  return a.order - b.order;
}
export function sortTopSkillsToFront(a: OrderedOmniT, b: OrderedOmniT): number {
  return a.top === b.top ? 0 : a.top ? -1 : 1;
}
