import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, LocationState, RouteComponentProps } from "@talentpair/routing";
import { Toolbar } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import { userService } from "@talentpair/api";
import { Button, Typography } from "@talentpair/quantic";
import { connect, MapStateToProps, useDispatch } from "@talentpair/redux";
import AuthButtons from "kyoto/components/AuthButtons";
import CandidateHeaderActions from "kyoto/components/CandidateHeaderActions";
import { getRoutePath } from "../../routeConfig";
import NotificationAction from "../../shared-apps/Notifications/store/notificationActions";
import type { StateT } from "../../store";

import AstralLink from "../AstralLink";
import { NavSearchButton } from "../NavSearch/NavSearchButton";

export interface MetaPropsT {
  title: string;
}
type MapStateToTitleProps<Params extends Record<string, string | undefined>> = MapStateToProps<
  MetaPropsT,
  TitleProps<Params> & RouteComponentProps<Params>,
  StateT
>;
type TitleMetaT<Params extends Record<string, string | undefined>> =
  | string
  | MapStateToTitleProps<Params>;
type TitleHeaderT<Params extends Record<string, string | undefined>> =
  | React.ReactNode
  | MapStateToTitleProps<Params>;

const Meta = ({ title }: MetaPropsT): React.ReactElement | null =>
  title !== "Talentpair" ? (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  ) : null;

export function createMeta<Params extends Record<string, string | undefined>>(
  title: TitleMetaT<Params>,
) {
  return (props: RouteComponentProps<Params>): React.ReactElement | null => {
    if (typeof title === "string") return <Meta title={title} />;
    const ConnectedMeta = connect(title)(Meta);
    return <ConnectedMeta {...props} />;
  };
}

interface BackButtonProps {
  home: boolean;
  onClick?: () => unknown;
}
const BackButton = ({ home, ...props }: BackButtonProps): React.ReactElement => (
  <Button
    size="lg"
    variant="icon"
    icon={home ? HomeIcon : ArrowBackIcon}
    style={{ marginLeft: -8 }}
    {...props}
  />
);

interface TitleProps<Params extends Record<string, string | undefined>> {
  title?: TitleHeaderT<Params>;
}
const Title = <Params extends Record<string, string | undefined>>({
  title = "Talentpair",
}: TitleProps<Params>): React.ReactElement | null =>
  title ? (
    <Typography variant="h2" color="common.white">
      <AstralLink responsive>{title}</AstralLink>
    </Typography>
  ) : null;

export interface HeaderProps<
  Params extends { [K in keyof Params]?: string } = Record<string, string | undefined>,
> extends RouteComponentProps<Params> {
  children?: React.ReactNode;
  desktop?: boolean;
}

export type HeaderBackSelectorT<
  Params extends Record<string, string | undefined>,
  State extends LocationState = LocationState,
> =
  | string
  | ((
      routeContext: RouteComponentProps<Params, Record<string, unknown>, State>,
    ) => string | (() => void));

export function createCapHeader<
  Params extends Record<string, string | undefined>,
  State extends LocationState = LocationState,
>(
  title: TitleHeaderT<Params>,
  backSelector: HeaderBackSelectorT<Params, State> = "",
  home = false,
) {
  return ({ children, ...props }: HeaderProps<Params>): React.ReactElement => {
    const dispatch = useDispatch();

    const backLink =
      typeof backSelector === "string"
        ? backSelector
        : backSelector(props as RouteComponentProps<Params, Record<string, unknown>, State>);

    const leftAction = (
      <div style={{ minWidth: "96px" }}>
        {!backLink ? null : typeof backLink === "function" ? (
          <BackButton onClick={backLink} home={home} />
        ) : (
          <Link className="color-inherit" to={backLink}>
            <BackButton home={home} />
          </Link>
        )}
        {backLink && userService.isRecruiter() ? <NavSearchButton /> : null}
      </div>
    );

    let renderedTitle: React.ReactNode = null;
    if (typeof title !== "function") {
      renderedTitle = <Title title={title} />;
    } else {
      const ConnectedTitle = connect<
        MetaPropsT,
        unknown,
        TitleProps<Params> & RouteComponentProps<Params, Record<string, unknown>, State>,
        StateT
      >(title as MapStateToTitleProps<Params>)(Title);
      // @ts-expect-error - it's fine
      renderedTitle = <ConnectedTitle {...props} />;
    }

    const rightAction = props.match.path.includes("/account-closed") ? (
      <AuthButtons />
    ) : (
      <CandidateHeaderActions
        showReferralLink={props.match.path !== getRoutePath("CAP/ReferFriends")}
        candidateId={
          props.match.params.candidate
            ? +props.match.params.candidate
            : userService.data?.candidate_id || 0
        }
        pathname={props.location.pathname}
        onNotificationClick={() => dispatch(NotificationAction.setView("all"))}
      />
    );

    return (
      <Toolbar className="relative flex-center">
        <div className="flex-auto flex justify-between items-center">
          {leftAction}
          {rightAction}
        </div>
        <div className="absolute flex-center">{renderedTitle}</div>
      </Toolbar>
    );
  };
}

export const _test = { Meta, BackButton, Title };
