import * as React from "react";
import { Snackbar, PortalSnackbarPropsT } from "./Snackbar";

type PortalTypeT = "modal" | "snackbar";
export type KeyT = number | string;

export type PortalPropsT = {
  open: boolean;
  onClose: () => unknown;
  onExited: () => unknown;
};

export type ConfigPropsT<P extends PortalPropsT> = Omit<P, keyof PortalPropsT> & {
  key?: KeyT;
  onExited?: PortalPropsT["onExited"];
};
interface PortalT<P extends PortalPropsT> {
  key: KeyT;
  cmp: React.ComponentType<P>;
  props: P;
  type: PortalTypeT;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StateT = PortalT<any>[];
interface PortalEventT {
  type: "add" | "update" | "close" | "remove";
  key: KeyT;
}

type ListenerT = (state: StateT, event: PortalEventT) => void;

interface PortalStoreT {
  modal: <P extends PortalPropsT>(cmp: React.ComponentType<P>, props?: ConfigPropsT<P>) => KeyT;
  snackbar: <P extends PortalPropsT & PortalSnackbarPropsT>(props: ConfigPropsT<P>) => KeyT;
  remove: (key: KeyT, cb?: () => unknown) => void;
  getState: () => StateT;
  getStateForType: (type: PortalTypeT) => StateT;
  subscribe: (cb: ListenerT) => () => void;
}

// Like Redux... for Material UI Portal-based components
export function createPortalStore(): PortalStoreT {
  let key = 0;
  let state: StateT = [];
  const subscribers: ListenerT[] = [];

  const getState = (): StateT => state;

  const getStateForType = (type: PortalTypeT): PortalT<PortalPropsT>[] =>
    getState().filter((o) => o.type === type);

  function callSubscribers(event: PortalEventT): void {
    const currSubscribers = subscribers.slice();
    currSubscribers.forEach((listener) => {
      listener(getState(), event);
    });
  }

  const remove = (id: KeyT, cb?: () => unknown) => (): void => {
    state = state.filter((portal) => portal.key !== id);
    callSubscribers({ key: id, type: "remove" });
    if (cb) cb();
  };

  // Need to close before removing to allow for transition components to complete
  const close = (id: KeyT) => (): void => {
    const index = state.findIndex((portal) => portal.key === id);
    if (index !== -1) {
      state = [
        ...state.slice(0, index),
        { ...state[index], props: { ...state[index].props, open: false } },
        ...state.slice(index + 1),
      ];
      callSubscribers({ key: id, type: "close" });
    }
  };

  function createElement<P extends PortalPropsT>(
    type: PortalTypeT,
    cmp: React.ComponentType<P>,
    keyId: KeyT,
    props: ConfigPropsT<P>,
  ): PortalT<P> {
    return {
      key: keyId,
      type,
      cmp,
      // @ts-expect-error - eslint upgrade
      props: {
        ...props,
        open: true,
        onClose: close(keyId),
        onExited: remove(keyId, props.onExited),
      },
    };
  }
  // Currently only supports Material UI props
  function add<P extends PortalPropsT>(
    type: PortalTypeT,
    cmp: React.ComponentType<P>,
    props: ConfigPropsT<P>,
  ): KeyT {
    // Sometimes we need to update the same component (ping related)
    if (props.key) {
      const element = createElement(type, cmp, props.key, props);
      const index = state.findIndex((portal) => portal.key === props.key);
      if (index !== -1) {
        state = [...state.slice(0, index), element, ...state.slice(index + 1)];
        callSubscribers({ key: props.key, type: "update" });
      } else {
        state = [...state, element];
        callSubscribers({ key: props.key, type: "add" });
      }
    } else {
      key += 1;
      state = [...state, createElement(type, cmp, key, props)];
      callSubscribers({ key, type: "add" });
    }
    return props.key || key;
  }

  return {
    // @ts-expect-error - eslint upgrade
    modal: (cmp, props = {}): KeyT => add("modal", cmp, props),
    snackbar: (props): KeyT => add("snackbar", Snackbar, props),
    // You probably won't need this, but occasionally you need to force a portal to delete from state
    remove: (keyToDelete, cb): void => remove(keyToDelete, cb)(),
    getState,
    getStateForType,
    subscribe(listener: ListenerT): () => void {
      subscribers.push(listener);
      return function unsubscribe(): void {
        subscribers.splice(subscribers.indexOf(listener), 1);
      };
    },
  };
}

export const portal = createPortalStore();
