import * as React from "react";
import { Grid } from "@mui/material";
import { Pane, Item, Title, Actions, PrimaryButton, Button } from "@talentpair/quantic";
import { DocumentInput } from "kyoto/components/FileInput";
import ResumeProgressSvg from "kyoto/components/ResumeProgressSvg";

export interface PickResumePaneProps {
  error: string;
  children?: React.ReactNode;
  uploadMessage?: string | null;
  onSkip?: null | ((event: React.SyntheticEvent) => unknown);
  onLinkGoogleDoc: null | (() => void);
  onPickFile: (files: FileList | null) => void | Promise<unknown>;
}

const PickResumePane = ({
  error,
  onLinkGoogleDoc,
  onPickFile,
  children = null,
  onSkip = null,
}: PickResumePaneProps): React.ReactElement => {
  const ref = React.useRef<HTMLInputElement | null>(null);
  return (
    <Pane>
      <Item>
        <ResumeProgressSvg state={error ? "fail" : "document"} />
      </Item>
      {!!children && <Item>{children}</Item>}
      {error && (
        <Item>
          <Title style={{ color: "#d25786" }}>{error}</Title>
        </Item>
      )}
      <Item>
        <p className="center h5">Talentpair supports the following file types:</p>
        <Grid classes={{ container: "px3" }} container justifyContent="center" spacing={2}>
          {[".doc", ".docx", ".html", ".pdf", ".rtf", ".txt"].map((ext) => (
            <Grid key={ext} item>
              <button
                className="border-none bg-grey-200 grey-600 h5 line-height-1 p2 pointer rounded"
                style={{ fontWeight: 500 }}
                onClick={() => ref.current?.click()}
                type="button"
              >
                {ext.toUpperCase()}
              </button>
            </Grid>
          ))}
        </Grid>
        {onLinkGoogleDoc ? (
          <>
            <p className="center h5">or</p>
            <Grid classes={{ container: "px3 mb3" }} container justifyContent="center" spacing={2}>
              <Grid item>
                <button
                  className="border-none bg-grey-200 grey-600 h5 line-height-1 p2 pointer rounded"
                  style={{ fontWeight: 500 }}
                  onClick={onLinkGoogleDoc}
                  type="button"
                >
                  Link a Google doc
                </button>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Item>
      <Actions>
        <Grid container justifyContent="space-around" spacing={3} wrap="nowrap">
          {onSkip && (
            <Grid item>
              <Button variant="contained" onClick={onSkip} data-testid="skipBtn">
                Skip for now
              </Button>
            </Grid>
          )}
          <Grid item>
            <PrimaryButton component="label">
              <DocumentInput
                inputRef={(el: HTMLInputElement | null) => {
                  ref.current = el;
                }}
                onChange={onPickFile}
                name="resume"
              />
              Choose a file
            </PrimaryButton>
          </Grid>
        </Grid>
      </Actions>
    </Pane>
  );
};

export default PickResumePane;
