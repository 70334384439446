// @flow
import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { TapProfileMeta } from "./containers/ProfileHeader";
import { SideNavigation } from "../components/Navigation/SideNavigation";

const TapProfile = React.lazy(() => import("./containers/ProfilePage"));

export const TapProfilePage = page({
  desktop: TapProfile,
  mobile: TapProfile,
  desktopSidenav: SideNavigation,
  header: TapHeader,
  meta: TapProfileMeta,
});
