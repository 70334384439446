// @flow
import { apiRequest, type ResponseDataT, userService } from "@talentpair/api";
import { type TapUserDetailT, type TapUserJobT } from "@talentpair/types/tap/entities/user";
import type { DispatchT, GetStateT } from "@talentpair/redux";
import track from "../../services/track";
import type { StateT } from "../../store";
import type { OrgReceiveDetailActionT } from "./orgActions";
import { getOrgUser } from "./orgSelectors";

export const ORG_USER_RECEIVE_DETAIL = "ORG_USER_RECEIVE_DETAIL";
export type OrgUserReceiveDetailActionT = {
  type: "ORG_USER_RECEIVE_DETAIL",
  orgId: number,
  user: TapUserDetailT,
  ...
};

export const ORG_USER_LOGIN_BLOCKED = "ORG_USER_LOGIN_BLOCKED";
export type OrgUserLoginBlockedT = {
  type: "ORG_USER_LOGIN_BLOCKED",
  orgId: number,
  user: {
    id: number,
    login_blocked: boolean,
    ...
  },
  ...
};

export const ORG_USER_RECEIVE_JOBS = "ORG_USER_RECEIVE_JOBS";
export type OrgUserReceiveJobsActionT = {
  type: "ORG_USER_RECEIVE_JOBS",
  orgId: number,
  user: {
    id: number,
    jobs: TapUserJobT[],
    ...
  },
  ...
};

export const ORG_USER_DELETED = "ORG_USER_DELETED";
export type OrgUserDeletedActionT = {
  type: "ORG_USER_DELETED",
  orgId: number,
  userId: number,
  ...
};

export type OrgUserActionT =
  | OrgReceiveDetailActionT
  | OrgUserReceiveDetailActionT
  | OrgUserReceiveJobsActionT
  | OrgUserDeletedActionT
  | OrgUserLoginBlockedT;

const receiveUserDetail = (orgId: number, user: TapUserDetailT): OrgUserReceiveDetailActionT => ({
  type: ORG_USER_RECEIVE_DETAIL,
  orgId,
  user,
});

const fetchUserJobs =
  (orgId: number, userId: number) =>
  (dispatch: DispatchT<>, getState: GetStateT<StateT>): Promise<*> => {
    if (!userService.isOrgAdmin()) return Promise.resolve();

    // Must ensure user is already in redux before fetching admin data. Same reason as https://bit.ly/2HaCPzR
    const detailRequest = getOrgUser(getState(), userId)
      ? Promise.resolve()
      : dispatch(fetchUserDetail(orgId, userId, false)); // eslint-disable-line no-use-before-define

    return detailRequest.then(() =>
      apiRequest
        .getMemoized(`users-tap/${userId}/admin/`)
        .then(({ data: { jobs } }: ResponseDataT<{ jobs: TapUserJobT[], ... }>) =>
          dispatch({
            type: ORG_USER_RECEIVE_JOBS,
            orgId,
            user: { id: userId, jobs },
          }),
        ),
    );
  };

const fetchUserDetail =
  (orgId: number, userId: number, fetchJobs?: boolean = true) =>
  (dispatch: DispatchT<>) =>
    Promise.all([
      apiRequest
        .getMemoized(`users-tap/${userId}/`)
        .then(({ data }: ResponseDataT<TapUserDetailT>) =>
          dispatch(receiveUserDetail(orgId, data)),
        ),
      fetchJobs ? dispatch(fetchUserJobs(orgId, userId)) : Promise.resolve(),
    ]);

const receiveUserLoginBlocked = (
  orgId: number,
  id: number,
  login_blocked: boolean,
): OrgUserLoginBlockedT => ({
  type: ORG_USER_LOGIN_BLOCKED,
  orgId,
  user: { id, login_blocked },
});

const inviteUser = (orgId: number, userId: number) => (dispatch: DispatchT<>) =>
  apiRequest
    .post(`users-tap/${userId}/invite/`, { organization: orgId })
    .then(() => dispatch(receiveUserLoginBlocked(orgId, userId, false)));

const createUser =
  (orgId: number, userData: { [string]: string, ... }) => (dispatch: DispatchT<>) =>
    apiRequest
      .post("users-tap/", { ...userData, organization: orgId })
      .then(({ data }: ResponseDataT<TapUserDetailT>) => {
        dispatch(receiveUserDetail(orgId, data));
        dispatch(inviteUser(orgId, data.id));
        track.companyAdmin({ action: "Invite New User" });
        return data;
      });

const updateUser =
  (orgId: number, userId: number, userData: { [string]: *, ... }) => (dispatch: DispatchT<>) =>
    apiRequest
      .patch(`users-tap/${userId}/`, userData)
      .then(({ data }: ResponseDataT<TapUserDetailT>) => {
        dispatch(receiveUserDetail(orgId, data));
        return data;
      });

const deleteUser =
  (orgId: number, userId: number) => (dispatch: DispatchT<OrgUserDeletedActionT>) =>
    apiRequest
      .delete(`users-tap/${userId}/`)
      .then(() => dispatch({ type: ORG_USER_DELETED, orgId, userId }));

export default {
  receiveUserDetail,
  fetchUserJobs,
  fetchUserDetail,
  receiveUserLoginBlocked,
  inviteUser,
  createUser,
  updateUser,
  deleteUser,
};
