import * as React from "react";
import { Tooltip } from "@mui/material";
import { dateFormat } from "@talentpair/datetime";
import { Typography, TypographyProps } from "../atoms/Typography";

function defaultFormatter(date: string | Date): string {
  return dateFormat.quanticRelative(typeof date === "string" ? date : date.toISOString());
}

function relativeFormatter(date: string | Date): string {
  return dateFormat.fromNowShort(typeof date === "string" ? date : date.toISOString());
}

function fullFormatter(date: string | Date): string {
  return dateFormat.fullTimestamp(typeof date === "string" ? date : date.toISOString());
}

const handles = new Set<() => unknown>();
let timerId: NodeJS.Timer | null = null;

function onTick(): void {
  if (window.document.visibilityState === "visible" && handles.size)
    handles.forEach((setter) => setter());
}

function registerUpdateInterval(setter: () => unknown): void {
  handles.add(setter);
  // if you're the first to arrive: turn on the lights
  if (handles.size === 1 && !timerId) {
    timerId = setInterval(onTick, 30000); // update every 30 seconds
  }
}

function unregisterUpdateInterval(setter: () => unknown): void {
  handles.delete(setter);
  // if you're the last to leave: turn off the lights
  if (!handles.size && timerId) clearInterval(timerId);
}

export type TimestampProps = {
  date: string | Date;
  variant?: "default" | "full" | "relative" | "dense";
  typography?: TypographyProps["variant"];
  color?: TypographyProps["color"];
};

const BaseTimestamp = ({
  typography = "body3",
  ...props
}: Omit<TypographyProps, "variant"> & Pick<TimestampProps, "typography">): React.ReactElement => (
  <Typography data-testid="timestamp" color="grey.500" {...props} variant={typography} />
);

// LiveTimestamp uses hooks to rerender the timestamp once every minute to keep the UI accurate
// You only want to use this if you want to display the timestamp in a format relative to the current time and therefore needs to update as time marches on.
function LiveTimestamp({
  date,
  formatter,
  ...props
}: Omit<TimestampProps, "variant"> & {
  formatter: (date: string | Date) => string;
}): React.ReactElement {
  const [[timestamp, full], setTimestamp] = React.useState(() => [
    formatter(date),
    fullFormatter(date),
  ]);
  React.useEffect(() => {
    const setter = (): void =>
      setTimestamp((s) => {
        const val = formatter(date);
        // if timestamp hasn't changed then leave state untouched (that way we skip rerender)
        if (val === s[0]) return s;
        return [formatter(date), s[1]];
      });
    registerUpdateInterval(setter);
    return () => unregisterUpdateInterval(setter);
  }, [date, formatter]);
  return (
    <Tooltip title={full}>
      {/* span as anchor for tooltip without needing to pass refs along to Typography */}
      <span>
        <BaseTimestamp {...props}>{timestamp}</BaseTimestamp>
      </span>
    </Tooltip>
  );
}

function FullTimestamp({ date, ...props }: Omit<TimestampProps, "variant">): React.ReactElement {
  return <BaseTimestamp {...props}>{fullFormatter(date)}</BaseTimestamp>;
}

export function Timestamp({ variant = "default", ...props }: TimestampProps): React.ReactElement {
  if (variant === "full") return <FullTimestamp {...props} />;
  // all the rest are relative formats and need to be live
  if (variant === "relative") return <LiveTimestamp {...props} formatter={relativeFormatter} />;
  return <LiveTimestamp {...props} formatter={defaultFormatter} />;
}
