import * as React from "react";
import lodash from "lodash";
import { Formik, FormikProvider, FormikConsumer, FormikValues, FormikTouched } from "formik";
import {
  FormikActionsT,
  FormikBagT,
  FormikChangeFieldValueT,
  FormikConfigT,
} from "@talentpair/types/formik";
import { MarginT } from "@talentpair/types/misc";
import Expose from "./Expose";
import NestedForm from "./NestedForm";
import Reinitialize from "./Reinitialize";
import FormError from "./FormError";
import { getMarginStyle, getInitialTouched, normalizeApiErrors } from "../utils";

export type FormPropsT<FormValues extends FormikValues> = FormikConfigT<FormValues> & {
  autoSave?: boolean;
  expose?: string | null | undefined;
  margin?: MarginT;
  onCancel?: (() => void) | null | undefined;
  canSubmitInitialValues?: boolean;
  canSubmitNullValues?: boolean;
  className?: string;
  style?: React.CSSProperties;
  nested?: boolean;
  showFormError?: boolean;
  NestedFormProps?: { allowEnter: boolean };
};

export default class Form<FormValues extends FormikValues> extends React.Component<
  FormPropsT<FormValues>
> {
  changeFieldValueHandlers: {
    [Name in keyof FormValues]?: FormikChangeFieldValueT<FormValues[Name]>;
  } = {};

  static defaultProps: Partial<FormPropsT<FormikValues>> = {
    autoSave: false,
    expose: null,
    margin: "normal",
    onCancel: null,
    canSubmitInitialValues: false,
    canSubmitNullValues: false,
    className: "",
    style: {},
    nested: false,
    showFormError: true,
  };

  render(): React.ReactNode {
    const {
      autoSave,
      expose,
      initialValues,
      // generate initial touched values based on the initialValues passed in so that any pre-filled required fields will appear complete from the start
      initialTouched = getInitialTouched(initialValues),
      onSubmit,
      render,
      validate,
      margin,
      onCancel,
      canSubmitInitialValues,
      canSubmitNullValues,
      className,
      style,
      nested,
      NestedFormProps,
      showFormError = true,
      ...props
    } = this.props;
    const FormCmp = nested ? NestedForm : "form";

    return (
      <Formik
        initialValues={initialValues}
        // formik touched shape is slightly different from our own.
        // our touched shape is a flat object containing boolean values and does not try to support nested objects and lists like formik does
        initialTouched={initialTouched as FormikTouched<FormValues>}
        validateOnMount={!autoSave}
        onSubmit={(
          values: FormValues,
          formik: FormikActionsT<FormValues>,
        ): void | Promise<unknown> => {
          // If an onCancel prop has been provided, call that instead of onSubmit if no changes have been made
          if (onCancel) {
            if (initialValues && !canSubmitInitialValues) {
              if (lodash.isEqual(values, initialValues)) return onCancel();
            } else if (
              !canSubmitNullValues &&
              !lodash.filter(values, (v) => v != null && v !== "").length
            ) {
              // If there are no initialValues to compare against, make sure that we at least have one non-null,
              // non-empty string value. An empty string value could get created by entering text into a text field
              // and then clearing it out.
              return onCancel();
            }
          }

          if (!onSubmit) return Promise.resolve();

          return onSubmit(values, formik).catch((err) => {
            const { status, errors } = normalizeApiErrors<FormValues>(err);
            formik.setStatus(status);
            formik.setErrors(errors || {});
            formik.setSubmitting(false);
          });
        }}
        validate={validate}
        {...props}
      >
        {(formik: FormikBagT<FormValues>): React.ReactNode => (
          <FormCmp
            onSubmit={formik.handleSubmit}
            className={className}
            style={style}
            {...(nested ? NestedFormProps : {})}
          >
            {showFormError && formik.status?.error && (
              <FormError style={getMarginStyle(margin, true, false)} error={formik.status.error} />
            )}
            {render(formik)}
            {expose && <Expose name={expose} />}
            {autoSave && <Reinitialize initialValues={initialValues} formik={formik} />}
          </FormCmp>
        )}
      </Formik>
    );
  }
}

export const FormikContext = {
  Provider: FormikProvider,
  Consumer: FormikConsumer,
};
