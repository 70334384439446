// @flow
import { combineReducers, type ActionT } from "@talentpair/redux";
import jobs, { type JobsReduxT } from "./jobReducers";
import orgs, { type OrgsReduxT } from "./orgReducers";
import orgJobs, { type OrgJobsReduxT } from "./orgJobReducers";
import orgUsers, { type OrgUsersReduxT } from "./orgUserReducers";
import pairActivity, { type PairActivityReduxT } from "./pairActivityReducers";
import pairs, { type TapPairsReduxT } from "./pairReducers";
import users, { type UsersReduxT } from "./userReducers";
import { UpcomingInterviewsReducer, type UpcomingInterviewsState } from "./upcomingInterviewsStore";

export type TapReduxT = {
  jobs: JobsReduxT,
  orgs: OrgsReduxT,
  orgJobs: OrgJobsReduxT,
  orgUsers: OrgUsersReduxT,
  pairActivity: PairActivityReduxT,
  pairs: TapPairsReduxT,
  users: UsersReduxT,
  upcomingInterviews: UpcomingInterviewsState,
  ...
};

export default combineReducers<_, ActionT>({
  jobs,
  orgs,
  orgJobs,
  orgUsers,
  pairActivity,
  pairs,
  users,
  upcomingInterviews: UpcomingInterviewsReducer,
});
