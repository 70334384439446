import * as React from "react";
import { StyleProps } from "@talentpair/types/component";
import { Typography } from "../atoms/Typography";
import { Box } from "../atoms/Box";

export type PlacardProps = StyleProps & {
  primary: React.ReactNode | undefined;
  // Placard assumes ringed avatar here wrt to spacing
  // so if non-ringed avatar supplied then caller must set the correct 8px margin/padding (level 2 spacing)
  avatar?: React.ReactNode | undefined;
  secondary?: React.ReactNode | undefined;
  tertiary?: React.ReactNode | undefined;
  quaternary?: React.ReactNode | undefined;
  badges?: React.ReactNode | undefined;
  href?: string | null | undefined;
  noWrap?: boolean; // should lines of text in the placard truncate rather than wrapping
  target?: React.HTMLAttributeAnchorTarget;
};

export function Placard({
  primary,
  secondary,
  tertiary,
  quaternary,
  avatar,
  badges,
  href,
  noWrap = false,
  target = "_blank",
  ...props
}: PlacardProps): React.ReactElement {
  const primaryTypography = (
    <Typography paragraph bold noWrap={noWrap}>
      {href ? (
        <a href={href} rel="noopener noreferrer" target={target} className="hover-underline black">
          {primary}
        </a>
      ) : (
        primary
      )}
    </Typography>
  );
  const moreThan2Lines = primary && secondary && (tertiary || quaternary);
  return (
    <Box
      variant="row"
      layout={moreThan2Lines ? "start-start" : "center-start"}
      space={1}
      {...props}
    >
      {avatar}
      <Box variant="column" className="overflow-hidden">
        {badges ? (
          <Box variant="row" layout="center-start" space={1}>
            {primaryTypography}
            {badges}
          </Box>
        ) : (
          primaryTypography
        )}
        {secondary ? (
          <Typography variant="body2" paragraph noWrap={noWrap}>
            {secondary}
          </Typography>
        ) : null}
        {tertiary ? (
          <Typography variant="body2" paragraph noWrap={noWrap}>
            {tertiary}
          </Typography>
        ) : null}
        {quaternary ? (
          <Typography variant="body2" paragraph noWrap={noWrap}>
            {quaternary}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}
