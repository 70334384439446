import { InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { withStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import { Button, ButtonProps } from "./atoms/Button";

const BaseSearchAdornment = withStyles({
  root: {
    color: "inherit",
    background: "#fff",
    height: "initial",
    marginLeft: 8,
    marginRight: 0,
  },
})(InputAdornment);

export function SearchIconAdornment(): React.ReactElement {
  return (
    <BaseSearchAdornment position="start" classes={{ root: "bg-transparent" }}>
      <SearchIcon />
    </BaseSearchAdornment>
  );
}

export function CloseButtonAdornment(props: Omit<ButtonProps, "children">): React.ReactElement {
  return (
    <InputAdornment position="end" classes={{ root: "bg-transparent" }}>
      <Button size="lg" variant="icon" icon={CloseIcon} color="inherit" {...props} />
    </InputAdornment>
  );
}
