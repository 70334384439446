type NotificationCountCallback = (count: number, hasNew: boolean) => unknown;

class BaseNotificationCountSingleton {
  private count = 0;
  private hasNew = false;
  private subscribers: NotificationCountCallback[] = [];

  private dispatch(): void {
    for (const cb of this.subscribers) cb(this.count, this.hasNew);
  }

  subscribe(cb: NotificationCountCallback): () => void {
    this.subscribers.push(cb);
    return () => {
      this.subscribers = this.subscribers.filter((_cb) => _cb !== cb);
    };
  }

  getState(): { count: number; hasNew: boolean } {
    return { count: this.count, hasNew: this.hasNew };
  }

  getCount(): number {
    return this.count;
  }

  getHasNew(): boolean {
    return this.hasNew;
  }

  setCount(count: number, hasNew?: boolean): void {
    const prevCount = this.count;
    const prevNew = this.hasNew;
    this.count = count;
    if (count === 0) hasNew = false;
    if (hasNew != null) this.hasNew = hasNew;
    if (prevCount !== count || prevNew !== this.hasNew) this.dispatch();
  }

  setHasNew(hasNew: boolean): void {
    const prev = this.hasNew;
    this.hasNew = hasNew;
    if (prev !== hasNew) this.dispatch();
  }
}

export const NotificationCountStore = new BaseNotificationCountSingleton();
