import { ExtraErrorData } from "@sentry/integrations";
import {
  BrowserOptions,
  captureException,
  configureScope,
  Event as SentryEvent,
  init as sentryInit,
  reactRouterV5Instrumentation,
  withScope,
} from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { apiUrl } from "@talentpair/env";
import { UserDataT } from "@talentpair/types/userService";
import { History } from "history";
import stringify from "json-stringify-safe";
import lodash from "lodash";

const ignoredTypeErrors = [
  "Load failed",
  "Failed to fetch",
  "cancelled",
  "NetworkError when attempting to fetch resource.",
];

// boolean to track if we successfully initialized with sentry and to guard against us trying to
// continue to hit up sentry when we have no access (such as when the user blocks requests to sentry and other 3rd party services)
let SentryOk = false;

function init(history?: History, config?: BrowserOptions): void {
  if (process.env.REACT_APP_SENTRY_ID) {
    try {
      sentryInit({
        autoSessionTracking: true,
        denyUrls: [/mxpnl\.com/, /inspectlet\.com/, /^chrome-extension:\/\//],
        beforeSend(event: SentryEvent): SentryEvent | PromiseLike<SentryEvent | null> | null {
          if (event.exception?.values?.length) {
            const { values } = event.exception;
            const { type, value } = values[0];
            if (
              (type === "TypeError" && ignoredTypeErrors.includes(value as string)) ||
              (type === "AbortError" && value === "The operation was aborted.") ||
              // this "UnhandledRejection" error is stemming from an Outlook extension which is trying to be too helpful on login pages.
              // See: https://github.com/getsentry/sentry-javascript/issues/3440
              (type === "UnhandledRejection" &&
                value?.startsWith("Non-Error promise rejection captured with value")) ||
              value?.includes("SENTRY IGNORE:") ||
              value?.includes("PING_TIMEOUT") ||
              (values.length === 2 && values[1].type === "ChunkLoadError")
            )
              return null;
            if (
              value &&
              (value.includes("[ngRepeat:dupes]") ||
                (type === "ResponseError" && value === "No error message"))
            ) {
              event.level = "info";
            }
          }
          return event;
        },
        dsn: process.env.REACT_APP_SENTRY_ID,
        environment: process.env.HOST,
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: ["localhost", new URL(apiUrl()).host],
            ...(history && { routingInstrumentation: reactRouterV5Instrumentation(history) }),
          }),
          new ExtraErrorData(),
        ],
        release: process.env.TAG,
        tracesSampleRate: 0.04,
        ...config,
      });
      SentryOk = true;
    } catch (err) {
      null; // who can we report errors to if we can't initialize sentry? :)
    }
  }
}

const capture = (level: "info" | "error" | "warning", err: Error): void => {
  if (!SentryOk) return;
  // Handle Angular stuff that isn't always an Error object
  const exception = lodash.isError(err) ? err : new Error(stringify(err));
  if (process.env.REACT_APP_SENTRY_ID) {
    withScope((scope) => {
      scope.setLevel(level);
      captureException(exception);
    });
  }

  // Log in console in dev mode
  if (
    process.env.NODE_ENV === "development" &&
    level !== "info" &&
    !(err.message && err.message.startsWith("SENTRY IGNORE"))
  ) {
    switch (level.toString()) {
      case "info":
        console.info(err); // eslint-disable-line no-console
        break;
      case "warning":
        console.warn(err); // eslint-disable-line no-console
        break;
      case "error":
        console.error(err); // eslint-disable-line no-console
        break;
      default:
        break;
    }
  }
};

const info = (err: Error): void => capture("info", err);
const warning = (err: Error): void => capture("warning", err);
const error = (err: Error): void => capture("error", err);

function register(user: UserDataT | null): void {
  if (process.env.REACT_APP_SENTRY_ID && user && SentryOk) {
    const { id, username } = user;
    configureScope((scope) => {
      scope.setUser({ id: `${id}`, username });
    });
  }
}

export const sentry = {
  init,
  info,
  warning,
  error,
  register,
};
