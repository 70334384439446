import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { OrgReportsMeta } from "./containers/OrgReportsHeader";
import { SideNavigation } from "../components/Navigation/SideNavigation";

const OrgReports = React.lazy(() => import("./containers/OrgReportsPage"));

export const OrgReportsPage = page({
  // @ts-expect-error - fix later
  mobile: OrgReports,
  // @ts-expect-error - fix later
  desktop: OrgReports,
  // @ts-expect-error - fix later
  desktopSidenav: SideNavigation,
  header: TapHeader,
  meta: OrgReportsMeta,
});
