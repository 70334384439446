import * as React from "react";
import clsx from "clsx";
import { objToQueryStr } from "@talentpair/utils";

type MailToParams = {
  cc?: string | string[];
  bcc?: string | string[];
  subject?: string;
  body?: string;
};

export function mailToLink(email: string | string[], params: MailToParams = {}): string {
  if (!params.cc || (Array.isArray(params.cc) && !params.cc.length)) delete params.cc;
  if (!params.bcc || (Array.isArray(params.bcc) && !params.bcc.length)) delete params.bcc;
  if (!params.subject) delete params.subject;
  if (!params.body) delete params.body;
  return `mailto:${Array.isArray(email) ? email.join(",") : email}${objToQueryStr(params)}`;
}

type MailToLinkProps = React.PropsWithChildren<
  {
    email: string;
    color?: string; // class to apply for text color
  } & MailToParams &
    React.AnchorHTMLAttributes<HTMLAnchorElement>
>;

export function MailToLink({
  email,
  children,
  color = "green-500",
  cc,
  bcc,
  subject,
  body,
  className,
  ...props
}: MailToLinkProps): React.ReactElement {
  return (
    <a
      href={mailToLink(email, { cc, bcc, subject, body })}
      className={clsx(color, className)}
      {...props}
    >
      {children || email}
    </a>
  );
}
