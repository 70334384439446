import * as React from "react";
import { FormikValues } from "formik";
import { FieldPropsT } from "@talentpair/types/formik";
import Field, { FieldWrapperPropsT } from "../Field";
import { defaultParserFormatter } from "../../parseFormat";
import TextInput, { TextInputProps } from "../../inputs/TextInput";
import {
  Textarea as BaseTextarea,
  ResizableTextarea as BaseResizableTextarea,
  NonResizableTextarea as BaseNonResizableTextarea,
  CustomInputProps,
  TextareaProps as BaseTextareaProps,
  ResizableTextAreaStylesProps,
} from "../../inputs/TextareaInput";

export interface InputPropsT<FV extends FormikValues, V = string>
  extends Omit<FieldWrapperPropsT<V, string, FV>, "classes">,
    Pick<TextInputProps, "placeholder" | "classes" | "rows" | "inputProps" | "onKeyDown"> {
  multiline?: boolean;
  submitOnEnter?: boolean;
  autoFocus?: boolean;
  inputRef?: ((instance: unknown) => void) | React.RefObject<unknown> | null;
  Input?: React.ComponentType<CustomInputProps>;
  InputProps?: Partial<CustomInputProps>;
}

export function Input<FV extends FormikValues, V = string>({
  submitOnEnter = false,
  updateOnlyOnBlur = false,
  multiline = false,
  parse = defaultParserFormatter.parse,
  format = defaultParserFormatter.format,
  // type assertion here due to mismatch between our more specific type for `minRows` vs the more generic mui type
  Input: Cmp = TextInput as React.ComponentType<CustomInputProps>,
  InputProps,
  ...props
}: InputPropsT<FV, V>): React.ReactElement {
  return (
    <Field
      fast={!updateOnlyOnBlur}
      fullWidth
      updateOnlyOnBlur={updateOnlyOnBlur}
      parse={parse}
      format={format}
      {...props}
      render={({ field, submitVal }: FieldPropsT<V, string, FV>) => (
        <Cmp
          {...InputProps}
          {...field}
          multiline={multiline}
          value={field.value || ""}
          onKeyDown={
            !submitVal || (multiline && !submitOnEnter)
              ? field.onKeyDown
              : (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
                  if (field.onKeyDown) field.onKeyDown(e);
                  if (e.key === "Enter") submitVal();
                }
          }
        />
      )}
    />
  );
}

export interface TextareaProps<FV extends FormikValues>
  extends Omit<BaseTextareaProps, "name" | "value" | "margin" | "innerRef">,
    Omit<FieldWrapperPropsT<string, string, FV>, "classes"> {}

export function Textarea<FV extends FormikValues>(props: TextareaProps<FV>): React.ReactElement {
  return <Input multiline minRows={1} {...props} Input={BaseTextarea} />;
}

export type ResizableTextareaProps<FV extends FormikValues> = TextareaProps<FV> &
  ResizableTextAreaStylesProps;

export function ResizableTextarea<FV extends FormikValues>(
  props: ResizableTextareaProps<FV>,
): React.ReactElement {
  return <Input multiline {...props} Input={BaseResizableTextarea} />;
}

export function NonResizableTextarea<FV extends FormikValues>(
  props: TextareaProps<FV>,
): React.ReactElement {
  return <Input updateOnlyOnBlur multiline {...props} Input={BaseNonResizableTextarea} />;
}

const initStyleProps = { rows: 3, style: { paddingTop: 0 } };

export function AutosizedTextarea<FV extends FormikValues>(
  props: TextareaProps<FV>,
): React.ReactElement {
  const ref = React.useRef<HTMLDivElement>(null);
  const [styleProps, setStyleProps] = React.useState(initStyleProps);

  React.useLayoutEffect(() => {
    if (ref.current) {
      // to properly size the textarea input we have to subtract space taken by field label, margin, and padding around the input
      const adjustedHeight = ref.current.clientHeight - 44;
      // and then divide that result by the input's line height to determine how many lines we can fit into the available space
      const rows = Math.floor(adjustedHeight / 19);
      // any leftover space gets distributed into padding
      const paddingTop = adjustedHeight - rows * 19 - 2;
      setStyleProps({ rows, style: { paddingTop } });
    }
  }, []);

  return (
    <div className="flex-auto" ref={ref} style={styleProps.style}>
      <Input multiline {...props} rows={styleProps.rows} Input={BaseTextarea} />
    </div>
  );
}
