import * as React from "react";
import { Grid } from "@mui/material";
import { Pane, Item, Title, Actions, PrimaryButton, Button } from "@talentpair/quantic";
import ResumeProgressSvg from "kyoto/components/ResumeProgressSvg";
import Form from "kyoto/components/Forms/Formik/Form";
import { Input } from "kyoto/components/Forms/Formik/fields/TextInput";

const googleDocBase = "https://docs.google.com/document/d/";
const googleDriveFileBase = "https://drive.google.com/file/d/";
const googleDocLinkValidator = (value: null | string): string | undefined =>
  value &&
  ((value !== googleDocBase && value.startsWith(googleDocBase)) ||
    (value !== googleDriveFileBase && value.startsWith(googleDriveFileBase)))
    ? undefined
    : "Please enter a valid google docs link.";

export type LinkGoogleDocPanePropsT = {
  value: string;
  error: string;
  onBack: () => void;
  onPickDoc: (doc: string) => Promise<unknown>;
};

const LinkGoogleDocPane = ({
  value,
  error,
  onBack,
  onPickDoc,
}: LinkGoogleDocPanePropsT): React.ReactElement => (
  <Pane>
    <Item>
      <ResumeProgressSvg state={error ? "fail" : "document"} />
    </Item>
    <Item>
      <Title>Add a link to your shareable google doc.</Title>
    </Item>
    <Form
      onSubmit={({ docLink }) => onPickDoc(docLink)}
      initialValues={{ docLink: value }}
      initialErrors={error ? { docLink: error } : {}}
      className="flex-auto full-width flex flex-column mt3"
      render={({ submitForm, touched, errors }) => (
        <>
          <Item>
            <Input
              autoFocus
              name="docLink"
              label="Google doc url"
              placeholder="https://docs.google.com/document/d/"
              validate={googleDocLinkValidator}
            />
          </Item>
          <Actions>
            <Grid container justifyContent="space-around" spacing={3} wrap="nowrap">
              <Grid item>
                <Button variant="contained" onClick={onBack} data-testid="backBtn">
                  Back
                </Button>
              </Grid>
              <Grid item>
                <PrimaryButton
                  disabled={!!errors.docLink || !touched.docLink}
                  component="label"
                  onClick={submitForm}
                  data-testid="submitBtn"
                >
                  Link Google doc
                </PrimaryButton>
              </Grid>
            </Grid>
          </Actions>
        </>
      )}
    />
  </Pane>
);

export default LinkGoogleDocPane;

export const _test = { googleDocLinkValidator };
