import * as React from "react";
import { StyleProps } from "@talentpair/types/component";
import { Typography } from "../atoms/Typography";
import { Box } from "../atoms/Box";

export type IndicatorProps = StyleProps & {
  count?: number;
  light?: boolean | "urgent";
};

export function Indicator({ count, light, ...props }: IndicatorProps): React.ReactElement | null {
  // only render if count exists and as greater than zero
  // or if no count defined then the light should be defined (though it might be turned off)
  if ((count != null && count <= 0) || (!count && light == null)) return null;

  return (
    <Box variant="row" layout="center-start" space={1} {...props}>
      {count ? (
        <Typography variant="body3" data-testid="indicator-count">
          {count > 99 ? "99+" : count}
        </Typography>
      ) : null}
      {light != null ? (
        <Box
          data-testid="indicator-light"
          background={light === "urgent" ? "red.500" : "primary.main"}
          style={{
            width: 10,
            height: 10,
            borderRadius: "50%",
            visibility: light ? "visible" : "hidden",
          }}
        />
      ) : null}
    </Box>
  );
}
