import * as React from "react";
import clsx from "clsx";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge } from "@mui/material";
import { userService, NotificationCountStore } from "@talentpair/api";
import { londonLink } from "@talentpair/env";
import { Button } from "@talentpair/quantic";
import { Link } from "@talentpair/routing";

type PropsT = {
  isTap?: boolean;
  pathname: string;
  onClick?: () => unknown;
};

export default function NotificationsLink({
  pathname,
  isTap = false,
  onClick,
}: PropsT): React.ReactElement {
  const [stats, setStats] = React.useState(NotificationCountStore.getState());
  React.useEffect(
    () => NotificationCountStore.subscribe((count, hasNew) => setStats({ count, hasNew })),
    [],
  );
  const { count, hasNew } = stats;
  const href = isTap
    ? londonLink.tapNotifications(userService.orgId())
    : londonLink.capNotifications(userService.data?.candidate_id || 0);
  const active = pathname === href;
  return (
    <Link
      className="color-inherit relative"
      onClick={active ? onClick : undefined}
      replace={active}
      to={href}
    >
      <Button
        size="lg"
        variant="icon"
        icon={
          count ? (
            <Badge
              max={9}
              badgeContent={count}
              classes={{ badge: clsx({ "bg-grey-600": !hasNew }) }}
              color="error"
            >
              <NotificationsIcon fontSize="inherit" />
            </Badge>
          ) : (
            NotificationsIcon
          )
        }
        data-testid="viewNotificationsButton"
      />
    </Link>
  );
}
