import { determineRoute, RoutesT } from "@talentpair/routing";
import { mixpanelHelper } from "./mixpanelHelper";

export function navigationData(
  routes: RoutesT,
  pathname: string,
  search: string,
): Record<string, string> {
  const { appType, appSubtype, match } = determineRoute(routes, pathname);

  const mixpanelData: {
    [id: string]: string;
  } = {
    "App Type": appType,
    "App Route": match.path,
    ...mixpanelHelper.trackQueryParams(search),
  };
  if (appSubtype) mixpanelData["App Subtype"] = appSubtype;
  if (Object.keys(match.params).length) mixpanelData["App Params"] = JSON.stringify(match.params);

  return mixpanelData;
}
