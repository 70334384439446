import * as React from "react";
import { NavSearchResultT } from "@talentpair/types/navSearch";
import {
  AtsBadge,
  IncompleteBadge,
  MaxHireBadge,
  SuggestionTpBadge,
  UserAvatar,
  JobAvatar,
  TeamAvatar,
  OrgAvatar,
  OrgBadges,
  PlanBadge,
} from "@talentpair/quantic";
import { displayFullName } from "@talentpair/entities/user";
import { RichSuggestion } from "../../../../Autocomplete/helpers";
import { SuggestionPropsT } from "../../../../Autocomplete/types";
import BaseEntitySearch, { EntitySearchSingleT } from "./BaseEntitySearch";

export const getSuggestionValue = (result: NavSearchResultT | null): string => {
  if (!result) return "";

  switch (result.type) {
    case "candidate":
    case "contact":
    case "maxhire_candidate":
    case "maxhire_contact":
      return displayFullName(result);
    case "organization":
    case "maxhire_organization":
      return result.company_name;
    case "job":
    case "team":
      return result.name || "";
    default:
      return "";
  }
};

const getSuggestionAvatar = (result: NavSearchResultT): React.ReactElement | null => {
  switch (result.type) {
    case "contact":
    case "maxhire_contact":
    case "candidate":
    case "maxhire_candidate":
      return (
        <UserAvatar
          user={
            result.status === "do_not_contact"
              ? { ...result, status: { id: 10 } }
              : result.status === "no_longer_employed"
              ? { ...result, no_longer_employed: true }
              : result
          }
        />
      );
    case "organization":
    case "maxhire_organization":
      return <OrgAvatar org={{ ...result, logo_url: result.profile_picture_url }} />;
    case "job":
      return <JobAvatar job={result} />;
    case "team":
      return <TeamAvatar />;
    default:
      return null;
  }
};

interface NavSearchSuggestionPropsT
  extends Omit<SuggestionPropsT<NavSearchResultT>, "suggestionValue"> {
  suggestionValue: string;
}
export const NavSearchSuggestion = ({
  suggestion,
  suggestionValue,
  ...props
}: NavSearchSuggestionPropsT): React.ReactElement | null => {
  if (suggestion == null) return null; // shouldn't ever happen because nav search returns a "No Options" suggestion
  let secondary;
  const { role } = suggestion;
  if (role) {
    if (suggestion.type === "contact") {
      secondary = role.startsWith("Unknown") ? role.split(" @ ")[1] : role;
    } else {
      const roleParts = role.split(" @ ");
      secondary =
        roleParts[0] === "Unknown" ? (
          roleParts[1]
        ) : (
          <div>
            {roleParts[0]}
            <br />
            {roleParts[1]}
          </div>
        );
    }
  } else if (suggestion.type === "job") {
    secondary = suggestion.company_name || ""; // flow... not actually possible to have an empty string in the case of a job result
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    if (suggestion.location) secondary += ` • ${suggestion.location}`;
  } else if (suggestion.type === "organization") {
    secondary = suggestion.location;
  } else if (suggestion.type === "team") {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    secondary = `${suggestion.company_name || "Unknown Team"} @ Talentpair`;
  }

  return (
    <RichSuggestion
      Avatar={getSuggestionAvatar(suggestion)}
      primary={
        <div className="flex items-center">
          <strong>{suggestionValue}</strong>
          {suggestion.type === "org" && suggestion.status === "OOB" && (
            <OrgBadges
              org={{
                id: suggestion.id,
                out_of_business: true,
                active_pricing_plan_type: suggestion.active_pricing_plan_type,
              }}
            />
          )}
          {suggestion.type === "job" && !suggestion.intake_completed && (
            <IncompleteBadge href="" className="ml2" type="job" id={suggestion.id} />
          )}
          {suggestion.platform_status && (
            <span className="ml2">
              <SuggestionTpBadge suggestion={suggestion} />
            </span>
          )}
          {suggestion.type === "job" && !!suggestion.active_pricing_plan_type && (
            <PlanBadge plan={suggestion.active_pricing_plan_type} className="ml2" />
          )}
          {suggestion.type === "job" && !!suggestion.ats_id && <AtsBadge className="ml2" />}
          {suggestion.type.startsWith("maxhire") && <MaxHireBadge className="ml2" />}
        </div>
      }
      secondary={secondary}
      {...props}
    />
  );
};

export type NavSearchPropsT = EntitySearchSingleT<NavSearchResultT>;

const NavSearch = ({ entity, ...props }: NavSearchPropsT): React.ReactElement => (
  <BaseEntitySearch
    entity="navSearch"
    label={null}
    name="query"
    placeholder="Search"
    getSuggestionValue={getSuggestionValue}
    Suggestion={NavSearchSuggestion}
    fetchImmediately
    {...props}
  />
);

export default NavSearch;

export const _test = { getSuggestionAvatar, getSuggestionValue, NavSearchSuggestion };
