import * as React from "react";
import { page } from "../../components/layout/responsive";
import ProfileTabs from "../components/ProfileTabs";
import {
  CapDesiredNextRoleHeader,
  CapDesiredNextRoleMeta,
} from "./containers/DesiredNextRoleHeader";

const CapDesiredNextRole = ProfileTabs(
  React.lazy(() => import("./containers/DesiredNextRolePage")),
);

export const CapDesiredNextRolePage = page({
  // @ts-expect-error - route param type mismatch
  mobile: CapDesiredNextRole,
  header: CapDesiredNextRoleHeader,
  meta: CapDesiredNextRoleMeta,
});
