// @flow
import { type TapOrgDetailReduxT } from "@talentpair/types/tap/entities/org";
import { replaceOrAddArrayItem, splice } from "kyoto/utils/array";
import type { OrgAddressT } from "@talentpair/types/entities/location";
import {
  ORG_ADDRESS_ITEM_UPDATE_SUCCESS,
  ORG_LIST_ITEM_DELETE_SUCCESS,
  ORG_LIST_ITEM_UPDATE_SUCCESS,
  ORG_RECEIVE_DETAIL,
  ORG_UPDATE_SUCCESS,
  type OrgDetailActionT,
} from "./orgActions";

export type OrgsReduxT = { [id: number]: ?TapOrgDetailReduxT, ... };

const processOrgAddress = (orgAddress: OrgAddressT) => ({
  ...orgAddress,
  id: orgAddress.address.id,
});

function org(state: ?TapOrgDetailReduxT, action: OrgDetailActionT): ?TapOrgDetailReduxT {
  switch (action.type) {
    case ORG_RECEIVE_DETAIL:
    case ORG_UPDATE_SUCCESS: {
      const { jobs: j, users: u, addresses, ...organization } = action.org;
      return {
        ...state,
        ...organization,
        addresses: addresses ? addresses.map(processOrgAddress) : state?.addresses,
      };
    }

    case ORG_LIST_ITEM_UPDATE_SUCCESS:
      return {
        ...state,
        [action.field]: replaceOrAddArrayItem(state ? state[action.field] : [], action.item),
      };

    case ORG_ADDRESS_ITEM_UPDATE_SUCCESS: {
      return {
        ...state,
        addresses: replaceOrAddArrayItem(
          state ? state.addresses : [],
          processOrgAddress(action.address),
        ),
      };
    }

    case ORG_LIST_ITEM_DELETE_SUCCESS: {
      // TODO this is a total copy of the one in candidateReducers. Can we pull this out into a separate function
      // without angering flow?
      const list = state ? state[action.field] : [];
      const index = list.indexOf(action.item);
      if (index < 0) return state;
      return {
        ...state,
        [action.field]: splice(list, index),
      };
    }

    default:
      return state;
  }
}

export default function orgs(state: OrgsReduxT = {}, action: OrgDetailActionT): OrgsReduxT {
  switch (action.type) {
    case ORG_RECEIVE_DETAIL:
    case ORG_UPDATE_SUCCESS:
    case ORG_LIST_ITEM_UPDATE_SUCCESS:
    case ORG_ADDRESS_ITEM_UPDATE_SUCCESS:
    case ORG_LIST_ITEM_DELETE_SUCCESS:
      return { ...state, [action.orgId]: org(state[action.orgId], action) };

    default:
      (action: empty);
      return state;
  }
}

export const _test = { org, processOrgAddress };
