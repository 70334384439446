import { Group } from "@mui/icons-material";
import { RingedAvatar, RingedAvatarProps } from "./RingedAvatar";

export type TeamAvatarProps = Pick<RingedAvatarProps, "size" | "ringed"> & {
  ringColor?: RingedAvatarProps["ringColor"];
};

export function TeamAvatar({ ringColor, ...props }: TeamAvatarProps): React.ReactElement {
  return (
    <RingedAvatar
      {...props}
      shape="circular"
      color="common.white"
      background="primary.400"
      ringColor={ringColor || "transparent"}
      fill="icon"
      Icon={Group}
    />
  );
}
