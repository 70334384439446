import * as React from "react";
import { auth, userService, useFeatureFlag, FeatureFlags } from "@talentpair/api";

export type BillingAccess = {
  canEditBilling: boolean;
  canSeeBilling: boolean;
};

export function useOrgBillingAccessControls(redirect = true): BillingAccess {
  const billingFeatureFlagOn = useFeatureFlag(FeatureFlags.TAP_PAYMENTS);
  const canEditBilling = userService.isOrgOwner() || userService.isOrgBillingAdmin();
  const canSeeBilling = canEditBilling || userService.isRecruiter();

  React.useEffect(() => {
    if ((!canSeeBilling || billingFeatureFlagOn === false) && redirect) auth.redirectToApp();
  }, [canSeeBilling, billingFeatureFlagOn, redirect]);

  if (!billingFeatureFlagOn)
    return {
      canEditBilling: false,
      canSeeBilling: false,
    };
  return {
    canEditBilling,
    canSeeBilling,
  };
}
