import {
  CandidateDetailT,
  CandidateT,
  CandidateVisibilityT,
  PairCandidateDetailT,
  TinyCandidateT,
} from "@talentpair/types/entities/candidate";
import { ChoiceT } from "@talentpair/types/entities/misc";
import { OrgT } from "@talentpair/types/entities/org";
import { MicroUserT } from "@talentpair/types/entities/user";
import { TAP_STATUS } from "@talentpair/entities/job";

export type IdentityCandidateT =
  | CandidateDetailT
  | TinyCandidateT
  | PairCandidateDetailT
  | CandidateT;

export const candidateIdentity = {
  isPrivate: (candidate: { visibility: CandidateVisibilityT }): boolean =>
    candidate.visibility === "PR",
  isPlatform: <C extends { user: { last_active: string | boolean | null } }>(
    candidate: C,
  ): boolean => !!candidate.user.last_active,
  doNotContact: (candidate: IdentityCandidateT): boolean =>
    candidate.status.name === "do_not_contact",
};

export const jobIdentity = {
  isPlatform: (job: { tap_status: ChoiceT }): boolean => job.tap_status.id !== TAP_STATUS.OFF,
  // If TAP Status is OFF, it's a classic job, and we don't need permissions around that
  isActive: (job: { tap_status: ChoiceT }): boolean =>
    ![TAP_STATUS.PAUSED, TAP_STATUS.CLOSED].includes(job.tap_status.id),
  hasHm: (job: { hiring_manager: MicroUserT | null }): boolean => !!job.hiring_manager,
};

export const orgIdentity = {
  isTp: (org: { id: number }): boolean => org.id === 2,
  isQg: (org: { id: number }): boolean => org.id === 1,
  isInternal: (org: { id: number }): boolean => orgIdentity.isQg(org) || orgIdentity.isTp(org),
  isPlatform: (org: OrgT): boolean => !orgIdentity.isInternal(org) && !!org.primary_owner,
};

export const userIdentity = {
  isInvited: <U extends { login_blocked: boolean }>(user: U): boolean => !user.login_blocked,
  isInternal: <U extends { organization: null | { id: number } }>(user: U): boolean =>
    !!user.organization && orgIdentity.isInternal(user.organization),
};

export const pairIdentity = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isPair: (maybePair: { [key: string]: any }): boolean =>
    !!(maybePair.candidate_state != null && maybePair.job_state != null),
};
