import clsx from "clsx";
import lodash from "lodash";
import { ClassNameMap } from "@mui/styles";
import { portal } from "@talentpair/portal";
import { LooseChoiceT } from "@talentpair/types/entities/misc";
import { objToQueryStr } from "@talentpair/utils";

export const updateAddressBarQueryParams = (params: { [key: string]: unknown }): void => {
  const url = window.location.href;
  const queryIndex = url.indexOf("?");
  const start = queryIndex < 0 ? url : url.slice(0, queryIndex);
  history.replaceState(null, document.title, `${start}${objToQueryStr(params)}`);
};

export const getViewportHeight = (viewport?: HTMLElement | null): number => {
  let viewportHeight =
    window.innerHeight || (document.documentElement ? document.documentElement.offsetHeight : 0);
  if (viewport) {
    const rect = viewport.getBoundingClientRect();
    if (rect.bottom < viewportHeight) viewportHeight = rect.bottom;
    if (rect.top > 0) viewportHeight -= rect.top;
  }
  return viewportHeight;
};

export const isBottomHalfOfViewport = (
  el: HTMLElement | null,
  viewport: HTMLElement | null = null,
): boolean | null => {
  if (!el) return null;
  const client = el.getBoundingClientRect();
  const viewportHeight = getViewportHeight(viewport);

  let clientTop = client.top;

  if (viewport) {
    const rect = viewport.getBoundingClientRect();
    if (rect.top > 0) clientTop -= rect.top;
  }

  return clientTop + client.height / 2 > viewportHeight / 2;
};

export const isMetaEnterKey = (e: React.KeyboardEvent): boolean =>
  !!((e.metaKey || e.ctrlKey) && (e.which === 13 || e.which === 10));

export const returnTrue = (): boolean => true;

export const getWebsiteForUrl = (url: string, other = "Other"): string => {
  url = url.toLowerCase();

  if (url.includes("github.com")) return "Github";
  if (url.includes("linkedin.com")) return "LinkedIn";
  if (url.includes("twitter.com")) return "Twitter";
  if (url.includes("facebook.com")) return "Facebook";
  if (url.includes("crunchbase.com")) return "Crunchbase";

  return other;
};

export const getObjVal = (
  obj: {
    id?: unknown;
    value?: unknown;
    name?: unknown;
    label?: unknown;
  } | null,
): unknown => {
  if (!obj) return undefined;
  if (obj.hasOwnProperty("id")) return obj.id;
  if (obj.hasOwnProperty("value")) return obj.value;
  if (obj.hasOwnProperty("name")) return obj.name;
  return obj.label;
};

export const mergeClasses = (
  ...classObjs: (Partial<ClassNameMap<string>> | null)[]
): ClassNameMap<string> =>
  lodash.mergeWith(
    {},
    ...classObjs,
    (a: Partial<ClassNameMap<string>>, b: Partial<ClassNameMap<string>>) => clsx(a, b),
  );

// Simplified version of https://github.com/sindresorhus/p-debounce
export const debouncedPromise = <A extends Array<unknown>, R>(
  fn: (...args: A) => Promise<R>,
  wait: number,
): ((...args: A) => Promise<R>) => {
  let timer: NodeJS.Timeout | null | undefined;
  let resolveList: ((value: Promise<R>) => void)[] = [];

  return (...args: A): Promise<R> =>
    new Promise((resolve: (value: Promise<R>) => void) => {
      if (timer) clearTimeout(timer);

      timer = setTimeout(() => {
        timer = null;
        const res = fn(...args);

        for (const r of resolveList) {
          r(res);
        }

        resolveList = [];
      }, wait);

      resolveList.push(resolve);
    });
};

export const choicesToIds = (values: { [key: string]: unknown }): { [key: string]: unknown } =>
  lodash.mapValues(values, (v) =>
    // @ts-expect-error - eslint upgrade
    lodash.isObject(v) && v.hasOwnProperty("id") ? v.id || v.name : v,
  );

export const choiceFromString = (name: string): LooseChoiceT => ({ name });

export const listFieldUrl = (field: string): string => field.replace(/_/g, "-");

export const asyncNoop = (): Promise<void> => Promise.resolve();

export const shouldIgnoreInputEvent = (e: KeyboardEvent): boolean =>
  e.target instanceof HTMLInputElement ||
  e.target instanceof HTMLTextAreaElement ||
  e.target instanceof HTMLSelectElement ||
  (e.target instanceof HTMLElement && e.target.isContentEditable);

export const shouldIgnoreInteractionEvent = (e: KeyboardEvent): boolean =>
  shouldIgnoreInputEvent(e) ||
  !!portal.getStateForType("modal").length ||
  document.body.className.includes("md-dialog-is-showing"); // for angular dialogs
