import * as React from "react";
import clsx from "clsx";
import { WindowSize } from "react-fns";
import { RouteComponentProps } from "@talentpair/routing";
import { AppBar, Button, Card, CardProps, Grid, LinearProgress, Toolbar } from "@mui/material";
import { Loader, Media, mailToLink } from "@talentpair/quantic";
import { isAuthPage, londonLink } from "@talentpair/env";
import { portal } from "@talentpair/portal";
import perry from "@talentpair/assets/images/perry-192.png";
import FeedbackModal from "kyoto/components/FeedbackModal";
import ErrorBoundary from "../ErrorBoundary";
import logo from "../../img/logo-white.png";

interface PageCardProps extends CardProps {
  justifyContent?: string;
  noValidate?: boolean;
}
export const PageCard = ({
  justifyContent = "start",
  ...props
}: PageCardProps): React.ReactElement => (
  <Media query={{ minWidth: "52em" }}>
    {(desktop: boolean) => (
      <Card
        classes={{
          // Be careful when changing these that Mobile Safari still works as expected
          root: clsx("col-12 px3 py2 relative", `justify-${justifyContent}`, {
            "max-width-1": desktop,
            "flex flex-auto flex-column min-height-auto": !desktop,
          }),
        }}
        elevation={0}
        style={desktop ? { minHeight: 300 } : {}}
        {...props}
      />
    )}
  </Media>
);

interface WrapperProps extends Omit<PageCardProps, "onSubmit"> {
  children: React.ReactNode;
  onSubmit?: (event: React.SyntheticEvent) => Promise<unknown>;
  submitting?: boolean;
}
export const Wrapper = ({
  children,
  onSubmit,
  submitting = false,
  ...props
}: WrapperProps): React.ReactElement => (
  <PageCard
    // @ts-expect-error - it's okay
    component={onSubmit ? "form" : "div"}
    noValidate={!!onSubmit}
    onSubmit={onSubmit}
    {...props}
  >
    {submitting && <LinearProgress classes={{ root: "absolute col-12 left-0 top-0" }} />}
    {children}
  </PageCard>
);

// Custom loader so that we still get the card background as a placeholder on desktop
export const AuthLoader = (): React.ReactElement => (
  <PageCard>
    <Loader absolute />
  </PageCard>
);
export const page =
  <P extends RouteComponentProps>(Cmp: React.ComponentType<P>) =>
  (props: P): React.ReactElement =>
    (
      // Be careful when changing classes/styles that Mobile Safari still works as expected
      <Media query={{ minWidth: "52em" }}>
        {(desktop: boolean) => (
          // Using WindowSize to fix a bug in Mobile Safari, where the card wouldn't fill the entire screen.
          // This gives us the window height and makes sure the page is at the very least as tall as the screen.
          <WindowSize
            render={({ height }: { height: number }) => (
              <Grid
                alignItems="center"
                classes={{
                  container: clsx("bg-green-500", {
                    p2: desktop,
                  }),
                }}
                container
                direction="column"
                justifyContent={desktop ? "center" : "space-between"}
                style={{ minHeight: desktop ? "100vh" : height }}
                wrap="nowrap"
              >
                {desktop ? (
                  <img className="mb3 pb2" src={logo} alt="Talentpair" width={150} />
                ) : (
                  <AppBar elevation={0} position="static">
                    <Toolbar classes={{ root: "justify-center" }}>
                      <img src={logo} alt="Talentpair" width={100} />
                    </Toolbar>
                  </AppBar>
                )}
                <ErrorBoundary>
                  <React.Suspense fallback={<AuthLoader />}>
                    <Cmp {...props} />
                  </React.Suspense>
                </ErrorBoundary>

                <div className="max-width-1 flex-center py3">
                  <Button
                    className="white bold"
                    href={mailToLink("support@talentpair.com", {
                      subject: "Support request",
                    })}
                  >
                    Help
                  </Button>
                  <Button className="white bold" onClick={() => portal.modal(FeedbackModal)}>
                    Feedback
                  </Button>
                  {isAuthPage() ? (
                    <Button className="white bold" href={londonLink.publicJobList()}>
                      Job board
                    </Button>
                  ) : null}
                </div>
                {desktop && <img src={perry} alt="Perry" style={{ width: 40, height: 40 }} />}
                {desktop && (
                  <span className="mt3 white">
                    &copy; {new Date().getFullYear()} Talentpair Inc.
                  </span>
                )}
              </Grid>
            )}
          />
        )}
      </Media>
    );
