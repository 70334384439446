import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { ManageJobBoardMeta } from "./containers/ManageJobBoardHeader";
import { SideNavigation } from "../components/Navigation/SideNavigation";

export const ManageJobBoard = React.lazy(() => import("./containers/ManageJobBoardPage"));

export const ManageJobBoardPage = page({
  // @ts-expect-error - it's okay
  desktop: ManageJobBoard,
  // @ts-expect-error - it's okay
  mobile: ManageJobBoard,
  // @ts-expect-error - it's okay
  desktopSidenav: SideNavigation,
  header: TapHeader,
  meta: ManageJobBoardMeta,
});
