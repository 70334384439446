import * as React from "react";
import { userService } from "@talentpair/api";
import { astralLink, env, londonLink } from "@talentpair/env";
import {
  locationFullUrl,
  determineRoute,
  Redirect,
  RouteComponentProps,
} from "@talentpair/routing";
import { routes } from "../routeConfig";
import { page } from "./layout/responsive";
import Error404 from "./Error404";

export const HomeRedirect = (): React.ReactElement => <Redirect to="/" />;

export const LoginRedirect = ({ from }: { from?: string }): React.ReactElement => (
  <Redirect
    from={from}
    to={`${
      determineRoute(routes, location.pathname).appType === "TAP"
        ? londonLink.authEmployerSignin()
        : londonLink.authCandidateSignin()
    }${location.pathname !== "/" ? `?next=${encodeURIComponent(locationFullUrl(location))}` : ""}`}
  />
);

const RecruitRedirect = ({ to }: { to: string }): React.ReactElement | null => {
  React.useEffect(() => {
    location.replace(to);
  }, [to]);
  return null;
};

export const UserPage = (props: RouteComponentProps): React.ReactElement => {
  const { data } = userService;
  if (data) {
    if (userService.isCandidate() && data.candidate_id)
      return data.account_deactivated ? (
        <Redirect to={londonLink.capProfile(data.candidate_id)} />
      ) : (
        <Redirect to={londonLink.capPairList(data.candidate_id)} />
      );
    if (userService.isHm() && data.organization)
      return <Redirect to={londonLink.tapJobList(data.organization.id)} />;
    if (userService.isRecruiter())
      return <RecruitRedirect to={env.gettysburgUrl(astralLink.toNotificationInbox(data.id))} />;
  }
  return <Error404 {...props} />;
};

export const UserRedirect = page({ mobile: UserPage });

export function PublicJobRedirect(): React.ReactElement | null {
  React.useEffect(() => {
    window.location.replace(
      env.queenstownUrl(
        `${window.location.pathname.replace("/public", "")}${window.location.search}` || undefined,
      ),
    );
  }, []);
  return null;
}
