import { userService } from "@talentpair/api";
import { londonLink } from "@talentpair/env";
import { ErrorBoundary, ErrorBoundaryProps, Error500 } from "@talentpair/sentry";
import * as React from "react";
import { Link } from "@talentpair/routing";

const LondonErrorBoundary = (props: ErrorBoundaryProps): React.ReactElement => (
  <ErrorBoundary
    fallback={
      <Error500>
        <Link className="block" to="/">
          Home
        </Link>
        {userService.isRecruiter() ? null : (
          <Link
            className="block"
            to={
              userService.data?.candidate_id
                ? londonLink.capNotifications(userService.data?.candidate_id)
                : londonLink.tapNotifications(userService.orgId())
            }
          >
            Notifications
          </Link>
        )}
      </Error500>
    }
    {...props}
  />
);

export default LondonErrorBoundary;
