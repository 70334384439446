// @flow
import { uniq } from "kyoto/utils/array";
import { ORG_RECEIVE_DETAIL } from "./orgActions";
import {
  ORG_USER_DELETED,
  ORG_USER_LOGIN_BLOCKED,
  ORG_USER_RECEIVE_DETAIL,
  ORG_USER_RECEIVE_JOBS,
  type OrgUserActionT,
} from "./userActions";

export type OrgUsersT = number[];
export type OrgUsersReduxT = { [id: number]: OrgUsersT, ... };

export default function orgUsers(
  state: OrgUsersReduxT = {},
  action: OrgUserActionT,
): OrgUsersReduxT {
  switch (action.type) {
    case ORG_RECEIVE_DETAIL:
      return {
        ...state,
        [action.orgId]: uniq([
          ...action.org.users.map((u) => u.id),
          ...(state[action.orgId] || []),
        ]),
      };

    case ORG_USER_RECEIVE_DETAIL:
    case ORG_USER_LOGIN_BLOCKED:
    case ORG_USER_RECEIVE_JOBS:
      return {
        ...state,
        [action.orgId]: uniq([...(state[action.orgId] || []), action.user.id]),
      };

    case ORG_USER_DELETED: {
      const user = action.userId;
      return {
        ...state,
        [action.orgId]: state[action.orgId] && state[action.orgId].filter((u) => u === user),
      };
    }

    default:
      (action: empty);
      return state;
  }
}
