import * as React from "react";
import { styled, CSSObject } from "@mui/material/styles";
import { StyleProps } from "@talentpair/types/component";
import { colorProp, ColorPropT } from "../theme/colorProp";
import { SpacingUnitsT } from "../theme/spacing";
import { useMediaContext } from "../Media";

type AlignItemsT = "stretch" | "center" | "start" | "end";
type JustifyContentT = "start" | "end" | "center" | "evenly" | "between";

type BoxVariants =
  | {
      variant?: "default" | "center";
      layout?: void;
      space?: void;
      wrap?: void;
    }
  | {
      variant: "row" | "column" | "row-reverse" | "column-reverse";
      layout: `${AlignItemsT}-${JustifyContentT}`;
      space?: SpacingUnitsT;
      wrap?: "nowrap" | "wrap" | "reverse";
    };

export type BoxProps = BoxVariants &
  React.HTMLAttributes<HTMLDivElement> &
  StyleProps & {
    component?: keyof React.ReactHTML;
    color?: ColorPropT;
    background?: ColorPropT;
    flex?: number | "auto" | "none";
    divider?: React.ReactNode | undefined;
    children?: React.ReactNode | undefined;
    boxRef?: React.RefObject<HTMLElement> | ((el: HTMLElement | null) => unknown);
  };

export function separateElementsWith(separator: unknown, arr: unknown[]): unknown[] {
  if (arr.length <= 1) return arr;
  return arr.map((e, i) => (i > 0 ? [separator, e] : [e])).flat();
}

export function UnstyledBox({
  component: Cmp = "div",
  variant,
  layout,
  space,
  color,
  background,
  flex,
  wrap,
  divider,
  children,
  boxRef,
  ...props
}: BoxProps): React.ReactElement {
  return (
    // @ts-expect-error - it's okay.
    <Cmp {...props} ref={boxRef}>
      {!divider || React.Children.count(children) < 2
        ? children
        : React.Children.toArray(children)
            .filter((child) => React.isValidElement(child))
            .map((child, i) =>
              i > 0 ? (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={i}>
                  {divider}
                  {child}
                </React.Fragment>
              ) : (
                child
              ),
            )}
    </Cmp>
  );
}

export const layoutVariant = {
  "stretch-start": { alignItems: "stretch", justifyContent: "flex-start" },
  "stretch-end": { alignItems: "stretch", justifyContent: "flex-end" },
  "stretch-center": { alignItems: "stretch", justifyContent: "center" },
  "stretch-evenly": { alignItems: "stretch", justifyContent: "space-evenly" },
  "stretch-between": { alignItems: "stretch", justifyContent: "space-between" },
  "center-start": { alignItems: "center", justifyContent: "flex-start" },
  "center-end": { alignItems: "center", justifyContent: "flex-end" },
  "center-center": { alignItems: "center", justifyContent: "center" },
  "center-evenly": { alignItems: "center", justifyContent: "space-evenly" },
  "center-between": { alignItems: "center", justifyContent: "space-between" },
  "start-start": { alignItems: "flex-start", justifyContent: "flex-start" },
  "start-end": { alignItems: "flex-start", justifyContent: "flex-end" },
  "start-center": { alignItems: "flex-start", justifyContent: "center" },
  "start-evenly": { alignItems: "flex-start", justifyContent: "space-evenly" },
  "start-between": { alignItems: "flex-start", justifyContent: "space-between" },
  "end-start": { alignItems: "flex-end", justifyContent: "flex-start" },
  "end-end": { alignItems: "flex-end", justifyContent: "flex-end" },
  "end-center": { alignItems: "flex-end", justifyContent: "center" },
  "end-evenly": { alignItems: "flex-end", justifyContent: "space-evenly" },
  "end-between": { alignItems: "flex-end", justifyContent: "space-between" },
};

export const Box = styled(UnstyledBox, { skipSx: true })(({ theme, ...props }) => {
  let styles: CSSObject = {};
  if (props.color) styles.color = colorProp(props.color, theme);
  if (props.background) styles.background = colorProp(props.background, theme);
  if (props.flex) styles.flex = props.flex;
  if (props.variant) {
    if (props.variant === "center")
      styles = { ...styles, display: "flex", justifyContent: "center", alignItems: "center" };
    else if (["row", "column", "column-reverse", "row-reverse"].includes(props.variant)) {
      styles = {
        ...styles,
        display: "flex",
        // @ts-expect-error - it's fine
        flexDirection: props.variant,
        ...layoutVariant[props.layout || "stretch-start"],
      };
      if (props.space)
        styles["& > :not(:last-child)"] = {
          [props.variant.startsWith("row") ? "marginRight" : "marginBottom"]: theme.spacing(
            props.space as number,
          ),
        };
      if (props.wrap) styles.flexWrap = props.wrap === "reverse" ? "wrap-reverse" : props.wrap;
    }
  }
  return styles;
});

export const ResponsiveBox = ({
  space,
  mobileSpace,
  ...props
}: BoxProps & { mobileSpace: SpacingUnitsT }): React.ReactElement => {
  const { desktop } = useMediaContext();
  return <Box {...props} space={desktop ? space : mobileSpace} />;
};
