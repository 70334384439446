import * as React from "react";
import { apiRequest, processPing, s3, ProcessResponseT } from "@talentpair/api";
import { sentry } from "@talentpair/sentry";
import { mixpanelHelper } from "@talentpair/tracking";
import { FileWithProcessIdsT } from "@talentpair/types/entities/misc";
import { validFile } from "kyoto/components/FileInput";
import ConfirmParsePane from "./ConfirmParsePane";
import PickResumePane from "./PickResumePane";
import LinkGoogleDocPane from "./LinkGoogleDocPane";
import ProcessingPane from "./ProcessingPane";
import UploadSuccessPane from "./UploadSuccessPane";
import UploadFailPane from "./UploadFailPane";

type ConfirmResumeStateT = {
  pane: "confirm_parse";
  parse: boolean;
};

type PickResumeStateT = {
  error: string;
  pane: "pick_resume";
  parse: boolean;
};
type LinkGoogleDocStateT = {
  error: string;
  pane: "link_google_doc";
  google_doc_url: string;
  parse: boolean;
};
type ProcessingStateT = {
  pane: "processing";
  parse: boolean;
};
type FailStateT = {
  error: string;
  pane: "fail";
  parse: boolean;
};
type SuccessStateT = {
  pane: "success";
  results: ProcessResponseT[];
  parse: boolean;
};

export type ResumeUploadStateT =
  | ConfirmResumeStateT
  | PickResumeStateT
  | LinkGoogleDocStateT
  | ProcessingStateT
  | SuccessStateT
  | FailStateT;

export type OnStateChangeT = (state: ResumeUploadStateT) => unknown;
export interface PickAndUploadResumeProps {
  uploadUrl: string;
  endpoint?: string;
  getUuids?: (file: FileWithProcessIdsT) => string[];
  forceParse?: undefined | null | boolean;
  canLinkGoogleDoc?: boolean | null;
  onSkip?: null | ((event: React.SyntheticEvent) => unknown);
  onStateChanged: OnStateChangeT;
  render(
    state: PickResumeStateT | SuccessStateT | FailStateT,
  ): React.ReactElement | React.ReactNode;
}

class PickAndUploadResume extends React.Component<PickAndUploadResumeProps, ResumeUploadStateT> {
  state: ResumeUploadStateT =
    this.props.forceParse == null
      ? {
          pane: "confirm_parse",
          parse: true,
        }
      : {
          error: "",
          pane: "pick_resume",
          parse: this.props.forceParse || false,
        };

  setPaneState<S extends Omit<ResumeUploadStateT, "parse"> & { parse?: boolean }>(state: S): void {
    this.setState(
      ({ parse }) => ({ ...state, parse: state.parse != null ? state.parse : parse }),
      () => this.props.onStateChanged(this.state),
    );
  }

  handleFileChange = (files: FileList | null | undefined): void | Promise<void> => {
    const { uploadUrl, endpoint } = this.props;
    const { parse } = this.state;

    const file = files ? files[0] : null;
    if (!file || !validFile(file)) {
      return this.setPaneState({
        pane: "pick_resume",
        error: "Not a valid file type. Please try another file.",
      });
    }
    this.setPaneState({ pane: "processing" });

    return s3
      .upload<FileWithProcessIdsT>(file, uploadUrl, { params: { parse }, endpoint })
      .then(({ data }) => this.pingResumeProcesses(data, "pick_resume"));
  };

  handleGoogleDoc = (google_doc_url: string): Promise<void> => {
    const { uploadUrl } = this.props;
    const { parse } = this.state;
    this.setPaneState({ pane: "processing" });
    return apiRequest
      .post<FileWithProcessIdsT>(`${uploadUrl}/files/`, { google_doc_url, parse })
      .then(({ data }) => {
        mixpanelHelper.track("Link Google Doc - Success");
        return this.pingResumeProcesses(data, "link_google_doc");
      })
      .catch((err) => {
        if (err.data && err.data.detail) {
          mixpanelHelper.track("Link Google Doc - Failure");
          this.setPaneState({
            pane: "link_google_doc",
            error: err.data.detail,
          });
        } else sentry.error(err as Error);
      });
  };

  pingResumeProcesses = (
    data: FileWithProcessIdsT,
    prev_pane: "pick_resume" | "link_google_doc",
  ): Promise<void> => {
    const { getUuids } = this.props;
    const uuids: string[] = getUuids ? getUuids(data) : Array.isArray(data) ? data : [];
    return (uuids.length ? Promise.all(uuids.map((id) => processPing(id))) : Promise.resolve([]))
      .then((results) => {
        const textkernel_result = results.find((r) => r.data.type === "textkernel_extract");
        if (!textkernel_result || textkernel_result?.data.success) {
          this.setPaneState({ pane: "success", results });
        } else {
          let tk_is_down = false;
          try {
            tk_is_down = textkernel_result?.data.status_message
              ? JSON.parse(textkernel_result?.data.status_message).will_reparse
              : false;
          } catch (e) {
            sentry.error(new Error("Missing status_message on textkernel bg process."));
          }
          this.setPaneState(
            tk_is_down
              ? { pane: "fail" }
              : {
                  pane: prev_pane,
                  error:
                    "Sorry, we couldn’t extract anything from the file you uploaded. Please try a different file.",
                },
          );
        }
      })
      .catch((e: Error) => {
        this.setPaneState({
          pane: prev_pane,
          error: "Sorry, there was an error processing this resume. Please try again.",
        });
        sentry.error(e);
      });
  };

  render(): React.ReactNode {
    const { onSkip, canLinkGoogleDoc, render } = this.props;
    switch (this.state.pane) {
      case "confirm_parse":
        return (
          <ConfirmParsePane
            initialValue={this.state.parse}
            onSkip={onSkip}
            onSubmit={(value: boolean) =>
              Promise.resolve(this.setPaneState({ pane: "pick_resume", parse: value }))
            }
          />
        );
      case "pick_resume":
        return (
          <PickResumePane
            error={this.state.error}
            onSkip={onSkip}
            onLinkGoogleDoc={
              canLinkGoogleDoc
                ? () =>
                    this.setPaneState({
                      pane: "link_google_doc",
                      error: "",
                    })
                : null
            }
            onPickFile={this.handleFileChange}
          >
            {render(this.state)}
          </PickResumePane>
        );
      case "link_google_doc":
        return (
          <LinkGoogleDocPane
            value={this.state.google_doc_url}
            error={this.state.error}
            onBack={() => this.setPaneState({ pane: "pick_resume", error: "" })}
            onPickDoc={this.handleGoogleDoc}
          />
        );
      case "processing":
        return <ProcessingPane />;
      case "success":
        return (
          <UploadSuccessPane didParse={this.state.parse}>{render(this.state)}</UploadSuccessPane>
        );
      case "fail":
        return <UploadFailPane didParse={this.state.parse}>{render(this.state)}</UploadFailPane>;
      default:
        return null;
    }
  }
}

export default PickAndUploadResume;
