/* eslint-disable class-methods-use-this */
import { apiUrl, isAuthPage } from "@talentpair/env";
import { portal } from "@talentpair/portal";
import { mixpanelHelper } from "@talentpair/tracking";
import { getFlyoutReferer, objToQueryStr } from "@talentpair/utils";
import lodash from "lodash";
import { userService } from "./user";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseResponse(response: Response): Promise<any> {
  // Handle "No Content"
  if (response.status === 204) return Promise.resolve({});

  const contentType = response.headers.get("Content-Type") || "";
  // Handle form data
  if (contentType.includes("multipart/form-data")) return response.formData();
  // Handle JSON and default test environment
  if (contentType.includes("application/json") || process.env.NODE_ENV === "test")
    return response.json();
  // Default
  return response.text();
}

export type ResponseDataT<T> = {
  data: T;
  headers: Headers;
  status: number;
  statusText: string;
};

// Based on how ky wraps errors: https://github.com/sindresorhus/ky/blob/master/index.js#L86
// Makes it nicer to read in Sentry
export class ResponseError<T> extends Error {
  data: T;
  headers: Headers;
  status: number;
  statusText: string;

  constructor(response: Response, data: T, ignore = false) {
    super(`${ignore ? "SENTRY IGNORE: " : ""}${response.statusText}`);
    this.name = "ResponseError";
    this.data = data;
    this.headers = response.headers;
    this.status = response.status;
    this.statusText = response.statusText;
  }
}

export const NO_AUTH_CREDS_MSG = "Authentication credentials were not provided.";

export const authCredsNotProvided = (body: unknown): boolean =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lodash.isObject(body) && "detail" in body && (body as any).detail === NO_AUTH_CREDS_MSG;

let showMissingAuth = true;
export const warnOnMissingAuth = (): void => {
  if (showMissingAuth && !isAuthPage()) {
    showMissingAuth = false;
    userService.loggedin = false;
    portal.snackbar({
      type: "warning",
      message: "You've been logged out. Please refresh this page to log back in.",
      autoHideDuration: null,
      onExited: () => {
        showMissingAuth = true;
      },
      ClickAwayListenerProps: {
        onClickAway: lodash.noop,
      },
    });
  }
};

export async function checkStatus<T>(response: Response): Promise<ResponseDataT<T>> {
  const body = await parseResponse(response);
  const { headers, ok, status, statusText, url } = response;
  // Mimic $http/axios style response
  if (ok) return { data: body, headers, status, statusText };
  // Warn user if they are getting specific 403s, and mark them as logged out
  // we're ignoring requests for the user info and settings to prevent showing this warning after they've already received it in recruit and refreshed the page
  if (
    status === 403 &&
    authCredsNotProvided(body) &&
    !(url.includes("/users/api/info/") || url.includes("/users/settings/"))
  )
    warnOnMissingAuth();
  // If the error is related to auth issues, mark it as ignored so it doesn't get re-sent.
  throw new ResponseError(response, body, [401, 403, 404].includes(status));
}

export function addFlyoutRefererToUrl(url: string, referrer: string): string {
  if (referrer)
    return `${url}${url.includes("?") ? "&" : "?"}flyout_referer=${encodeURIComponent(referrer)}`;
  return url;
}

export const shouldAddFlyoutReferer = (url: string, method: string): boolean =>
  method === "POST" && url.startsWith(apiUrl());

export async function request<T>(
  url: string,
  options: RequestInit = {},
): Promise<ResponseDataT<T>> {
  const method = options.method || "GET";
  // if (process.env.NODE_ENV === "test")
  //   throw new Error(`Unmocked call to API in test - ${method}: ${url}`);
  mixpanelHelper.trackPageLoad({ url, method });
  const rsp = await fetch(
    shouldAddFlyoutReferer(url, method) ? addFlyoutRefererToUrl(url, getFlyoutReferer()) : url,
    { credentials: "include", ...options },
  );
  return checkStatus<T>(rsp);
}

// Makes a memoized version of request that caches based on the url.
// Should really only be used for GET requests.
export const memoizedRequest: (<T>(
  url: string,
  options?: RequestInit,
) => Promise<ResponseDataT<T>>) &
  lodash.MemoizedFunction = lodash.memoize(request);

/**
 * Must set `apiRequest.token` to a valid CSRF token before use. See https://goo.gl/yIZYsk
 * CSRF Token is added to headers for POST/PUT/PATCH/DELETE requests
 */
class ApiRequest {
  token = "";

  setToken(token: string): void {
    this.token = token;
  }

  options<D = Record<string, unknown>>(method: string, data?: D): RequestInit {
    const headers = new Headers({ "X-CSRFToken": this.token });
    const opts: RequestInit = { method };
    if (data) {
      opts.body = JSON.stringify(data);
      headers.set("Content-Type", "application/json");
    }
    opts.headers = headers;
    return opts;
  }

  get<T, D = Record<string, unknown>>(
    uri: string,
    params?: D,
    useRepeatedKeys = false,
  ): Promise<ResponseDataT<T>> {
    const queryStr = params ? objToQueryStr(params, useRepeatedKeys) : "";
    return request(`${apiUrl(uri)}${queryStr}`);
  }

  /**
   * Caches based on endpoint, including query strings. (key = endpoint)
   * Useful for things like London responsive layouts that will be calling the same endpoints in rapid succession.
   * Once fetch request is completed, it cleans up the key so the cache doesn't grow too large and so that the app
   * can refetch the same endpoint and get new data.
   */
  getMemoized<T, D = Record<string, unknown>>(
    uri: string,
    params?: D,
    useRepeatedKeys = false,
  ): Promise<ResponseDataT<T>> {
    const queryStr = params ? objToQueryStr(params, useRepeatedKeys) : "";
    const endpoint = `${apiUrl(uri)}${queryStr}`;
    return memoizedRequest<T>(endpoint)
      .then((rsp) => {
        lodash.defer(() => memoizedRequest.cache.delete(endpoint));
        return rsp;
      })
      .catch((e: ResponseError<T>) => {
        lodash.defer(() => memoizedRequest.cache.delete(endpoint));
        throw e;
      });
  }

  post<T, D = Record<string, unknown>>(uri: string, data?: D): Promise<ResponseDataT<T>> {
    return request(apiUrl(uri), this.options("POST", data));
  }

  patch<T, D = Record<string, unknown>>(uri: string, data?: D): Promise<ResponseDataT<T>> {
    return request(apiUrl(uri), this.options("PATCH", data));
  }

  delete<T, D = Record<string, unknown>>(uri: string, data?: D): Promise<ResponseDataT<T>> {
    return request(apiUrl(uri), this.options("DELETE", data));
  }
}

export const apiRequest = new ApiRequest();
