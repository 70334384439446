// @flow
import { combineReducers } from "@talentpair/redux";
import {
  ACTIVITY_LOADING,
  ACTIVITY_RECEIVE_ITEM,
  ACTIVITY_RECEIVE_LIST,
  ACTIVITY_RECEIVE_LAST_FETCH,
  ACTIVITY_RECEIVE_LATEST,
  ACTIVITY_REMOVE_ITEM,
} from "../../shared-apps/ActivityFeed/store/activityActions";
import type { ActivityActionT } from "../../shared-apps/ActivityFeed/store/activityActions";
import sharedActivityReducers, {
  type SharedActivityDataReduxT,
} from "../../shared-apps/ActivityFeed/store/sharedActivityReducers";

/**
 * Since most actions are created by ActivityAction creators as opposed to ones specifically for PairActivity, the
 * root reducer in this file only runs if the `action.activityType === "pair"`.
 */

export type PairActivityReduxT = {
  team: { [id: number]: SharedActivityDataReduxT, ... },
  pair: { [id: number]: SharedActivityDataReduxT, ... },
};

const singlePairActivity = combineReducers(sharedActivityReducers);

export default function pairActivity(
  state: PairActivityReduxT = { team: {}, pair: {} },
  action: ActivityActionT,
): PairActivityReduxT {
  if (!["pair_tap", "pair_shared"].includes(action.activityType)) return state;
  const feed = action.activityType === "pair_shared" ? "pair" : "team";

  switch (action.type) {
    case ACTIVITY_LOADING:
    case ACTIVITY_RECEIVE_ITEM:
    case ACTIVITY_REMOVE_ITEM:
    case ACTIVITY_RECEIVE_LAST_FETCH:
    case ACTIVITY_RECEIVE_LATEST:
    case ACTIVITY_RECEIVE_LIST:
      return {
        ...state,
        [feed]: {
          ...state[feed],
          [action.id]: singlePairActivity(state[feed][action.id], action),
        },
      };

    default:
      (action: empty);
      return state;
  }
}
