import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  useMediaQuery,
  Grid,
  IconButton,
  DialogProps,
  DialogContentProps,
  DialogActionsProps,
} from "@mui/material";
import { Typography } from "@talentpair/quantic";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import { ComponentType, ReactElement, ReactNode, forwardRef } from "react";
import { PortalPropsT } from "./portal";

type PropsT = PortalPropsT &
  Omit<DialogProps, "onExited" | "onClose" | "onSubmit" | "disableBackdropClick"> & {
    actions?: ReactNode;
    divider?: boolean;
    render?: (el: HTMLDivElement | null) => ReactNode;
    closeButton?: boolean;
    title?: string;
    DialogContentProps?: DialogContentProps;
    ActionProps?: DialogActionsProps;
    "data-testid"?: string;
    disableBackdropClick?: boolean;
  };

export function BaseModal({
  actions,
  divider = false,
  closeButton = true,
  children,
  render,
  title,
  classes,
  DialogContentProps: dialogContentProps,
  ActionProps,
  disableBackdropClick = false,
  onExited,
  ...props
}: PropsT): React.ReactElement {
  const onClose = (e: unknown, reason: "escapeKeyDown" | "backdropClick"): void => {
    if (disableBackdropClick && reason === "backdropClick") return;
    props.onClose();
  };
  return (
    <Dialog
      classes={classes}
      data-testid="modal"
      {...props}
      onClose={onClose}
      TransitionProps={{ onExited }}
    >
      {title && (
        <DialogTitle>
          <Grid alignItems="center" container justifyContent="space-between" wrap="nowrap">
            <Typography color="primary.contrastText" noWrap variant="h3" data-testid="modalTitle">
              {title}
            </Typography>
            {closeButton && (
              <Typography color="primary.contrastText">
                <IconButton
                  classes={{ root: "ml5" }}
                  color="inherit"
                  onClick={props.onClose}
                  style={{ marginRight: -12 }}
                  data-testid="closeModalBtn"
                >
                  <CloseIcon classes={{ root: "h2" }} />
                </IconButton>
              </Typography>
            )}
          </Grid>
        </DialogTitle>
      )}
      <DialogContent
        {...dialogContentProps}
        className={dialogContentProps?.className}
        dividers={!!actions && divider}
      >
        {children}
      </DialogContent>
      {actions && <DialogActions {...ActionProps}>{actions}</DialogActions>}
    </Dialog>
  );
}

const ResponsiveModal: ComponentType<PropsT> = forwardRef((props, ref) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return <BaseModal {...props} ref={ref} fullScreen={fullScreen} />;
});

export type NoModalPropsT = {
  noModal?: boolean;
  titleless?: boolean;
};

// Uses modal style without actually overlaying modal on top of current dom
// meant for use OUTSIDE of portal
export const NoModal = ({
  title,
  divider,
  titleless,
  closeButton = true,
  children,
  actions,
  onClose,
  DialogContentProps: dialogContentProps,
  ...props
}: NoModalPropsT & PropsT): ReactElement => (
  <section className="flex-auto flex flex-column items-stretch" {...props}>
    {title && !titleless ? (
      <div
        className="flex-none flex justify-between items-center nowrap bg-green-500 white"
        style={{ padding: "8px 20px 8px 12px", height: 64 }}
      >
        <Typography color="inherit" noWrap variant="h3" data-testid="modalTitle">
          {title}
        </Typography>
        {closeButton && (
          <IconButton
            classes={{ root: "ml5" }}
            color="inherit"
            onClick={onClose}
            style={{ marginRight: -12 }}
          >
            <CloseIcon classes={{ root: "h2" }} />
          </IconButton>
        )}
      </div>
    ) : null}
    <div className={clsx("flex-auto overflow-auto", dialogContentProps?.classes?.root, "p3")}>
      {typeof children !== "function" && children}
    </div>
    {actions && (
      <div className={clsx("flex justify-end items-center p2", divider && "grey-border-top")}>
        {actions}
      </div>
    )}
  </section>
);

export type ModalPropsT = PropsT &
  NoModalPropsT & {
    responsive?: boolean;
  };

export const Modal = ({ children, responsive, noModal, ...props }: ModalPropsT): ReactElement => {
  const Cmp = noModal ? NoModal : responsive ? ResponsiveModal : BaseModal;
  return <Cmp {...props}>{children}</Cmp>;
};
