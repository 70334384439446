import * as React from "react";
import { apiRequest } from "@talentpair/api";
import { SubmitButton } from "@talentpair/quantic";
import Form from "kyoto/components/Forms/Formik/Form";
import { Textarea } from "kyoto/components/Forms/Formik/fields/TextInput";

export type FeedbackFormProps = {
  params?: Record<string, unknown>;
  autoFocus?: boolean;
  submitLabel?: string;
  initialValue?: string;
  onSubmitSuccess?: null | (() => unknown);
  placeholder?: string;
};

export default function FeedbackForm({
  params,
  autoFocus = false,
  submitLabel = "Send feedback",
  initialValue = "",
  onSubmitSuccess = null,
  placeholder = "Type your feedback",
}: FeedbackFormProps): React.ReactElement {
  return (
    <Form
      initialValues={{ text: initialValue }}
      onSubmit={({ text }: Record<string, unknown>): Promise<unknown> =>
        apiRequest
          .post("users-platform/feedback/", { text, ...params })
          .then(() => onSubmitSuccess?.())
      }
      render={({ isSubmitting }) => (
        <div>
          <Textarea autoFocus={autoFocus} placeholder={placeholder} name="text" rows={10} />
          <SubmitButton className="mt3 mb2" disabled={isSubmitting}>
            {submitLabel}
          </SubmitButton>
        </div>
      )}
    />
  );
}
