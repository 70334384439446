// @flow
import * as React from "react";
import { createCapHeader, createMeta } from "../../components/hocs/createCapHeader";
import { page } from "../../components/layout/responsive";

const title = "Invite friends";

export const CapReferFriendsPage = page({
  mobile: React.lazy(() => import("./containers/ReferFriendsPage")),
  header: createCapHeader(title, "/", true),
  meta: createMeta(title),
});
