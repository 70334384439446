export type DynamicPollParams = {
  // Takes the old ISO string timestamp (or empty string)
  // Should return a changed timestamp to poll more often, or the same timestamp to poll more slowly
  poll: (timestamp: string) => Promise<string>;
  min_delay_ms: number; // x > 0
  max_delay_ms: number; // x > 0
  start_delay_ms?: number; // defaults to minimum rate
  slow_rate?: number; // x > 1, default 1.5
  accel_rate?: number; // x < 1, default 0.75
  jitter_percent?: number; // 0 < x < 1, default 0.01
  delayed_start?: boolean;
  timestamp?: string;
};

export function dynamicPoll({
  poll,
  min_delay_ms,
  max_delay_ms,
  start_delay_ms = min_delay_ms,
  slow_rate = 1.5,
  accel_rate = 0.5,
  jitter_percent = 0.01,
  delayed_start = false,
  timestamp = "",
}: DynamicPollParams): AbortController {
  const ac = new AbortController();
  let delay = start_delay_ms;
  let jitter = 0;

  function run(): void {
    if (ac.signal.aborted) return;

    if (window.document.visibilityState === "hidden") {
      setTimeout(run, delay + jitter);
      return;
    }

    poll(timestamp).then((new_timestamp) => {
      if (timestamp) {
        // either increase or decrease the delay
        if (new_timestamp === timestamp) {
          // If the timestamps match, it indicates no update since the last poll.
          // Thus, we slow down the polling by multiplying the delay with the slow_rate
          delay *= slow_rate;
        } else {
          // If the timestamps differ, calculate the difference between them.
          const d = new Date(new_timestamp).getTime();
          const difference = d - new Date(timestamp).getTime();
          // We want our poll rate to synchronize with the actual rate of update of the record (if there even is one) but we also don't want it to bounce around too much, so we prefer the least amount of change to delay
          // Adjust the delay: pick the lesser value between the time difference of the two timestamps
          // and the product of current delay and accel_rate.
          delay = Math.min(difference, delay * accel_rate);
          // Subtract the time that has passed since the new record's timestamp from the delay.
          // This effectively "speeds up" the next poll to be closer to when the next change might occur.
          delay -= Math.max(0, Date.now() - d);
        }

        // Ensure the delay is within the bounds set by min_delay_ms and max_delay_ms.
        delay = Math.max(min_delay_ms, Math.min(delay, max_delay_ms));
      }

      timestamp = new_timestamp || new Date().toISOString(); // Fallback to date in case we started with nothing and didn't get an update else we'll ping at fastest rate forever

      // Jitter is a random value that's added to or subtracted from the main delay to avoid synchronized behavior.
      // Calculate jitter value and adjust the delay accordingly. (this may allow it to exceed the min and max bounds we set, but jitter is about breaking hard rules anyway right? ;)
      jitter = delay * jitter_percent * (Math.random() * 2 - 1);
      setTimeout(run, delay + jitter);
    });
  }
  // kick off the polling!
  setTimeout(run, delayed_start ? start_delay_ms : 0);

  // Return the AbortController, which can be used to stop the polling externally.
  return ac;
}
