import * as React from "react";
// @rs-expect-error - REMOVE
import PairListTabs from "../components/PairListTabs";

export {
  PairListHeader as CapPairListTitle,
  PairListMeta as CapPairListMeta,
} from "./containers/PairListHeader";

// @ts-expect-error - it's okay for now
export const CapPairList = PairListTabs(React.lazy(() => import("./containers/PairListPage")));

/* PAGE HANDLED BY ../PairRoutes.jsx */
