import { astralLink, env, londonLink } from "@talentpair/env";
import { NotificationT, SmallNotificationT } from "@talentpair/types/notifications";
import { jobIdentity } from "kyoto/services/identity";
import {
  displayJobAndOrg,
  getRole,
  JobWithRoleAndOrgT,
  JobWithRoleT,
} from "@talentpair/entities/job";
import { displayFullName, FirstLastNameT } from "@talentpair/entities/user";
import { activityLink, didCandidateDecide } from "./activity";

type CandidateWithUserT = { user: FirstLastNameT };

export const candidateForJob = (candidate: CandidateWithUserT, job: JobWithRoleT): string =>
  `${displayFullName(candidate.user)} for ${getRole(job)}`;

export const candidateForJobAtOrg = (
  candidate: CandidateWithUserT,
  job: JobWithRoleAndOrgT,
): string => `${displayFullName(candidate.user)} for ${displayJobAndOrg(job)}`;

type OptionsT = {
  desktop?: boolean; // if client is currently in mobile or desktop view -- DOES NOT have anything to do with desktop notifications
  isRecruiter?: boolean;
  isCap?: boolean;
};

export function subtitle(
  notification: NotificationT | SmallNotificationT,
  options: OptionsT = { desktop: false, isRecruiter: false, isCap: false },
): string {
  switch (notification.type) {
    case "mpc": {
      const user = notification.activity.instance;
      return `${displayFullName(notification.activity.subject)} added MPC tag to ${displayFullName(
        user,
      )}, ${user.role?.name || "Unknown Role"} @ ${user.company?.name || "Unknown Company"}`;
    }
    case "curate_user": {
      const role = notification.activity.subject.role?.name;
      const company = notification.activity.subject.company?.name;
      return role || company ? `${role || "Unknown Role"} @ ${company || "Unknown Company"}` : "";
    }
    case "candidate_send":
    case "candidate_send_luther": {
      return displayJobAndOrg(notification.activity.indirect_object_jobs[0]);
    }

    case "comment": {
      const { pair, cap_visible, tap_visible } = notification.activity.instance;
      if (options.isRecruiter) {
        return `Left a message ${cap_visible && tap_visible ? "in the shared chat for" : "on"} ${
          cap_visible && !tap_visible
            ? displayJobAndOrg(pair.job)
            : candidateForJobAtOrg(pair.candidate, pair.job)
        }`;
      }
      return `Left a message ${
        cap_visible && tap_visible
          ? `in the ${options.isCap ? "company" : "candidate"} chat for`
          : "on"
      } ${cap_visible ? displayJobAndOrg(pair.job) : candidateForJob(pair.candidate, pair.job)}`;
    }

    case "recruiter_interest":
    case "recruiter_pass": {
      const { pair } = notification.activity.instance;
      return options.isRecruiter
        ? candidateForJobAtOrg(pair.candidate, pair.job)
        : displayJobAndOrg(pair.job);
    }

    case "greenhouse_pass":
    case "interest":
    case "pass": {
      const { pair } = notification.activity.instance;
      const candidateInterestPass = didCandidateDecide(notification.activity);
      if (options.isRecruiter) {
        return candidateInterestPass
          ? displayJobAndOrg(pair.job)
          : candidateForJobAtOrg(pair.candidate, pair.job);
      }
      return candidateInterestPass
        ? displayJobAndOrg(pair.job)
        : candidateForJob(pair.candidate, pair.job);
    }

    case "interest_reminder": {
      const { candidate, job } = notification.activity.instance.pair;
      return `Reminder: ${displayFullName(candidate.user)} is interested in ${displayJobAndOrg(
        job,
      )}`;
    }

    case "greenhouse_interview":
    case "interview": {
      const { candidate, job } = notification.activity.instance;
      if (options.isCap) return displayJobAndOrg(job);
      if (!options.isRecruiter) return candidateForJob(candidate, job);
      return candidateForJobAtOrg(candidate, job);
    }

    case "job_create":
      return "Created a new job";

    case "job_owner": {
      const { direct_object_jobs } = notification.activity;
      return `Assigned you as the Growth Partner for ${displayJobAndOrg(direct_object_jobs[0])}.`;
    }

    case "job_update":
      return "Updated the job details.";

    case "job_update_pro":
      return "Upgraded to Pro!";

    case "job_update_tap_status": {
      const { data } = notification.activity;
      return `Updated status${data?.tap_status ? ` to ${data.tap_status.name}` : ""}`;
    }

    case "job_grade": {
      return "Recommends you take action";
    }

    case "job_greenhouse_connect":
      return "Connected to Greenhouse ATS";

    case "greenhouse_pair_update":
      return "Greenhouse updated";

    case "mention":
    case "reminder": {
      const {
        direct_objects: users,
        direct_object_jobs: jobs,
        direct_object_organization: org,
        instance,
      } = notification.activity;
      // can't use destructuring here because flow won't recognize the type refinement
      if (notification.activity.type === "reminder" && notification.activity.instance.pair) {
        const { candidate, job } = notification.activity.instance.pair;
        return `${displayFullName(candidate.user)} for ${displayJobAndOrg(job)}`;
      }
      if ("pair_id" in instance && typeof instance.pair_id === "number" && users[0] && jobs[0])
        return `${displayFullName(users[0])} for ${displayJobAndOrg(jobs[0])}`;
      if (users[0]) return displayFullName(users[0]);
      if (jobs[0]) return `${displayJobAndOrg(jobs[0])}`;
      if (org) return org.name;
      return "";
    }

    case "message_bounce":
    case "message_spam_report": {
      const { instance } = notification.activity;
      return instance.user
        ? `${displayFullName(instance.user)}${
            instance.user.organization ? ` at ${instance.user.organization.name}` : ""
          }`
        : "";
    }

    case "mutual_interest": {
      const { pair } = notification.activity.instance;
      if (options.isRecruiter) return candidateForJobAtOrg(pair.candidate, pair.job);
      if (!options.isCap) return candidateForJob(pair.candidate, pair.job);
      return displayJobAndOrg(pair.job);
    }

    case "org_owner": {
      const { direct_object_organization } = notification.activity;
      return `Assigned you as the Growth Executive for ${
        direct_object_organization?.name || "Unknown Org"
      }.`;
    }

    case "org_update":
      return notification.activity.instance.name;

    case "user_comment":
      return "Left you a message";

    default:
      return "";
  }
}

export function notificationLink(
  notification: NotificationT | SmallNotificationT,
  options: OptionsT = { desktop: false, isRecruiter: false, isCap: false },
): string {
  switch (notification.type) {
    // eslint-disable-next-line no-fallthrough
    case "billing_options":
      return env.londonUrl(
        londonLink.tapBilling(
          notification.activity.direct_object_organization?.id ||
            notification.activity.instance.organization.id,
        ),
      );
    case "greenhouse_candidate_send": // pretty much identical to candidate_send which is already looking for the "greenhouse" instance type
    case "candidate_send_luther":
    case "candidate_send": {
      const { direct_objects, indirect_object_jobs, instance } = notification.activity;
      const job = indirect_object_jobs[0];
      const candidateUser = direct_objects[0];
      if (
        instance.type === "greenhouse" &&
        typeof candidateUser.pair_id === "number" &&
        options.isRecruiter
      )
        return env.gettysburgUrl(astralLink.toPair(candidateUser.pair_id));
      if (job) {
        if (options.isCap && !!candidateUser.candidate && candidateUser.pair_id)
          return env.londonUrl(
            londonLink.capPairActivity(candidateUser.candidate, candidateUser.pair_id),
          );

        if (options.isRecruiter && typeof candidateUser.pair_id === "number")
          return env.gettysburgUrl(
            jobIdentity.isPlatform(job)
              ? astralLink.toPairTapFeed(candidateUser.pair_id)
              : astralLink.toPairActivity(candidateUser.pair_id),
          );

        const gotoPairDetail =
          (options.desktop || direct_objects.length === 1) &&
          candidateUser &&
          typeof candidateUser.pair_id === "number";

        return gotoPairDetail
          ? env.londonUrl(
              londonLink.tapPairDetail(job.organization.id, job.id, candidateUser.pair_id || 0),
            )
          : env.londonUrl(londonLink.tapPairList(job.organization.id, job.id));
      }
      return "";
    }

    case "interview_scorecard": {
      if (options.isRecruiter)
        return env.gettysburgUrl(astralLink.toPairActivity(notification.activity.instance.pair.id));
      return "";
    }

    case "interest_reminder":
      return env.gettysburgUrl(astralLink.toPairCapFeed(notification.activity.instance.pair.id));

    case "candidate_upload_resume":
      return env.gettysburgUrl(
        astralLink.toCandidateDocs(notification.activity.direct_objects[0].id),
      );

    case "greenhouse_interview":
      return env.gettysburgUrl(astralLink.toPairActivity(notification.activity.instance.pair_id));

    case "greenhouse_pass":
      return env.gettysburgUrl(astralLink.toPairActivity(notification.activity.instance.pair.id));

    case "greenhouse_pair_update":
      return env.gettysburgUrl(astralLink.toPairActivity(notification.activity.instance.id));

    case "job_create":
    case "job_owner":
    case "job_update":
    case "job_update_pro":
    case "job_update_tap_status": {
      const { direct_object_jobs: jobs } = notification.activity;
      if (!jobs.length) return "";

      return options.isRecruiter
        ? env.gettysburgUrl(astralLink.toJobIntake(jobs[0].id))
        : env.londonUrl(londonLink.tapJobInfo(jobs[0].organization.id, jobs[0].id));
    }

    case "job_grade": {
      return env.gettysburgUrl(astralLink.toJob(notification.activity.direct_object_jobs[0].id));
    }

    case "job_send": {
      const { direct_object_jobs, indirect_objects } = notification.activity;
      const candidate = indirect_objects[0] ? indirect_objects[0].candidate : null;
      const job = direct_object_jobs[0];
      if (candidate) {
        return (options.desktop || direct_object_jobs.length === 1) &&
          job &&
          typeof job.pair_id === "number"
          ? env.londonUrl(londonLink.capPairDetail(candidate, job.pair_id))
          : env.londonUrl(londonLink.capPairList(candidate));
      }
      return "";
    }

    case "mention":
    case "reminder": {
      const {
        direct_objects: users,
        direct_object_jobs: jobs,
        direct_object_organization: org,
        instance,
      } = notification.activity;
      // can't use destructuring here because flow won't recognize the type refinement
      if (notification.activity.type === "reminder" && notification.activity.instance.pair)
        return env.gettysburgUrl(astralLink.toPairPipeline(notification.activity.instance.pair.id));
      if ("pair_id" in instance && typeof instance.pair_id === "number")
        return env.gettysburgUrl(astralLink.toPairActivity(instance.pair_id));
      if (users[0]) return env.gettysburgUrl(astralLink.toProfile("candidate", users[0].id));
      if (jobs[0]) return env.gettysburgUrl(`${astralLink.toProfile("job", jobs[0].id)}/activity`);
      if (org) return env.gettysburgUrl(astralLink.toProfile("organization", org.id));
      return "";
    }

    case "org_owner":
    case "org_update":
      return env.londonUrl(londonLink.tapOrgProfile(notification.activity.instance.id));

    case "mpc":
    case "curate_user":
      return env.gettysburgUrl(
        astralLink.toProfile("candidate", notification.activity.instance.id),
      );

    case "offer":
    case "placement":
      return env.gettysburgUrl(astralLink.toJob(notification.activity.indirect_object_jobs[0].id));

    case "pricing_plan":
      return env.gettysburgUrl(
        astralLink.toOrgPayment(
          notification.activity.direct_object_organization?.id ||
            notification.activity.instance.organization_id,
        ),
      );

    default:
      return activityLink(notification.activity, {
        isRecruiter: options.isRecruiter,
        isCap: options.isCap,
      });
  }
}

export const desktopNotificationLink = (
  notification: NotificationT,
  options: OptionsT = { desktop: false, isRecruiter: true, isCap: false },
): string =>
  options.isRecruiter && notification.chore
    ? env.gettysburgUrl(
        astralLink.toNotificationInboxItem(notification.user.id, notification.chore),
      )
    : `${notificationLink(notification, options)}?notification=${
        notification.id
      }&notification_type=${notification.type}`;

export const _test = { candidateForJob, candidateForJobAtOrg };
