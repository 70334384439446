import clsx from "clsx";
import { withStyles } from "@mui/styles";

const starStyle = {
  "@keyframes resume-success-star": {
    "0%": { opacity: 1 },
    "50%": { opacity: 0.33 },
    "100%": { opacity: 1 },
  },
  star: {
    animationDuration: "3s",
    animationIterationCount: "infinite",
    animationName: "$resume-success-star",
  },
  starOffset: {
    animationDelay: "-1s",
  },
  starOffset2: {
    animationDelay: "-2s",
  },
};
export const Stars = withStyles(starStyle)(
  ({
    classes,
  }: {
    classes: {
      star: string;
      starOffset: string;
      starOffset2: string;
    };
  }) => (
    <g fill="#D6F6E7" fillRule="evenodd">
      <path
        className={classes.star}
        d="M26.284 132.487l-10.873-2.582 10.408-4.071 2.582-10.872 4.07 10.408 10.872 2.581-10.407 4.07-2.582 10.873zM191.205 91.155l-4.126-.642 4.181-1.988 1.387-4.416 1.21 3.996 4.126.643-4.181 1.987-1.386 4.417zM23.665 97.931l-5.68-1.011 5.667-2.569 1.756-5.97 1.792 5.483 5.68 1.012-5.668 2.569-1.756 5.97zM72.657 29.31l-10.873-2.583 10.407-4.07 2.582-10.872 4.071 10.407 10.872 2.582-10.407 4.07-2.582 10.873zM204.268 55.797l-4.056-.722 4.048-1.836 1.254-4.263 1.28 3.916 4.056.723-4.048 1.834-1.253 4.265zM194.763 126.664l-5.678-1.011 5.667-2.57 1.755-5.97 1.791 5.484 5.68 1.011-5.667 2.569-1.756 5.97z"
      />
      <path
        className={clsx(classes.star, classes.starOffset)}
        d="M178.657 37.31l-10.873-2.583 10.407-4.07 2.582-10.872 4.071 10.407 10.872 2.582-10.407 4.07-2.582 10.873zM124.763 30.664l-5.678-1.011 5.667-2.57 1.755-5.97 1.791 5.484 5.68 1.011-5.667 2.569-1.756 5.97zM119.143 161.994l-7.232-1.38 7.154-3.15 2.124-7.524 2.374 6.97 7.232 1.38-7.154 3.15-2.124 7.524zM6.002 66.69l-4.126-.642 4.181-1.988 1.386-4.416 1.21 3.996 4.127.643-4.181 1.987-1.386 4.417zM62.763 157.664l-5.678-1.011 5.667-2.57 1.755-5.97 1.791 5.484 5.68 1.011-5.667 2.569-1.756 5.97zM104.763 12.664l-5.678-1.011 5.667-2.57 1.755-5.97 1.791 5.484 5.68 1.011-5.667 2.569-1.756 5.97z"
      />
      <path
        className={clsx(classes.star, classes.starOffset2)}
        d="M166.143 68.994l-7.232-1.38 7.154-3.15 2.124-7.524 2.374 6.97 7.232 1.38-7.154 3.15-2.124 7.524zM152.657 140.31l-10.873-2.583 10.407-4.07 2.582-10.872 4.071 10.407 10.872 2.582-10.407 4.07-2.582 10.873zM40.763 75.664l-5.678-1.011 5.667-2.57 1.755-5.97 1.791 5.484 5.68 1.011-5.667 2.569-1.756 5.97zM84.143 133.994l-7.232-1.38 7.154-3.15 2.124-7.524 2.374 6.97 7.232 1.38-7.154 3.15-2.124 7.524zM32.643 34.994l-8.033-2.077 7.574-2.785 1.704-7.888 3.175 7.666 8.033 2.077-7.574 2.785-1.704 7.888z"
      />
    </g>
  ),
);
