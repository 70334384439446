/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from "react";

export const newTabLinkProps = {
  rel: "noopener noreferrer",
  target: "_blank",
};

export const NewTabLink = React.forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement>
>((props, ref) => <a {...props} {...newTabLinkProps} ref={ref} />);
