import * as React from "react";
import { FeatureFlagT, featureFlags } from "../featureFlags";

export function useFeatureFlag(flag: FeatureFlagT): null | boolean {
  const [hasFeature, setHasFeature] = React.useState<null | boolean>(null);
  React.useEffect(() => {
    let setter = setHasFeature;
    featureFlags.hasFeatureFlag(flag).then(setter);
    return () => {
      setter = () => null;
      setHasFeature(null);
    };
  }, [flag]);

  return hasFeature;
}
