import pairService from "../../services/pair";
import { CAP_PAIR_RECEIVE_DETAIL, PairReceiveDetailActionT } from "./pairActions";
import { PAIR_FILTER } from "./pairFilterActions";
import type { PairFilterActionT, PairFilterT } from "./pairFilterActions";

export type PairFilterReduxT = PairFilterT;

function pairFilter(
  state: PairFilterReduxT = "REVIEW",
  action: PairFilterActionT | PairReceiveDetailActionT,
): PairFilterReduxT {
  switch (action.type) {
    case PAIR_FILTER:
      return action.filter;

    case CAP_PAIR_RECEIVE_DETAIL: {
      return pairService.capIsPairInactive(action.pair)
        ? "INACTIVE"
        : pairService.capIsPairActive(action.pair)
        ? "ACTIVE"
        : "REVIEW";
    }

    default:
      return state;
  }
}

export default pairFilter;
