// @flow
import { displayFullName } from "@talentpair/entities/user";
import type { ContextRouter } from "@talentpair/routing";
import { createMeta } from "../../../components/TapHeader";
import type { StateT } from "../../../store";
import { getOrgUser } from "../../store/orgSelectors";

const getTitle = (state: StateT, { match: { params } }: ContextRouter) => {
  const user = getOrgUser(state, +params.user);
  return { title: user ? displayFullName(user, user.email) : "" };
};

export const OrgUserProfileMeta = createMeta(getTitle);

export const _test = { getTitle };
