/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from "react";
import { styled } from "@mui/material/styles";
import { TypographyProps } from "./Typography";
import { dynamicDarken } from "../theme/dynamicDarken";

export type LinkProps = Pick<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  | "download"
  | "rel"
  | "href"
  | "hrefLang"
  | "onClick"
  | "style"
  | "className"
  | "children"
  | "target"
> & {
  variant?: Exclude<TypographyProps["variant"], "inherit">;
  color?: "inherit" | "primary";
  unsafe?: boolean;
  responsiveColor?: boolean;
};

const UnstyledLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ color, unsafe, ...props }, ref) =>
    // unsafe means a link to some external location that we don't trust or don't want to send referrer data to
    // we should use this for candidate links, or links we otherwise cannot trust
    // if we're linking to an external location that we don't control but with whom we have a professional relationship (like a client) then we should consider them safe to receive the referrer info
    unsafe ? (
      <a rel="noopener noreferrer" referrerPolicy="no-referrer" {...props} ref={ref} />
    ) : (
      <a {...props} ref={ref} />
    ),
);

export const Link = styled(UnstyledLink, { skipSx: true })(
  ({ variant = "body1", color = "inherit", responsiveColor = true, theme }) => ({
    color:
      color !== "primary"
        ? "inherit"
        : responsiveColor
        ? dynamicDarken(theme.palette.primary.main)
        : theme.palette.primary.main,
    textDecorationLine: "none",
    ...theme.typography[variant],
    "&:hover": { textDecorationLine: "underline" },
    "&:active": { textDecorationLine: "underline" },
  }),
);
