import lodash from "lodash";
import { astralLink, env, londonLink } from "@talentpair/env";
import { ActivityFeedItemT, FirstInterestT, InterestT, PassT } from "@talentpair/types/activity";
import { ChoiceT } from "@talentpair/types/entities/misc";
import { dateFormat, getShortTimeZone } from "@talentpair/datetime";
import { jobIdentity } from "kyoto/services/identity";
import { documentTitle } from "kyoto/utils/string";
import { displayJobAndOrg, getRole } from "@talentpair/entities/job";
import { displayFullName } from "@talentpair/entities/user";
import { InterviewType } from "./entities/misc";

type VerbsT = {
  [id: string]: string;
};

export const formattedVerb = (verb: ChoiceT, options: VerbsT = {}): string => {
  switch (verb.name) {
    case "deleted":
      return options.deleted || "Deleted";
    case "updated":
      return options.updated || "Updated";
    default:
      return options[verb.name] || verb.name;
  }
};

export function didCandidateDecide({
  instance: { decider, pair },
}: FirstInterestT | InterestT | PassT): boolean {
  return decider.id === pair.candidate.user.id;
}

type OptionsT = {
  notification?: boolean;
  isCap?: boolean;
  isRecruiter?: boolean;
  type?: string;
};
export function body(
  item: ActivityFeedItemT,
  options: OptionsT = { notification: false, isCap: false, isRecruiter: false },
): string {
  switch (item.type) {
    case "application":
      return "Applied";
    case "availability_request":
      return item.verb.name === "created"
        ? "Request for availability"
        : "Here are time slots I am available for interview(s).";

    case "badness_report": {
      const user = item.direct_objects[0];
      const job = item.direct_object_jobs[0];
      return `${user ? displayFullName(user) : displayJobAndOrg(job)} was reported`;
    }
    case "call":
    case "comment":
    case "remark":
    case "taj":
    case "user_comment":
      return item.instance.text;

    case "candidate_owner":
      return `Reassigned ${options.isRecruiter ? "the" : "your"} Growth Partner.`;

    case "candidate_send_event": {
      const candidates = item.direct_objects;
      const job = item.indirect_object_jobs[0];
      if (item.instance.type === "greenhouse" && !options.isCap)
        return `Submitted ${displayFullName(candidates[0])} for ${getRole(job)} at ${
          job.organization.name
        }`;
      return `Sourced ${candidates.length} candidate${
        candidates.length === 1 ? "" : "s"
      } for ${getRole(job)}${options.isRecruiter ? ` at ${job.organization.name}` : ""}`;
    }

    case "candidate_send_receipt": {
      const candidates = item.direct_objects;
      const job = item.indirect_object_jobs[0];
      if (options.notification && !options.isCap) {
        return `Sourced ${candidates.length} candidate${
          candidates.length === 1 ? "" : "s"
        } for ${getRole(job)}${options.isRecruiter ? ` at ${job.organization.name}` : ""}`;
      }
      return `Submitted your profile for review`;
    }

    case "first_interest":
      return "Sent the job to the candidate";

    case "interest": {
      if (options.notification) return "";
      if (didCandidateDecide(item)) {
        return options.isCap ? "Reviewed the job" : "Candidate reviewed the job";
      }
      return options.isCap ? "Company reviewed the candidate" : "Reviewed the candidate";
    }

    case "recruiter_pass":
    case "recruiter_interest":
      return "Reviewed your profile";

    case "recruiter_send_receipt":
      return "Submitted your profile for recruiter review";

    case "pass_feedback_receipt":
      return "Thanks for your feedback";

    case "pair":
      if (options.type === "greenhouse_pair_update") return displayJobAndOrg(item.instance.job);
      return `Updated the ${options.isCap ? "job" : "candidate"} status`;

    case "interview": {
      const { round, type } = item.instance;
      return type.id === InterviewType.HOMEWORK
        ? `${formattedVerb(item.verb, { created: "Assigned" })} the ${round.name} ${type.name}`
        : `${formattedVerb(item.verb, { created: "Scheduled" })} the ${round.name} ${
            type.name
          } interview`;
    }

    case "job":
      return "";

    case "job_send_event": {
      const { direct_object_jobs: jobs, indirect_objects: users } = item;
      return `Sent ${jobs.length} job${jobs.length === 1 ? "" : "s"}${
        options.isRecruiter ? ` to ${displayFullName(users[0])}` : ""
      }`;
    }

    case "message_bounce":
      return "Email Bounced";

    case "message_spam_report":
      return "Spam Report";

    case "mutual_interest":
      return "Congrats. It's a mutual match!";

    case "offer_platform":
      return `${formattedVerb(item.verb, { created: "Recorded" })} an offer`;

    case "organization":
      return "Updated the company profile.";

    case "pass":
      if (options.isCap && !didCandidateDecide(item)) return "Updated the job status";
      if (options.notification) return "";
      if (
        item.subject.username === "luther" ||
        (!options.isCap && !options.isRecruiter && didCandidateDecide(item))
      ) {
        // luther passing on behalf of someone
        // or we're displaying the candidate's pass to the HM
        return `Moved the ${!didCandidateDecide(item) ? "job" : "candidate"} to inactive.`;
      }
      return `Reviewed the ${didCandidateDecide(item) ? "job" : "candidate"}.`;

    case "placement":
      return item.verb.name !== "created"
        ? `Agreement ${formattedVerb(item.verb)}`
        : `Congratulations on your ${options.isCap ? "new job" : "hire"}!`;

    case "reminder":
      return "Reminder!";

    case "user_active":
      return "New sign in!";

    case "job_grade": {
      const job = item.direct_object_jobs[0];
      return `In the past seven days, no submissions or interview activity was recorded for the job ${displayJobAndOrg(
        job,
      )}. The job health is bad.`;
    }

    case "candidate_file":
      return `Uploaded a new resume: ${documentTitle(item.instance)}`;

    case "pair_owner":
      return `Connected you with ${
        item.verb.name === "created" ? "the" : "a new"
      } Growth Partner for this ${options.isCap ? "job" : "candidate"}.`;

    default:
      return "";
  }
}

export function caption(
  item: ActivityFeedItemT,
  options: OptionsT = { notification: false, isCap: false, isRecruiter: false },
): string {
  switch (item.type) {
    case "application":
      return options.isCap
        ? `Interested in ${getRole(item.instance.pair.job)}`
        : displayJobAndOrg(item.direct_object_jobs[0]);

    case "badness_report":
      return `Reported for ${item.instance.text}`;

    case "candidate_send_event": {
      if (item.instance.type === "greenhouse" && !options.isCap)
        return "Submitted the candidate to Greenhouse";
      const candidates = item.direct_objects;
      return candidates.map(displayFullName).join(", ");
    }

    case "candidate_send_receipt": {
      const recruiter = item.subject.first_name;
      return options.notification
        ? `${recruiter} submitted you for ${displayJobAndOrg(item.indirect_object_jobs[0])}.`
        : `${recruiter} submitted you to the company for review.`;
    }

    case "interest": {
      const { candidate, job } = item.instance.pair;
      if (didCandidateDecide(item)) {
        return options.isCap
          ? `Interested in ${getRole(job)}`
          : `${displayFullName(candidate.user)} is interested in the role.`;
      }
      return options.isCap
        ? `${job.organization.name} is interested in you.`
        : `Interested in ${displayFullName(candidate.user)}`;
    }

    case "interview": {
      const { end_time, start_time, type } = item.instance;
      const datestring = `${dateFormat.mon(start_time)} ${dateFormat.jan1st(
        start_time,
      )}, ${dateFormat.t12pm(start_time)}`;

      return type.id === InterviewType.HOMEWORK
        ? `Due: ${datestring} (${getShortTimeZone()})`
        : `${datestring} – ${dateFormat.t12pm(end_time)} (${getShortTimeZone()})`;
    }

    case "job":
    case "job_send_event":
      return item.direct_object_jobs.map(displayJobAndOrg).join(", ");

    case "message_bounce": {
      const { email, user, message } = item.instance;
      return `${displayFullName(message.from_user)}'s email to ${displayFullName(
        user,
      )} at ${email} bounced. Please fix the email address and try again.`;
    }

    case "message_spam_report": {
      const { email, user, message } = item.instance;
      return `${displayFullName(user)} at ${email} marked ${displayFullName(
        message.from_user,
      )}'s email as spam.`;
    }

    case "mutual_interest": {
      if (options.isRecruiter) return "Schedule interview";
      // @ts-expect-error - handle old schema
      const owner = item.instance.pair.owner || item.instance.pair.job.owner;
      return `${
        owner ? displayFullName(owner) : "The Growth Partner for this job"
      } will be following up with you soon.`;
    }

    case "offer_platform":
      return item.instance.sent ? dateFormat.january1st(item.instance.sent) : "";

    case "pass": {
      if (options.isCap && !didCandidateDecide(item)) {
        return `${item.instance.pair.job.organization.name} has decided to move forward with another candidate for this role. \n\nTalentpair will continue to send you alternative roles to review that align with your background as soon as possible. \n\nWe’re sorry this match didn’t work out. `;
      }
      if (item.subject.first_name === "Talentpair") {
        if (options.isCap)
          return lodash.capitalize(item.instance.feedback) || "No longer looking for a job";
        return `${displayFullName(item.instance.pair.candidate.user)} ${
          item.instance.feedback || "accepted another role"
        }.`;
      }
      if (!options.isCap && !options.isRecruiter && didCandidateDecide(item)) {
        // display candidate's pass for HM
        return `${displayFullName(
          item.instance.pair.candidate.user,
        )} is no longer interested in this role.`;
      }
      return `Passed on ${
        didCandidateDecide(item)
          ? getRole(item.instance.pair.job)
          : displayFullName(item.instance.pair.candidate.user)
      }${item.instance.feedback ? `\n\nFeedback:\n${item.instance.feedback}` : ""}`;
    }

    case "pair":
      if (options.type === "greenhouse_pair_update") return "";
      return `Set the ${options.isCap ? "job" : "candidate"} back to review`;

    case "placement":
      return `Accepted Date: ${dateFormat.january1st(item.instance.accepted_date)}`;

    case "recruiter_interest":
      return `Interested in ${displayFullName(item.instance.pair.candidate.user)}`;

    case "recruiter_pass":
      return `Passed on ${displayFullName(item.instance.pair.candidate.user)}${
        item.instance.feedback ? `\n\nFeedback:\n${item.instance.feedback}` : ""
      }`;

    case "recruiter_send_receipt": {
      // @ts-expect-error - handle old schema
      const owner = item.instance.owner || item.instance.job.owner;
      return `Talentpair submitted your profile to ${
        owner ? displayFullName(owner) : "your recruiter"
      } to review`;
    }

    case "reminder":
      return item.instance.text;

    case "user_active":
      return displayFullName(item.instance);

    case "interview_scorecard":
      return `Added scorecard for ${displayFullName(
        item.instance.pair.candidate.user,
      )} for ${displayJobAndOrg(item.instance.job)}`;

    default:
      return "";
  }
}

export function activityLink(
  activity: ActivityFeedItemT,
  options: OptionsT = { notification: false, isCap: false, isRecruiter: false },
): string {
  switch (activity.type) {
    case "application": {
      const { subject } = activity;
      if (subject) return env.gettysburgUrl(`/${String(subject.id)}`);
      return "";
    }
    case "availability_request": {
      const { pair } = activity.instance;

      if (options.isRecruiter) {
        return env.gettysburgUrl(
          jobIdentity.isPlatform(pair.job)
            ? astralLink.toPairTapFeed(pair.id)
            : astralLink.toPairActivity(pair.id),
        );
      }
      const capFeedLink = pair.shared_feed_opened_at
        ? londonLink.capSharedPairActivity
        : londonLink.capPairActivity;
      return env.londonUrl(capFeedLink(pair.candidate.id, pair.id));
    }

    case "badness_report": {
      const { direct_objects: users, direct_object_jobs: jobs } = activity;
      if (users[0]) return env.gettysburgUrl(astralLink.toProfile("candidate", users[0].id));
      if (jobs[0]) return env.gettysburgUrl(astralLink.toProfile("job", jobs[0].id));
      return "";
    }

    case "comment": {
      const { cap_visible, tap_visible, pair } = activity.instance;
      if (options.isRecruiter) {
        return cap_visible && tap_visible
          ? env.gettysburgUrl(astralLink.toPairSharedFeed(pair.id))
          : cap_visible
          ? env.gettysburgUrl(astralLink.toPairCapFeed(pair.id))
          : env.gettysburgUrl(astralLink.toPairTapFeed(pair.id));
      }
      if (!cap_visible || !tap_visible) {
        return cap_visible
          ? env.londonUrl(londonLink.capPairActivity(pair.candidate.id, pair.id))
          : env.londonUrl(
              londonLink.tapPairActivity(pair.job.organization.id, pair.job.id, pair.id),
            );
      }
      return options.isCap
        ? env.londonUrl(londonLink.capSharedPairActivity(pair.candidate.id, pair.id))
        : env.londonUrl(
            londonLink.tapSharedPairActivity(pair.job.organization.id, pair.job.id, pair.id),
          );
    }

    case "recruiter_interest":
    case "recruiter_pass": {
      const { pair } = activity.instance;
      return options.isRecruiter
        ? env.gettysburgUrl(astralLink.toPairCapFeed(pair.id))
        : env.londonUrl(londonLink.capPairActivity(pair.candidate.id, pair.id));
    }

    case "pass_feedback_receipt": {
      const { pair } = activity.instance;
      return options.isRecruiter
        ? env.gettysburgUrl(astralLink.toPairCapFeed(pair.id))
        : env.londonUrl(londonLink.capPairActivity(pair.candidate.id, pair.id));
    }

    case "first_interest":
    case "interest":
    case "pass": {
      const { pair } = activity.instance;
      if (options.isRecruiter) {
        return env.gettysburgUrl(astralLink.toPairCapFeed(pair.id));
      }
      return options.isCap
        ? env.londonUrl(londonLink.capPairActivity(pair.candidate.id, pair.id))
        : env.londonUrl(londonLink.tapPairActivity(pair.job.organization.id, pair.job.id, pair.id));
    }

    case "interview": {
      const { candidate, job, pair_id } = activity.instance;
      return options.isCap
        ? env.londonUrl(londonLink.capPairActivity(candidate.id, pair_id))
        : options.isRecruiter
        ? env.gettysburgUrl(astralLink.toPairTapFeed(pair_id))
        : env.londonUrl(londonLink.tapPairActivity(job.organization.id, job.id, pair_id));
    }

    case "job": {
      const { direct_object_jobs: jobs } = activity;
      if (jobs[0]) return env.londonUrl(londonLink.tapJobInfo(jobs[0].organization.id, jobs[0].id));
      return "";
    }

    case "message_bounce":
    case "message_spam_report": {
      const { instance } = activity;
      return instance.user
        ? env.gettysburgUrl(`${astralLink.toProfile("candidate", instance.user.id)}/activity`)
        : "";
    }

    case "mutual_interest": {
      const { pair } = activity.instance;
      return options.isCap
        ? env.londonUrl(londonLink.capPairActivity(pair.candidate.id, pair.id))
        : options.isRecruiter
        ? env.gettysburgUrl(astralLink.toPairTapFeed(pair.id))
        : env.londonUrl(londonLink.tapPairActivity(pair.job.organization.id, pair.job.id, pair.id));
    }

    case "organization":
      return env.londonUrl(londonLink.tapOrgProfile(activity.instance.id));

    case "user_active": {
      const { candidate, id, organization } = activity.instance;
      if (candidate) return env.gettysburgUrl(astralLink.toCandidateIntake(id));
      if (organization) return env.londonUrl(londonLink.tapOrgUserProfile(organization.id, id));
      return "";
    }

    case "user_comment": {
      const { candidate, organization } = activity.instance.user;
      if (candidate) return env.londonUrl(londonLink.capUserActivity(candidate));
      if (organization) return env.londonUrl(londonLink.tapUserActivity(organization));
      return "";
    }

    default:
      return "";
  }
}
