import * as React from "react";
import clsx from "clsx";
import { Grid, GridProps } from "@mui/material";
import { Typography, TypographyProps } from "@talentpair/quantic";
import { withStyles } from "@mui/styles";
import logo from "@talentpair/assets/images/logo.png";
import { SuspenseWrapper } from "@talentpair/sentry";

export type SectionPropsT = {
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  maxWidth?: 1 | 2 | 3 | 4;
};

export const Section = ({
  children,
  className = "",
  fullWidth = false,
  fullHeight = false,
  maxWidth = 3,
  ...props
}: SectionPropsT): React.ReactElement => (
  <section
    className={clsx(`col-12 max-width-${maxWidth} mx-auto`, className, {
      py3: !fullHeight,
      px3: !fullWidth,
    })}
    {...props}
  >
    {children}
  </section>
);

export const Placeholder = ({ className = "" }: { className?: string }): React.ReactElement => (
  <div
    data-testid="tpPlaceholder"
    className={`flex flex-auto height-100 justify-center items-center o25 ${className}`}
  >
    <img src={logo} alt="Talentpair" />
  </div>
);

export const Sidebar = ({ children }: { children: React.ReactNode }): React.ReactElement => (
  <div className="border-right border-grey-300 col-3 overflow-auto relative">
    <SuspenseWrapper>{children}</SuspenseWrapper>
  </div>
);

const PaneGrid = withStyles({
  container: {
    flexGrow: 1,
    marginLeft: "auto",
    marginRight: "auto",
    minHeight: "100%",
    paddingBottom: 8,
    paddingTop: 8,
  },
})(Grid);

export const Pane = (props: GridProps): React.ReactElement => (
  <PaneGrid container alignItems="center" item direction="column" wrap="nowrap" {...props} />
);

export const Item = (props: GridProps): React.ReactElement => (
  <Grid classes={{ item: "py2" }} item {...props} />
);

export const Actions = (props: GridProps): React.ReactElement => (
  <Item
    alignItems="center"
    classes={{ item: "flex-grow py2" }}
    container
    direction="column"
    justifyContent="flex-end"
    wrap="nowrap"
    {...props}
  />
);

export const Title = (props: TypographyProps): React.ReactElement => (
  <Typography align="center" variant="body2" {...props} />
);
