import { connect as baseConnect } from "react-redux";
import { DispatchProps, MapStateToProps, MapDispatchToProps, ConnectorT } from "./types";

export function connect<StateToProps, DispatchToProps, OwnProps, State>(
  mapStateToProps?: undefined | null | MapStateToProps<StateToProps, OwnProps, State>,
  mapDispatchToProps?: undefined | null | MapDispatchToProps<DispatchToProps, OwnProps>,
): ConnectorT<
  StateToProps &
    (DispatchToProps extends Record<string, unknown> ? DispatchToProps : DispatchProps),
  OwnProps
> {
  // @ts-expect-error - our types are simple
  return baseConnect<StateToProps, DispatchToProps, OwnProps, State>(
    mapStateToProps,
    // @ts-expect-error - We're purposefully overriding these types because the DispatchT type defined in react-redux does not align with ours due to our use of redux-thunk
    mapDispatchToProps,
  );
}
