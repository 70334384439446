import { combineReducers, configureStore, StoreT } from "@talentpair/redux";
import { createReduxEnhancer } from "@talentpair/sentry";
import activity, { ActivityReduxT } from "./shared-apps/ActivityFeed/store/activityReducers";
import notifications, {
  NotificationsStateT,
} from "./shared-apps/Notifications/store/notificationReducers";
import cap, { CapStateT } from "./cap/store/store";
// @ts-expect-error - eslint upgrade
import tap, { TapReduxT } from "./tap/store/store";
import userActivity, {
  UserActivitiesReduxT,
} from "./shared-apps/ActivityFeed/store/userActivityReducers";

const rootReducer = combineReducers({
  cap,
  tap,
  notifications,
  activity,
  userActivity,
});

export interface StateT {
  cap: CapStateT;
  tap: TapReduxT;
  notifications: NotificationsStateT;
  activity: ActivityReduxT;
  userActivity: UserActivitiesReduxT;
}
// TODO: replace above with this below once all the reducers have been converted to typescript
// export type StateT = ReturnType<typeof rootReducer>

const store = configureStore<StateT>({
  enhancers: [
    createReduxEnhancer({
      actionTransformer: ({ type }) => ({ type }),
      stateTransformer: () => null,
    }),
  ],
  reducer: rootReducer,
}) as StoreT<StateT>;

export type DispatchT = typeof store.dispatch;

export default store;
