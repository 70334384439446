import { matchPath } from "@talentpair/routing";

export type RouteConfigT = [string, { path: string; exact?: boolean }][];

export const sharedConfig: RouteConfigT = [
  ["Redirect", { path: "/", exact: true }],

  // AUTH
  ["AUTH/Signin", { path: "/signin" }],
  ["AUTH/Signup", { path: "/signup" }],
  ["AUTH/CandidateSignin", { path: "/candidate-signin", exact: true }],
  ["AUTH/EmployerSignin", { path: "/employer-signin", exact: true }],
  ["AUTH/CandidateSignup", { path: "/candidate-signup", exact: true }],
  ["AUTH/EmployerSignup", { path: "/employer-signup", exact: true }],
  ["AUTH/Forgot", { path: "/forgot-password", exact: true }],
  ["AUTH/Reset", { path: "/forgot-password/:token", exact: true }],
  ["AUTH/Invite", { path: "/invite/:token", exact: true }],
];

export const isAuthPage = (pathname: string = location.pathname): boolean => {
  for (const config of sharedConfig) {
    const match = matchPath(pathname, config[1]);
    if (match && match.path !== "/") return true;
  }
  return false;
};
