import * as React from "react";
import { env } from "@talentpair/env";
import testPixel from "../../public/test_pixel.png";

const conversionIds = {
  tap_sign_up: "1150483",
  mpc_sign_up: "4281548", // used in the MpcLinkedinPixel component
};

type LinkedinPixelProps = {
  type: keyof typeof conversionIds;
  onLoad?: () => unknown;
};

export default function LinkedinPixel({ type, onLoad }: LinkedinPixelProps): React.ReactElement {
  return (
    <img
      height="1"
      width="1"
      className="display-none"
      alt=""
      onLoad={onLoad}
      src={
        env.isProduction() || env.isStaging()
          ? `https://px.ads.linkedin.com/collect/?pid=1334476&conversionId=${conversionIds[type]}&fmt=gif`
          : testPixel
      }
    />
  );
}
