import * as React from "react";
import { withRouter, RouteComponentProps } from "@talentpair/routing";
import { londonLink } from "@talentpair/env";
import { userService } from "@talentpair/api";
import { useSelector } from "@talentpair/redux";
import { UserAvatar, JobAvatar, ListItem, Placard } from "@talentpair/quantic";
import { NavSearchResultT } from "@talentpair/types/navSearch";
import Hotkey from "kyoto/components/Hotkey";
import Form from "kyoto/components/Forms/Formik/Form";
import { displayFullName } from "@talentpair/entities/user";
import { SuggestionPropsT } from "kyoto/components/Autocomplete/types";
import BaseEntitySearch from "kyoto/components/Forms/Formik/fields/EntitySearch/BaseEntitySearch";
// @ts-expect-error - REMOVE ME
import { getOrg } from "../../tap/store/orgSelectors";

export const getSuggestionValue = (result: NavSearchResultT | null): string => {
  if (!result) return "";
  switch (result.type) {
    case "candidate":
    case "contact":
      return displayFullName(result);
    case "job":
      return result.name || "";
    default:
      return "";
  }
};

interface NavSearchSuggestionPropsT
  extends Omit<SuggestionPropsT<NavSearchResultT>, "suggestionValue"> {
  suggestionValue: string;
}
export const NavSearchSuggestion = ({
  suggestion,
  suggestionValue,
  selected,
  isHighlighted,
}: NavSearchSuggestionPropsT): React.ReactElement | null => {
  if (suggestion == null) return null; // shouldn't ever happen because nav search returns a "No Options" suggestion
  return (
    <ListItem
      avatar
      selected={selected || isHighlighted}
      onSelected={() => {}}
      className="full-width"
      primaryContent={
        suggestion.type === "job" ? (
          <Placard
            avatar={<JobAvatar job={suggestion} />}
            primary={suggestionValue}
            secondary={suggestion.location || undefined}
          />
        ) : (
          <Placard
            avatar={
              <UserAvatar
                user={
                  suggestion.status === "do_not_contact"
                    ? { ...suggestion, status: { id: 10 } }
                    : suggestion.status === "no_longer_employed"
                    ? { ...suggestion, no_longer_employed: true }
                    : suggestion
                }
              />
            }
            primary={suggestionValue}
            secondary={
              // BE formats these role strings like: Role Title @ Company Name
              // In TAP nav search all contacts are part of the same TAP org so we strip that name out
              (suggestion.type === "contact"
                ? suggestion?.role?.split(" @ ")?.[0]
                : suggestion?.role) || undefined
            }
          />
        )
      }
    />
  );
};

type NavSearchProps = RouteComponentProps<{ org?: string }> & {
  onSubmit?: () => unknown;
};

function UnconnectedNavSearch({
  onSubmit,
  history,
  location,
  match,
}: NavSearchProps): React.ReactElement {
  const orgId = match.params.org ? +match.params.org : userService.orgId() || 0;
  const org = useSelector((state) => getOrg(state, orgId));
  const user_id = userService.isHm()
    ? userService.id()
    : org?.contact_owner?.id || userService.id();

  const focusNavSearch = (): void => {
    document.getElementById("nav-search")?.focus();
  };
  return (
    <Form
      key={location.pathname}
      initialValues={{ query: "" }}
      className="relative col-12 max-width-3"
      render={() => (
        <>
          <BaseEntitySearch<NavSearchResultT | null | undefined>
            fullHeight
            label={null}
            name="query"
            variant="appbar"
            selectOnTab={false}
            showErrors={false}
            showSearchIcon
            showClearButton
            margin="none"
            getSuggestionValue={getSuggestionValue}
            Suggestion={NavSearchSuggestion}
            fetchImmediately
            shouldRenderSuggestions={() => true}
            placeholder={org?.name ? `Search ${org.name}` : "Search..."}
            entity="tapNavSearch"
            params={{ user_id }}
            FormControlProps={{
              classes: { root: "static" },
            }}
            inputProps={{
              id: "nav-search",
            }}
            submitOnChange={({ val, form }) => {
              if (val) {
                // Map value to a url in TAP and navigate to it if possible
                let nextUrl = "";
                if (val.type === "contact") {
                  if (val.extra_id !== orgId)
                    throw new Error(
                      "Whoah, TAP users should not be able to search outside of their own org!",
                    );
                  // View contact profile under the team
                  nextUrl = londonLink.tapOrgUserProfile(orgId, user_id);
                } else if (val.type === "candidate") {
                  // View candidate in candidate mydesk under the "All candidates" bucket to be sure we can link to them
                  nextUrl = `${londonLink.tapCandidates(orgId)}/pipeline/all/${val.id}/pairs`;
                } else if (val.type === "job") {
                  if (val.extra_id !== orgId)
                    throw new Error(
                      "Whoah, TAP users should not be able to search outside of their own org!",
                    );
                  nextUrl = londonLink.tapJobInfo(orgId, val.id);
                }
                if (nextUrl && nextUrl !== window.location.pathname) {
                  history.push(nextUrl);
                }
                if (onSubmit) onSubmit();
                setTimeout(() => form.setFieldValue("query", ""), 0);
              }
              return Promise.resolve();
            }}
          />
          <Hotkey on="s" handler={focusNavSearch} />
          <Hotkey on="/" handler={focusNavSearch} />
        </>
      )}
    />
  );
}

export const TapNavSearch = withRouter(UnconnectedNavSearch);
