import * as React from "react";
import { Pane, Item, Title, Actions, Button } from "@talentpair/quantic";
import { Grid } from "@mui/material";
import Form from "kyoto/components/Forms/Formik/Form";
import SubmitButton from "kyoto/components/Forms/Formik/SubmitButton";
import RadioGroup from "kyoto/components/Forms/Formik/fields/RadioGroup";
import { numberToBooleanParserFormatter } from "kyoto/components/Forms/parseFormat";
import ResumeProgressSvg from "kyoto/components/ResumeProgressSvg";

const confirmParseChoices = [
  { id: 1, name: "Yes, update the content of my profile." },
  { id: 0, name: "No, only update my resume on file." },
];

interface ConfirmParsePaneProps {
  initialValue: boolean;
  onSkip?: null | ((event: React.SyntheticEvent) => unknown);
  onSubmit(value: boolean): Promise<unknown>;
}

export default function ConfirmParsePane({
  initialValue,
  onSubmit,
  onSkip = null,
}: ConfirmParsePaneProps): React.ReactElement {
  return (
    <Pane>
      <Item>
        <ResumeProgressSvg state="question" />
      </Item>
      <Item>
        <Title>Would you like to update your profile with the content from your new resume?</Title>
      </Item>
      <Form
        className="flex-auto flex flex-column"
        initialValues={{ parse: initialValue }}
        onSubmit={({ parse }) => onSubmit(parse)}
        render={() => (
          <>
            <Item className="px3 py3 mb5">
              <RadioGroup
                name="parse"
                choices={confirmParseChoices}
                {...numberToBooleanParserFormatter}
                FormControlLabelProps={{ classes: { label: "h5" } }}
              />
            </Item>
            <Actions>
              <Grid container justifyContent="space-around" spacing={3} wrap="nowrap">
                {onSkip && (
                  <Grid item>
                    <Button variant="contained" onClick={onSkip} data-testid="skipBtn">
                      Skip for now
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <SubmitButton>Continue</SubmitButton>
                </Grid>
              </Grid>
            </Actions>
          </>
        )}
      />
    </Pane>
  );
}
