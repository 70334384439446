import * as React from "react";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import clsx from "clsx";
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  SvgIconProps as MuiIconProps,
  Tooltip,
} from "@mui/material";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import GroupIcon from "@mui/icons-material/Group";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PersonIcon from "@mui/icons-material/Person";
import { initials, generateUserColor } from "@talentpair/quantic/molecules/avatars/UserAvatar";
import greenhouseImg from "@talentpair/assets/images/greenhouse-logo.png";
import perryIcon from "@talentpair/assets/images/perry-192.png";
import { ProfilePictureUserT } from "@talentpair/types/entities/user";
import { displayFullName } from "@talentpair/entities/user";

export interface AvatarPropsT extends Omit<MuiAvatarProps, "src"> {
  icon?: React.ComponentType<MuiIconProps> | null;
  src?: null | string;
  size?: number;
  title?: string;
  tooltip?: boolean;
  TooltipProps?: Record<string, unknown> | null;
  background?: string;
  // Don't love that this is here... But I'm guessing we are overspreading somewhere
  dnc?: boolean;
}

export type AvatarWithIconT = Omit<AvatarPropsT, "classes" | "icon">;

export const AvatarSVG = ({ children, ...props }: MuiAvatarProps): React.ReactElement => (
  <MuiAvatar {...props}>{children}</MuiAvatar>
);

export const Avatar = ({
  icon: Icon = null,
  size = 40,
  src = "",
  style = {},
  title = "",
  tooltip = false,
  TooltipProps = null,
  classes = {},
  background = "",
  dnc,
  ...props
}: AvatarPropsT): React.ReactElement => {
  const styles = { width: size, height: size, fontSize: `${size / 2 / 16}rem`, ...style };
  const titleInitials = initials(title || "");
  classes = {
    ...classes,
    colorDefault: clsx(classes.colorDefault, background),
  };

  let avatar = (
    <MuiAvatar style={styles} classes={classes} {...props}>
      {titleInitials}
    </MuiAvatar>
  );
  if (Icon && !title) {
    avatar = (
      <MuiAvatar style={styles} classes={classes} {...props}>
        <Icon style={{ fontSize: "1.2em" }} />
      </MuiAvatar>
    );
  }
  if (src)
    avatar = (
      <MuiAvatar style={styles} src={src} alt={titleInitials} classes={classes} {...props} />
    );
  return tooltip && title ? (
    <Tooltip title={title} {...TooltipProps}>
      {avatar}
    </Tooltip>
  ) : (
    avatar
  );
};

interface GoldenAvatarPropsT extends AvatarPropsT {
  golden?: boolean;
}
export const GoldenAvatar = ({
  golden = false,
  ...props
}: GoldenAvatarPropsT): React.ReactElement => {
  if (!golden) return <Avatar {...props} />;
  const { src, className } = props;
  return src ? (
    <Avatar {...props} className={clsx(className, "bg-white border border-amber-400 border-w2")} />
  ) : (
    <Avatar
      {...props}
      background="bg-amber-400"
      className={clsx(className, "border border-amber-400 border-w2")}
    />
  );
};

interface CandidateAvatarPropsT extends Omit<GoldenAvatarPropsT, "id"> {
  id?: number;
}
export const CandidateAvatar = ({
  id,
  src,
  style,
  dnc = false,
  ...props
}: CandidateAvatarPropsT): React.ReactElement => {
  if (dnc)
    return (
      <Avatar
        background="bg-error"
        color="white"
        icon={NotInterestedIcon}
        size={props.size || 40}
      />
    );
  return (
    <GoldenAvatar
      icon={PersonIcon}
      background={id ? undefined : "bg-blue-600"}
      src={src}
      style={src || !id ? style : { backgroundColor: generateUserColor(id), ...style }}
      {...props}
    />
  );
};

export const ApplicantAvatar = (props: AvatarWithIconT): React.ReactElement => (
  <Avatar background="bg-blue-600" icon={AssignmentIndIcon} {...props} />
);

export const ContactAvatar = (props: AvatarWithIconT): React.ReactElement => (
  <Avatar background="bg-green-400" icon={PermContactCalendarIcon} {...props} />
);

export interface UserAvatarPropsT<U extends ProfilePictureUserT> extends AvatarPropsT {
  user: U;
  dnc?: boolean;
}
export function UserAvatar<U extends ProfilePictureUserT>({
  user,
  style,
  ...props
}: UserAvatarPropsT<U>): React.ReactElement {
  const title = displayFullName(user, "");
  const dnc =
    // either this is a contact/recruiter that was marked no_longer_employed
    user.no_longer_employed ||
    // or this is a candidate perhaps marked as do_not_contact
    (typeof user.candidate !== "number" && user.candidate?.status?.id === 10);

  if (dnc)
    return (
      <Avatar
        background="bg-error"
        color="white"
        icon={NotInterestedIcon}
        size={props.size || 40}
      />
    );
  return (
    <Avatar
      icon={title ? null : PersonIcon}
      src={user.profile_picture_medium || ""}
      style={
        user.profile_picture_medium
          ? style
          : { backgroundColor: generateUserColor(user.id), ...style }
      }
      title={title}
      {...props}
    />
  );
}

export const TeamAvatar = (props: AvatarWithIconT): React.ReactElement => (
  <Avatar background="bg-green-400" icon={GroupIcon} {...props} />
);

export const JobAvatar = (props: GoldenAvatarPropsT): React.ReactElement => (
  <GoldenAvatar background="bg-pink-600" icon={CardTravelIcon} {...props} />
);

export const JobLogoAvatar = (props: GoldenAvatarPropsT): React.ReactElement => (
  <GoldenAvatar
    classes={{ root: "rounded" }}
    background="bg-pink-600"
    icon={CardTravelIcon}
    {...props}
  />
);

export const PerryAvatar = ({ golden, ...props }: GoldenAvatarPropsT): React.ReactElement => (
  <Avatar title="Talentpair Perry" background="bg-green-500" {...props} src={perryIcon} />
);

export const GreenhouseAvatar = (props: AvatarPropsT): React.ReactElement => (
  <Avatar {...props} src={greenhouseImg} />
);

export const GreenIconAvatar = (
  props: Omit<AvatarPropsT, "icon"> & { icon: React.ComponentType<MuiIconProps> },
): React.ReactElement => <Avatar className="bg-grey-200 green-500" {...props} />;

export const _test = { initials };
