import * as React from "react";
import { env, londonLink } from "@talentpair/env";
import { CandidatePlatformStatusT } from "@talentpair/types/entities/candidate";
import { Badge, BadgePropsT } from "./Badge";

const userBadgeColor = {
  INVITED: "bg-blue-400",
  "INVITE EXPIRED": "bg-black",
  PENDING: "bg-amber-500",
  ACTIVE: "bg-green-500",
  DEACTIVATED: "bg-amber-500",
  DELETED: "bg-red-500",
};
type UserBadgeKey = keyof typeof userBadgeColor;

function preparePlatformStatus(status: CandidatePlatformStatusT | null | undefined): UserBadgeKey {
  return typeof status === "string"
    ? (status.toUpperCase().replaceAll("_", " ") as UserBadgeKey)
    : "PENDING";
}

export interface CandidateTpBadgePropsT extends Partial<BadgePropsT> {
  candidate: {
    id: number;
    platform_status?: CandidatePlatformStatusT | null;
  };
}
export function CandidateTpBadge({
  candidate,
  href,
  ...props
}: CandidateTpBadgePropsT): React.ReactElement {
  const text = preparePlatformStatus(candidate.platform_status);
  const link =
    href != null
      ? href
      : candidate.platform_status !== "deleted"
      ? env.londonUrl(londonLink.capPairList(candidate.id))
      : undefined;
  return (
    <Badge
      text={text}
      bgColor={userBadgeColor[text]}
      href={link}
      data-testid="platform-badge"
      {...props}
    />
  );
}
