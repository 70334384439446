import * as React from "react";
import clsx from "clsx";
import ReorderIcon from "@mui/icons-material/Reorder";
import { AvatarProps, Grid, IconButton, IconButtonProps } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import PauseIcon from "@mui/icons-material/Pause";
import RemoveIcon from "@mui/icons-material/Remove";
import WarningIcon from "@mui/icons-material/Warning";
import { Section, SectionPropsT, useMediaContext } from "@talentpair/quantic";
import { Avatar, AvatarWithIconT } from "../../ui/Avatars";
import { Row, SectionContainer, SectionTitleNoNumber, RowPropsT } from "../components";

interface PropsT {
  children: React.ReactNode;
  className?: string | null;
  dataSection?: string;
  fullWidth?: boolean;
}

export const ColumnTitle = ({ children, className }: PropsT): React.ReactElement => (
  <h3 className={clsx("my0 regular", className)}>{children}</h3>
);

export const SectionButton = (
  props: IconButtonProps & { component?: string },
): React.ReactElement => <IconButton color="primary" className="p1" {...props} />;

export const SectionRow = (props: RowPropsT): React.ReactElement => (
  <Row {...props} classes={{ container: "border-none" }} />
);

interface WarningAvatarPropsT extends AvatarProps {
  small?: boolean;
}
export const WarningAvatar = ({
  small = false,
  ...props
}: WarningAvatarPropsT): React.ReactElement => (
  <Avatar
    classes={{ root: "align-middle inline-flex", colorDefault: "white bg-amber-800" }}
    // @ts-expect-error - component is totally fine
    component="span"
    icon={WarningIcon}
    size={small ? 20 : 40}
    {...props}
  />
);

export const FieldWarningLabel = ({
  children,
  showWarning,
}: {
  children: React.ReactNode;
  showWarning: boolean;
}): React.ReactElement =>
  showWarning ? (
    <div className="flex items-center">
      <div>{children}</div>
      <WarningAvatar className="ml2" small />
    </div>
  ) : (
    <div>{children}</div>
  );

interface WarnableSectionRowPropsT extends RowPropsT {
  showWarning: boolean;
}
export const WarnableSectionRow = ({
  title,
  showWarning = false,
  ...props
}: WarnableSectionRowPropsT): React.ReactElement => (
  <SectionRow
    title={showWarning ? <FieldWarningLabel showWarning>{title}</FieldWarningLabel> : title}
    {...props}
  />
);

interface SectionRowContainerPropsT {
  className?: string | null;
  children: React.ReactNode;
  fullWidth?: boolean;
}
export const SectionRowContainer = ({
  className = null,
  ...props
}: SectionRowContainerPropsT): React.ReactElement => (
  <div className={clsx("border-bottom border-grey-300", className)} {...props} />
);

export const SectionButtons = ({ children, className, ...props }: PropsT): React.ReactElement => (
  <div className={clsx("flex", className)} {...props}>
    {children}
  </div>
);

interface SectionWrapperPropsT extends PropsT {
  title: React.ReactNode;
  buttons?: React.ReactNode;
  showWarning?: boolean;
  isLast?: boolean;
  "data-testid"?: string;
  dataSection?: string;
  withDescription?: boolean;
}
export const SectionWrapper = ({
  title,
  dataSection,
  buttons = null,
  className = null,
  children = null,
  showWarning = false,
  isLast = false,
  fullWidth = false,
  withDescription = false,
  ...props
}: SectionWrapperPropsT): React.ReactElement => (
  <SectionContainer
    // Possible runtime issue here with React.ReactNode being turned into a string
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    data-testid={props["data-testid"] || `Section: ${title}`}
    data-section={dataSection}
    className={clsx({ px3: !fullWidth }, className)}
    isLast={isLast}
  >
    <Grid
      classes={{ container: withDescription ? "mb1" : "mb3" }}
      container
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid item xs zeroMinWidth>
        <SectionTitleNoNumber paragraph={false}>
          <span>
            {title}
            {showWarning && <WarningAvatar className="ml2" small />}
          </span>
        </SectionTitleNoNumber>
      </Grid>
      {buttons && (
        <Grid item>
          <SectionButtons>{buttons}</SectionButtons>
        </Grid>
      )}
    </Grid>
    {children}
  </SectionContainer>
);

interface RowSectionWrapperPropsT extends PropsT {
  buttons: React.ReactNode;
  title: string;
  isEditing: boolean;
  dataSection?: string;
}
export const RowSectionWrapper = ({
  isEditing,
  title,
  dataSection,
  buttons = null,
  className = null,
  fullWidth = false,
  children = null,
}: RowSectionWrapperPropsT): React.ReactElement =>
  isEditing ? (
    <SectionWrapper
      data-section={dataSection}
      data-testid={`Section: ${title}`}
      className={className}
      title={<span className="grey-500 h6">{title}</span>}
      fullWidth={fullWidth}
    >
      {children}
    </SectionWrapper>
  ) : (
    <SectionRowContainer
      data-section={dataSection}
      data-testid={`Section: ${title}`}
      className={clsx({ px3: !fullWidth }, className)}
      fullWidth={fullWidth}
    >
      <Grid alignItems="center" container justifyContent="space-between" wrap="nowrap">
        <Grid item xs zeroMinWidth>
          {children}
        </Grid>
        <Grid item>
          <SectionButtons>{buttons}</SectionButtons>
        </Grid>
      </Grid>
    </SectionRowContainer>
  );

export const SectionWrapperNoTitle = ({
  isLast = false,
  buttons = null,
  children = null,
  className = null,
  fullWidth = false,
  dataSection,
}: PropsT & {
  buttons: React.ReactNode;
  isLast?: boolean;
}): React.ReactElement => (
  <SectionContainer
    data-section={dataSection}
    className={clsx({ px3: !fullWidth }, className)}
    isLast={isLast}
  >
    <Grid container justifyContent="space-between" wrap="nowrap">
      <Grid item xs zeroMinWidth>
        {children}
      </Grid>
      {buttons && (
        <Grid item>
          <SectionButtons>{buttons}</SectionButtons>
        </Grid>
      )}
    </Grid>
  </SectionContainer>
);

export const LeftColumn = (props: Record<string, unknown>): React.ReactElement => {
  const { desktop } = useMediaContext();
  return (
    <div
      className={`lg-border-left lg-border-right border-grey-300 ${
        desktop ? "col-8 p2" : "col-12"
      }`}
      {...props}
    />
  );
};

export const RightColumn = (props: Record<string, unknown>): React.ReactElement => {
  const { desktop } = useMediaContext();
  return <div className={desktop ? "col-4 p2" : "col-12"} {...props} />;
};

export const CompleteAvatar = (props: AvatarWithIconT): React.ReactElement => (
  <Avatar
    {...props}
    classes={{ colorDefault: "white" }}
    background="bg-green-500"
    icon={CheckIcon}
  />
);

export const PauseAvatar = (props: AvatarWithIconT): React.ReactElement => (
  <Avatar
    classes={{ colorDefault: "white" }}
    background="bg-amber-700"
    {...props}
    icon={PauseIcon}
  />
);

export const ClosedAvatar = (props: AvatarWithIconT): React.ReactElement => (
  <Avatar
    classes={{ colorDefault: "white" }}
    background="bg-red-700"
    {...props}
    icon={RemoveIcon}
  />
);

export const ProfileWrapper = (props: SectionPropsT): React.ReactElement => (
  <Section className="flex flex-wrap h5 max-width-4 mb3" fullHeight fullWidth {...props} />
);

export const TapProfileWrapper = (props: SectionPropsT): React.ReactElement => (
  <Section
    className="flex flex-column items-center h5 max-width-4 mb3 full-height"
    fullHeight
    fullWidth
    {...props}
  />
);

export const DraggableListItem = ({
  className = null,
  children,
}: {
  className?: string | null;
  children: React.ReactNode;
}): React.ReactElement => (
  <div className={clsx("flex", className)}>
    <div className="flex-auto">{children}</div>
    <ReorderIcon classes={{ root: "grey-300 my-auto mx2" }} />
  </div>
);
