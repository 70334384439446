import { withStyles, StyleRules } from "@mui/styles";
import { Backdrop, Grow, Popper } from "@mui/material";
import { Media } from "@talentpair/quantic";
import clsx from "clsx";
import { computeStyles, Placement, ModifierArguments, Options } from "@popperjs/core";
import * as React from "react";
import { defaultMemoize } from "@talentpair/redux";
import type { PortalPropsT } from "@talentpair/portal";
import Arrow from "./Arrow";
import Card, { CardConfigPropsT } from "./Card";

const ARROW_WIDTH = 47;
const ARROW_MID_X = ARROW_WIDTH / 2;
const ARROW_HEIGHT = 29;
const ARROW_MARGIN = ARROW_HEIGHT * 0.25;

const styles: StyleRules = {
  root: {
    display: "inline-block",
    flexDirection: "inherit", // Makes the wrapper more transparent.
  },
  backdrop: { zIndex: 1299 },
  button: {
    backgroundColor: "white",
    height: 30,
    minHeight: 30,
    position: "absolute",
    right: 0,
    top: 0,
    transform: "translate(50%, -50%)",
    width: 30,
  },
  arrow: {
    position: "absolute",
    '[data-popper-placement*="bottom"] &': {
      bottom: "calc(100% - 1px) !important",
      "& div": {
        transform: "rotate(180deg)",
      },
    },
    '[data-popper-placement*="left"] &': {
      left: `calc(100% - ${ARROW_MARGIN}px) !important`,
      "& div": {
        transform: "rotate(270deg)",
      },
    },
    '[data-popper-placement*="right"] &': {
      right: `calc(100% - ${ARROW_MARGIN}px) !important`,
      "& div": {
        transform: "rotate(90deg)",
      },
    },
    '[data-popper-placement*="top"] &': {
      top: "calc(100% - 1px) !important",
      "& div": {
        transform: "rotate(0deg)",
      },
    },
    transition: "all 240ms cubic-bezier(.38,.72,.43,.97)",
  },
  popper: {
    width: 312,
    zIndex: 1300,
    '&[data-popper-placement*="bottom"]': {
      marginTop: `${ARROW_MARGIN}px !important`,
    },
    '&[data-popper-placement*="left"]': {
      marginRight: `${ARROW_MARGIN}px !important`,
    },
    '&[data-popper-placement*="right"]': {
      marginLeft: `${ARROW_MARGIN}px !important`,
    },
    '&[data-popper-placement*="top"]': {
      marginBottom: `${ARROW_MARGIN}px !important`,
    },
    transition: "transform 240ms cubic-bezier(.38,.72,.43,.97)",
  },
  popperClose: { pointerEvents: "none" },
};

// Makes the grow animation start from whichever side the arrow is pointing to
function growOrigin(placement: Placement): string {
  switch (placement) {
    case "bottom-end":
      return "right top";
    case "bottom-start":
      return "left top";
    case "bottom":
      return "center top";
    case "left-end":
      return "right bottom";
    case "left-start":
      return "right top";
    case "left":
      return "right center";
    case "right-end":
      return "left bottom";
    case "right-start":
      return "left bottom";
    case "right":
      return "left center";
    case "top-end":
      return "right bottom";
    case "top-start":
      return "left bottom";
    case "top":
      return "center bottom";
    default:
      return "center center";
  }
}

interface TooltipProps extends PortalPropsT, CardConfigPropsT {
  className?: string;
  classes: Record<string, string>;
  placement: {
    mobile: Placement;
    desktop: Placement;
  };
  fixed?: boolean;
  target: undefined | null | HTMLElement;
}

interface TooltipState {
  arrowRef: null | HTMLElement;
  arrowOffset: number;
  background: string;
}

// This is a wrapper around the Material UI wrapper around Popper.js
// Material UI did most of the hard work for us, but this has some customization around some of the
// Popper.js config. You can see all available settings here: https://popper.js.org/
class Tooltip extends React.Component<TooltipProps, TooltipState> {
  state: TooltipState = {
    arrowRef: null,
    arrowOffset: 0,
    background: this.props.slides[0].background,
  };

  setArrowOffset = defaultMemoize((arrowOffset: number) => this.setState({ arrowOffset }));

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  computeStyle = (options: ModifierArguments<Options>) => {
    // Hook in here to snag the final arrow position computed by Popper.js
    this.setArrowOffset(options.state.modifiersData.arrow?.y || 0);
    // @ts-expect-error - it's okay
    return computeStyles.fn?.(options);
  };

  // Putting the ref in state based on the demo here: https://material-ui.com/demos/tooltips/
  // My hunch is that it is in state because it has to be passed into Popper and also gets styles from
  // Popper, so whenever it changes we need to make sure to rerender.
  handleArrowRef = (arrowRef: HTMLElement | null): void => {
    this.setState({ arrowRef });
  };

  // Sync background (and arrow color) with child card
  handleBackgroundChange = (background: string): void => this.setState({ background });

  render(): React.ReactNode {
    const {
      classes,
      className,
      onClose,
      onExited,
      open,
      placement,
      fixed = false,
      target,
      ...props
    } = this.props;
    const { arrowOffset, arrowRef, background } = this.state;

    if (!target) return null;
    return (
      <Media>
        {(desktop) => (
          <div className={classes.root}>
            {/* We've decided to make it so a user can only interact with our card. Blocks other interation. */}
            <Backdrop className={classes.backdrop} open={open} />
            <Popper
              anchorEl={target}
              popperOptions={{
                strategy: fixed ? "fixed" : "absolute",
                // Custom Popper.js config. Also to allow us to get the arrow position.
                modifiers: [
                  { name: "arrow", enabled: true, options: { element: arrowRef } },
                  {
                    name: "computeStyle",
                    enabled: true,
                    fn: this.computeStyle,
                    phase: "main",
                    options: {
                      adaptive: false,
                    },
                  },
                  { name: "hide", enabled: false },
                  {
                    name: "preventOverflow",
                    enabled: true,
                    options: {
                      boundariesElement: "viewport",
                    },
                  },
                ],
                // Allows us to give the card a different position on desktop vs mobile
                placement: placement[desktop ? "desktop" : "mobile"],
              }}
              className={clsx(classes.popper, { [classes.popperClose]: !open }, className)}
              open={open}
              transition
            >
              {(popperProps) => (
                <Grow
                  {...popperProps.TransitionProps}
                  onExited={() => {
                    onExited();
                    popperProps.TransitionProps?.onExited();
                  }}
                  style={{ transformOrigin: growOrigin(popperProps.placement) }}
                >
                  <div className="relative">
                    <Card
                      onBackgroundChange={this.handleBackgroundChange}
                      onClose={onClose}
                      {...props}
                    />
                    <span className={classes.arrow} ref={this.handleArrowRef}>
                      <div>
                        <Arrow
                          color={
                            // If arrow is on the upper half of the card, give it the card bg color
                            !popperProps.placement.startsWith("top") &&
                            (popperProps.placement.startsWith("bottom") ||
                              arrowOffset < 242 - ARROW_MID_X)
                              ? background
                              : "white"
                          }
                        />
                      </div>
                    </span>
                  </div>
                </Grow>
              )}
            </Popper>
          </div>
        )}
      </Media>
    );
  }
}

export default withStyles(styles, { name: "OnboardingTooltip" })(Tooltip);
