import { displayFullName, FirstLastNameT } from "@talentpair/entities/user";
import lodash from "lodash";

type ObjT = {
  [key: string]: unknown;
};

export function splice<V>(arr: V[], index: number, ...newItems: V[]): V[] {
  return index < 0
    ? [...arr, ...newItems]
    : [...arr.slice(0, index), ...newItems, ...arr.slice(index + 1)];
}

export const maybeReplaceArrayItem = <V extends ObjT>(
  arr: V[],
  newObj: V,
  merge?: boolean,
  key = "id",
): V[] => {
  const index = arr.findIndex((obj) => obj[key] === newObj[key]);
  return index > -1 ? splice(arr, index, merge ? { ...arr[index], ...newObj } : newObj) : arr;
};

export const replaceOrAddArrayItem = <V extends ObjT>(
  arr: V[],
  newObj: V,
  merge?: boolean,
  key = "id",
): V[] => {
  const newArr = maybeReplaceArrayItem(arr, newObj, merge, key);
  return newArr !== arr ? newArr : [...arr, newObj];
};

export const arrayDifference = <V extends ObjT>(
  newArray: V[],
  oldArray: V[],
  property?: string | null,
): {
  add: V[];
  remove: V[];
} =>
  property != null && property.length
    ? {
        add: lodash.differenceBy(newArray, oldArray, property),
        remove: lodash.differenceBy(oldArray, newArray, property),
      }
    : {
        add: lodash.difference(newArray, oldArray),
        remove: lodash.difference(oldArray, newArray),
      };

export function uniq<V>(arr: V[]): V[] {
  return [...new Set(arr)];
}

// alpha sort
export function sortByUserName<U extends FirstLastNameT>(a: U, b: U): number {
  const aName = displayFullName(a);
  const bName = displayFullName(b);
  return aName < bName ? -1 : aName > bName ? 1 : 0;
}

export function sortByName(a: { name: string }, b: { name: string }): number {
  return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
}

export function separateElementsWith(separator: unknown, arr: unknown[]): unknown[] {
  if (arr.length <= 1) return arr;
  return arr.map((e, i) => (i > 0 ? [separator, e] : [e])).flat();
}

// NaN is also falsy but it cannot be typed properly here
// Use like: `arrayOfThings.filter(toTruthy)`
type Falsy = "" | false | 0 | null | undefined;
export const toTruthy = <T>(x: T | Falsy): x is T => !!x;

export const choicesToNumbers = <Id>(choices: Array<{ id: Id }>): number[] =>
  choices.map(({ id }) => (id ? +id : null)).filter(toTruthy);
