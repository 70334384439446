import * as React from "react";
import { Pane, Item, Title } from "@talentpair/quantic";
import ResumeProgressSvg from "kyoto/components/ResumeProgressSvg";

export default function UploadSuccessPane({
  didParse,
  children,
}: React.PropsWithChildren<{ didParse: boolean }>): React.ReactElement {
  return (
    <Pane>
      <Item>
        <ResumeProgressSvg state="success" />
      </Item>
      <Item>
        <Title paragraph className="mb5">
          {didParse
            ? "Your resume was successfully processed."
            : "Your resume on file has been updated."}
        </Title>
      </Item>
      {children}
    </Pane>
  );
}
