// @flow
import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { UserActivityMeta } from "./containers/UserActivityHeader";
import { SideNavigation } from "../components/Navigation/SideNavigation";

// $FlowFixMe
export const TapUserActivity = React.lazy(() => import("./containers/UserActivityPage"));

export const TapUserActivityPage = page({
  desktop: TapUserActivity,
  mobile: TapUserActivity,
  desktopSidenav: SideNavigation,
  header: TapHeader,
  meta: UserActivityMeta,
});
