// @flow
import { londonLink } from "@talentpair/env";
import { getRole } from "@talentpair/entities/job";
import type { ContextRouter } from "@talentpair/routing";
import type { StateT } from "../../store";
import { getPair } from "../store/pairSelectors";

export const titleSelector = {
  pair: (state: StateT, { match: { params } }: ContextRouter) => {
    const pair = getPair(state, +params.pair);
    return { title: pair ? getRole(pair.job) : "" };
  },
};

export const paramLink = {
  capPairList: ({ match }: ContextRouter) => londonLink.capPairList(+match.params.candidate),
};
