export type PairFilterT = "ACTIVE" | "INACTIVE" | "REVIEW";
export const PAIR_FILTER = "CAP_PAIR_FILTER";
export type PairFilterActionT = {
  type: "CAP_PAIR_FILTER";
  filter: PairFilterT;
};

function filter(filterType: PairFilterT): PairFilterActionT {
  return {
    type: PAIR_FILTER,
    filter: filterType,
  };
}

export default {
  filter,
};
