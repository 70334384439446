import * as React from "react";
import { env, londonLink } from "@talentpair/env";
import { Badge, BadgePropsT } from "./Badge";

export interface BadgedContactT {
  id: number;
  organization?: { id: number } | null;
  login_blocked: boolean;
  last_active: string | null;
}

export interface ContactTpBadgePropsT extends Partial<BadgePropsT> {
  contact: BadgedContactT;
}
export const ContactTpBadge = ({
  contact,
  href,
  ...props
}: ContactTpBadgePropsT): React.ReactElement => {
  const link =
    href != null
      ? href
      : contact.organization
      ? env.londonUrl(londonLink.tapOrgUserProfile(contact.organization.id, contact.id))
      : undefined;
  return contact.last_active ? (
    <Badge
      text="ACTIVE"
      bgColor="bg-green-500"
      href={link}
      data-testid="platform-badge"
      {...props}
    />
  ) : (
    <Badge
      text="INVITED"
      bgColor="bg-blue-400"
      href={link}
      data-testid="platform-badge"
      {...props}
    />
  );
};
