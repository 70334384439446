import { objToQueryStr } from "@talentpair/utils";
import { apiUrl } from "./api";
import { env } from "./env";

type ParamT = number | string;

export const greenhouseJob = (id: ParamT): string => {
  switch (process.env.HOST) {
    case "local":
    case "gojira":
    case "gojira2":
    case "staging":
    case "production":
      return `https://app2.greenhouse.io/sdash/${id}`;

    default:
      return `https://app.greenhouse.io/`;
  }
};

export const getLinkedInUrl = (login?: boolean, extraData?: Record<string, unknown>): string =>
  `https://www.linkedin.com/oauth/v2/authorization${objToQueryStr({
    client_id: env.isProduction() ? "75wl7vvwfyyruk" : "861u9n6u729ci9",
    response_type: "code",
    redirect_uri: apiUrl(`oauth/linkedin${login ? "-login" : ""}/`),
    scope: "r_liteprofile r_emailaddress",
    ...extraData,
  })}`;

export const getGoogleLoginUrl = (extraData?: Record<string, unknown>): string =>
  `https://accounts.google.com/o/oauth2/auth${objToQueryStr({
    client_id: env.isProduction()
      ? "1071410398709-q1ld15c31h252709k9ardbk7q3qenmnr.apps.googleusercontent.com"
      : "1087069802095-u4ebdmuole09pl6togv4cabi3sfgfkau.apps.googleusercontent.com",
    response_type: "code",
    redirect_uri: apiUrl(`oauth/google/login/`),
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    ...extraData,
  })}`;

export const getTalentSourcingExtensionUrl = (): string => {
  switch (process.env.HOST) {
    case "local":
    case "gojira":
    case "gojira2":
      return "https://chrome.google.com/webstore/detail/talent-sourcing-extension/gfakjngipgehkhkebkkbcfhpcbjpagbe";
    case "staging":
      return "https://chrome.google.com/webstore/detail/talent-sourcing-extension/epfhlaompmeppkikbdgmbenbfhaobdna";
    case "production":
      return "https://chrome.google.com/webstore/detail/talent-sourcing-extension/ggmcoagjdnbalnocafkppachlkigfinn";
    default:
      return "";
  }
};
