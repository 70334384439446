import { queryStrToObj } from "@talentpair/utils";
import * as React from "react";
import { connect, DispatchT } from "@talentpair/redux";
import { withRouter, RouteComponentProps } from "@talentpair/routing";
import notifications from "kyoto/services/notifications";
import { NotificationCountWatcherNoDispatch } from "../containers/NotificationCountWatcher";
import NotificationAction from "../store/notificationActions";

type DispatchToPropsT = { markRead: (id: number) => Promise<unknown> };
type PropsT = RouteComponentProps & DispatchToPropsT;

class MarkAsRead extends React.Component<PropsT> {
  componentDidMount(): void {
    this.checkQueryParams();
  }

  componentDidUpdate(nextProps: PropsT): void {
    if (nextProps.location.search !== this.props.location.search) {
      this.checkQueryParams();
    }
  }

  checkQueryParams(): void {
    const { location, markRead } = this.props;
    const params = queryStrToObj(location.search);
    if (params.notification) markRead(+params.notification);
  }

  render(): React.ReactNode {
    return null;
  }
}

const mapDispatchToProps = (dispatch: DispatchT): DispatchToPropsT => ({
  markRead: (id: number) => dispatch(NotificationAction.markRead(id, true)),
});

const MarkNotificationAsRead = withRouter(
  connect<void, DispatchToPropsT, RouteComponentProps, unknown>(
    null,
    mapDispatchToProps,
  )(MarkAsRead),
);

export default (): React.ReactElement => (
  <>
    <NotificationCountWatcherNoDispatch fetchAction={notifications.getDesktopNotifications} />
    <MarkNotificationAsRead />
  </>
);
