import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { MyCandidatesMeta } from "./containers/MyCandidatesHeader";
import { SideNavigation } from "../components/Navigation/SideNavigation";

export const MyCandidates = React.lazy(() => import("./containers/MyCandidatesPage"));

export const MyCandidatesPage = page({
  // @ts-expect-error - it's okay
  desktop: MyCandidates,
  // @ts-expect-error - it's okay
  mobile: MyCandidates,
  // @ts-expect-error - it's okay
  desktopSidenav: SideNavigation,
  header: TapHeader,
  meta: MyCandidatesMeta,
});
