import lodash from "lodash";
import { userService, featureFlags, FeatureFlags } from "@talentpair/api";
import { env } from "@talentpair/env";
import { getFlyoutReferer, queryStrToObj } from "@talentpair/utils";
import { timeDeltaInSeconds } from "@talentpair/datetime";
import { UtmParamsT } from "./utm";

// Legacy configs and helper functions to track api requests. Check the apiWhitelist to see which endpoints we still support.
type ApiRequestT = {
  "Requested Asset": string | null;
  "API App": string | null;
  "API Resource": string | null;
  Method: string;
};
type ConfigT = {
  url: string;
  method: string;
};

function checkType(arr: string[]): string {
  const defaultResource = arr[arr.length - 2];

  return defaultResource && Number.isNaN(Number(defaultResource))
    ? defaultResource
    : arr[arr.length - 3];
}

function parseURL(type: "requestedAsset" | "apiApp" | "apiResource", url: string): string | null {
  if (!url) return null;

  const tempArr = url.split("/");

  switch (type) {
    case "requestedAsset":
      return `/${tempArr.slice(3, tempArr.length).join("/")}`;
    case "apiApp":
      return tempArr[3];
    case "apiResource":
      return checkType(tempArr);
    default:
      return null;
  }
}

// Converts all query params to be `q:<querykey>: value` so they are namespaced and don't overwrite other properties.
const trackQueryParams = (url: string): lodash.Dictionary<string | undefined> =>
  lodash.mapKeys(queryStrToObj(url), (val: string | undefined, key: string) => `q:${key}`);

type ApiDataT = {
  apiApp: string | null;
  apiResource: string | null;
  requestedAsset: string | null;
};
const parseApi = (url: string): ApiDataT => ({
  apiApp: parseURL("apiApp", url),
  apiResource: parseURL("apiResource", url),
  requestedAsset: parseURL("requestedAsset", url),
});

type ApiWhitelistT = {
  apiApp?: string;
  apiResource?: string;
};
const apiWhitelist: ApiWhitelistT[] = [
  { apiApp: "candidates", apiResource: "tags" },
  { apiApp: "search" },
  { apiApp: "tags" },
  { apiApp: "tools" },
  { apiApp: "users", apiResource: "searches" },
  { apiResource: "remarks" },
  { apiResource: "receive-jobs" },
  { apiResource: "send-to-candidates" },
  { apiResource: "reminders" },
];

const whitelistKeys: ("apiApp" | "apiResource")[] = ["apiApp", "apiResource"];

const validUrl = (apiData: ApiDataT): boolean =>
  apiWhitelist.some((whitelist) =>
    whitelistKeys.every((k) => !(k in whitelist) || whitelist[k] === apiData[k]),
  );

function getApiRequestTrackData({ url, method }: ConfigT): ApiRequestT | null {
  const isPing = /notifications/.exec(url);
  const isAsset = /(cache-bust|html|png|svg)/.exec(url);
  const queryParams = trackQueryParams(url);
  const parsed = parseApi(url);

  // If the event is a ping or a template request do not return any track data, signaling that
  // event shouldn't be recorded
  return isPing || isAsset || !validUrl(parsed)
    ? null
    : {
        "Requested Asset": parsed.requestedAsset,
        "API App": parsed.apiApp,
        "API Resource": parsed.apiResource,
        Method: method,
        ...queryParams,
      };
}

// Based off https://help.mixpanel.com/hc/en-us/articles/360001337103
function registerUtmParams(params: Partial<UtmParamsT>): void {
  if (window.mixPanelId) {
    // map over params and rename keys
    const campaignParams = Object.entries(params).reduce<Record<string, string>>(
      (acc, [key, value]) => {
        if (value) acc[`${key} [last touch]`] = value;
        return acc;
      },
      {},
    );
    // set the last touched values
    window.mixpanel.register(campaignParams);
  }
}

let flyoutReferer = "";

// value should be initialized by call to checkIfShouldUseMixpanel() within the register function
let _shouldUseMixpanel = env.isProduction();
let _trackAnonUsers = false;

async function checkIfShouldUseMixpanel(): Promise<boolean> {
  _shouldUseMixpanel = await featureFlags.hasFeatureFlag(FeatureFlags.USE_MIXPANEL);
  _trackAnonUsers = await featureFlags.hasFeatureFlag(FeatureFlags.ANON_MIXPANEL);
  return _shouldUseMixpanel;
}

function shouldUseMixpanel(): boolean {
  // if we're not connected to mixpanel then obviously don't track anything
  if (!window.mixPanelId || !window.mixpanel) return false;
  // depending on feature flag we may or may not track anonymous users
  if (!userService.data && !_trackAnonUsers) return false;
  // don't track QA users on prod
  if (env.isProduction() && userService.isQaUser()) return false;
  // otherwise return the result of the mixpanel feature flag
  // defaults to true in production
  return _shouldUseMixpanel;
}

async function register(extraData?: Record<string, unknown>): Promise<void> {
  await checkIfShouldUseMixpanel();
  if (!shouldUseMixpanel()) return;
  const { email, ...userData } = userService.getAnalyticsData();
  // Register Mixpanel: https://mixpanel.com/help/reference/javascript-full-api-reference#mixpanel.register
  window.mixpanel.register({
    ...userData,
    "JS App Version": process.env.TAG,
    ...extraData,
  });
  // Make sure user is associated with correct user in people database:
  // https://mixpanel.com/help/reference/javascript-full-api-reference#mixpanel.identify
  // if no email then user may not be authenticated yet or does not have an account
  if (email) window.mixpanel.identify(email);
  flyoutReferer = getFlyoutReferer();
}

// eslint-disable-next-line flowtype/no-weak-types
function track(
  eventType: string,
  extraData?: {
    [key: string]: unknown;
  },
): void {
  if (!shouldUseMixpanel()) return;

  if (extraData && lodash.isObject(extraData.data)) {
    const { data, ...remainingData } = extraData;
    extraData = {
      ...remainingData,
      ...lodash.mapKeys(data, (v, k) => `data:${k}`),
    };
  }

  // https://mixpanel.com/help/reference/javascript-full-api-reference#mixpanel.track
  window.mixpanel.track(eventType, {
    Page: location.pathname,
    Iframe: window.self !== window.top,
    ...(flyoutReferer ? { "Flyout-Referer": flyoutReferer } : null),
    ...extraData,
  });
}

let NAV_TIME_CACHE = Date.now();
let INACTIVE_TIME_CACHE = 0;
let BLUR_TIME_CACHE = 0;

function resetNavCachedTime(): void {
  NAV_TIME_CACHE = Date.now();
  INACTIVE_TIME_CACHE = 0;
  BLUR_TIME_CACHE = 0;
}

function onPageBlur(): void {
  BLUR_TIME_CACHE = Date.now();
}

function onPageFocus(): void {
  if (BLUR_TIME_CACHE > 0) INACTIVE_TIME_CACHE += timeDeltaInSeconds(BLUR_TIME_CACHE);
}

window.document.addEventListener("visibilitychange", () =>
  window.document.visibilityState === "visible" ? onPageFocus() : onPageBlur(),
);

// Called upon page EXIT to record time spent viewing the page
function trackPageView(data: Record<string, string | number>): void {
  // page_time is time spent on the page, but doesn't mean the page was active the whole time (ex. the user could navigate to a different tab and come back later)
  const page_time = timeDeltaInSeconds(NAV_TIME_CACHE);
  // active_time is the time spent looking at the page. But the user could still have walked away from their desk, so it's not bulletproof.
  const active_time = Math.max(page_time - INACTIVE_TIME_CACHE, 1); // use Math.max to clamp value to be >= 1
  resetNavCachedTime();
  track("Page View", { ...data, page_time, active_time });
}

// Called Page Load, but it's really tracking every api request...
function trackPageLoad(config: ConfigT): void {
  const apiTrackData = getApiRequestTrackData(config);

  // only track the event if we were able to get config data
  if (apiTrackData) {
    track("Page Load", apiTrackData);
  }
}

export const mixpanelHelper = {
  register,
  track,
  trackPageView,
  trackPageLoad,
  resetNavCachedTime,
  trackQueryParams,
  registerUtmParams,
};
export const _test = {
  checkType,
  checkIfShouldUseMixpanel,
  getApiRequestTrackData,
  parseApi,
  parseURL,
  validUrl,
  onPageFocus,
  onPageBlur,
};
