import * as React from "react";
import { SvgIcon, ButtonProps as MuiButtonProps, ButtonBase } from "@mui/material";
import { styled, darken, alpha } from "@mui/material/styles";
import { colorProp, ColorPropT } from "../theme/colorProp";
import { dynamicDarken } from "../theme/dynamicDarken";

export type ButtonProps = Omit<
  MuiButtonProps,
  "variant" | "startIcon" | "endIcon" | "size" | "component" | "color"
> & {
  component?: React.ComponentType | keyof React.ReactHTML;
  variant?: MuiButtonProps["variant"] | "icon";
  icon?: typeof SvgIcon | React.ReactElement;
  color?: ColorPropT;
  background?: `#${string}`;
  iconRight?: boolean;
  fullWidth?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
  "data-testid"?: string;
  // props for link buttons
  href?: string;
  to?: string;
  download?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
  responsiveColor?: boolean;
};

const UnstyledButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { variant = "text", color, icon: Icon, size, iconRight, children, ...props },
    ref,
  ): React.ReactElement => {
    const renderedIcon = React.isValidElement(Icon) || !Icon ? Icon : <Icon fontSize="inherit" />;
    return (
      <ButtonBase component="button" {...props} ref={ref}>
        {variant === "icon" ? (
          renderedIcon
        ) : (
          <>
            {!iconRight && renderedIcon ? renderedIcon : undefined}
            {children ? <span>{children}</span> : undefined}
            {iconRight && renderedIcon ? renderedIcon : undefined}
          </>
        )}
      </ButtonBase>
    );
  },
);

export const Button = styled(UnstyledButton, { skipSx: true })(
  ({
    variant = "text",
    size = "md",
    color: _color = "inherit",
    background,
    fullWidth,
    responsiveColor = true,
    theme,
  }) => {
    const color = colorProp(_color, theme);
    const colorProps =
      variant === "contained"
        ? {
            color: color === "inherit" ? "inherit" : theme.palette.getContrastText(color),
            background: color,
            boxShadow:
              "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px",
            transition:
              "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            "&:hover": {
              background: color.startsWith("#") ? darken(color, 0.3) : "rgba(0, 0, 0, 0.04)",
              boxShadow:
                "rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px",
            },
            "&:disabled": {
              color: "rgba(0, 0, 0, 0.26)",
              boxShadow: "none",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
          }
        : variant === "outlined"
        ? {
            color: _color === "primary" && responsiveColor ? dynamicDarken(color) : color,
            background: "rgba(0,0,0,0)",
            border: `1px solid ${color.startsWith("#") ? alpha(color, 0.5) : "rgba(0, 0, 0, 0.5)"}`,
            transition:
              "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            "&:hover": {
              background: color.startsWith("#") ? alpha(color, 0.04) : "rgba(0, 0, 0, 0.04)",
              border: `1px solid ${color}`,
            },
            "&:disabled": { color: "rgba(0, 0, 0, 0.26)", border: "1px solid rgba(0, 0, 0, 0.12)" },
          }
        : {
            color: _color === "primary" && responsiveColor ? dynamicDarken(color) : color,
            background: background || "rgba(0,0,0,0)",
            transition:
              "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            "&:hover": {
              background: background
                ? darken(background, 0.1)
                : color.startsWith("#")
                ? alpha(color, 0.04)
                : "rgba(0, 0, 0, 0.04)",
            },
            "&:disabled": {
              color: "rgba(0, 0, 0, 0.26)",
            },
          };

    if (variant === "icon") {
      switch (size) {
        case "sm":
          return { fontSize: 14, padding: 5, ...colorProps, borderRadius: "50%" };
        case "lg":
          return { fontSize: 22, padding: 9, ...colorProps, borderRadius: "50%" };
        case "xl":
          return { fontSize: 28, padding: 10, ...colorProps, borderRadius: "50%" };
        default:
          return { fontSize: 18, padding: 7, ...colorProps, borderRadius: "50%" };
      }
    }

    if (size === "md")
      return {
        "& > :not(:first-child)": {
          marginLeft: 10,
        },
        borderRadius: 4,
        ...theme.typography.button1,
        ...colorProps,
        padding: "8px 12px",
        height: 32,
        width: fullWidth ? "100%" : undefined,
        "& .MuiSvgIcon-root": { fontSize: 18, maxHeight: "fit-content" },
      };

    if (size === "lg")
      return {
        "& > :not(:first-child)": {
          marginLeft: 12,
        },
        borderRadius: 4,
        ...theme.typography.buttonLG,
        ...colorProps,
        padding: "12px 16px",
        height: 40,
        width: fullWidth ? "100%" : undefined,
        "& .MuiSvgIcon-root": { fontSize: 22, maxHeight: "fit-content" },
      };

    if (size === "xl")
      return {
        "& > :not(:first-child)": {
          marginLeft: 16,
        },
        borderRadius: 4,
        ...theme.typography.buttonXL,
        ...colorProps,
        padding: "16px 18px",
        height: 48,
        width: fullWidth ? "100%" : undefined,
        "& .MuiSvgIcon-root": { fontSize: 28, maxHeight: "fit-content" },
      };
    return {
      "& > :not(:first-child)": {
        marginLeft: 6,
      },
      borderRadius: 4,
      ...theme.typography.button2,
      ...colorProps,
      padding: "4px 6px",
      height: 24,
      width: fullWidth ? "100%" : undefined,
      "& .MuiSvgIcon-root": { fontSize: 14, maxHeight: "fit-content" },
    };
  },
);
