import { ResponseError } from "@talentpair/api";
import { FormikStatusT } from "@talentpair/types/formik";
import { MarginT } from "@talentpair/types/misc";
import lodash from "lodash";
import { FormikErrors, FormikProps, FormikValues } from "formik";
import { displayFullName, FirstLastNameT } from "@talentpair/entities/user";

interface MarginStyleT {
  marginTop?: number;
  marginBottom?: number;
}
export const getMarginStyle = (
  margin: MarginT = "normal",
  includeBottom = true,
  includeTop = true,
): MarginStyleT => {
  const style: MarginStyleT = {};

  if (margin === "normal") {
    if (includeTop) style.marginTop = 16;
    if (includeBottom) style.marginBottom = 8;
  } else if (margin === "dense") {
    if (includeTop) style.marginTop = 8;
    if (includeBottom) style.marginBottom = 4;
  }

  return style;
};

export interface EventObjectT<V> {
  persist: () => void;
  target: { name?: string; value: V; checked?: boolean; type?: string };
}
export const createEventObject = <V>(value: V): EventObjectT<V> => ({
  persist: lodash.noop,
  target: { value },
});

type StatusValueT = string | string[];
export function mergeStatus<V extends Record<string, unknown>>(
  form: FormikProps<V>,
  newStatus: FormikStatusT,
): void {
  form.setStatus(
    lodash.mergeWith(
      {},
      form.status || {},
      newStatus,
      (objValue: StatusValueT, srcValue: StatusValueT): StatusValueT | void => {
        // If it's an array, just accept new value (form level warnings)
        if (Array.isArray(objValue)) return srcValue;
        // If this function returns undefined, default behavior invoked
        return undefined;
      },
    ),
  );
}

export interface MappableUserT extends FirstLastNameT, Record<string, unknown> {
  id: number;
}
export function userToChoice<U extends MappableUserT>(user: U): { id: number; name: string } {
  return {
    ...user,
    name: displayFullName(user),
  };
}

export type ApiResponseErrorT<FormValues extends FormikValues> = ResponseError<
  | Array<keyof FormValues>
  // | { detail: string } - can't use this type because of the FormValues generic, but this shape is possible
  | Record<keyof FormValues, string>
  | Record<keyof FormValues, string[]>
  | Record<keyof FormValues, Record<string, string | string[]>>
  // phew! Now that's what I call standardized! /s
>;
export type ApiFormErrorT<FormValues extends FormikValues> = {
  status?: { error: string };
  errors?: FormikErrors<FormValues>;
};

export function normalizeApiErrors<FormValues extends FormikValues>(
  e: ApiResponseErrorT<FormValues>,
): ApiFormErrorT<FormValues> {
  const { data } = e;

  if (Array.isArray(data)) {
    if (data.every((v) => typeof v === "string"))
      return {
        status: { error: (data as string[]).map(lodash.upperFirst).join(", ") },
      };
  } else if (typeof data === "object") {
    if (typeof data.detail === "string")
      return { status: { error: lodash.upperFirst(data.detail) } };

    const errors: FormikErrors<FormValues> = {};
    Object.keys(data).forEach((k: keyof FormValues) => {
      const err = data[k];
      if (Array.isArray(err)) {
        // @ts-expect-error - eslint upgrade
        errors[k] = lodash.upperFirst(err[0]);
      } else if (typeof err === "string") {
        // DRF will will automatically provide an array, but when we manually raise a serializer
        // error we might forget to do it as an array, so we do this defensive check.
        // @ts-expect-error - eslint upgrade
        errors[k] = lodash.upperFirst(err);
      } else {
        // Somtimes an error comes back with a structure like { address: { location: ["You must select a location"] } }
        Object.assign(
          errors,
          lodash.mapValues(err, (nestedErr: string | string[]) =>
            lodash.upperFirst(Array.isArray(nestedErr) ? nestedErr[0] : nestedErr),
          ),
        );
      }
    });
    return { errors };
  }
  throw e;
}

// handy util used by default in the Formik form for auto-generating touched form field values based on the initialValues object
// does *NOT* support nested field values, so things like: { primary_phone: { number: string, extension: string } } map to { primary_phone: true }
// Formik does in fact support nested form values and thus nested touch values, but this util does not
export function getInitialTouched<V extends Record<string, unknown>, K extends keyof V>(
  values: V,
  override?: Record<K, boolean>,
): Record<keyof V, boolean> {
  const initialTouched = {} as Record<keyof V, boolean>;
  for (const [key, value] of Object.entries(values)) {
    let touched = false;
    if (Array.isArray(value)) {
      if (value.length && typeof value[0] === "object")
        // @ts-expect-error - it's okay, probably not worth trying to type this
        touched = value.map((v) => getInitialTouched(v));
      else touched = !!value.length;
    } else if (typeof value === "boolean") touched = true;
    // eslint-disable-next-line eqeqeq
    else touched = value != 0 && value != null && !Number.isNaN(value);

    initialTouched[key as K] = touched;
  }
  return override ? { ...initialTouched, ...override } : initialTouched;
}
