import * as React from "react";
import { styled } from "@mui/material/styles";
import { StyleProps } from "@talentpair/types/component";
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  IconProps as MuiIconProps,
} from "@mui/material";
import { colorProp, ColorPropT } from "../theme/colorProp";

type AvatarSizeT = "xs" | "sm" | "md" | "lg" | "xl";

type AvatarFillProps =
  | {
      fill: "text";
      text: string;
    }
  | {
      fill: "icon";
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Icon: React.ComponentType<MuiIconProps<any>>;
    }
  | {
      fill: "image";
      src: string;
      // image variants can have fallbacks set in case the image fails to load
      text?: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Icon?: React.ComponentType<MuiIconProps<any>>;
    };

export type AvatarProps = StyleProps &
  Omit<MuiAvatarProps, "src" | "srcSet" | "children"> &
  AvatarFillProps & {
    size?: AvatarSizeT; // default is medium
    shape?: "circular" | "rounded"; // default is circular
    color?: ColorPropT; // default is grey[500]
    background?: ColorPropT; // default is grey[200]
  };

const cssForSizes: Record<AvatarSizeT, React.CSSProperties> = {
  xs: { width: 20, height: 20 },
  sm: { width: 30, height: 30 },
  md: { width: 40, height: 40 },
  lg: { width: 80, height: 80 },
  xl: { width: 120, height: 120 },
};

const textCssForSizes: Record<AvatarSizeT, React.CSSProperties> = {
  xs: { fontSize: "10px", lineHeight: "12px", fontWeight: "700" },
  sm: { fontSize: "14px", lineHeight: "20px", fontWeight: "600" },
  md: { fontSize: "20px", lineHeight: "24px", fontWeight: "600" },
  lg: { fontSize: "32px", lineHeight: "38px", fontWeight: "600" },
  xl: { fontSize: "48px", lineHeight: "57px", fontWeight: "600" },
};

function UnstyledAvatar({
  color,
  background,
  shape = "circular",
  ...props
}: AvatarProps): React.ReactElement {
  if (props.fill === "icon") {
    const { fill, Icon, ...variantProps } = props;
    return (
      <MuiAvatar {...variantProps} variant={shape}>
        <Icon style={textCssForSizes[props.size || "md"]} />
      </MuiAvatar>
    );
  }
  if (props.fill === "text") {
    const { fill, text, ...variantProps } = props;
    return (
      <MuiAvatar {...variantProps} variant={shape}>
        {text}
      </MuiAvatar>
    );
  }
  // fill === "image"
  const { fill, Icon, text, src, alt, ...variantProps } = props;
  return (
    <MuiAvatar {...variantProps} variant={shape} src={src} alt={alt}>
      {Icon || text ? (
        <span className="AvatarFallback">
          {Icon ? <Icon style={textCssForSizes[props.size || "md"]} /> : text}
        </span>
      ) : undefined}
    </MuiAvatar>
  );
}

export const Avatar = styled(UnstyledAvatar, { skipSx: true })(
  ({
    theme,
    fill,
    shape = "circular",
    size = "md",
    color = "grey.600",
    background = "grey.200",
  }) => {
    const sharedStyles = {
      color: colorProp(color, theme),
      backgroundColor: colorProp(background, theme),
      borderRadius: shape === "circular" ? "50%" : "20%",
      ...cssForSizes[size],
      ...textCssForSizes[size],
    };
    return {
      overflow: "hidden",
      ...sharedStyles,
      ...(fill === "image"
        ? {
            objectFit: "contain",
            backgroundColor: "rgba(0,0,0,0)",
            "& > .AvatarFallback": {
              ...sharedStyles,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          }
        : null),
    };
  },
) as React.ComponentType<AvatarProps>;
