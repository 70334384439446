const MAX_AGE = 60 * 60 * 24 * 30; // 30 days in seconds

export const TpCookie = {
  COUPON: "TP_COUPON",
  JOB_BOARD_REFERRER: "JOB_BOARD_REFERER",
  PLAN_TYPE: "TP_PLAN_TYPE",
  PRICE_ID: "TP_PRICE_ID",
  REFERRAL_CODE: "REFERRAL_CODE",
  UTM_PARAMS: "UTM_PARAMS",
} as const;
type TpCookieType = typeof TpCookie[keyof typeof TpCookie];

export function setTpCookie(name: TpCookieType, contents: string, secure = true): void {
  const isLocal = process.env.HOST === "local";
  const domain = isLocal ? "localhost" : ".talentpair.com";
  window.document.cookie = `${name}=${contents}; domain=${domain}; max-age=${MAX_AGE}; path=/; ${
    isLocal || !secure ? "" : "secure"
  }`;
}

export function getCookie(name: TpCookieType): string | null {
  const cookie = document.cookie.split(";").find((c) => c.trim().startsWith(`${name}=`));
  return cookie ? cookie.split("=")[1] : null;
}

export function clearCookie(name: TpCookieType): void {
  const isLocal = process.env.HOST === "local";
  const domain = isLocal ? "localhost" : ".talentpair.com";
  window.document.cookie = `${name}=; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; ${
    isLocal ? "" : "secure"
  }`;
}
