import lodash from "lodash";
import { replaceOrAddArrayItem, splice } from "kyoto/utils/array";
import type { CapCandidateDetailReduxT } from "@talentpair/types/cap/entities/candidate";
import { sortCandidateData } from "../../utils/misc";
import type { CandidateActionT } from "./candidateActions";
import {
  CANDIDATE_LIST_ITEM_DELETE_SUCCESS,
  CANDIDATE_LIST_ITEM_UPDATE_SUCCESS,
  CANDIDATE_RECEIVE_DETAIL,
  CANDIDATE_UPDATE_SUCCESS,
  USER_UPDATE_SUCCESS,
  RECEIVED_CANDIDATE_REFERRAL_CODE,
} from "./candidateActions";

export type CandidatesReduxT = Record<number, CapCandidateDetailReduxT | null | undefined>;

function candidate(
  state: CapCandidateDetailReduxT | null = null,
  action: CandidateActionT,
): CapCandidateDetailReduxT | null {
  switch (action.type) {
    case CANDIDATE_RECEIVE_DETAIL:
      return { ...action.candidate, referral_code: null };

    case CANDIDATE_UPDATE_SUCCESS:
      return { referral_code: null, ...state, ...action.candidate };

    case RECEIVED_CANDIDATE_REFERRAL_CODE:
      return { ...(state as CapCandidateDetailReduxT), referral_code: action.referral_code };

    case CANDIDATE_LIST_ITEM_UPDATE_SUCCESS: {
      if (!state) return state;
      let list = replaceOrAddArrayItem(state[action.field] || [], action.item);
      if ("order" in action.item) list = lodash.sortBy(list, "order");
      return { ...state, [action.field]: list };
    }

    case CANDIDATE_LIST_ITEM_DELETE_SUCCESS: {
      if (!state) return state;
      const { field, item } = action;
      const list = state[field];
      const index = list ? list.findIndex(({ id }: { id: number }) => id === item.id) : -1;
      if (index < 0) return state;
      return {
        ...state,
        // @ts-expect-error - conform!
        [field]: splice(list, index),
      };
    }

    case USER_UPDATE_SUCCESS:
      return { ...state, user: action.user } as CapCandidateDetailReduxT;

    default:
      return state;
  }
}

export default function candidates(
  state: CandidatesReduxT = {},
  action: CandidateActionT,
): CandidatesReduxT {
  switch (action.type) {
    case CANDIDATE_RECEIVE_DETAIL:
    case CANDIDATE_UPDATE_SUCCESS:
    case CANDIDATE_LIST_ITEM_UPDATE_SUCCESS:
    case CANDIDATE_LIST_ITEM_DELETE_SUCCESS:
    case RECEIVED_CANDIDATE_REFERRAL_CODE:
    case USER_UPDATE_SUCCESS: {
      const c = candidate(state[action.candidateId], action);
      return { ...state, [action.candidateId]: c ? sortCandidateData(c) : c };
    }

    default:
      return state;
  }
}
export const _test = {
  candidate,
};
