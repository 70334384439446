// @flow
import { createSelector } from "@talentpair/redux";
import type { TapOrgDetailReduxT } from "@talentpair/types/tap/entities/org";
import type { TapOrgUserReduxT, TapUserJobT } from "@talentpair/types/tap/entities/user";
import type { StateT } from "../../store";
import type { UsersReduxT } from "./userReducers";

export const getOrg = ({ tap }: StateT, id: number): ?TapOrgDetailReduxT => tap.orgs[id];

export const getOrgJobs = ({ tap }: StateT, id: number): number[] | null =>
  tap.orgJobs?.[id] || null;

const getUsers = ({ tap }: StateT): UsersReduxT => tap.users;

export const getOrgUserIds = ({ tap }: StateT, id: number): number[] => tap.orgUsers[id] || [];

export const getOrgUsers = createSelector<StateT, number, TapOrgUserReduxT[], _, _>(
  [getUsers, getOrgUserIds],
  (users, userIds) =>
    userIds.reduce((acc, id) => (users[id] != null ? [...acc, users[id]] : acc), []),
);

export const getOrgUser = ({ tap }: StateT, id: number): ?TapOrgUserReduxT => tap.users[id];

export const getOrgUserJobsById = createSelector<StateT, number, { [number]: TapUserJobT, ... }, _>(
  [getOrgUser],
  (user) => {
    const jobs = {};
    if (user && user.jobs)
      user.jobs.forEach((job) => {
        jobs[job.id] = job;
      });
    return jobs;
  },
);
