import lodash from "lodash";
import { format } from "@talentpair/datetime";
import isURL from "validator/lib/isURL";
import { FileT, FileWithProcessIdsT, LinkT } from "@talentpair/types/entities/misc";
import { PhoneT } from "@talentpair/types/entities/user";
import { FirstLastNameT } from "@talentpair/entities/user";
import { toTruthy } from "./array";

export const phoneLink = (phoneNumber: string): string =>
  `tel:${phoneNumber.replace(/[^\d+]/g, "")}`;

export const displayPhoneNumber = (number: string): string => {
  let clean = number.replace(/[^\d]/g, "");
  if (clean.startsWith("1")) clean = clean.slice(1);
  if (clean.length < 4) return clean;
  if (clean.length < 7) return `(${clean.slice(0, 3)}) ${clean.slice(3)}`;
  return `(${clean.slice(0, 3)}) ${clean.slice(3, 6)}-${clean.slice(6)}`;
};

export const displayPhone = ({ number, extension, type }: PhoneT): string =>
  `${displayPhoneNumber(number)}${extension ? ` Ex. ${extension}` : ""}${
    type ? ` (${type.name.toUpperCase()})` : ""
  }`;

export const truncateTextByWordCount = (text: string, words = 30): string => {
  const shortened = text.split(/\s+/g).slice(0, words).join(" ");
  if (shortened.endsWith("…") || shortened.endsWith("...")) return shortened;
  return `${shortened}…`;
};

export const displayCurrency = (
  num?: (number | string) | null,
  useK = false,
  sign = "",
  singleDecimal = false,
): string | null => {
  if (num == null || Number.isNaN(+num)) return null;
  const abs = Math.abs(+num);
  const negative = +num < 0;
  if (useK)
    return `${negative ? "-" : ""}${sign}${
      singleDecimal ? (abs / 1000).toFixed(1) : +(abs / 1000).toFixed(1)
    }k`;
  return `${negative ? "-" : ""}${sign}${abs.toLocaleString()}`;
};

export const displayDollars = (num: number, useK = false): string =>
  displayCurrency(num, useK, "$") as string;

export const displayPercent = (num: number, decimals = 0, oneIs100 = false): string => {
  if (Number.isNaN(num)) return `${(0).toFixed(decimals)}%`;
  // If oneIs100 is true and num === 1, then we treat num as 100%, else we treat num as 1%
  if (oneIs100 ? num <= 1 && num >= -1 : num < 1 && num > -1)
    return `${(num * 100).toFixed(decimals)}%`;
  // limit infinities to 100% or -100%, but all else limit to max int size (which is huge)
  const limit = !Number.isFinite(num) ? 100 : Number.MAX_SAFE_INTEGER;
  return `${lodash.clamp(num, -limit, limit).toFixed(decimals)}%`;
};

type SalaryT = {
  readonly salary_min?: (number | string) | null;
  readonly salary_max?: (number | string) | null;
};
export const displaySalary = (
  { salary_min, salary_max }: SalaryT,
  useK = false,
  sign = "",
): string => {
  const min = displayCurrency(salary_min, useK, sign);
  const max = displayCurrency(salary_max, useK, sign);

  if (min && max) return `${min} - ${max}`;
  if (min) return min;
  if (max) return max;

  return "";
};

export function commafyByKey<O extends { name?: string; label?: string }>(
  objList: ReadonlyArray<O>,
  key?: keyof O,
): string {
  const firstObj = objList[0];
  if (!firstObj) return "";
  const finalKey = key || (firstObj.hasOwnProperty("name") ? "name" : "label");
  // @ts-expect-error - TS doesn't seem happy with this, but seems logical: Ben 03/02/21
  return objList.map((o) => o[finalKey]).join(", ");
}

export const displayInitials = ({ first_name, last_name }: FirstLastNameT): string => {
  if (first_name && last_name) return first_name[0] + last_name[0];
  if (first_name) return first_name[0];
  return last_name ? last_name[0] : "";
};

export const stripTags = (text: string, ...tags: string[]): string => {
  if (!text) return text;

  return tags.reduce((newText, tag) => {
    const re = new RegExp(`</?${tag}>`, "g");
    return newText.replace(re, "");
  }, text);
};

export const unescapeHtml = (text: string): string => {
  if (!text) return text;
  // unescaping a few really common html escape codesthat we might encounter in our note fields
  return text.replace(/&gt;/gi, ">").replace(/&lt;/gi, "<").replace(/&amp;/gi, "&");
};

export const titlecase = (str: string): string => {
  // using codePointAt to make sure that we get the full utf16 code even if it's part of a surrogate pair
  const codePoint = str.codePointAt(0);
  const first = codePoint == null ? "" : String.fromCodePoint(codePoint);
  return `${first.toUpperCase() || ""}${str.slice(first.length)}`;
};

export const reverseSlug = (str: string, lowerCase: unknown = false): string => {
  str = str.replace(/_/g, " ");
  // Explicit check to avoid iteration counter truthiness, e.g. someArray.map(reverseSlug)
  return lowerCase === true ? str : titlecase(str);
};

export const boolYesNo = (val: unknown): string => (val ? "Yes" : "No");

const dateRegex =
  /^(\d{4})(?:-?W(\d+)(?:-?(\d+)D?)?|(?:-(\d+))?-(\d+))(?:[T ](\d+):(\d+)(?::(\d+)(?:\.(\d+))?)?)?(?:Z(-?\d*))?$/;
export const isISODateStr = (str: string): boolean => !!dateRegex.exec(str);

export function stripProtocol(url: string): string {
  let domain = url;
  if (domain.includes("//")) domain = domain.split("//")[1];
  if (domain.startsWith("www.")) domain = domain.substr(4);
  return domain;
}

export const justDomain = (url: string | null): string | null =>
  url && isURL(url) ? stripProtocol(url).split("/")[0] : null;

export const externalLink = (url: string): string =>
  `${url.startsWith("http") ? "" : "http://"}${url}`;

const pluralizeRules: { [key: string]: string } = {
  this: "these",
  match: "matches",
  Industry: "Industries",
  company: "companies",
};
export const pluralize = (str: string, count: number | null): string =>
  count === 1 ? str : pluralizeRules[str] || `${str}s`;

export const documentTitle = (doc: FileT | FileWithProcessIdsT): string =>
  `${doc.name || "Untitled file"}${doc.ext ? `.${doc.ext}` : ""}`;

export const fileNameFromUrl = (url: string): string => decodeURI(url).split("/").pop() || "File";

export const splitLines = (body: string): string[] => body.split(/\r?\n|\r/g).filter(toTruthy);

export const displayLink = (link: LinkT | null): string => (link ? link.url : "");

export const createDateStr = (
  year: number | null,
  month: number | null,
  day?: number,
): string | null => (year ? format(new Date(year, month || 0, day || 1), "yyyy-MM-dd") : null);

export const toFixedPercentString = (val: number): string => {
  if (Number.isNaN(val) || val <= 0) return "00";
  if (val > 0.995) return "100";
  return val.toFixed(2).substring(2);
};

export const displayNotificationSetting = (email: boolean, sms = false): string => {
  if (!email && !sms) return "Off";
  if (!email) return "Text";
  if (!sms) return "Email";
  return "Email, Text";
};
