import * as React from "react";
import { userService } from "@talentpair/api";
import { useMediaContext, Box, Link } from "@talentpair/quantic";
import { env, EnvIndicator, londonLink } from "@talentpair/env";
import NotificationsLink from "./NotificationsLink";
import CandidateMenu from "./CandidateMenu";

export type CandidateHeaderActionsProps = {
  showReferralLink?: boolean;
  candidateId: number;
  pathname: string;
  onNotificationClick?: () => unknown;
};

export default function CandidateHeaderActions({
  candidateId,
  onNotificationClick,
  pathname,
  showReferralLink = false,
}: CandidateHeaderActionsProps): React.ReactElement {
  const { desktop } = useMediaContext();
  const isActiveCandidate = userService.isCandidate() && !userService.isDeactivated();
  return (
    <Box variant="row" layout="center-start">
      {desktop && isActiveCandidate && showReferralLink ? (
        <Link variant="body1" className="bold" href={env.londonUrl(londonLink.capReferFriends())}>
          Invite & Earn
        </Link>
      ) : null}
      {isActiveCandidate ? (
        <NotificationsLink isTap={false} pathname={pathname} onClick={onNotificationClick} />
      ) : null}
      <EnvIndicator />
      <div className="relative" style={{ right: -8 }}>
        <CandidateMenu candidateId={candidateId} desktop={desktop} />
      </div>
    </Box>
  );
}
