import lodash from "lodash";
import ErrorIcon from "@mui/icons-material/Error";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import InfoIcon from "@mui/icons-material/Info";
import { Button, ButtonProps, Snackbar as MuiSnackbar, SnackbarProps } from "@mui/material";
import { ReactElement } from "react";
import { PortalPropsT } from "./portal";

export const ActionButton = (props: ButtonProps): ReactElement => (
  <Button color="inherit" size="small" {...props} />
);

export interface PortalSnackbarPropsT extends SnackbarProps {
  buttonText?: string;
  type?: "default" | "error" | "warning" | "info";
}
export type PropsT = PortalPropsT & PortalSnackbarPropsT;

export const Snackbar = ({
  buttonText = "DISMISS",
  message,
  onClose,
  type = "default",
  onExited,
  ...props
}: PropsT): ReactElement => {
  let defaultProps: Partial<PropsT> = {
    action: <ActionButton onClick={onClose}>{buttonText}</ActionButton>,
    anchorOrigin: { vertical: "bottom", horizontal: "right" },
    autoHideDuration: 5000,
    message,
    onClose,
    TransitionProps: { onExited },
  };

  if (type === "error") {
    defaultProps = {
      ...defaultProps,
      message: (
        <span className="flex items-center">
          <ErrorIcon key="error" classes={{ root: "mr2 line-height-inherit" }} /> {message}
        </span>
      ),
      onClose: lodash.noop,
      ContentProps: { classes: { root: "bg-error" } },
    };
  } else if (type === "warning") {
    defaultProps = {
      ...defaultProps,
      message: (
        <span className="flex items-center">
          <ReportProblemIcon key="error" classes={{ root: "mr2 line-height-inherit" }} /> {message}
        </span>
      ),
      onClose: lodash.noop,
      ContentProps: { classes: { root: "bg-warning" } },
    };
  } else if (type === "info") {
    defaultProps = {
      ...defaultProps,
      message: (
        <span className="flex items-center">
          <InfoIcon key="info" classes={{ root: "mr2 line-height-inherit" }} /> {message}
        </span>
      ),
      onClose: lodash.noop,
      ContentProps: { classes: { root: "bg-green-300" } },
    };
  }

  return <MuiSnackbar {...lodash.merge({}, defaultProps, props)} />;
};
