import { autocompleteFetcher, makeCancelable, CancelablePromiseT } from "@talentpair/api";
import { SubmitOnChangeT } from "@talentpair/types/formik";
import { FormikValues } from "formik";
import * as React from "react";
import { debouncedPromise } from "../../../../../utils/misc";
import SingleOrMultiAutocomplete, {
  SingleOrMultiAutocompletePropsT,
} from "../../../../Autocomplete/SingleOrMultiAutocomplete";
import { AutocompleteMultiPropsT } from "../../../../Autocomplete/AutocompleteMulti";

export type BaseEntitySearchPropsT<V, P> = SingleOrMultiAutocompletePropsT & {
  entity: keyof typeof autocompleteFetcher;
  params?: P;
  fetchOnEmptyQuery?: boolean;
  submitOnChange?: SubmitOnChangeT<V, Record<string, unknown>> | null;
  fetchImmediately?: boolean;
  disabled?: boolean | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type EntitySearchMultiT<V, P = Record<string, unknown>> = Omit<
  BaseEntitySearchPropsT<V[], P>,
  "name" | "entity"
> & {
  renderChip?: AutocompleteMultiPropsT<V>["renderChip"];
};

export type EntitySearchSingleT<V, P = Record<string, unknown>> = Omit<
  BaseEntitySearchPropsT<V | null, P>,
  "entity" | "name" | "multiple"
>;

type StateT<V> = { suggestions: V[] };

export default class BaseEntitySearch<V, P = Record<string, unknown>> extends React.Component<
  BaseEntitySearchPropsT<V, P>,
  StateT<V>
> {
  lookupFn: ((query: string, params?: P) => Promise<V[]>) | null = null;
  promise: CancelablePromiseT<V[]> | null = null;
  throttleTimeout = false;

  static defaultProps = {
    params: null,
    multiple: false,
    transformSuggestions: null,
    meta: null,
    canCreate: false,
    fetchOnEmptyQuery: false,
    submitOnChange: null,
    fetchImmediately: false,
    disabled: false,
  };

  state: StateT<V> = {
    suggestions: [],
  };

  componentDidMount(): void {
    const { entity, fetchImmediately } = this.props;
    this.lookupFn = debouncedPromise<[string, P | undefined], V[]>(
      // @ts-expect-error - typescript is having a hard time with the generics here
      autocompleteFetcher[entity],
      200,
    );
    if (fetchImmediately) this.onSuggestionsFetchRequested();
  }

  componentWillUnmount(): void {
    if (this.promise) this.promise.cancel();
  }

  clearSuggestions = (): void => {
    if (this.props.fetchOnEmptyQuery) {
      this.onSuggestionsFetchRequested();
    } else {
      this.setState({ suggestions: [] });
    }
  };

  onSuggestionsFetchRequested = (value = ""): Promise<void> => {
    if (this.lookupFn) {
      this.promise = makeCancelable<V[]>(this.lookupFn(value, this.props.params));
      return this.promise.then((suggestions: V[]) => this.setState({ suggestions }));
    }
    return Promise.resolve();
  };

  render(): React.ReactNode {
    const { entity, params, ...props } = this.props;
    return (
      <SingleOrMultiAutocomplete<V, FormikValues>
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        clearSuggestions={this.clearSuggestions}
        suggestions={this.state.suggestions}
        {...props}
      />
    );
  }
}
