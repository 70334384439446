import { combineReducers } from "@talentpair/redux";
import type { CursorPaginationT } from "@talentpair/types/misc";
import {
  USER_ACTIVITY_RECEIVE_NOTIFICATION_COUNT,
  UserActivityReceiveNotificationCountActionT,
} from "./userActivityActions";
import sharedActivityReducers, {
  initialState as activityInitialState,
} from "./sharedActivityReducers";
import type { ActivityActionT } from "./activityActions";
import {
  ACTIVITY_LOADING,
  ACTIVITY_RECEIVE_ITEM,
  ACTIVITY_RECEIVE_LIST,
  ACTIVITY_RECEIVE_LAST_FETCH,
  ACTIVITY_RECEIVE_LATEST,
  ACTIVITY_REMOVE_ITEM,
} from "./activityActions";

/**
 * Since most actions are created by ActivityAction creators as opposed to ones specifically for PairActivity, the
 * root reducer in this file only runs if the `action.activityType === "user"`.
 */
export type UserActivityReduxT = {
  items: number[];
  lastFetched: string;
  latestActivity: string;
  loading: boolean;
  pagination: CursorPaginationT;
  notificationCount: number;
};
export type UserActivitiesReduxT = Record<number, UserActivityReduxT>;
const initialState = { ...activityInitialState, notificationCount: 0 };

function notificationCount(
  state: number = initialState.notificationCount,
  action: UserActivityReceiveNotificationCountActionT,
): number {
  return action.type === USER_ACTIVITY_RECEIVE_NOTIFICATION_COUNT ? action.count : state;
}

const singleUserActivity = combineReducers({ ...sharedActivityReducers, notificationCount });

export default function userActivityReducer(
  state: UserActivitiesReduxT = {},
  action: ActivityActionT | UserActivityReceiveNotificationCountActionT,
): UserActivitiesReduxT {
  if (action.activityType !== "user") return state;

  switch (action.type) {
    case ACTIVITY_LOADING:
    case ACTIVITY_RECEIVE_ITEM:
    case ACTIVITY_RECEIVE_LAST_FETCH:
    case ACTIVITY_RECEIVE_LATEST:
    case ACTIVITY_RECEIVE_LIST:
    case ACTIVITY_REMOVE_ITEM:
    case USER_ACTIVITY_RECEIVE_NOTIFICATION_COUNT:
      // @ts-expect-error - some odd type inference here
      return { ...state, [action.id]: singleUserActivity(state[action.id], action) };

    default:
      return state;
  }
}
export const _test = {
  notificationCount,
};
