import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TpHeader } from "../../components/TpHeader";
import { OrgPricingMeta } from "./containers/OrgPricingHeader";

const OrgPricing = React.lazy(() => import("./containers/OrgPricingPage"));

export const OrgPricingPage = page({
  mobile: OrgPricing,
  header: TpHeader,
  meta: OrgPricingMeta,
});
