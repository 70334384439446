import * as React from "react";
import { useLocation } from "@talentpair/routing";
import { portal, StateT as PortalStateT } from "./portal";

interface PropsT {
  pathname: string;
}
interface StateT {
  portals: PortalStateT;
}

export class BasePortalRenderer extends React.Component<PropsT, StateT> {
  unsubscribe: (() => void) | null = null;
  state: StateT = { portals: [] };

  componentDidMount(): void {
    this.unsubscribe = portal.subscribe((portals) => {
      this.setState({ portals });
    });
  }

  componentDidUpdate(prevProps: PropsT): void {
    if (prevProps.pathname !== this.props.pathname) {
      this.state.portals.forEach((p) => {
        if (p.type === "modal") p.props.onClose();
      });
    }
  }

  componentWillUnmount(): void {
    if (this.unsubscribe) this.unsubscribe();
  }

  render(): React.ReactNode {
    return (
      <>
        {this.state.portals.map(({ cmp: Cmp, key, props }) => (
          <Cmp {...props} key={key} />
        ))}
      </>
    );
  }
}

export const PortalRenderer = (): React.ReactElement => {
  const location = useLocation();
  return <BasePortalRenderer pathname={location.pathname} />;
};
