import { styled } from "@mui/material/styles";
import { StyleProps } from "@talentpair/types/component";
import { Box } from "../../atoms/Box";
import { Typography } from "../../atoms/Typography";

export type MenuSectionProps = React.PropsWithChildren<
  StyleProps & {
    bordered?: boolean;
    title?: React.ReactNode;
  }
>;

export function UnstyledMenuSection({
  bordered,
  title,
  children,
  ...props
}: MenuSectionProps): React.ReactElement {
  return (
    <>
      <Box layout="stretch-start" background="inherit" {...props} variant="column">
        {title ? (
          <Typography variant="body2" color="common.black" bold className="px3 py2">
            {title}
          </Typography>
        ) : undefined}
      </Box>
      {/* purposefully keeping the children out of this box because otherwise arrow controls won't work. */}
      {children}
    </>
  );
}

export const MenuSection = styled(UnstyledMenuSection, { skipSx: true })(({ bordered, theme }) => ({
  margin: 0,
  marginTop: bordered ? theme.spacing(2) : 0,
  padding: 0,
  borderWidth: 0,
  borderTop: bordered ? `solid 1px ${theme.palette.grey[200]}` : undefined,
}));
