import * as React from "react";
import { Menu, MenuProps } from "@mui/material";
import { StyleProps } from "@talentpair/types/component";

export type ControlledMenuProps = React.PropsWithChildren<
  StyleProps & {
    button: React.ReactElement;
    open: boolean;
    onClose: () => void;
    onOpen: () => void;
    minWidth?: number;
    menuProps?: Omit<
      MenuProps,
      "disableRestoreFocus" | "open" | "anchorEl" | "onClose" | "onClick" | "onKeyUp" | "onKeyDown"
    >;
    // eslint-disable-next-line @typescript-eslint/ban-types
    MenuListProps?: {};
  }
>;

export class ControlledMenu extends React.Component<ControlledMenuProps> {
  menuRef = React.createRef<Element>();

  onKeyEvent = (e: React.KeyboardEvent): void => {
    if (this.props.open)
      switch (e.key) {
        case "Escape":
          this.props.onClose();
        // eslint-disable-next-line no-fallthrough
        case "Enter":
        case "Spacebar":
        case " ":
        case "ArrowDown":
        case "ArrowUp":
          e.stopPropagation();
          break;
        default:
          break;
      }
  };

  onOpen = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
    const { onOpen } = this.props;
    e.stopPropagation();
    onOpen();
  };

  onClose = (e: React.SyntheticEvent): void => {
    const { onClose } = this.props;
    e.stopPropagation();
    onClose();
  };

  render(): React.ReactNode {
    const {
      button,
      children,
      open,
      onOpen,
      onClose,
      menuProps,
      minWidth = 200,
      MenuListProps,
      ...other
    } = this.props;

    if (!React.isValidElement(button)) throw Error("Button menu must have a button!");
    return (
      // @ts-expect-error - ref typedef mismatch
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div {...other} ref={this.menuRef} onKeyUp={this.onKeyEvent} onKeyDown={this.onKeyEvent}>
        {
          // @ts-expect-error - It's okay
          React.cloneElement(button, { onClick: this.onOpen })
        }
        <Menu
          disableRestoreFocus
          open={open}
          anchorEl={this.menuRef ? this.menuRef.current : null}
          onClose={this.onClose}
          onClick={this.onClose}
          {...menuProps}
          MenuListProps={{ ...MenuListProps, style: { minWidth } }}
        >
          {children}
        </Menu>
      </div>
    );
  }
}
