import { auth } from "@talentpair/api";
import * as React from "react";

export interface UseAuthHook {
  state: "checking" | "confirmed";
  isLoggedIn: boolean;
  logout(): Promise<void>;
}

const logout = (): Promise<void> => auth.logout(false);

export function useAuth(): UseAuthHook {
  const [authState, setState] = React.useState<UseAuthHook>({
    state: "checking",
    isLoggedIn: false,
    logout,
  });

  React.useEffect(() => {
    let setLoginState = (isLoggedIn: boolean): void =>
      setState({
        isLoggedIn,
        state: "confirmed",
        logout: () => {
          setState((current: UseAuthHook) => ({
            ...current,
            isLoggedIn: false,
            logout,
          }));
          return logout();
        },
      });

    auth
      .isLoggedin(() => true)
      .then(() => setLoginState(true))
      .catch(() => setLoginState(false));

    return () => {
      setLoginState = () => null;
    };
  }, []);

  return authState;
}
