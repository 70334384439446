import * as React from "react";
import DraftsIcon from "@mui/icons-material/Drafts";
import EventIcon from "@mui/icons-material/Event";
import StarsIcon from "@mui/icons-material/Stars";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PairStateT as BasePairStateT } from "@talentpair/types/entities/pairs";
import { CapPairReduxT } from "@talentpair/types/cap/entities/pair";
import { TapJobReduxT } from "@talentpair/types/tap/entities/job";
import { TapPairReduxT } from "@talentpair/types/tap/entities/pair";
import { CapPairTrackDataT, TapPairTrackDataT } from "./track";

export interface PairStatusConfigT {
  label: string;
  icon: React.ComponentType<{ classes?: Record<string, unknown> }>;
  color: string;
}

export type ExtendedMyDeskPairStateT = "FI" | "SI" | "TI";

type PairStatusConfigObjT = Record<BasePairStateT | ExtendedMyDeskPairStateT, PairStatusConfigT>;

export const PairStatusConfig: PairStatusConfigObjT = {
  AP: { label: "Applied", icon: AssignmentIndIcon, color: "blue-500" },
  PE: { label: "Pending", icon: VisibilityIcon, color: "blue-500" },
  OP: { label: "Sourced", icon: VisibilityIcon, color: "blue-500" },
  PR: { label: "Sourced", icon: VisibilityIcon, color: "blue-500" },
  IN: { label: "Interviewing", icon: EventIcon, color: "amber-700" },
  FI: { label: "1st interview", icon: EventIcon, color: "amber-700" },
  SI: { label: "2nd interview", icon: EventIcon, color: "amber-700" },
  TI: { label: "3rd+ interview", icon: EventIcon, color: "amber-700" },
  OF: { label: "Offer", icon: DraftsIcon, color: "blue-900" },
  PL: { label: "Hired", icon: StarsIcon, color: "green-400" },
  PA: { label: "Passed", icon: ThumbDownIcon, color: "amber-900" },
  HI: { label: "Interested", icon: ThumbUpIcon, color: "green-600" },
};

const hasPairNotes = (pair: {
  intro_notes?: null | string;
  work_authorization_notes?: null | string;
  location_notes?: null | string;
  skill_notes?: null | string;
  wiifm?: null | string;
  compensation_notes?: null | string;
}): boolean =>
  !!(
    pair.intro_notes ||
    pair.work_authorization_notes ||
    pair.location_notes ||
    pair.skill_notes ||
    pair.wiifm ||
    pair.compensation_notes
  );

type PairStateT = Pick<
  CapPairReduxT,
  "candidate_state" | "am_state" | "job_state" | "job_inactive"
>;

const isMutualInterestOrBeyond = (
  pair: null | { candidate_state: BasePairStateT; job_state: BasePairStateT },
): boolean =>
  !!pair &&
  ["HI", "IN", "OF", "PL"].includes(pair.candidate_state) &&
  ["HI", "IN", "OF", "PL"].includes(pair.job_state);

const isReviewState = (state: string): boolean => state === "OP" || state === "PR";

const capState = ({ candidate_state }: PairStateT): BasePairStateT => candidate_state;

const capPairClosed = (pair: null | PairStateT): boolean => !!pair && pair.job_inactive;

const capPairRejected = (pair: null | PairStateT): boolean =>
  (!!pair && (pair.am_state === "PA" || pair.job_state === "PA")) || capPairClosed(pair);

const capIsPairInactive = (pair: null | PairStateT): boolean =>
  !!pair && (pair.candidate_state === "PA" || capPairRejected(pair));

const capPairUnreviewed = ({ candidate_state }: PairStateT): boolean =>
  candidate_state === "PR" || candidate_state === "OP";

const capIsPairReview = (pair: null | PairStateT): boolean =>
  !!pair && capPairUnreviewed(pair) && !capPairClosed(pair);

const capIsPairActive = (pair: null | PairStateT): boolean =>
  !!pair && !capIsPairInactive(pair) && !capIsPairReview(pair);

type PairApplicantT = {
  added_to_cap_by?: null | { id: number };
  created_by?: { id: number };
  candidate?: { user: { id: number } };
};
const capIsApplication = (pair: null | PairApplicantT): boolean =>
  !!pair &&
  (pair.created_by?.id === pair.candidate?.user.id ||
    pair.added_to_cap_by?.id === pair.candidate?.user.id);

const capIconConfig = (pair: CapPairReduxT): Omit<PairStatusConfigT, "label"> => {
  const { icon, color } = PairStatusConfig[capState(pair)];
  return { icon, color: capPairRejected(pair) ? "grey-400" : color };
};

const capTrackData = (pair: CapPairReduxT): CapPairTrackDataT => ({
  state: capState(pair),
  candidate_state: pair.candidate_state,
  job_inactive: pair.job_inactive,
  sent_to_job: pair.sent_to_job,
});

const tapState = ({ job_state }: TapPairReduxT): BasePairStateT => job_state;

const tapPairUnreviewed = ({ job_state }: Pick<TapPairReduxT, "job_state">): boolean =>
  job_state === "PR" || job_state === "OP";

const tapPairClosed = (pair: null | TapPairReduxT): boolean =>
  !!pair && pair.candidate_state === "PA";

const tapIsPairInactive = (pair: null | TapPairReduxT): boolean =>
  !!pair && (pair.job_state === "PA" || pair.am_state === "PA" || tapPairClosed(pair));

const tapIsPairReview = (pair: null | TapPairReduxT): boolean =>
  !!pair && tapPairUnreviewed(pair) && !tapPairClosed(pair);

const tapIsPairActive = (pair: null | TapPairReduxT): boolean =>
  !!pair && !tapIsPairInactive(pair) && !tapIsPairReview(pair);

const tapIconConfig = (pair: TapPairReduxT): Omit<PairStatusConfigT, "label"> => {
  const { icon, color } = PairStatusConfig[tapState(pair)];
  return { icon, color: tapPairClosed(pair) ? "grey-400" : color };
};

const tapTrackData = (
  pair: TapPairReduxT,
  job: TapJobReduxT,
  job_admin: boolean,
): TapPairTrackDataT => ({
  state: tapState(pair),
  candidate_state: pair.candidate_state,
  job_state: pair.job_state,
  tap_status: job.tap_status.name,
  job_admin,
});

const getPairTab = (pair: null | TapPairReduxT): "ACTIVE" | "INACTIVE" | "REVIEW" =>
  tapIsPairInactive(pair) ? "INACTIVE" : tapIsPairReview(pair) ? "REVIEW" : "ACTIVE";

export default {
  hasPairNotes,
  isReviewState,
  capIconConfig,
  capPairClosed,
  capPairRejected,
  capPairUnreviewed,
  capIsPairInactive,
  capIsPairReview,
  capIsPairActive,
  capIsApplication,
  capState,
  capTrackData,
  tapIconConfig,
  tapPairClosed,
  tapPairUnreviewed,
  tapIsPairInactive,
  tapIsPairReview,
  tapIsPairActive,
  tapState,
  tapTrackData,
  getPairTab,
  isMutualInterestOrBeyond,
};
