import { apiRequest } from "@talentpair/api";
import { ThunkActionT } from "@talentpair/redux";
import { getUserActivity } from "./userActivitySelectors";
import ActivityAction from "./activityActions";
import { StateT } from "../../../store";
import { getApp } from "../../../routeConfig";

export const USER_ACTIVITY_RECEIVE_NOTIFICATION_COUNT = "USER_ACTIVITY_RECEIVE_NOTIFICATION_COUNT";
export type UserActivityReceiveNotificationCountActionT = {
  type: "USER_ACTIVITY_RECEIVE_NOTIFICATION_COUNT";
  activityType: "user";
  id: number;
  count: number;
};

const receiveNotificationCount = (
  id: number,
  count: number,
): UserActivityReceiveNotificationCountActionT => ({
  type: USER_ACTIVITY_RECEIVE_NOTIFICATION_COUNT,
  activityType: "user",
  id,
  count,
});

const fetchNotificationCount =
  (id: number): ThunkActionT<unknown, StateT> =>
  (dispatch) =>
    apiRequest
      .get<{ unread_notification_count: number }>(`users-${getApp()}/${id}/activity-unread/`)
      .then(({ data }) => dispatch(receiveNotificationCount(id, data.unread_notification_count)));

const fetchNextPage =
  (id: number): ThunkActionT<unknown, StateT> =>
  (dispatch, getState) => {
    const state = getState();
    const userActivity = getUserActivity(state, id);
    const nextLink = userActivity ? userActivity.pagination.next : null;
    if (!nextLink) return Promise.resolve();
    return dispatch(ActivityAction.fetchList("user", id, nextLink));
  };

export default {
  receiveNotificationCount,
  fetchNotificationCount,
  fetchNextPage,
};
