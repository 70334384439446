import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { SettingsMeta } from "./containers/TapSettingsHeader";
import { SideNavigation } from "../components/Navigation/SideNavigation";

const TapSettings = React.lazy(() => import("./containers/TapSettingsPage"));

export const TapSettingsPage = page({
  // @ts-expect-error - hmmm
  desktop: TapSettings,
  // @ts-expect-error - hmmm
  mobile: TapSettings,
  // @ts-expect-error - hmmm
  desktopSidenav: SideNavigation,
  header: TapHeader,
  meta: SettingsMeta,
});
