type RequiredLabelProps = React.PropsWithChildren<{
  error?: string | null;
  focused?: boolean;
  touched?: boolean;
}>;

export default function RequiredLabel({
  focused,
  error,
  touched,
  children,
}: RequiredLabelProps): React.ReactElement {
  const isComplete = !error && touched && !focused;
  // believe it or not this fragment prevents type errors.
  if (isComplete) return <>{children}</>;

  const mark = <span className={error ? "fg-error" : "black"}>*</span>;

  if (!children) return mark;

  return (
    <>
      {children} {mark}
    </>
  );
}
