import * as React from "react";
import { page } from "../../components/layout/responsive";
import { CalendarMeta, CalendarHeader } from "./containers/CapCalendarHeader";

const CapCalendar = React.lazy(() => import("./containers/CapCalendarPage"));

export const CapCalendarPage = page({
  // @ts-expect-error - hmmm
  mobile: CapCalendar,
  header: CalendarHeader,
  meta: CalendarMeta,
});
