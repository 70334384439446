import { auth, userService } from "@talentpair/api";
import * as React from "react";
import lodash from "lodash";
import { asyncNoop } from "kyoto/utils/misc";
import { skipSignupRedirect } from "../../routeConfig";
import track from "../../services/track";
import { getNextTour, TourT } from "./utils";

export interface OnboardingProviderProps {
  children: React.ReactNode;
}
export interface ValueT {
  currentTour: TourT | undefined;
  currentTourStep: number | undefined;
  setTourStep: (step: number) => void;
  markTourAsDone: (tour: TourT) => () => Promise<void>;
  registerTour: (tour: TourT) => void;
  unregisterTour: (tour: TourT) => void;
}
interface OnboardingProviderState {
  availableTours: TourT[];
  value: ValueT;
}

const OnboardingContext = React.createContext<ValueT>({
  currentTour: undefined,
  currentTourStep: undefined,
  setTourStep: lodash.noop,
  markTourAsDone: () => asyncNoop,
  registerTour: lodash.noop,
  unregisterTour: lodash.noop,
});

class OnboardingProvider extends React.Component<OnboardingProviderProps, OnboardingProviderState> {
  constructor(props: OnboardingProviderProps) {
    super(props);
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      availableTours: [],
      value: {
        currentTour: undefined,
        currentTourStep: undefined,
        setTourStep: this.setTourStep,
        markTourAsDone: this.markTourAsDone,
        registerTour: this.registerTour,
        unregisterTour: this.unregisterTour,
      },
    };
  }

  componentDidMount(): void {
    // Uses debounced check so we don't load it several times,
    // but ensures it's been called
    auth
      .isLoggedin(skipSignupRedirect)
      .then(() => this.updateTours(this.state.availableTours)) // Don't care about the errors here, <AuthRoutes /> handle it
      .catch(lodash.noop);
  }

  // Save preference and update current tour
  markTourAsDone = (tour: TourT) => (): Promise<void> =>
    userService.updateUIPreferences({ [tour]: true } as Record<TourT, boolean>).then(() => {
      this.updateTours(this.state.availableTours);
      track.onboarding({ tour, action: "Completed" });
    });

  registerTour = (tour: TourT): void => this.updateTours([...this.state.availableTours, tour]);

  setTourStep = (step: number): void =>
    this.setState((state) => ({ ...state, value: { ...state.value, currentTourStep: step } }));

  unregisterTour = (tour: TourT): void =>
    this.updateTours(this.state.availableTours.filter((t) => t !== tour));

  updateTours(availableTours: TourT[]): void {
    this.setState(({ value }) => {
      const currentTour = getNextTour(availableTours);
      return {
        availableTours,
        value: { ...value, currentTour, currentTourStep: currentTour ? 0 : undefined },
      };
    });
  }

  render(): React.ReactNode {
    const { children } = this.props;
    return (
      <OnboardingContext.Provider value={this.state.value}>{children}</OnboardingContext.Provider>
    );
  }
}

export default { Provider: OnboardingProvider, Consumer: OnboardingContext.Consumer };
