import { apiRequest, NotificationCountStore } from "@talentpair/api";
import { DispatchT } from "@talentpair/redux";
import { CursorPaginationT } from "@talentpair/types/misc";
import {
  PlatformNotificationT,
  PlatformNotificationResultsT,
} from "@talentpair/types/notifications";
import { StateT } from "../../../store";
import { getNextLink } from "./notificationSelectors";
import { ViewStateT } from "./notificationReducers";

export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const NOTIFICATION_RECEIVE_ITEM = "NOTIFICATION_RECEIVE_ITEM";
export const NOTIFICATION_RECEIVE_LIST = "NOTIFICATION_RECEIVE_LIST";
export const NOTIFICATION_SET_HAS_NEW = "NOTIFICATION_SET_HAS_NEW";
export const NOTIFICATION_SET_STATS = "NOTIFICATION_SET_STATS";
export const NOTIFICATION_SET_VIEW = "NOTIFICATION_SET_VIEW";

export type NotificationLoadingT = {
  type: "NOTIFICATION_LOADING";
  loading: boolean;
};
export type NotificationReceiveItemT = {
  type: "NOTIFICATION_RECEIVE_ITEM";
  item: PlatformNotificationT;
};
export type NotificationReceiveListActionT = {
  type: "NOTIFICATION_RECEIVE_LIST";
  pagination: CursorPaginationT;
  results: PlatformNotificationT[];
};
export type NotificationSetViewActionT = {
  type: "NOTIFICATION_SET_VIEW";
  view: ViewStateT;
};
export type NotificationActionT = NotificationReceiveItemT | NotificationReceiveListActionT;

const isLoading = (loading: boolean): NotificationLoadingT => ({
  type: NOTIFICATION_LOADING,
  loading,
});

const resetStats = (): Promise<unknown> =>
  apiRequest
    .patch("notifications-platform/reset-count/", {})
    .then(() => NotificationCountStore.setCount(0, false));

const receiveItem = (item: PlatformNotificationT): NotificationReceiveItemT => ({
  type: NOTIFICATION_RECEIVE_ITEM,
  item,
});

const receiveList = ({
  results,
  ...pagination
}: PlatformNotificationResultsT): NotificationReceiveListActionT => ({
  type: NOTIFICATION_RECEIVE_LIST,
  results,
  pagination,
});

const fetchList =
  (url: string) =>
  (dispatch: DispatchT): Promise<unknown> => {
    dispatch(isLoading(true));
    return apiRequest
      .getMemoized<PlatformNotificationResultsT>(url)
      .then(({ data }) => Promise.all([dispatch(isLoading(false)), dispatch(receiveList(data))]));
  };

const fetchView =
  (filters?: string) =>
  (dispatch: DispatchT): Promise<unknown> =>
    Promise.all([
      dispatch(fetchList(`notifications-platform/${filters ? `?filters=${filters}` : ""}`)),
      resetStats(),
    ]);

const fetchNextPage =
  () =>
  (dispatch: DispatchT, getState: () => StateT): Promise<unknown> => {
    const nextLink = getNextLink(getState());
    return nextLink ? dispatch(fetchList(nextLink)) : Promise.resolve();
  };

const setView =
  (view: ViewStateT) =>
  (dispatch: DispatchT): Promise<unknown> =>
    Promise.all([
      dispatch({ type: NOTIFICATION_SET_VIEW, view }),
      dispatch(fetchView(view === "unread" ? view : "")),
    ]);

const markRead =
  (id: number, read: boolean) =>
  (dispatch: DispatchT<NotificationReceiveItemT>): Promise<unknown> =>
    apiRequest
      .patch<PlatformNotificationT>(`notifications-platform/${id}/`, { read })
      .then(({ data }) =>
        Promise.all([
          dispatch(receiveItem(data)),
          NotificationCountStore.setCount(
            data.user.notification_count,
            data.user.has_new_notifications,
          ),
        ]),
      );

export default {
  fetchList,
  fetchNextPage,
  fetchView,
  isLoading,
  markRead,
  receiveItem,
  receiveList,
  resetStats,
  setView,
};
