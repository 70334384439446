import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FeedbackIcon from "@mui/icons-material/Feedback";
import SettingsIcon from "@mui/icons-material/Settings";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button, UserAvatar, Menu, MenuItem, MenuSection } from "@talentpair/quantic";
import { auth, userService } from "@talentpair/api";
import { londonLink, env, queenstownLink } from "@talentpair/env";
import { portal } from "@talentpair/portal";
import { displayFullName } from "@talentpair/entities/user";
import * as React from "react";
import FeedbackModal from "./FeedbackModal";

export type CandidateMenuProps = {
  desktop: boolean;
  candidateId: number;
};
export default function CandidateMenu({
  desktop,
  candidateId,
  ...props
}: CandidateMenuProps): React.ReactElement {
  const isCandidate = userService.isCandidate();
  return (
    <Menu
      button={
        <Button
          size="lg"
          variant="icon"
          icon={
            <>
              {userService.data && <UserAvatar user={userService.data} size="md" />}
              <ArrowDropDownIcon fontSize="inherit" />
            </>
          }
          className="p0"
        />
      }
      minWidth={320}
      MenuListProps={{ disablePadding: true }}
    >
      {userService.data ? (
        <MenuItem
          data-testid="profileBtn"
          component={isCandidate ? "a" : "li"}
          icon={<UserAvatar user={userService.data} ringed={false} />}
          href={isCandidate ? env.londonUrl(londonLink.capProfile(candidateId)) : undefined}
          {...props}
        >
          {displayFullName(userService.data, userService.data.email)}
        </MenuItem>
      ) : null}
      <MenuSection bordered>
        <MenuItem
          large
          icon={PersonIcon}
          component="a"
          id="profile"
          href={env.londonUrl(londonLink.capProfile(candidateId))}
        >
          {isCandidate ? "Your" : "Candidate"} profile
        </MenuItem>
        <MenuItem
          large
          icon={CalendarMonthIcon}
          component="a"
          id="calendar"
          href={env.londonUrl(londonLink.capCalendar(candidateId))}
        >
          Calendar
        </MenuItem>
        {isCandidate || userService.isCustomerSuccessUser() || userService.isDataScientist() ? (
          <MenuItem
            large
            icon={SettingsIcon}
            component="a"
            id="settings"
            href={env.londonUrl(londonLink.capSettings(candidateId))}
          >
            Settings
          </MenuItem>
        ) : null}
        {!desktop && isCandidate ? (
          <MenuItem
            large
            icon={AttachMoneyIcon}
            component="a"
            id="referFriends"
            href={env.londonUrl(londonLink.capReferFriends())}
          >
            Invite & earn
          </MenuItem>
        ) : null}
      </MenuSection>
      <MenuItem
        large
        icon={ListAltIcon}
        name="jobBoard"
        component="a"
        href={env.queenstownUrl(queenstownLink.jobBoard())}
      >
        Job board
      </MenuItem>
      <MenuSection bordered>
        <MenuItem
          large
          icon={FeedbackIcon}
          name="feedback"
          onClick={() => portal.modal(FeedbackModal)}
        >
          Feedback
        </MenuItem>
        <MenuItem large icon={LogoutIcon} name="logout" onClick={() => auth.logout()}>
          Log out
        </MenuItem>
      </MenuSection>
    </Menu>
  );
}
