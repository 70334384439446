import * as React from "react";
import { userService, useUser } from "@talentpair/api";
import { londonLink } from "@talentpair/env";
import { Redirect } from "@talentpair/routing";
import { LoginRedirect } from "../../../components/Redirects";

export const BillingRedirect = (): React.ReactElement | null => {
  useUser("info");
  return userService.loggedin ? (
    <Redirect from="/billing" exact to={londonLink.tapBilling(userService.orgId())} />
  ) : userService.loggedin === false ? (
    <LoginRedirect from="/billing" />
  ) : null;
};
