import * as React from "react";
import clsx from "clsx";
import { MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps, SvgIcon } from "@mui/material";
import { Typography } from "@talentpair/quantic";

export type MenuItemProps<D extends React.ElementType> = MuiMenuItemProps<D> & {
  icon?: typeof SvgIcon | React.ReactElement;
  large?: boolean;
};

export function MenuItem<D extends React.ElementType>({
  icon: Icon,
  children,
  large = false,
  className,
  ...props
}: MenuItemProps<D>): React.ReactElement {
  const renderedIcon =
    React.isValidElement(Icon) || !Icon ? (
      Icon
    ) : (
      <Icon fontSize="medium" className={large ? "mr2" : undefined} />
    );
  return (
    <MuiMenuItem {...props} className={clsx(large ? "px4 py3" : "px3 py2", className)}>
      {renderedIcon}
      <Typography variant="body1" className={Icon ? "ml3" : undefined}>
        {children}
      </Typography>
    </MuiMenuItem>
  );
}
