import { useTheme } from "@mui/material";
import clsx from "clsx";
import { DOMAttributes, ReactElement } from "react";

interface TextButtonLinkProps extends DOMAttributes<HTMLButtonElement & HTMLAnchorElement> {
  href?: string;
  className?: string;
  style?: Record<string, string | number>;
  inheritFontSize?: boolean;
  disabled?: boolean;
  download?: boolean;
}

export const TextButtonLink = ({
  className,
  style,
  inheritFontSize = true,
  disabled = false,
  ...props
}: TextButtonLinkProps): ReactElement => {
  const theme = useTheme();
  const sharedProps = {
    disabled,
    className: clsx("appearance-none border-none bg-none outline-none p0", className, {
      "font-size-inherit": inheritFontSize,
      "hover-underline pointer": !disabled,
    }),
    style: disabled ? style : { color: theme.palette.primary.main, ...style },
    ...props,
  };
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return props.href ? <a {...sharedProps} /> : <button type="button" {...sharedProps} />;
};
