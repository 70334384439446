import * as React from "react";
import { Button } from "@talentpair/quantic";
import { env } from "@talentpair/env";
import { useAuth } from "@talentpair/api";

export default function AuthButtons({
  signupOnly = false,
  variant = "white",
  ...props
}: {
  signupOnly?: boolean;
  style?: React.CSSProperties;
  variant?: "white" | "green";
}): React.ReactElement | null {
  const { state, isLoggedIn, logout } = useAuth();
  const color = variant === "green" ? "primary" : undefined;
  return state === "checking" ? null : (
    <div {...props}>
      {isLoggedIn ? (
        <Button variant="text" color={color} onClick={logout} data-testid="signoutBtn">
          Log out
        </Button>
      ) : (
        <>
          {signupOnly ? null : (
            <Button
              variant="text"
              color={color}
              href={env.londonUrl(`/signin?next=${encodeURIComponent(location.href)}`)}
              data-testid="signinBtn"
            >
              Log in
            </Button>
          )}
          <Button
            color={color}
            variant={variant === "white" ? "text" : "contained"}
            href={env.londonUrl("/signup")}
            data-testid="signupBtn"
          >
            Sign up
          </Button>
        </>
      )}
    </div>
  );
}
