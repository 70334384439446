import * as React from "react";
import { mixpanelHelper } from "@talentpair/tracking";
import { Link, LinkProps } from "@talentpair/quantic";
import { stripProtocol, externalLink } from "../../utils/string";

type PropsT = Exclude<LinkProps, "href"> & {
  href: string;
  text?: React.ReactNode;
};

const ExternalLink = ({ href, text = null, ...props }: PropsT): React.ReactElement => (
  <Link
    unsafe
    color="primary"
    href={externalLink(href)}
    target="_blank"
    onClick={(): void => mixpanelHelper.track("External Link", { href })}
    {...props}
  >
    {text || stripProtocol(href)}
  </Link>
);

export default ExternalLink;
