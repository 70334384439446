import { connect, DispatchT } from "@talentpair/redux";
import { userService } from "@talentpair/api";
import { FileWithProcessIdsT } from "@talentpair/types/entities/misc";
import PickAndUploadResume, {
  PickAndUploadResumeProps,
} from "../../../components/ResumeUpload/PickAndUploadResume";
import CandidateAction from "../../store/candidateActions";

type DispatchToPropsT = Pick<PickAndUploadResumeProps, "onStateChanged">;

type OwnPropsT = {
  candidateId: number;
} & Omit<PickAndUploadResumeProps, "getUuids" | "uploadUrl">;

export type CapResumeUploadPropsT = OwnPropsT & DispatchToPropsT;

const CapResumeUpload = ({ candidateId, ...props }: CapResumeUploadPropsT): React.ReactElement => (
  <PickAndUploadResume
    uploadUrl={`candidates-cap/${candidateId}`}
    getUuids={(data: FileWithProcessIdsT) =>
      data.background_process_ids
        .filter(({ type }) => type === "textkernel_extract")
        .map(({ uuid }) => uuid)
    }
    {...props}
  />
);

const mapDispatchToProps = (
  dispatch: DispatchT,
  { candidateId, onStateChanged }: OwnPropsT,
): DispatchToPropsT => ({
  onStateChanged: (state) =>
    state.pane !== "success"
      ? onStateChanged(state)
      : Promise.all([
          dispatch(CandidateAction.fetchDetail(candidateId)),
          userService.loadData(),
        ]).then(() => onStateChanged(state)),
});

export default connect(undefined, mapDispatchToProps)(CapResumeUpload);
export const _test = { CapResumeUpload, mapDispatchToProps };
