import { astralLink, env, londonLink } from "@talentpair/env";
import * as React from "react";
import { withRouter, RouteComponentProps } from "@talentpair/routing";
import Form from "kyoto/components/Forms/Formik/Form";
import NavSearch from "kyoto/components/Forms/Formik/fields/EntitySearch/NavSearch";
import { NavSearchResultT } from "@talentpair/types/navSearch";

const INPUT_CONTAINER_HEIGHT = 91;

export type PropsT = RouteComponentProps & {
  onSubmit?: () => unknown;
};

const NavSearchForm = ({ onSubmit, history }: PropsT): React.ReactElement => (
  <Form
    initialValues={{ query: "" }}
    className="relative"
    render={() => (
      <NavSearch
        autoFocus
        fullHeight
        alwaysRenderSuggestions
        selectOnTab={false}
        showErrors={false}
        margin="none"
        placeholder="Jump to..."
        classes={{
          inputContainer: "sticky col-12 top-0 left-0 bg-white z1",
        }}
        FormControlProps={{
          classes: { root: "static" },
          style: { minHeight: INPUT_CONTAINER_HEIGHT },
        }}
        showSearchIcon
        showClearButton
        submitOnChange={({ val }: { val: NavSearchResultT | null | undefined }) => {
          if (val) {
            if (val.platform_status != null || val.type === "contact") {
              const nextUrl = londonLink.toProfile(val.type, val.id, val.extra_id);
              if (nextUrl !== window.location.pathname) {
                history.push(nextUrl);
              }
              if (onSubmit) onSubmit();
            } else {
              const id = val.type === "job" ? val.id : val.extra_id || val.id;
              window.location.assign(env.gettysburgUrl(astralLink.toProfile(val.type, id)));
            }
          }
          return Promise.resolve();
        }}
      />
    )}
  />
);

export default withRouter(NavSearchForm);

export const _test = { NavSearchForm };
