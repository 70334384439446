// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck - most of this code is directly from inspectlet
import { userService } from "@talentpair/api";
import { env } from "@talentpair/env";

export function loadInspectlet(): void {
  // Register Inspectlet for Hiring Managers on prod
  if (env.isProduction() && userService.data && userService.data.enable_inspectlet) {
    /* eslint-disable */
    window.__insp = window.__insp || [];
    window.__insp.push(["wid", 1414936687]);
    window.__insp.push([
      "identify",
      `${userService.id()}${userService.data ? ` - ${userService.data.username}` : ""}`,
    ]);
    (function () {
      function ldinsp() {
        if (typeof window.__inspld != "undefined") return;
        window.__inspld = 1;
        var insp = document.createElement("script");
        insp.type = "text/javascript";
        insp.async = true;
        insp.id = "inspsync";
        insp.src =
          ("https:" == document.location.protocol ? "https" : "http") +
          "://cdn.inspectlet.com/inspectlet.js";
        var x = document.getElementsByTagName("script")[0];
        if (x.parentNode) x.parentNode.insertBefore(insp, x);
      }
      setTimeout(ldinsp, 500);
      document.readyState != "complete"
        ? window.attachEvent
          ? window.attachEvent("onload", ldinsp)
          : window.addEventListener("load", ldinsp, false)
        : ldinsp();
    })();
    /* eslint-enable */
  }
}
