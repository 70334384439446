import * as React from "react";
import { RouteComponentProps, withRouter } from "@talentpair/routing";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FeedbackIcon from "@mui/icons-material/Feedback";
import PaymentIcon from "@mui/icons-material/Payment";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { auth, userService } from "@talentpair/api";
import { londonLink } from "@talentpair/env";
import { Box } from "@talentpair/quantic";
import { portal } from "@talentpair/portal";
import FeedbackModal from "kyoto/components/FeedbackModal";
import OnboardingTapNavigationUi from "../../../components/Onboarding/tours/OnboardingTapNavigationUi";
import { useOrgBillingAccessControls } from "../Billing/useOrgBillingAccessControls";
import { AccountOwnerNavLink } from "./AccountOwnerNavLink";
import { NavLink } from "./NavLink";

function BaseSideNavigation({ match }: RouteComponentProps<{ org: string }>): React.ReactElement {
  const { canSeeBilling } = useOrgBillingAccessControls(false);
  const orgId = match.params.org ? +match.params.org : userService.orgId() || 0;
  return (
    <>
      <Box
        className="border-right border-grey-300 z1 fixed fixed-under-appbar left-0 bottom-0 overflow-auto relative flex-none"
        style={{ width: 57 }}
        variant="column"
        layout="stretch-between"
        background="grey.50"
      >
        <Box variant="column" layout="stretch-start" flex="auto">
          <Box variant="column" layout="stretch-start" className="border-bottom border-grey-300">
            <NavLink
              data-testid="jobsBtn"
              title="Jobs"
              to={londonLink.tapJobList(orgId)}
              icon={WorkIcon}
              match={match}
            />
            <OnboardingTapNavigationUi step={1}>
              <NavLink
                data-testid="candidatesBtn"
                title="Candidates"
                to={londonLink.tapCandidates(orgId)}
                icon={PersonIcon}
                match={match}
              />
            </OnboardingTapNavigationUi>
            {userService.isRecruiter() ? null : (
              <NavLink
                data-testid="calendarBtn"
                title="Calendar"
                to={londonLink.tapCalendar(orgId, userService.data?.id || 0)}
                icon={CalendarMonthIcon}
                match={match}
              />
            )}
            <NavLink
              data-testid="reportsBtn"
              title="Reports"
              to={londonLink.tapReports(orgId)}
              icon={ShowChartIcon}
              match={match}
            />
          </Box>
          <Box variant="column" layout="stretch-start" className="border-bottom border-grey-300">
            <NavLink
              title="Company profile"
              to={londonLink.tapOrgProfile(orgId)}
              icon={BusinessIcon}
              data-testid="companyProfileBtn"
              match={match}
            />
            {userService.isOrgAdmin() ? (
              <NavLink
                title="Hiring team"
                to={londonLink.tapOrgSettings(orgId)}
                icon={GroupsIcon}
                data-testid="hiringTeamBtn"
                match={match}
              />
            ) : null}
            <NavLink
              title="Job board"
              to={londonLink.tapManageJobBoard(orgId)}
              icon={ListAltIcon}
              data-testid="jobBoardBtn"
              match={match}
            />
          </Box>
          <OnboardingTapNavigationUi step={0}>
            <Box variant="column" layout="stretch-start" className="border-bottom border-grey-300">
              {canSeeBilling ? (
                <NavLink
                  title="Billing"
                  data-testid="billingBtn"
                  to={londonLink.tapBilling(orgId)}
                  icon={PaymentIcon}
                  match={match}
                />
              ) : null}
              <NavLink
                title="Feedback"
                data-testid="feedbackBtn"
                to={() => portal.modal(FeedbackModal)}
                icon={FeedbackIcon}
                match={match}
              />
              {userService.isRecruiter() ? null : (
                <NavLink
                  title="Settings"
                  data-testid="settingsBtn"
                  to={londonLink.tapSettings(orgId, userService.data?.id || 0)}
                  icon={SettingsIcon}
                  match={match}
                />
              )}
              <NavLink
                title="Log out"
                data-testid="logoutBtn"
                to={() => auth.logout()}
                icon={LogoutIcon}
                match={match}
              />
            </Box>
          </OnboardingTapNavigationUi>
        </Box>
        <AccountOwnerNavLink path={match.path} orgId={orgId} />
      </Box>
      <Box className="flex-none" style={{ width: 57 }} variant="column" layout="stretch-between" />
    </>
  );
}

export const SideNavigation = withRouter(BaseSideNavigation);
export const _test = { BaseSideNavigation };
