// @flow
import lodash from "lodash";
import { type TapMicroUserT } from "@talentpair/types/tap/entities/user";
import { replaceOrAddArrayItem, splice } from "kyoto/utils/array";
import type { TapJobReduxT } from "@talentpair/types/tap/entities/job";
import {
  JOB_CREATE_SUCCESS,
  JOB_LIST_ITEM_DELETE_SUCCESS,
  JOB_LIST_ITEM_UPDATE_SUCCESS,
  JOB_RECEIVE_ADMIN_DETAIL,
  JOB_RECEIVE_DETAIL,
  JOB_RECEIVE_TEAM_MEMBER,
  JOB_UPDATE_SUCCESS,
  type JobReceiveActionT,
} from "./jobActions";
import { mergeListDetail } from "./storeUtils";

export type JobsReduxT = { [id: number]: ?TapJobReduxT, ... };

const hiringTeam = (curJob: ?TapJobReduxT, member: TapMicroUserT) => {
  const team = curJob && curJob.hiring_team ? curJob.hiring_team : [];
  const index = team.findIndex((m) => m.id === member.id);
  return index !== -1
    ? [...team.slice(0, index), member, ...team.slice(index + 1)]
    : [...team, member];
};

const jobs = (state: JobsReduxT = {}, action: JobReceiveActionT) => {
  switch (action.type) {
    case JOB_RECEIVE_DETAIL:
    case JOB_RECEIVE_ADMIN_DETAIL:
    case JOB_CREATE_SUCCESS:
    case JOB_UPDATE_SUCCESS:
      return { ...state, [action.job.id]: mergeListDetail(state, action.job) };

    case JOB_LIST_ITEM_UPDATE_SUCCESS: {
      const { jobId, field, item } = action;
      const job = state[jobId];
      let list = replaceOrAddArrayItem(job && Array.isArray(job[field]) ? job[field] : [], item);
      if ("order" in action.item) list = lodash.sortBy(list, "order");
      return {
        ...state,
        [jobId]: {
          ...job,
          [field]: list,
        },
      };
    }

    case JOB_LIST_ITEM_DELETE_SUCCESS: {
      const { jobId, field, item } = action;
      const job = state[jobId];
      const list = job ? job[field] : [];
      const index = list.findIndex(({ id }) => id === item.id);
      if (index < 0) return state;
      return {
        ...state,
        [jobId]: {
          ...job,
          [field]: splice(list, index),
        },
      };
    }

    case JOB_RECEIVE_TEAM_MEMBER:
      return {
        ...state,
        [action.jobId]: {
          ...state[action.jobId],
          hiring_team: hiringTeam(state[action.jobId], action.member),
        },
      };

    default:
      (action: empty);
      return state;
  }
};

export default jobs;

export const _test = { hiringTeam };
