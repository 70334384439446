import { portal, ActionButton } from "@talentpair/portal";

export default function promptAppRefresh(): void {
  portal.snackbar({
    action: <ActionButton onClick={() => location.reload()}>UPDATE</ActionButton>,
    key: "UPDATE SNACKBAR", // so that one doesn't get added every 10 seconds
    message: "A new version of Talentpair is available.",
    type: "info",
  });
}
