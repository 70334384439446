import { apiRequest } from "@talentpair/api";
import { sentry } from "@talentpair/sentry";
import { DispatchT } from "@talentpair/redux";
import type {
  CommentT,
  UserCommentT,
  PlatformActivityFeedItemT,
  PlatformActivityFeedResultsT,
  AvailabilityRequestT,
} from "@talentpair/types/activity";
import type { CursorPaginationT, TimeSlotT } from "@talentpair/types/misc";
import localStorageService from "kyoto/services/localStorageService";
import { getApp } from "../../../routeConfig";

export const ACTIVITY_LOADING = "ACTIVITY_LOADING";
export const ACTIVITY_RECEIVE_LAST_FETCH = "ACTIVITY_RECEIVE_LAST_FETCH";
export const ACTIVITY_RECEIVE_LATEST = "ACTIVITY_RECEIVE_LATEST";
export const ACTIVITY_RECEIVE_ITEM = "ACTIVITY_RECEIVE_ITEM";
export const ACTIVITY_REMOVE_ITEM = "ACTIVITY_REMOVE_ITEM";
export const ACTIVITY_RECEIVE_LIST = "ACTIVITY_RECEIVE_LIST";
export type ActivityFeedTypeT = "pair_cap" | "pair_tap" | "user" | "pair_shared";

const entityEndpoint = (type: ActivityFeedTypeT): string =>
  `${["pair_cap", "pair_tap", "pair_shared"].includes(type) ? "pair" : type}s-${getApp()}`;

export type ActivityLoadingT = {
  type: "ACTIVITY_LOADING";
  activityType: ActivityFeedTypeT;
  id: number;
  loading: boolean;
};
export type ActivityReceiveLastFetchT = {
  type: "ACTIVITY_RECEIVE_LAST_FETCH";
  activityType: ActivityFeedTypeT;
  id: number;
  lastFetched: string;
};
export type ActivityReceiveLatestT = {
  type: "ACTIVITY_RECEIVE_LATEST";
  activityType: ActivityFeedTypeT;
  id: number;
  latestActivity: string;
};
type ActivityReceiveItemActionT = {
  type: "ACTIVITY_RECEIVE_ITEM";
  activityType: ActivityFeedTypeT;
  id: number;
  item: PlatformActivityFeedItemT;
};
type ActivityRemoveItemActionT = {
  type: "ACTIVITY_REMOVE_ITEM";
  activityType: ActivityFeedTypeT;
  id: number;
  itemId: number;
};
export type ActivityReceiveListActionT = {
  type: "ACTIVITY_RECEIVE_LIST";
  activityType: ActivityFeedTypeT;
  id: number;
  pagination: CursorPaginationT;
  results: PlatformActivityFeedItemT[];
};
export type ActivityItemsActionT =
  | ActivityReceiveItemActionT
  | ActivityRemoveItemActionT
  | ActivityReceiveListActionT;
export type ActivityActionT =
  | ActivityLoadingT
  | ActivityReceiveLastFetchT
  | ActivityReceiveLatestT
  | ActivityReceiveItemActionT
  | ActivityRemoveItemActionT
  | ActivityReceiveListActionT;

const isLoading = (
  activityType: ActivityFeedTypeT,
  id: number,
  loading: boolean,
): ActivityLoadingT => ({
  type: ACTIVITY_LOADING,
  activityType,
  id,
  loading,
});

const receiveLastFetched = (
  activityType: ActivityFeedTypeT,
  id: number,
  lastFetched: string,
): ActivityReceiveLastFetchT => ({
  type: ACTIVITY_RECEIVE_LAST_FETCH,
  activityType,
  id,
  lastFetched,
});

const receiveLatestActivity = (
  activityType: ActivityFeedTypeT,
  id: number,
  latestActivity: string,
): ActivityReceiveLatestT => ({
  type: ACTIVITY_RECEIVE_LATEST,
  activityType,
  id,
  latestActivity,
});

const receiveItem = (
  activityType: ActivityFeedTypeT,
  id: number,
  item: PlatformActivityFeedItemT,
): ActivityReceiveItemActionT => ({
  type: ACTIVITY_RECEIVE_ITEM,
  activityType,
  id,
  item,
});

const removeItem = (
  activityType: ActivityFeedTypeT,
  id: number,
  itemId: number,
): ActivityRemoveItemActionT => ({
  type: ACTIVITY_REMOVE_ITEM,
  activityType,
  id,
  itemId,
});

const receiveList = (
  activityType: ActivityFeedTypeT,
  id: number,
  { results, ...pagination }: PlatformActivityFeedResultsT,
): ActivityReceiveListActionT => ({
  type: ACTIVITY_RECEIVE_LIST,
  activityType,
  id,
  results,
  pagination,
});

const fetchTimestamp = (type: ActivityFeedTypeT, id: number): Promise<string> =>
  apiRequest
    .getMemoized<{ last_updated: string }>(
      `${entityEndpoint(type)}/${id}/${type === "pair_shared" ? "shared-" : ""}activity-timestamp/`,
    )
    .then(({ data: { last_updated } }) => last_updated);

const fetchLastFetched =
  (type: ActivityFeedTypeT, id: number) =>
  (dispatch: DispatchT): Promise<unknown> =>
    fetchTimestamp(type, id).then((lastFetched) =>
      dispatch(receiveLastFetched(type, id, lastFetched)),
    );

const fetchLatestActivity =
  (type: ActivityFeedTypeT, id: number) =>
  (dispatch: DispatchT): Promise<unknown> =>
    fetchTimestamp(type, id).then((latestActivity) => {
      dispatch(receiveLatestActivity(type, id, latestActivity));
      return latestActivity;
    });

const fetchList =
  (type: ActivityFeedTypeT, id: number, url: string) =>
  (dispatch: DispatchT): Promise<unknown> => {
    dispatch(isLoading(type, id, true));
    return apiRequest
      .getMemoized<PlatformActivityFeedResultsT>(url)
      .then(({ data }) =>
        Promise.all([dispatch(isLoading(type, id, false)), dispatch(receiveList(type, id, data))]),
      );
  };

const fetchFirstPage =
  (type: ActivityFeedTypeT, id: number) =>
  (dispatch: DispatchT): Promise<unknown> =>
    Promise.all([
      dispatch(fetchLastFetched(type, id)),
      dispatch(
        fetchList(
          type,
          id,
          `${entityEndpoint(type)}/${id}/${type === "pair_shared" ? "shared-" : ""}activity/`,
        ),
      ),
    ]);

const saveComment =
  (type: ActivityFeedTypeT, id: number, text: string) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest
      .post<{ activity: PlatformActivityFeedItemT }>(
        `${entityEndpoint(type)}/${id}/${type === "pair_shared" ? "shared-" : ""}comment/`,
        {
          text,
        },
      )
      .then(({ data: { activity } }) => dispatch(receiveItem(type, id, activity)))
      .catch(sentry.error);

const deleteComment =
  (type: ActivityFeedTypeT, id: number, item: CommentT | UserCommentT) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest
      .delete(
        `${entityEndpoint(type)}/${id}/${type === "pair_shared" ? "shared-" : ""}comment/${
          item.instance.id
        }/`,
      )
      .then(() => dispatch(removeItem(type, id, item.id)))
      .catch(sentry.error);

const editComment =
  (type: ActivityFeedTypeT, id: number, item: CommentT | UserCommentT, text: string) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest
      .patch<{ activity: CommentT | UserCommentT }>(
        `${entityEndpoint(type)}/${id}/${type === "pair_shared" ? "shared-" : ""}comment/${
          item.instance.id
        }/`,
        { text },
      )
      .then(({ data: { activity } }) => dispatch(receiveItem(type, id, activity)))
      .catch(sentry.error);

const receiveAvailability = (activity: AvailabilityRequestT): ActivityReceiveItemActionT => {
  const { id, pair } = activity.instance;
  localStorageService.setItem(`cap.pair.${pair.id}.availability.${id}`, true);
  return receiveItem("pair_cap", pair.id, activity);
};

const addAvailability =
  (times: TimeSlotT[], pairId: number) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest
      .patch<{ activity: AvailabilityRequestT }>(`pairs-cap/candidate-availability/${pairId}/`, {
        times,
      })
      .then(({ data: { activity } }) => dispatch(receiveAvailability(activity)))
      .catch(sentry.error);

const updateAvailability =
  (times: TimeSlotT[], activityItem: AvailabilityRequestT) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest
      .patch(`pairs-cap/candidate-availability/${activityItem.instance.pair.id}/`, {
        times,
      })
      .then(() =>
        dispatch(
          receiveAvailability({
            ...activityItem,
            instance: { ...activityItem.instance, times },
          }),
        ),
      )
      .catch(sentry.error);

export default {
  fetchFirstPage,
  fetchLastFetched,
  fetchLatestActivity,
  fetchList,
  isLoading,
  receiveItem,
  removeItem,
  receiveLastFetched,
  receiveLatestActivity,
  receiveList,
  saveComment,
  editComment,
  deleteComment,
  addAvailability,
  updateAvailability,
};
