import * as React from "react";
import clsx from "clsx";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  FormControlProps,
  FormHelperTextProps,
  FormLabelProps,
  InputLabelProps,
} from "@mui/material";
import RequiredLabel from "./RequiredLabel";

/*

LabeledInput is our base component for wrapping a form field. It handles displaying of the field label, helper text, error text

*/

export interface FieldLabelProps extends Omit<FormControlProps, "error"> {
  name: string;
  children: React.ReactNode;
  disabled?: boolean;
  group?: boolean;
  helperText?: React.ReactNode;
  label?: React.ReactNode;
  className?: undefined | string;
  inlineLabel?: boolean;
  error?: string | undefined | null;
  hasFocus?: boolean;
  touched?: boolean;
  FormHelperTextProps?: FormHelperTextProps;
  FormLabelProps?: FormLabelProps<"legend">;
  InputLabelProps?: InputLabelProps;
}

function Inline({ children }: { children: React.ReactNode }): React.ReactElement {
  return <div className="flex-auto">{children}</div>;
}

function Block({ children }: { children: React.ReactNode }): React.ReactElement {
  return <>{children}</>;
}

export default function FieldLabel({
  name,
  children,
  inlineLabel = false,
  fullWidth = true,
  disabled = false,
  error = "",
  group = false,
  label = null,
  hasFocus = false,
  touched = false,
  classes = undefined,
  margin = "normal",
  required = false,
  FormHelperTextProps: formHelperTextProps = {},
  FormLabelProps: formLabelProps = {},
  InputLabelProps: inputLabelProps = {},
  helperText: defaultHelperText = "",
  ...props
}: FieldLabelProps): React.ReactElement {
  const helperText = touched && error ? error : defaultHelperText;
  const helperTextId = helperText ? `${name}-helper-text` : undefined;

  const Wrapper = inlineLabel ? Inline : Block;

  const labelContents =
    !label || !required ? (
      label
    ) : (
      <RequiredLabel error={error} touched={touched} focused={hasFocus}>
        {label}
      </RequiredLabel>
    );

  return (
    <FormControl
      aria-describedby={helperTextId}
      component={group ? "fieldset" : "div"}
      disabled={disabled}
      error={!!error}
      fullWidth={fullWidth}
      margin={margin}
      variant="standard"
      {...props}
      classes={inlineLabel ? { ...classes, root: "flex-row" } : classes}
    >
      {labelContents &&
        (group ? (
          <FormLabel component="legend" {...formLabelProps}>
            {labelContents}
          </FormLabel>
        ) : (
          <InputLabel htmlFor={name} {...inputLabelProps} focused={hasFocus}>
            {labelContents}
          </InputLabel>
        ))}
      <Wrapper>
        {children}
        {!!helperText && (
          <FormHelperText
            id={helperTextId}
            className={clsx("pl1", { "fg-error": error && touched })}
            {...formHelperTextProps}
          >
            {helperText}
          </FormHelperText>
        )}
      </Wrapper>
    </FormControl>
  );
}
