import * as React from "react";
import { withStyles } from "@mui/styles";
import { Pane, Item, Title, Actions, SubmitButton } from "@talentpair/quantic";
import { londonLink } from "@talentpair/env";
import { Modal as MuiModal, PortalPropsT } from "@talentpair/portal";
import { withRouter, RouteComponentProps } from "@talentpair/routing";
import resumeSvg from "../../img/candidate-profile-with-resume.svg";
import track from "../../services/track";
import ResumeUpload from "./Resume/CapResumeUpload";

const Modal = withStyles({ paper: { "@media (min-width:900px)": { minHeight: 588 } } })(MuiModal);

type PageT = "Welcome" | "Upload" | "Success";

type PropsT = RouteComponentProps &
  PortalPropsT & { candidateId: number; onSubmitSuccess?: () => unknown };
type StateT = {
  blockClose: boolean;
  page: PageT;
};

function title(page: PageT): string {
  switch (page) {
    case "Upload":
      return "Choose your file";
    case "Success":
      return "Success";
    default:
      return "Finish your profile";
  }
}

class CreateProfileModal extends React.Component<PropsT, StateT> {
  state = { blockClose: false, page: "Welcome" as PageT };

  nav = (page: PageT): void => {
    this.setState({ page });
    track.signup({ action: `Nav - ${page}` });
  };

  isProfilePage = (): boolean => {
    const { candidateId, location } = this.props;
    return location.pathname === londonLink.capProfile(candidateId);
  };

  redirectAndClose = (): void => {
    const { candidateId, history, onClose, onSubmitSuccess } = this.props;
    // navigate to profile if not already there
    if (!this.isProfilePage()) history.push(londonLink.capProfile(candidateId));
    if (onSubmitSuccess) onSubmitSuccess();
    onClose();
  };

  closeIfOnProfile(): void {
    const { onSubmitSuccess, onClose } = this.props;
    if (this.isProfilePage()) {
      if (onSubmitSuccess) onSubmitSuccess();
      onClose();
    }
  }

  render(): React.ReactNode {
    const {
      candidateId,
      history,
      location,
      match,
      onClose,
      staticContext,
      onSubmitSuccess,
      ...props
    } = this.props;
    const { blockClose, page } = this.state;
    return (
      <Modal
        data-testid="createProfileModal"
        classes={{ paper: "md-col-12" }}
        closeButton={!blockClose}
        DialogContentProps={{ className: "flex flex-column" }}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => {
          if (onSubmitSuccess) onSubmitSuccess();
          return onClose();
        }}
        responsive
        title={title(page)}
        {...props}
      >
        {page === "Welcome" ? (
          <Pane>
            <Item>
              <img src={resumeSvg} alt="Finish your profile" />
            </Item>
            <Item>
              <Title>Upload your resume to finish creating your profile.</Title>
            </Item>
            <Actions>
              <SubmitButton onClick={() => this.nav("Upload")}>Upload your resume</SubmitButton>
            </Actions>
          </Pane>
        ) : (
          <ResumeUpload
            candidateId={candidateId}
            forceParse
            canLinkGoogleDoc
            onStateChanged={(state) => {
              if (state.pane === "success") {
                track.signup({ action: "Upload File", parse: state.parse });
                track.signup({ action: `Nav - ${page}` });
                this.setState({ page: "Success", blockClose: false });
                this.closeIfOnProfile();
              } else if (state.pane === "processing") {
                track.signup({ action: "Nav - processing" });
                this.setState({ blockClose: true });
              } else if (this.state.blockClose) {
                // we're not processing a resume, so let's allow the user to close the modal again
                this.setState({ blockClose: false });
              }
            }}
            render={(state) =>
              state.pane === "success" ? (
                <>
                  <Item>
                    <Title>We&apos;re able to parse your resume and create your profile.</Title>
                  </Item>
                  <Actions>
                    <SubmitButton onClick={this.redirectAndClose}>Go to your profile</SubmitButton>
                  </Actions>
                </>
              ) : null
            }
          />
        )}
      </Modal>
    );
  }
}

export default withRouter(CreateProfileModal);

export const _test = { CreateProfileModal, title };
