import * as React from "react";
import clsx from "clsx";
import { SpacingUnitsT } from "../theme/spacing";
import { Box } from "../atoms/Box";
import { OutOfBusinessBadge } from "./OutOfBusinessBadge";
import { OrgTpBadge } from "./OrgTpBadge";
import { OrgPipelineBadge } from "./OrgPipelineBadge";
import { PlanBadge, PlanBadgeTypeT } from "./PlanBadge";

interface OrgT {
  id: number;
  out_of_business?: boolean;
  is_on_platform?: boolean;
  pipeline_state?: string | { name: string };
  active_pricing_plan_type?: null | PlanBadgeTypeT;
}

export interface OrgBadgesProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "color"> {
  org: OrgT;
  noLink?: boolean;
  noWrap?: boolean;
  noPipelineBadge?: boolean;
  space?: SpacingUnitsT;
  extraBadges?: React.ReactNode[];
  classNames?: {
    wrapper?: string;
    badges?: string;
  };
}

export function OrgBadges({
  org,
  noLink = false,
  noWrap = false,
  noPipelineBadge = false,
  space = 1,
  classNames,
  extraBadges = [],
  ...props
}: OrgBadgesProps): React.ReactElement | null {
  const badges: React.ReactNode[] = [];

  if (org.is_on_platform)
    badges.push(
      <OrgTpBadge org={{ id: org.id }} href={noLink ? "" : null} key="org-platform-badge" />,
    );
  else if (org.out_of_business) {
    badges.push(<OutOfBusinessBadge className="" key="org-oob-badge" />);
  }

  if (org.active_pricing_plan_type) {
    badges.push(<PlanBadge key="pp" plan={org.active_pricing_plan_type} />);
  } else if (!noPipelineBadge) {
    const pipelineBadge = <OrgPipelineBadge key="org-pipeline-badge" org={org} noLink={noLink} />;
    if (pipelineBadge) badges.push(pipelineBadge);
  }

  if (extraBadges.length) badges.push(...extraBadges);

  return badges.length ? (
    <Box
      {...props}
      wrap={noWrap ? "nowrap" : "wrap"}
      variant="row"
      layout="center-start"
      space={space}
      data-testid={`org-${org.id}-Badges`}
      className={clsx(classNames?.wrapper)}
    >
      {badges}
    </Box>
  ) : null;
}
