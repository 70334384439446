import { ListItemAvatar } from "@mui/material";
import { useScrollIntoView, OrgAvatar } from "@talentpair/quantic";
import { CapPairReduxT } from "@talentpair/types/cap/entities/pair";
import { ListItem, ListItemPropsT, ListItemText } from "kyoto/components/ui/List";
import { getRole } from "@talentpair/entities/job";
import { displayMultipleAddresses } from "kyoto/utils/entities/location";
import { displayCapJobRemoteOption } from "kyoto/utils/entities/misc";
import Badge from "../../components/ListItemNotificationBadge";
import pairService from "../../services/pair";

type PairListItemTextPropsT = {
  pair: CapPairReduxT;
  timestamp?: boolean;
  className?: string | undefined;
};

const PairListItemText = ({
  pair: { job, last_cap_activity },
  timestamp = true,
  ...props
}: PairListItemTextPropsT): React.ReactElement => {
  const { addresses, remote, organization } = job;
  const location = displayMultipleAddresses(addresses);
  return (
    <ListItemText
      primary={getRole(job)}
      primaryTypographyProps={{ classes: { root: "line-height-1_3" } }}
      secondaryTypographyProps={{ classes: { root: "line-height-1_3" } }}
      secondary={
        <span className="block">
          <span className="block">{organization.name}</span>
          <span className="block">
            {location}
            {location && remote ? <span> &middot; </span> : null}
            {remote ? displayCapJobRemoteOption(remote) : null}
          </span>
        </span>
      }
      timestamp={timestamp ? last_cap_activity : null}
      {...props}
    />
  );
};

type PropsT = {
  pair: CapPairReduxT;
  badge?: boolean;
  ListItemTextProps?: null | undefined | Partial<PairListItemTextPropsT>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Omit<ListItemPropsT<React.ElementType<any>>, "style">;
const PairListItem = ({
  badge = false,
  pair,
  ListItemTextProps,
  ...props
}: PropsT): React.ReactElement => {
  const el = useScrollIntoView(props.selected);
  const { job, unread_notification_count, unread_shared_notification_count } = pair;
  const { color, icon: Icon } = pairService.capIconConfig(pair);
  const pairIcon = <Icon classes={{ root: color }} />;
  return (
    <ListItem
      data-testid={badge ? `job-${job.id}` : undefined}
      style={{ height: 69 }}
      {...props}
      ref={el}
    >
      <ListItemAvatar>
        <OrgAvatar org={job.organization} />
      </ListItemAvatar>
      <PairListItemText pair={pair} {...ListItemTextProps} className="mr3" />
      {badge && unread_notification_count ? (
        <Badge badgeContent={unread_notification_count}>{pairIcon}</Badge>
      ) : badge && unread_shared_notification_count ? (
        <Badge badgeContent={unread_shared_notification_count}>{pairIcon}</Badge>
      ) : (
        pairIcon
      )}
    </ListItem>
  );
};

export default PairListItem;
