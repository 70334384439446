// @flow
import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { OrgUserProfileMeta } from "./containers/OrgUserProfileHeader";
import { SideNavigation } from "../components/Navigation/SideNavigation";
import { OrgSettings } from "../OrgSettings";

// $FlowFixMe
export const OrgUserProfile = React.lazy(() => import("./containers/OrgUserProfilePage"));

export const OrgUserProfilePage = page({
  desktop: OrgUserProfile,
  mobile: OrgUserProfile,
  desktopSidenav: SideNavigation,
  sidebarOne: OrgSettings,
  header: TapHeader,
  meta: OrgUserProfileMeta,
});
