import * as React from "react";
import lodash from "lodash";
import { FormikValues } from "formik";
import { FieldPropsT } from "@talentpair/types/formik";
import Field from "../Forms/Formik/Field";
import { createEventObject } from "../Forms/utils";
import Autocomplete from "./Autocomplete";
import AutocompleteMulti from "./AutocompleteMulti";
import { autocompleteErrorMsg } from "./helpers";
import { FetchRequestedT } from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @typescript-eslint/explicit-module-boundary-types
const validateSuggestion = (suggestion?: any): string | undefined =>
  typeof suggestion === "string" ? autocompleteErrorMsg : undefined;

export interface SingleOrMultiAutocompletePropsT {
  multiple?: boolean;
  canCreate?: boolean | ((query: string) => string);
  name: string;
  omitSelected?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface PropsT<V> extends SingleOrMultiAutocompletePropsT {
  onSuggestionsFetchRequested: FetchRequestedT;
  clearSuggestions: () => void;
  suggestions: V[];
}

function SingleOrMultiAutocomplete<V, FormValues extends FormikValues>({
  multiple,
  onSuggestionsFetchRequested,
  clearSuggestions,
  suggestions,
  ...props
}: PropsT<V>): React.ReactElement {
  const AutocompleteCmp = multiple ? AutocompleteMulti : Autocomplete;

  return (
    <Field
      validate={multiple ? undefined : validateSuggestion}
      parse={lodash.identity}
      format={lodash.identity}
      {...props}
      render={({ field: { onChange, ...field }, setWarning }: FieldPropsT<V, V, FormValues>) => (
        // @ts-expect-error - because of the withStyles() wrapping the Autocomplete component typescript loses the generic V
        <AutocompleteCmp<V>
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          clearSuggestions={clearSuggestions}
          suggestions={suggestions}
          onInvalidSelection={multiple ? () => setWarning(autocompleteErrorMsg) : null}
          onQueryCleared={multiple ? () => setWarning(null) : null}
          onChange={(value: V) => {
            if (multiple) setWarning(null);
            onChange(createEventObject(value));
          }}
          selectedValues={Array.isArray(field.value) ? field.value : undefined}
          {...props}
          {...field}
        />
      )}
    />
  );
}
SingleOrMultiAutocomplete.defaultProps = { multiple: false, canCreate: false, omitSelected: false };

export default SingleOrMultiAutocomplete;

export const _test = { validateSuggestion };
