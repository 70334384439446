import { combineReducers } from "@talentpair/redux";
import { CursorPaginationT } from "@talentpair/types/misc";
import { NotificationT, NotificationTypeT } from "@talentpair/types/notifications";
import { SmallUserT } from "@talentpair/types/entities/user";
import { uniq } from "kyoto/utils/array";
import {
  NOTIFICATION_LOADING,
  NOTIFICATION_RECEIVE_ITEM,
  NOTIFICATION_RECEIVE_LIST,
  NOTIFICATION_SET_VIEW,
  NotificationActionT,
  NotificationLoadingT,
  NotificationReceiveListActionT,
  NotificationSetViewActionT,
} from "./notificationActions";

export type NotificationStoreT = {
  id: number;
  activity: number;
  read: boolean;
  desktoped: boolean;
  chore: number | null;
  type: NotificationTypeT;
  user: SmallUserT & {
    has_new_notifications: boolean;
    notification_count: number;
  };
  created: string;
};

type IdsStateT = number[];
export type ItemsStateT = Record<number, NotificationStoreT>;
export type ViewStateT = "unread" | "all";
export type NotificationsStateT = {
  ids: IdsStateT;
  items: ItemsStateT;
  loading: boolean;
  pagination: CursorPaginationT;
  view: ViewStateT;
};

const transformItem = (notification: NotificationT): NotificationStoreT => ({
  ...notification,
  activity: notification.activity.id,
});

function ids(state: IdsStateT = [], action: NotificationActionT): IdsStateT {
  switch (action.type) {
    case NOTIFICATION_RECEIVE_ITEM:
      return uniq([...state, action.item.id]);

    case NOTIFICATION_RECEIVE_LIST:
      return action.pagination.previous
        ? uniq([...state, ...action.results.map(({ id }) => id)])
        : action.results.map(({ id }) => id);

    default:
      return state;
  }
}

function items(state: ItemsStateT = {}, action: NotificationActionT): ItemsStateT {
  switch (action.type) {
    case NOTIFICATION_RECEIVE_ITEM:
      return { ...state, [action.item.id]: transformItem(action.item) };

    case NOTIFICATION_RECEIVE_LIST:
      return Object.assign(
        {},
        action.pagination.previous ? state : {},
        ...action.results.map((item) => ({ [item.id]: transformItem(item) })),
      );

    default:
      return state;
  }
}

function loading(state = false, action: NotificationLoadingT): boolean {
  switch (action.type) {
    case NOTIFICATION_LOADING:
      return action.loading;

    default:
      return state;
  }
}

const initPagination = { next: null, previous: null, page_size: 0 };
function pagination(
  state: CursorPaginationT = initPagination,
  action: NotificationReceiveListActionT,
): CursorPaginationT {
  switch (action.type) {
    case NOTIFICATION_RECEIVE_LIST:
      return action.pagination;

    default:
      return state;
  }
}

function view(state: ViewStateT = "all", action: NotificationSetViewActionT): ViewStateT {
  switch (action.type) {
    case NOTIFICATION_SET_VIEW:
      return action.view;

    default:
      return state;
  }
}

export default combineReducers({ ids, items, loading, pagination, view });

export const _test = { transformItem, ids, items, loading, pagination, view };
