import * as React from "react";
import { UserSubscriptionType } from "@talentpair/types/userService";
import { userService } from "../user";

export function useUser(events: UserSubscriptionType): number {
  const [state, setState] = React.useState(Number.MIN_VALUE);
  React.useEffect(
    () => userService.subscribe(events, () => setState((s) => s + Number.EPSILON)),
    [events],
  );
  return state;
}
