import * as React from "react";
import { RouteComponentProps } from "@talentpair/routing";
import { setTpCookie } from "./cookie";

// HOC meant to wrap an entire page in order to support grabbing a referral token and then pushing them onto the next page.
export type ReferralParams = { referral_code?: string };

export function createReferralRoute<P extends ReferralParams = ReferralParams>(
  path: string | ((params: P) => string),
): React.ComponentType<RouteComponentProps<P>> {
  return ({ history, location, match }) => {
    // RECORD THE REFERRAL!
    const referral_code = match.params.referral_code;
    React.useEffect(() => {
      if (!referral_code) throw new Error("Whoah, where's our referral code?");
      setTpCookie("REFERRAL_CODE", referral_code);
    }, [referral_code]);

    // AND REDIRECT!
    let url = typeof path === "function" ? path(match.params) : path;
    if (location.search) {
      url = `${url}${url.includes("?") ? "&" : "?"}${location.search}`;
    }
    const state = location.state;
    React.useEffect(() => history.replace(url, state), [history, url, state]);

    // Return nothing in the meantime
    return null;
  };
}
