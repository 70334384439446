import * as React from "react";
import clsx from "clsx";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { useScrollIntoView } from "@talentpair/quantic";

export type FormErrorProps = {
  className?: string;
  style?: React.CSSProperties;
  error: string;
};

export default function FormError({ className, style, error }: FormErrorProps): React.ReactElement {
  // In long forms errors can be rendered out of sight so we want to scroll them into view
  const ref = useScrollIntoView<HTMLDivElement>(error, "center");
  return (
    <div ref={ref} className={clsx("h5 bg-error white bold p3 flex", className)} style={style}>
      <ErrorOutline classes={{ root: "mr2" }} />
      <span className="my-auto">{error}</span>
    </div>
  );
}
