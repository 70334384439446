import { apiUrl } from "@talentpair/env";
import { mixpanelHelper } from "@talentpair/tracking";
import {
  TapSignupPageT,
  UserDataT,
  UserSettingsApiT,
  UserSettingsT,
  UIPreferencesT,
  UserSubscriptionType,
  UserEventType,
} from "@talentpair/types/userService";
import { ChoiceT } from "@talentpair/types/entities/misc";
import { objToQueryStr } from "@talentpair/utils";
import lodash from "lodash";
import { ArraySubmitOnChangePayloadT } from "kyoto/components/Forms/Formik/submitOnArrayChange";
import { FormikValues } from "formik";
import { apiRequest } from "./apiRequest";

// OAUTH CONSTANTS
const TENANT_ID = "ad3e27e8-aefc-4993-a3b0-8f724add0929";
const GOOGLE_AUTHORIZE_URI = "https://accounts.google.com/o/oauth2/auth";
const GOOGLE_SCOPE = [
  "https://www.googleapis.com/auth/gmail.readonly",
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/gmail.send",
].join(" ");
export const QUESTGROUPS_DOMAIN = "questgroups.com";

export const defaultUIPreferences: UIPreferencesT = {
  onboarding_cap_pair_actions: false,
  onboarding_cap_pair_list: false,
  onboarding_cap_profile: false,
  onboarding_tap_profile: false,
  onboarding_tap_candidate_pass: false,
  onboarding_tap_machine_pairs: false,
  onboarding_tap_navigation_ui: false,
  reminders_default_date: 1,
  add_full_intake_ideal_roles: false,
  viewed_upload_candidate: 0,
  interview_follow_up_reminder: true,
  tracked_sign_up: false,
  // default to empty string so people that have already created their profile don't get sent back to the create profile flow when they login
  // the create profile flow itself will set this appropriately when they get directly routed there
  cap_signup_page: "",
  tap_signup_page: "",
  requested_account_deletion: false,
};
export const defaultSettings: UserSettingsT = {
  blacklist: null,

  desktop_mention: true,
  desktop_reminder: true,
  desktop_tap: true,
  desktop_application: false,
  ats_notifications: false,
  mpc_notifications: false,
  desktop_recruiter_auto_submissions: false,
  desktop_machine_auto_submissions: false,
  desktop_interests: false,
  email_signature: "",

  email_add_to_platform: true,
  email_comment: true,
  email_interview: true,
  sms_mutual_matches: false,
  sms_job_send: false,
  email_mutual_matches: true,

  ui_preferences: defaultUIPreferences,
};

export function trackSettings(settings: UserSettingsApiT): void {
  lodash.forEach(settings, (value, key) => {
    if (key === "ui_preferences" && typeof value === "object") {
      trackSettings(lodash.mapKeys(value, (_: unknown, k: string) => `UI: ${k}`));
    } else {
      mixpanelHelper.track("Update User Settings", { setting: key, value });
    }
  });
}

// Handles all merges to provide default values if not available.
// (Using Lodash's assign because it's easier to flow type.)
export const mergeSettings = (...args: Array<UserSettingsApiT | UserSettingsT>): UserSettingsT =>
  lodash.assign({}, defaultSettings, ...args, {
    ui_preferences: lodash.assign(
      {},
      defaultUIPreferences,
      ...args.map(({ ui_preferences = {} }) => ui_preferences),
    ),
  });

export const validTapSignupPages: TapSignupPageT[] = [
  "Welcome",
  "SyncWithLinkedIn",
  "CreateManually",
  "Summary",
];

export class User {
  data: UserDataT | null = null;
  loggedin: boolean | null = null;
  settings: UserSettingsT = defaultSettings;
  dispatcher = new EventTarget();

  set(userData: UserDataT): UserDataT {
    this.data = userData;
    this.loggedin = true;
    this.dispatcher.dispatchEvent(new Event("info"));
    return this.data;
  }

  getInfo(): Promise<UserDataT> {
    return apiRequest.getMemoized<UserDataT>("users/api/info/").then(({ data }) => this.set(data));
  }

  // Load current settings from API and set in user service
  getSettings(): Promise<UserSettingsT> {
    return apiRequest
      .getMemoized<UserSettingsApiT>(`users/settings/${this.id()}/`)
      .then(({ data }) => {
        this.settings = mergeSettings(data);
        return this.settings;
      });
  }

  subscribe(events: UserSubscriptionType, cb: (type: UserEventType) => unknown): () => void {
    const types = events === "all" ? ["info", "settings"] : [events];
    const callback = (e: Event): unknown => cb(e.type as UserEventType);
    types.forEach((t) => this.dispatcher.addEventListener(t, callback));
    return () => {
      types.forEach((t) => this.dispatcher.removeEventListener(t, callback));
    };
  }

  // Makes sure all data and settings are loaded
  loadData = async (): Promise<UserDataT> => {
    try {
      // first load user info so we can get the user id
      const userData = await this.getInfo();
      // now load settings for the current user
      if (userData) await this.getSettings();
      return userData;
    } catch (err) {
      this.loggedin = false;
      this.dispatcher.dispatchEvent(new Event("info"));
      throw err;
    }
  };

  getAnalyticsData(): Record<string, unknown> & { email: string } {
    if (this.data) {
      const { id, is_qa, team, username, email } = this.data;
      return {
        email,
        User: username,
        UserID: id,
        "QA User": is_qa,
        Team: team ? team.name : null,
      };
    }
    return { email: "" };
  }

  // Update settings and keep user service in sync. Use same behavior as API for ui_preferences merging
  // this is for internal use within the user service. IF you want to update user settings: use the "updateSettings" method below.
  private saveSettings(data: UserSettingsApiT): Promise<UserSettingsT> {
    return apiRequest.patch(`users/settings/${this.id()}/`, data).then(() => {
      this.settings = mergeSettings(this.settings, data);
      this.dispatcher.dispatchEvent(new Event("settings"));
      return this.settings;
    });
  }

  updateSettings(data: UserSettingsApiT): Promise<UserSettingsT> {
    trackSettings(data);
    return this.saveSettings(data);
  }

  updateFOW = async (
    data: ArraySubmitOnChangePayloadT<ChoiceT, FormikValues>,
  ): Promise<unknown> => {
    if (data.didAdd) {
      return apiRequest.post(`users/${this.id()}/fows/`, { fow_id: data.changed.id }).then(() => {
        this.data && this.data.fows.push(data.changed);
      });
    }
    return apiRequest.delete(`users/${this.id()}/fows/${data.changed.id}/`).then(() => {
      this.data && (this.data.fows = this.data.fows.filter((fow) => fow.id !== data.changed.id));
    });
  };

  // Helper for UI Preferences since it's a nested object
  updateUIPreferences<K extends keyof UIPreferencesT>(
    ui_preferences: Pick<UIPreferencesT, K>,
  ): Promise<UserSettingsT> {
    trackSettings({ ui_preferences });
    return this.saveSettings({
      ui_preferences: { ...this.settings.ui_preferences, ...ui_preferences },
    });
  }

  getCapSignupPage = (): string => this.settings.ui_preferences.cap_signup_page;

  getTapSignupPage = (): TapSignupPageT => {
    let page = this.settings.ui_preferences.tap_signup_page;
    // if they dropped off on a page that no longer exists then reset them to the beginning
    if (page !== "" && !validTapSignupPages.includes(page)) page = validTapSignupPages[0];
    return page;
  };

  clear(): boolean {
    const wasLoggedIn = this.loggedin === true;
    this.data = null;
    this.loggedin = null;
    this.settings = defaultSettings;
    return wasLoggedIn;
  }

  id(): number {
    return this.data ? this.data.id : 0;
  }

  orgId(): number {
    return this.data?.organization?.id || 0;
  }

  // If this ever gets too long, we should consider refactoring this out
  userFlag(
    key:
      | "is_recruiter_admin"
      | "is_talentpair_data_scientist"
      | "is_qa"
      | "is_talentpair_customer_success"
      | "is_staff",
  ): boolean {
    return !!(this.data && this.data[key]);
  }

  canSendEmail(): boolean {
    return !!this.data && this.data.mail.can_send_email;
  }

  oauthSyncValid(): boolean {
    return !!this.data && this.data.mail.has_refresh_token && !this.data.mail.sync_failed;
  }

  hasGreenhouseToken(): boolean {
    return !!this.data && !!this.data.has_greenhouse_token;
  }

  // Checks if user has permissions to access the app
  hasAppAccess(app: string): boolean {
    return !!(
      this.data &&
      this.loggedin &&
      this.data.allowed_apps_hint?.some((val) => val === app)
    );
  }

  hasGettysburgAccess(): boolean {
    return this.hasAppAccess("gettysburg");
  }

  hasTapAccess(): boolean {
    return this.hasAppAccess("jericho");
  }

  hasCapAccess(): boolean {
    return this.hasAppAccess("jericho.candidate");
  }

  // Check in this order because Recruiter can have access to all 3 apps, Employer could potentially also be candidate.
  userType(): ("Recruiter" | "Employer" | "Candidate") | null | undefined {
    if (this.hasGettysburgAccess()) return "Recruiter";
    if (this.hasTapAccess()) return "Employer";
    if (this.hasCapAccess()) return "Candidate";
    return null;
  }

  isDeactivated(): boolean {
    return !!this.data && this.data.account_deactivated;
  }

  isRecruiter(): boolean {
    return this.userType() === "Recruiter";
  }

  isHm(): boolean {
    return this.userType() === "Employer";
  }

  isCandidate(): boolean {
    return this.userType() === "Candidate";
  }

  isOrgOwner(): boolean {
    const { data } = this;
    return data ? lodash.get(data.organization, ["contact_owner", "id"]) === data.id : false;
  }

  isOrgBillingAdmin(): boolean {
    const { data } = this;
    return data ? lodash.get(data.organization, ["billing_contact", "id"]) === data.id : false;
  }

  isOrgAdmin(): boolean {
    return this.isOrgOwner() || this.isRecruiter();
  }

  isRecruiterAdmin(): boolean {
    return this.userFlag("is_recruiter_admin");
  }

  isDataScientist(): boolean {
    return this.userFlag("is_talentpair_data_scientist");
  }

  isAdminOrDsUser(): boolean {
    return this.isRecruiterAdmin() || this.isDataScientist();
  }

  isDjangoAdmin(): boolean {
    return this.userFlag("is_staff");
  }

  isQaUser(): boolean {
    return this.userFlag("is_qa");
  }

  isCustomerSuccessUser(): boolean {
    return this.userFlag("is_talentpair_customer_success");
  }

  mailDomain(): string {
    return this.data?.mail.domain || "";
  }

  mailSyncLink(params?: Record<string, string | number>): string {
    let rv = "";
    let mailOauthParams;

    if (this.data && this.data.mail) {
      if (this.data.mail.provider === "outlook") {
        // TODO get list of Microsoft domains from BE, but for now QG is the only one who uses M$
        const microsoftAuthURI = `https://login.microsoftonline.com/${TENANT_ID}/oauth2/authorize`;

        mailOauthParams = {
          client_id: this.data.outlook_sync_client_id,
          response_type: "code",
          redirect_uri: [apiUrl(`oauth/microsoft/mail/`)],
          ...params,
        };

        rv = `${microsoftAuthURI}${objToQueryStr(mailOauthParams)}`;
      } else {
        // for now google is the only other provider
        mailOauthParams = {
          client_id: this.data.gmail_client_id,
          response_type: "code",
          redirect_uri: apiUrl(`oauth/google/mail/`),
          scope: GOOGLE_SCOPE,
          prompt: "consent",
          access_type: "offline",
          ...params,
        };
        rv = `${GOOGLE_AUTHORIZE_URI}${objToQueryStr(mailOauthParams)}`;
      }
    }
    return rv;
  }

  getABTestGroup(): "A" | "B" {
    return this.isQaUser() || this.id() % 2 ? "A" : "B";
  }
}

export const userService = new User();

export async function getUserSettingsForUser(id: number): Promise<User> {
  if (!!userService.data && userService.id() === id) {
    if (userService.settings === defaultSettings) await userService.getSettings();
    return userService;
  }
  const service = new User();
  // @ts-expect-error - we're only using this one for the settings object
  service.data = { id };
  await service.getSettings();
  return service;
}
