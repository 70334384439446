import * as React from "react";
import clsx from "clsx";
import { connect } from "@talentpair/redux";
import { withRouter, RouteComponentProps } from "@talentpair/routing";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { userService } from "@talentpair/api";
import { Media, NewTabLink } from "@talentpair/quantic";
import { astralLink, env } from "@talentpair/env";
import { getCandidate } from "../cap/store/candidateSelectors";
import { getPair as getCapPair } from "../cap/store/pairSelectors";
import { getRoutePath } from "../routeConfig";
import { StateT } from "../store";
// @ts-expect-error - eslint upgrade
import { getPair as getTapPair } from "../tap/store/pairSelectors";

type StateToPropsT = {
  desktop: string;
  mobile: string;
};

interface Params {
  candidate?: string;
  pair?: string;
  org?: string;
  job?: string;
}

export type OwnPropsT = RouteComponentProps<Params> & {
  children?: React.ReactNode;
  href?: string | null | undefined;
  responsive?: boolean;
  iconClassName?: string | null | undefined;
};
type PropsT = OwnPropsT & StateToPropsT;

const mapStateToProps = (state: StateT, { match: { params, path } }: OwnPropsT): StateToPropsT => {
  let mobile = "";
  let desktop = "";

  if (path === getRoutePath("TAP/OrgSettings") || path.startsWith("/org/:org(\\d+)/team")) {
    mobile = "";
    desktop = "";
  } else if (params.candidate) {
    // CAP
    const candidate = getCandidate(state, +params.candidate);
    const userId = candidate ? `/${candidate.user.id}` : "";
    desktop = userId;
    if (params.pair) {
      // PAIR DETAIL
      const capPair = getCapPair(state, +params.pair);
      mobile = capPair ? astralLink.toJob(capPair.job.id) : "";
    } else {
      // PAIR LIST
      mobile = userId;
    }
  } else if (params.org) {
    // TAP
    const org = astralLink.toOrgJobs(+params.org);
    desktop = org;
    if (params.pair) {
      // PAIR DETAIL
      const tapPair = getTapPair(state, +params.pair);
      mobile = tapPair ? `/${tapPair.candidate.user.id}` : "";
    } else if (params.job) {
      // PAIR LIST
      mobile = astralLink.toJobPairs(+params.job);
    } else {
      // JOB LIST
      mobile = org;
    }
  }
  return { mobile, desktop };
};

const AstralLink = ({
  children,
  href = null,
  desktop,
  mobile,
  responsive = false,
  iconClassName = null,
}: PropsT): React.ReactElement =>
  userService.isRecruiter() && (href || desktop || mobile) ? (
    <Media>
      {(matches: boolean) => (
        <NewTabLink
          href={env.gettysburgUrl(href || (responsive && matches ? desktop : mobile))}
          className="hover-underline"
          style={{ color: "inherit" }}
        >
          {children}
          <OpenInNewIcon classes={{ root: clsx("font-size-inherit ml1", iconClassName) }} />
        </NewTabLink>
      )}
    </Media>
  ) : (
    <span>{children}</span>
  );

export default withRouter(connect(mapStateToProps)(AstralLink));

export const _test = { mapStateToProps, AstralLink };
