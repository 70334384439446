import * as React from "react";
import clsx from "clsx";
import { FormikChoiceT } from "../Forms/Formik/choiceFieldTypes";
import { ListItem } from "../ui/List";
import { GetSuggestionValueT, SuggestionPropsT } from "./types";

export const autocompleteErrorMsg = "Please select an option from the dropdown.";

export const defaultGetSuggestionValue = ({ name }: FormikChoiceT): React.ReactNode => name || "";

export const defaultCreateSuggestionValue = (name: string): FormikChoiceT => ({ name, id: null });

export function getQueryFromValue<V>(
  value: (string | V) | null,
  getSuggestionValue: GetSuggestionValueT<V>,
): string {
  if (!value) return "";
  return typeof value === "string" ? value : getSuggestionValue(value);
}

interface BaseSuggestionWrapperPropsT {
  isHighlighted?: boolean;
  selected?: boolean;
  onMouseLeave?: (event: React.SyntheticEvent) => void;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface SuggestionWrapperPropsT extends BaseSuggestionWrapperPropsT {
  children: React.ReactNode;
}
export const SuggestionWrapper = ({
  isHighlighted = false,
  selected = false,
  className = "px2 py2",
  ...props
}: SuggestionWrapperPropsT): React.ReactElement => (
  <ListItem
    {...props}
    {...(props.disabled
      ? // if suggestion is disabled we need to prevent the user from clicking and make this item look disabled as it's not being provided by mui, and react-autosuggest must have it's own even listeners in place.
        {
          onClick: (e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
          },
          style: { cursor: "default", ...props.style },
          selected: false,
          className: clsx(className, "o100 grey-600"),
        }
      : {
          selected: isHighlighted,
          className: clsx(className, "o100", {
            "bg-green-100": selected && !isHighlighted,
          }),
        })}
  />
);

export function Suggestion<V>({
  suggestion,
  suggestionValue,
  query,
  children,
  ...props
}: SuggestionPropsT<V>): React.ReactElement {
  return <SuggestionWrapper {...props}>{children || suggestionValue}</SuggestionWrapper>;
}

interface RichSuggestionPropsT extends BaseSuggestionWrapperPropsT {
  Avatar?: React.ReactNode;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  className?: string;
}

// We put `py2` padding on Avatar and `py1` on text. We really want vertical padding around py2,
// but if the text is taller, then py1 is appropriate because text always appears to have
// additional vertical spacing around it.
export const RichSuggestion = ({
  primary,
  Avatar = null,
  secondary = null,
  selected = false,
  className = "px2 py0",
  ...props
}: RichSuggestionPropsT): React.ReactElement => (
  <SuggestionWrapper className={className} selected={selected} {...props}>
    {Avatar}
    <div className="line-height-normal py1">
      {primary}
      {secondary && <div className="grey-600 h6">{secondary}</div>}
    </div>
  </SuggestionWrapper>
);
