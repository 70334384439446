// @flow
import { type TapOrgUserReduxT } from "@talentpair/types/tap/entities/user";
import { ORG_RECEIVE_DETAIL } from "./orgActions";
import {
  ORG_USER_DELETED,
  ORG_USER_LOGIN_BLOCKED,
  ORG_USER_RECEIVE_DETAIL,
  ORG_USER_RECEIVE_JOBS,
  type OrgUserActionT,
} from "./userActions";
import { mergeListDetail } from "./storeUtils";

export type UsersReduxT = { [id: number]: ?TapOrgUserReduxT, ... };

export default function users(state: UsersReduxT = {}, action: OrgUserActionT): UsersReduxT {
  switch (action.type) {
    case ORG_RECEIVE_DETAIL:
      return Object.assign(
        {},
        state,
        ...action.org.users.map((user) => ({ [user.id]: mergeListDetail(state, user) })),
      );

    case ORG_USER_RECEIVE_DETAIL:
    case ORG_USER_LOGIN_BLOCKED:
    case ORG_USER_RECEIVE_JOBS:
      return { ...state, [action.user.id]: mergeListDetail(state, action.user) };

    case ORG_USER_DELETED: {
      const { [action.userId]: deletedUser, ...newState } = state;
      return newState;
    }

    default:
      (action: empty);
      return state;
  }
}
