import * as React from "react";
import { Chip } from "@mui/material";
import clsx from "clsx";
import lodash from "lodash";
import { splice } from "../../utils/array";
import { mergeClasses } from "../../utils/misc";
import FocusableChip, {
  FocusableChipRenderPropsT,
  RenderFocusableChipT,
} from "../FocusableChip/FocusableChip";
import FocusableChipList from "../FocusableChip/FocusableChipList";
import Autocomplete, { AutocompletePropsT } from "./Autocomplete";
import { defaultCreateSuggestionValue, defaultGetSuggestionValue } from "./helpers";

function defaultChip<V>({ value, ...props }: FocusableChipRenderPropsT<V>): React.ReactNode {
  return <Chip {...props} />;
}

export interface AutocompleteMultiPropsT<V>
  extends Omit<AutocompletePropsT<V>, "value" | "onChange"> {
  inputRef: ((el: HTMLInputElement) => void) | null;
  omitSelected?: boolean;
  onChange: (values: V[]) => void;
  renderChip: RenderFocusableChipT<V>;
  uniqueById?: boolean;
  limitTo?: number;
  value: V[];
}
interface StateT<V> {
  value: V[];
}

export default class AutocompleteMulti<V extends { id?: number }> extends React.Component<
  AutocompleteMultiPropsT<V>,
  StateT<V>
> {
  inputRef: HTMLInputElement | null = null;
  didFocusChipWithBackspace = false;
  keyIsDown = false;

  static defaultProps = {
    value: [],
    inputRef: null,
    inputProps: null,
    // getSuggestionValue & createSuggestionValue must be compatible, i.e. createSuggestionValue should create the
    // shape of object that getSuggestionValue expects.
    getSuggestionValue: defaultGetSuggestionValue,
    createSuggestionValue: defaultCreateSuggestionValue,
    selectOnTab: true,
    renderChip: defaultChip,
    uniqueById: false,
    disabled: false,
    omitSelected: false,
    limitTo: null,
  };

  onSuggestionSelected = (suggestion: V): void => {
    const { getSuggestionValue, clearSuggestions, uniqueById, value, onChange } = this.props;

    clearSuggestions();
    const suggestionVal = getSuggestionValue(suggestion);
    const isDupe = lodash.some(
      value,
      uniqueById ? (v) => v.id === suggestion.id : (v) => getSuggestionValue(v) === suggestionVal,
    );

    if (isDupe) return;
    onChange([...value, suggestion]);
  };

  onChipDelete = (index: number): void => {
    const { value, onChange } = this.props;
    onChange(splice(value, index));
  };

  focusInput = (): void => {
    if (this.inputRef) this.inputRef.focus();
  };

  renderChip: RenderFocusableChipT<V> = (chipProps) =>
    this.props.renderChip({
      ...chipProps,
      classes: mergeClasses(chipProps.classes || {}, {
        root: "ml1 my1 self-center",
        label: "truncate",
      }),
    });

  render(): React.ReactNode {
    const {
      inputRef,
      inputProps,
      onChange,
      placeholder,
      disabled,
      uniqueById,
      limitTo,
      value,
      suggestions,
      omitSelected,
      getSuggestionValue,
      ...props
    } = this.props;

    let finalSuggestions = suggestions;
    if (omitSelected) {
      if (uniqueById) {
        const ids = value.map((v) => v.id);
        finalSuggestions = suggestions.filter((v) => !ids.includes(v.id));
      } else {
        const strs = value.map(getSuggestionValue);
        finalSuggestions = suggestions.filter((v) => !strs.includes(getSuggestionValue(v)));
      }
    }

    const metOrExceededLimit = limitTo && value.length >= limitTo;

    return (
      <div className={this.props.disabled ? "o50" : undefined}>
        <FocusableChipList
          values={value}
          onChipDelete={this.onChipDelete}
          focusField={this.focusInput}
          render={({ setChipFocusIndex, chipFocusIndex, chipProps }) => {
            const chips = value.map((val, i) => {
              const label = getSuggestionValue(val);
              return (
                <FocusableChip
                  key={uniqueById ? val.id : label}
                  index={i}
                  disabled={disabled}
                  focus={chipFocusIndex === i}
                  label={label}
                  render={this.renderChip}
                  value={val}
                  {...chipProps}
                />
              );
            });
            return (
              // @ts-expect-error - because of the withStyles() wrapping the Autocomplete component typescript loses the generic V
              <Autocomplete<V>
                disabled={disabled || metOrExceededLimit}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={{
                  classes: { root: clsx("flex-wrap", { "pointer-events-none": disabled }) },
                  startAdornment: chips,
                  placeholder: metOrExceededLimit
                    ? `Reached limit of ${limitTo || ""} items`
                    : undefined,
                  ...inputProps,
                  onKeyDown: (e: React.KeyboardEvent, query: string) => {
                    if (!!value.length && !query && ["Backspace", "ArrowLeft"].includes(e.key)) {
                      setChipFocusIndex(value.length - 1);
                      e.preventDefault();
                    }
                  },
                }}
                inputRef={(el: HTMLInputElement) => {
                  this.inputRef = el;
                  if (inputRef) inputRef(el);
                }}
                placeholder={value.length ? null : placeholder}
                suggestions={finalSuggestions}
                {...props}
              />
            );
          }}
        />
      </div>
    );
  }
}

export const _test = { defaultChip };
