import * as React from "react";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import { Link, RouteComponentProps } from "@talentpair/routing";
import { Toolbar, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { connect, MapStateToProps } from "@talentpair/redux";
import { userService } from "@talentpair/api";
import { Button, ButtonProps, UserAvatar, Typography } from "@talentpair/quantic";
import { EnvIndicator, londonLink } from "@talentpair/env";
import { PerryAvatar } from "kyoto/components/ui/Avatars";
import NotificationsLink from "kyoto/components/NotificationsLink";
import OnboardingTapNavigationUi from "./Onboarding/tours/OnboardingTapNavigationUi";
import type { StateT } from "../store";
import AstralLink from "./AstralLink";
import { TapNavSearch } from "./NavSearch/TapNavSearch";
import { MenuNavigation } from "../tap/components/Navigation/MenuNavigation";

export interface MetaPropsT {
  title: string;
}
type MapStateToTitleProps<Params extends Record<string, string | undefined>> = MapStateToProps<
  MetaPropsT,
  TitleProps<Params> & RouteComponentProps<Params>,
  StateT
>;
type TitleMetaT<Params extends Record<string, string | undefined>> =
  | string
  | MapStateToTitleProps<Params>;
type TitleHeaderT<Params extends Record<string, string | undefined>> =
  | React.ReactNode
  | MapStateToTitleProps<Params>;

const Meta = ({ title }: MetaPropsT): React.ReactElement | null =>
  title !== "Talentpair" ? (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  ) : null;

export const createMeta =
  <Params extends Record<string, string | undefined>>(title: TitleMetaT<Params>) =>
  (props: RouteComponentProps<Params>): React.ReactElement | null => {
    if (typeof title === "string") return <Meta title={title} />;

    const ConnectedMeta = connect(title)(Meta);
    // @ts-expect-error - it's fine
    return <ConnectedMeta {...props} />;
  };

type BackLinkT = string | (() => void);

interface BackButtonProps {
  link: BackLinkT;
}
const BackButton = ({ link }: BackButtonProps): React.ReactElement => {
  const buttonProps: ButtonProps = {
    size: "lg",
    variant: "icon",
    icon: ArrowBackIcon,
    style: { marginLeft: -6, marginRight: 16 },
  };
  return typeof link === "function" ? (
    <Button {...buttonProps} onClick={link} />
  ) : (
    <Link className="color-inherit" to={link}>
      <Button {...buttonProps} />
    </Link>
  );
};

const HomeButton = (): React.ReactElement => (
  <Link className="color-inherit" to="/" style={{ marginLeft: -6, marginRight: 16 }}>
    <PerryAvatar />
  </Link>
);

interface TitleProps<Params extends Record<string, string | undefined>> {
  backLink: BackLinkT;
  home: boolean;
  title?: TitleHeaderT<Params>;
}

export interface HeaderProps<
  Params extends { [K in keyof Params]?: string } = Record<string, string | undefined>,
> extends RouteComponentProps<Params> {
  children?: React.ReactNode;
  desktop?: boolean;
}

export const TapHeader = <Params extends Record<string, string | undefined>>({
  children,
  desktop = false,
  ...props
}: HeaderProps<Params>): React.ReactElement => (
  <Toolbar>
    {desktop ? null : (
      <OnboardingTapNavigationUi step={0}>
        <MenuNavigation />
      </OnboardingTapNavigationUi>
    )}
    <div className={clsx("flex items-center flex-auto", desktop ? "mr2" : "ml2")}>
      {desktop ? <HomeButton /> : null}
      <div style={{ position: "relative", left: 146, top: 20, width: 0, pointerEvents: "none" }}>
        <OnboardingTapNavigationUi step={desktop ? 2 : 1}>
          <div style={{ width: 1, pointerEvents: "none" }} />
        </OnboardingTapNavigationUi>
      </div>
      <TapNavSearch />
      {userService.isRecruiter() ? (
        <Typography variant="h2" className="mt1 ml2 mr1">
          <AstralLink responsive />
        </Typography>
      ) : null}
    </div>
    {!desktop ? null : !userService.isRecruiter() ? (
      <>
        <Tooltip
          title="Notifications"
          PopperProps={{
            popperOptions: { strategy: "fixed" },
          }}
        >
          <NotificationsLink isTap pathname={props.location.pathname} />
        </Tooltip>
        <EnvIndicator />
        <div className="relative" style={{ right: desktop ? -12 : -8 }}>
          <Tooltip
            title="Profile"
            PopperProps={{
              popperOptions: { strategy: "fixed" },
            }}
          >
            <Link className="color-inherit" to={londonLink.tapProfile()}>
              <UserAvatar user={userService.data} size="md" />
            </Link>
          </Tooltip>
        </div>
      </>
    ) : (
      <>
        <EnvIndicator />
        <div className="relative" style={{ right: desktop ? -12 : -8 }}>
          <UserAvatar user={userService.data} size="md" />
        </div>
      </>
    )}
  </Toolbar>
);

export const _test = { Meta, BackButton };
