import * as React from "react";
import { StyleProps } from "@talentpair/types/component";
import { styled } from "@mui/material/styles";
import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  ThemeOptions,
} from "@mui/material";
import { colorProp, ColorPropT } from "../theme/colorProp";

// Note: It's important to keep in mind that the heading level tag you use may not always match your headline style variant
// This is because when rendering heading tags like <h1>, <h2>, etc you should never skip a level. See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements#usage_notes
// This means that if your design uses Headline1 and Headline3 variants, you should still use the <h1> and <h2> tags respectively.
type HeadingLevelTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type TypographyProps<
  D extends "div" | "span" | "p" | HeadingLevelTag | React.ComponentType = "span",
> = React.PropsWithChildren<
  StyleProps &
    Pick<MuiTypographyProps<D>, "noWrap" | "align" | "paragraph"> & {
      variant?:
        | "inherit"
        | "hero"
        | "h1"
        | "h2"
        | "h3"
        | "subtitle"
        | "body1"
        | "body2"
        | "body3"
        | "buttonXL" // xl
        | "buttonLG" // lg
        | "button1" // md
        | "button2" // sm
        | "overline";
      component?: D;
      color?: ColorPropT;
      bold?: boolean; // only supported on body variants
      semibold?: boolean; // only supported on body variants
      italic?: boolean; // only supported on body variants
      gutterBottom?: boolean;
      id?: string;
    }
>;
// variantMapping is used by mui to determine the default html tag to use for a given variant
// these mappings can still be overridden using the `component` prop
const variantMapping = {
  hero: "h1",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  subtitle: "h6",
  body1: "span",
  body2: "span",
  body3: "span",
  button: "span",
  button1: "span",
  button2: "span",
  buttonLG: "span",
  buttonXL: "span",
  overline: "span",
  inherit: "span",
};

export type GenericElement = "div" | "span" | "p" | HeadingLevelTag | React.ComponentType;

function UnstyledTypography<D extends GenericElement = "span">({
  // pull these one off to avoid spreading them below
  semibold,
  bold,
  italic,
  color,
  paragraph,
  ...props
}: TypographyProps<D>): React.ReactElement {
  return (
    // @ts-expect-error - it's fine?
    <MuiTypography
      {...props}
      variantMapping={variantMapping}
      {...(paragraph ? { component: "p" } : null)}
    />
  );
}

export const Typography = styled(UnstyledTypography, { skipSx: true })(
  ({ variant = "body1", bold, semibold, italic, color = "inherit", theme }) =>
    // See `typographyThemeOverride` below for top-level variant styles
    ({
      color: colorProp(color, theme),
      ...((variant.startsWith("body") && bold) || semibold
        ? { fontWeight: bold ? 700 : 500 }
        : null),
      ...(variant.startsWith("body") && italic ? { fontStyle: "italic" } : null),
    }),
);

// while normally we prefer to keep all our variant style definitions encapsulated within emotion styled() calls here at the component
// level, typography is special in that mui considers it to be a part of the theme. Additionally the typography values in the theme are
// used all over the place within mui components that use the MuiTypography component internally so we need to override those places at
// the theme level rather than at the component level
export const typographyThemeOverride: ThemeOptions["typography"] = {
  fontFamily: [
    // Inherit system-ui stack from resets
    "system-ui",
    "-apple-system",
    "Segoe UI",
    "Roboto",
    "Ubuntu",
    "Cantarell",
    "Noto Sans",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji",
  ].join(","),
  hero: {
    fontWeight: 700,
    fontSize: 64,
    lineHeight: "72px",
    letterSpacing: 0,
  },
  h1: {
    fontWeight: 700,
    fontSize: 40,
    lineHeight: "48px",
    letterSpacing: 0,
  },
  h2: { fontWeight: 600, fontSize: 24, lineHeight: "32px", letterSpacing: 0 },
  subtitle: {
    fontWeight: 400,
    fontSize: 24,
    lineHeight: "32px",
    letterSpacing: 0,
  },
  h3: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  body1: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: 0,
  },
  body2: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    letterSpacing: 0,
  },
  body3: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: 0,
  },
  overline: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: "0.25px",
  },
  buttonLG: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "20px",
    letterSpacing: "0.65px",
    textTransform: "none",
  },
  buttonXL: {
    fontWeight: 600,
    fontSize: 23,
    lineHeight: "24px",
    letterSpacing: "0.75px",
    textTransform: "none",
  },
  button1: {
    fontWeight: 600,
    fontSize: 15,
    lineHeight: "16px",
    letterSpacing: "0.5px",
    textTransform: "none",
  },
  button2: {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: "15px",
    letterSpacing: "0.5px",
    textTransform: "none",
  },
  button: {
    // TODO: delete this someday when we've converted over all the mui Buttons to use the new format
    textTransform: "none",
  },
  // TODO: uncomment these once all Typography components have been converted over
  // h4: undefined,
  // h5: undefined,
  // h6: undefined,
  // caption: undefined,
  // subtitle2: undefined,
};

// extending the mui theme to add support for additional body3 variant
// and disabling variants we don't use like h4, h5, h6, caption, and subtitle2
declare module "@mui/material/styles" {
  interface TypographyVariants {
    hero?: React.CSSProperties;
    subtitle: React.CSSProperties;
    body3: React.CSSProperties;
    button1?: React.CSSProperties;
    button2?: React.CSSProperties;
    buttonLG?: React.CSSProperties;
    buttonXL?: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    hero?: React.CSSProperties;
    subtitle?: React.CSSProperties;
    body3?: React.CSSProperties;
    button1?: React.CSSProperties;
    button2?: React.CSSProperties;
    buttonLG?: React.CSSProperties;
    buttonXL?: React.CSSProperties;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subtitle: true;
    body3: true;
    button1: true;
    button2: true;
    // TODO: uncomment these once all Typography components have been converted over
    // h4: false;
    // h5: false;
    // h6: false;
    // caption: false;
    // subtitle1: false;
    // subtitle2: false;
  }
}
