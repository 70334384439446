import { AnimatedEllipsis, Pane, Item, Title } from "@talentpair/quantic";
import * as React from "react";
import ResumeProgressSvg from "kyoto/components/ResumeProgressSvg";

const ProcessingPane = (): React.ReactElement => (
  <Pane>
    <Item>
      <ResumeProgressSvg state="processing" />
    </Item>
    <Item>
      <Title>
        Processing your resume
        <AnimatedEllipsis />
      </Title>
    </Item>
  </Pane>
);

export default ProcessingPane;
