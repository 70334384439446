import type { PlatformActivityFeedItemT } from "@talentpair/types/activity";
import {
  NOTIFICATION_RECEIVE_ITEM,
  NOTIFICATION_RECEIVE_LIST,
} from "../../Notifications/store/notificationActions";
import type { NotificationActionT } from "../../Notifications/store/notificationActions";
import {
  ACTIVITY_RECEIVE_ITEM,
  ACTIVITY_REMOVE_ITEM,
  ACTIVITY_RECEIVE_LIST,
} from "./activityActions";
import type { ActivityItemsActionT } from "./activityActions";

export type ActivityReduxT = Record<number, PlatformActivityFeedItemT>;
export default function activity(
  state: ActivityReduxT = {},
  action: ActivityItemsActionT | NotificationActionT,
): ActivityReduxT {
  switch (action.type) {
    case ACTIVITY_RECEIVE_ITEM:
      return { ...state, [action.item.id]: action.item };

    case ACTIVITY_REMOVE_ITEM:
      delete state[action.itemId];
      return { ...state };

    case ACTIVITY_RECEIVE_LIST:
      return Object.assign(
        {},
        state,
        ...action.results.map((item) => ({
          [item.id]: item,
        })),
      );

    case NOTIFICATION_RECEIVE_ITEM:
      return { ...state, [action.item.activity.id]: action.item.activity };

    case NOTIFICATION_RECEIVE_LIST:
      return Object.assign(
        {},
        state,
        ...action.results.map(({ activity: item }) => ({
          [item.id]: item,
        })),
      );

    default:
      return state;
  }
}
