import { apiRequest } from "@talentpair/api";
import { portal } from "@talentpair/portal";
import { uniq, toTruthy } from "kyoto/utils/array";
import { expectNever } from "kyoto/utils/types";
import type { DispatchT, GetStateT } from "@talentpair/redux";
import type { UnreadNotificationT } from "@talentpair/types/entities/misc";
import type { PairActiveStateT } from "@talentpair/types/entities/pairs";
import type { CapPairDetailT, CapPairListT } from "@talentpair/types/cap/entities/pair";
import track, { PairTrackDataT } from "../../services/track";
import ActivityAction from "../../shared-apps/ActivityFeed/store/activityActions";
import type { StateT } from "../../store";
import ActivateProfileModal from "../components/ActivateProfileModal";
import CreateProfileModal from "../components/CreateProfileModal";
// @ts-expect-error - REMOVE ME
import IncompleteProfileModal from "../components/IncompleteProfileModal";
import { canSendCandidate, getCandidate, profileComplete } from "./candidateSelectors";
import type { PairFilterT } from "./pairFilterActions";
import PairFilterAction from "./pairFilterActions";
import type { PairFilterReduxT } from "./pairFilterReducers";
import { getFilteredPairIds } from "./pairSelectors";

export const CAP_PAIR_RECEIVE_DETAIL = "CAP_PAIR_RECEIVE_DETAIL";
export const CAP_PAIR_RECEIVE_LIST = "CAP_PAIR_RECEIVE_LIST";
export const CAP_PAIR_RECEIVE_NOTIFICATION_COUNTS = "CAP_PAIR_RECEIVE_NOTIFICATION_COUNTS";
export const CAP_PAIR_SET_STATE = "CAP_PAIR_SET_STATE";
export type PairReceiveDetailActionT = {
  type: "CAP_PAIR_RECEIVE_DETAIL";
  pair: CapPairDetailT;
};
export type PairReceiveListActionT = {
  type: "CAP_PAIR_RECEIVE_LIST";
  candidateId: number;
  pairs: CapPairListT[];
  filter: PairFilterT;
};
type PairReceiveNotificationCountsActionT = {
  type: "CAP_PAIR_RECEIVE_NOTIFICATION_COUNTS";
  pairs: UnreadNotificationT[];
};
type PairSetStateT = {
  type: "CAP_PAIR_SET_STATE";
  pairId: number;
  candidate_state: PairActiveStateT;
};
export type PairActionT =
  | PairReceiveDetailActionT
  | PairReceiveListActionT
  | PairSetStateT
  | PairReceiveNotificationCountsActionT;

const receiveDetail = (pair: CapPairDetailT): PairReceiveDetailActionT => ({
  type: CAP_PAIR_RECEIVE_DETAIL,
  pair,
});

const fetchDetail =
  (pairId: number) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest
      .getMemoized<CapPairDetailT>(`pairs-cap/${pairId}/`)
      .then(({ data }) => dispatch(receiveDetail(data)));

const receiveList = (
  candidateId: number,
  {
    results,
  }: {
    results: CapPairListT[];
  },
  filter: PairFilterReduxT,
): PairReceiveListActionT => ({
  type: CAP_PAIR_RECEIVE_LIST,
  candidateId,
  pairs: results,
  filter,
});

function filterParam(filter: PairFilterReduxT): string {
  switch (filter) {
    case "ACTIVE":
      return "&f=active";

    case "INACTIVE":
      return "&f=inactive";

    case "REVIEW":
      return "";

    default:
      expectNever(filter);
      return "";
  }
}

const fetchList =
  (candidateId: number, filter: PairFilterReduxT) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest
      .getMemoized<{ results: CapPairListT[] }>(
        `pairs-cap/?candidate_pk=${candidateId}${filterParam(filter)}`,
      )
      .then(({ data }) => dispatch(receiveList(candidateId, data, filter)));

const receiveNotificationCounts = (
  pairs: UnreadNotificationT[],
): PairReceiveNotificationCountsActionT => ({
  type: CAP_PAIR_RECEIVE_NOTIFICATION_COUNTS,
  pairs,
});

const baseNotificationCounts =
  (pair_ids: number[]) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest
      .getMemoized<UnreadNotificationT[]>("pairs-cap/unread/", {
        pair_ids,
      })
      .then(({ data }) => dispatch(receiveNotificationCounts(data)));

const fetchNotificationCount =
  (pairId: number) =>
  (dispatch: DispatchT): Promise<unknown> =>
    dispatch(baseNotificationCounts([pairId]));

const fetchNotificationCounts =
  (candidate: number, pairId?: number) =>
  (dispatch: DispatchT, getState: GetStateT<StateT>): Promise<unknown> =>
    dispatch(
      baseNotificationCounts(
        uniq([pairId, ...getFilteredPairIds(getState(), candidate)].filter(toTruthy)),
      ),
    );

const setState = (pairId: number, candidate_state: PairActiveStateT): PairSetStateT => ({
  type: CAP_PAIR_SET_STATE,
  pairId,
  candidate_state,
});

type CapPairFeedTypes = "pair_shared" | "pair_cap";

const saveState =
  (
    id: number,
    feed_type: CapPairFeedTypes,
    endpoint: string,
    state: PairActiveStateT,
    payload: Record<string, unknown> = {},
  ) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest.post(`pairs-cap/${id}/${endpoint}/`, payload).then(() => {
      dispatch(setState(id, state));
      dispatch(ActivityAction.fetchFirstPage(feed_type, id));
    });

const saveInterest =
  (
    feed_type: CapPairFeedTypes,
    candidateId: number,
    id: number,
    source: string,
    trackData: PairTrackDataT,
  ) =>
  (dispatch: DispatchT, getState: GetStateT<StateT>): Promise<unknown> => {
    const state = getState();
    const candidate = getCandidate(state, candidateId);
    if (!candidate) return Promise.reject(); // Shouldn't be possible

    // Check to make sure that profile is ready
    if (canSendCandidate(state, candidateId)) {
      track.pairAction({
        action: "Interest",
        source,
        ...trackData,
      });
      return dispatch(saveState(id, feed_type, "interests", "HI")).then(() =>
        dispatch(PairFilterAction.filter("ACTIVE")),
      );
    }

    // Fallbacks
    if (!candidate.resume) {
      // Needs to upload a resume
      portal.modal(CreateProfileModal, {
        candidateId: candidate.id,
      });
      track.pairAction({
        action: "Interest - Go Create Profile",
        source,
        ...trackData,
      });
    } else if (!profileComplete(state, candidateId)) {
      // Profile is incomplete
      portal.modal(IncompleteProfileModal, {
        // @ts-expect-error - REMOVE ME
        candidate,
      });
      track.pairAction({
        action: "Interest - Incomplete Profile",
        source,
        ...trackData,
      });
    } else {
      // Needs to activate their profile
      portal.modal(ActivateProfileModal, {
        candidateId: candidate.id,
      });
      track.pairAction({
        action: "Interest - Go Activate Profile",
        source,
        ...trackData,
      });
    }

    // Give some way to block further async control flows w/o throwing error
    return Promise.reject(Error("SENTRY IGNORE: Can't send profile."));
  };

const savePass =
  (
    feed_type: CapPairFeedTypes,
    id: number,
    trackData: PairTrackDataT,
    feedback?: Record<string, unknown> | null,
  ) =>
  (dispatch: DispatchT): Promise<unknown> => {
    track.pairAction({
      action: feedback ? "Pass w/ Feedback" : "Pass",
      ...trackData,
    });
    return dispatch(saveState(id, feed_type, "passes", "PA", feedback || {})).then(() =>
      dispatch(PairFilterAction.filter("INACTIVE")),
    );
  };

// Recruiter Only
const saveReset =
  (feed_type: CapPairFeedTypes, id: number, trackData: PairTrackDataT) =>
  (dispatch: DispatchT): Promise<unknown> => {
    track.pairAction({
      action: "Reset",
      ...trackData,
    });
    return apiRequest
      .patch(`pairs/${id}/`, {
        candidate_state: "PR",
      })
      .then(() => {
        dispatch(setState(id, "PR"));
        dispatch(ActivityAction.fetchFirstPage(feed_type, id));
        dispatch(PairFilterAction.filter("REVIEW"));
      });
  };

export default {
  baseNotificationCounts,
  fetchDetail,
  fetchList,
  fetchNotificationCount,
  fetchNotificationCounts,
  receiveDetail,
  receiveList,
  receiveNotificationCounts,
  saveInterest,
  savePass,
  saveReset,
  saveState,
  setState,
};
