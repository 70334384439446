/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import lodash from "lodash";
import { Theme } from "@mui/material";

export const nestedFormTheme = (outerTheme: Theme): Theme =>
  lodash.merge({}, outerTheme, {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            // Icon font size
            "& svg": {
              fontSize: 20,
            },
            "& span, & div": {
              // Ensure that the event.target on a button click is the button element itself, not the
              // button label.
              pointerEvents: "none",
            },
          },
        },
      },
    },
  });

export type PropsT<P extends Record<string, unknown>> = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  allowEnter?: boolean;
} & P;

/**
 * Replicates standard HTML form functionality but allows nesting
 */
class NestedForm<P extends Record<string, unknown>> extends React.Component<PropsT<P>> {
  enterIsDown = false;

  componentDidMount(): void {
    document.addEventListener("keydown", this.onDocumentKeyDown);
    document.addEventListener("keyup", this.onDocumentKeyUp);
  }

  componentWillUnmount(): void {
    document.removeEventListener("keydown", this.onDocumentKeyDown);
    document.removeEventListener("keyup", this.onDocumentKeyUp);
  }

  onDocumentKeyDown = (e: KeyboardEvent): void => {
    if (e.key === "Enter") this.enterIsDown = true;
  };

  onDocumentKeyUp = (e: KeyboardEvent): void => {
    if (e.key === "Enter") this.enterIsDown = false;
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    // See https://codesandbox.io/s/82y6vpv5j8 for which form elements should submit on enter.
    // Not bothering to handle `<input type="submit" />`
    if (e.key === "Enter") {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLSelectElement ||
        (e.target instanceof HTMLButtonElement && e.target.type === "submit")
      ) {
        if (this.props.allowEnter) return;
        e.preventDefault(); // Prevent real, outer form from submitting.
        // @ts-expect-error - eslint upgrade
        this.props.onSubmit(e);
      }
    } else if (e.key === "Space") {
      this.checkForSubmitButton(e);
    }
  };

  checkForSubmitButton = (e: React.SyntheticEvent): void => {
    if (e.target instanceof HTMLButtonElement && e.target.type === "submit" && !this.enterIsDown) {
      // @ts-expect-error - eslint upgrade
      this.props.onSubmit(e);
    }
  };

  render(): React.ReactNode {
    const { onSubmit, allowEnter, ...props } = this.props;

    return (
      <ThemeProvider theme={nestedFormTheme}>
        <div
          onKeyDownCapture={this.onKeyDown}
          onClick={this.checkForSubmitButton}
          role="form"
          {...props}
        />
      </ThemeProvider>
    );
  }
}

export default NestedForm;
