import * as React from "react";
import clsx from "clsx";
import { withStyles } from "@mui/styles";
import { Badge, Tab as MuiTab, Tabs, TabProps, TabsProps } from "@mui/material";
import { Media } from "@talentpair/quantic";

export type PaneT = "sidebar1" | "sidebar2" | "main1" | "fullWidth" | "centered";

export const fixedPanePosition = (pane: PaneT): string =>
  clsx("col-12 fixed flex z1", {
    // "fullWidth" is default
    "max-width-3": pane === "centered",
    "left-0 md-col-3 md-border-right border-grey-300": pane === "sidebar1",
    "md-col-3 md-left-25 md-border-right border-grey-300": pane === "sidebar2",
    "md-col-9 md-left-25 right-0": pane === "main1",
  });

export const tabFixedPosition = (pane: PaneT): string =>
  `bg-white fixed-under-appbar ${fixedPanePosition(pane)}`;

export const tapFixedPanePosition = (pane?: PaneT): string =>
  clsx("col-12 fixed border-box flex z1", {
    "max-width-3": pane === "centered",
    "tap-pane-left-0": pane === "fullWidth",
    "tap-pane-col-3 tap-pane-left-0 md-border-right border-grey-300": pane === "sidebar1",
    "tap-pane-col-3 tap-pane-left-25 md-border-right border-grey-300": pane === "sidebar2",
    "tap-pane-col-9 tap-pane-left-25": pane === "main1",
  });

export const tapTabFixedPosition = (pane?: PaneT): string =>
  `bg-white fixed-under-appbar ${tapFixedPanePosition(pane)}`;

// Tabs use opacity for unselected styles, but it also applies to the badge. This replicates it with color.
const Tab = withStyles({
  textColorInherit: { color: "rgb(17, 17, 17, 0.7)", opacity: 1 },
  selected: { color: "#111" },
})(MuiTab);

interface BadgeTabProps extends Omit<TabProps, "label"> {
  label: React.ReactNode;
  count?: number;
}
export const BadgeTab = ({ label, count, ...props }: BadgeTabProps): React.ReactElement => (
  <Tab
    label={
      count ? (
        <Badge badgeContent={count} classes={{ root: "px1" }} color="error">
          {label}
        </Badge>
      ) : (
        label
      )
    }
    {...props}
  />
);

export const CenteredTabs = (props: TabsProps): React.ReactElement => (
  <Media>
    {(matches: boolean) => (
      <Tabs
        classes={{ root: "border-bottom border-grey-300" }}
        centered={matches}
        variant={matches ? "standard" : "fullWidth"}
        {...props}
      />
    )}
  </Media>
);

interface PageTabsProps<V extends string> extends Omit<TabsProps, "onChange"> {
  isTap?: boolean;
  pane: PaneT;
  onChange: (event: React.SyntheticEvent, value: V) => void;
}
export const PageTabs = <V extends string>({
  pane,
  onChange,
  isTap = false,
  ...props
}: PageTabsProps<V>): React.ReactElement => {
  const classes = {
    root: clsx(
      "border-bottom border-grey-300",
      isTap ? tapTabFixedPosition(pane) : tabFixedPosition(pane),
    ),
    flexContainer: "col-12",
  };
  return ["fullWidth", "centered"].includes(pane) ? (
    <CenteredTabs onChange={onChange} classes={classes} {...props} />
  ) : (
    <Tabs classes={classes} variant="fullWidth" onChange={onChange} {...props} />
  );
};

interface TabsWrapperProps extends React.DOMAttributes<HTMLDivElement> {
  style?: React.CSSProperties;
}
export const PageTabsWrapper = ({ style, ...props }: TabsWrapperProps): React.ReactElement => (
  <div className="full-height overflow-scroll" style={{ paddingTop: 48, ...style }} {...props} />
);
