import * as reactRedux from "react-redux";
import * as reselect from "reselect";

import { DispatchT, AnyActionT } from "./types";
// doing this so we can export only these things from react-redux but also still be able to mock them for tests
// if we did something like: export { useSelector } from "react-redux" we get errors trying to mock this export in tests
export const Provider = reactRedux.Provider;
export const useSelector = reactRedux.useSelector;
export const useStore = reactRedux.useStore;
// this one needs wrapped further so we can use our internal DispatchT type
export function useDispatch<A extends AnyActionT = AnyActionT>(): DispatchT<A> {
  return reactRedux.useDispatch();
}
export type { Store } from "redux";

export {
  createSlice,
  createSelector,
  createEntityAdapter,
  configureStore,
  combineReducers,
  nanoid,
} from "@reduxjs/toolkit";
export type { PayloadAction } from "@reduxjs/toolkit";

// exporting this way to make flow happy
export const defaultMemoize = reselect.defaultMemoize;
export type { OutputSelector } from "reselect";

export * from "./types";
export * from "./connect";
export * from "./useReduxSlice";
