import * as React from "react";
import clsx from "clsx";
import { dateFormat } from "@talentpair/datetime";

interface PropsT {
  date: string;
  className?: string;
}

const Timestamp = ({ date, className, ...props }: PropsT): React.ReactElement => (
  <span className={clsx("grey-500 h6 regular m0 nowrap", className)} {...props}>
    {dateFormat.fromNowShort(date)}
  </span>
);

export function LiveTimestamp({
  date,
  className,
  updateEvery = 5000, // update every 5 seconds
  ...props
}: PropsT & { updateEvery?: number }): React.ReactElement {
  const [value, setValue] = React.useState(dateFormat.fromNowShort(date));
  React.useEffect(() => {
    const intervalId = setInterval(() => setValue(dateFormat.fromNowShort(date)), updateEvery);
    return () => clearInterval(intervalId);
  }, [date, updateEvery]);
  return (
    <span className={clsx("grey-500 h6 regular m0 nowrap", className)} {...props}>
      {value}
    </span>
  );
}

export default Timestamp;
