import * as React from "react";
import capProfile1 from "../assets/cap-profile-1.svg";
import capProfile2 from "../assets/cap-profile-2.svg";
import capProfile3 from "../assets/cap-profile-3.svg";
import Modal from "../ui/Modal";
import Tour from "../Tour";
import { BG_COLOR } from "../utils";

const OnboardingCapProfile = (props: { children: React.ReactNode }): React.ReactElement => (
  <Tour
    tour="onboarding_cap_profile"
    component={Modal}
    doneText="Got it"
    slides={[
      {
        background: BG_COLOR.YELLOW,
        img: capProfile1,
        text: "Preview your profile. Confirm employers see the best you.",
      },
      {
        background: BG_COLOR.PINK,
        img: capProfile2,
        text: "Edit your profile to get personalized job matches.",
      },
      {
        background: BG_COLOR.PURPLE,
        img: capProfile3,
        text: "Review your jobs and submit your profile to jobs you like.",
      },
    ]}
    {...props}
  />
);

export default OnboardingCapProfile;
