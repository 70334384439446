import lodash from "lodash";
import { CAP_PAIR_RECEIVE_LIST } from "./pairActions";
import type { PairReceiveListActionT } from "./pairActions";

export type CandidatePairsT = {
  REVIEW: number[];
  ACTIVE: number[];
  INACTIVE: number[];
};
export const defaultCandidatePairs = (): CandidatePairsT => ({
  REVIEW: [],
  ACTIVE: [],
  INACTIVE: [],
});
export type CandidatePairsReduxT = Record<number, CandidatePairsT>;

export default function candidatePairs(
  state: CandidatePairsReduxT = {},
  action: PairReceiveListActionT,
): CandidatePairsReduxT {
  switch (action.type) {
    case CAP_PAIR_RECEIVE_LIST: {
      const pairs = state[action.candidateId] || defaultCandidatePairs();
      const newList = action.pairs.map((p) => p.id);
      return {
        ...state,
        [action.candidateId]: {
          REVIEW: lodash.difference(pairs.REVIEW, newList),
          ACTIVE: lodash.difference(pairs.ACTIVE, newList),
          INACTIVE: lodash.difference(pairs.INACTIVE, newList),
          [action.filter]: newList,
        },
      };
    }

    default:
      return state;
  }
}
