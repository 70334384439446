import * as React from "react";
import {
  CircularProgress as MuiCircularProgress,
  LinearProgress as MuiLinearProgress,
  Theme,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import { AnimatedEllipsis } from "./AnimatedEllipsis";

export const TextLoader = ({
  children = "loading",
  ...props
}: {
  children?: React.ReactNode;
} & React.DOMAttributes<HTMLSpanElement>): React.ReactElement => (
  <span {...props}>
    {children}
    <AnimatedEllipsis />
  </span>
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const primaryColorStyle = (theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
});

const CircularProgress = withStyles(primaryColorStyle)(MuiCircularProgress);
export const LinearProgress = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    margin: "-2px 0", // margin prevents it from taking up vertical space in the layout
  },
}))(MuiLinearProgress);

export interface LoaderPropsT extends React.DOMAttributes<HTMLDivElement> {
  absolute?: boolean;
  className?: string;
  style?: React.CSSProperties;
}
export const Loader = ({
  absolute = false,
  className,
  ...props
}: LoaderPropsT): React.ReactElement => (
  <div
    className={clsx(
      "flex justify-center items-center",
      absolute && "absolute left-0 right-0 top-0 bottom-0",
      className,
    )}
    style={{ minHeight: 200 }}
    data-testid="loader"
    {...props}
  >
    <CircularProgress size={60} />
  </div>
);

export type LazyLoaderProps = LoaderPropsT & { delay?: number };

export function LazyLoader({ delay = 250, ...props }: LazyLoaderProps): React.ReactElement {
  const [showLoader, setShowLoader] = React.useState(false);
  React.useEffect(() => {
    // show the loader after the given delay in milliseconds
    const timeoutId = setTimeout(setShowLoader, delay, true);
    return () => clearTimeout(timeoutId);
  }, [delay]);
  return showLoader ? (
    <Loader {...props} />
  ) : (
    <div style={{ minHeight: 200 }} data-testid="loaderPlaceholder" />
  );
}
