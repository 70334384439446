import { Color } from "@mui/material";
import { PaletteColor } from "@mui/material/styles/createPalette";

export type TpColorT = Omit<Color, "A100" | "A200" | "A400" | "A700">;
export type TalentpairColorT = TpColorT & PaletteColor;

const green: TalentpairColorT = {
  "50": "#e4f5ec",
  "100": "#bfe5d0",
  "200": "#95d5b3",
  "300": "#68c595",
  "400": "#41b97f",
  "500": "#00ad69",
  "600": "#009e5f",
  "700": "#008c52",
  "800": "#007a46",
  "900": "#005b31",
  contrastText: "#fff",
  main: "#00ad69",
  light: "#77ecae",
  dark: "#008851",
};

const pink: TalentpairColorT = {
  "50": "#f9e7f1",
  "100": "#f1c2dd",
  "200": "#eb9cc6",
  "300": "#e676af",
  "400": "#e35c9c",
  "500": "#e2498a",
  "600": "#d04584",
  "700": "#b9417b",
  "800": "#a33d73",
  "900": "#7b3663",
  contrastText: "#fff",
  main: "#b9417b",
  light: "#ee72aa",
  dark: "#85004f",
};

const purple: TalentpairColorT = {
  "50": "#f0e8f6",
  "100": "#d8c7ea",
  "200": "#bea2dd",
  "300": "#a57ccf",
  "400": "#925ec4",
  "500": "#7f41b9",
  "600": "#763bb3",
  "700": "#6a33a9",
  "800": "#5f2da1",
  "900": "#4e2093",
  contrastText: "#fff",
  main: "#7f41b9",
  light: "#b26fec",
  dark: "#4d1188",
};

const grey: TpColorT = {
  "50": "#fafafa",
  "100": "#f5f5f5",
  "200": "#eee",
  "300": "#e0e0e0",
  "400": "#bdbdbd",
  "500": "#9e9e9e",
  "600": "#757575",
  "700": "#616161",
  "800": "#424242",
  "900": "#212121",
};

const red: TpColorT = {
  "50": "#ffebee",
  "100": "#ffcdd2",
  "200": "#ef9a9a",
  "300": "#e57373",
  "400": "#ef5350",
  "500": "#f44336",
  "600": "#e53935",
  "700": "#d32f2f",
  "800": "#c62828",
  "900": "#b71c1c",
};

const amber: TpColorT = {
  "50": "#fff8e1",
  "100": "#ffecb3",
  "200": "#ffe082",
  "300": "#ffd54f",
  "400": "#ffca28",
  "500": "#ffc107",
  "600": "#ffb300",
  "700": "#ffa000",
  "800": "#ff8f00",
  "900": "#ff6f00",
};

const blue: TpColorT = {
  "50": "#e7edfe",
  "100": "#c3d3fc",
  "200": "#9bb5fa",
  "300": "#7297f7",
  "400": "#5481f6",
  "500": "#366bf4",
  "600": "#3063f3",
  "700": "#2958f1",
  "800": "#224eef",
  "900": "#163cec",
};

export const colors = {
  green,
  pink,
  purple,
  grey,
  red,
  amber,
  blue,
};
