import * as React from "react";
import { env, londonLink } from "@talentpair/env";
import { Badge, BadgePropsT } from "./Badge";

export interface OrgTpBadgePropsT extends Partial<BadgePropsT> {
  org: { id: number };
}
export const OrgTpBadge = ({ org, href, ...props }: OrgTpBadgePropsT): React.ReactElement => (
  <Badge
    text="ACTIVE"
    data-testid="platform-badge"
    href={href != null ? href : env.londonUrl(londonLink.tapJobList(org.id))}
    {...props}
  />
);
