import { makeCancelable, CancelablePromiseT } from "./async";

export type PingConfigT<R> = {
  request: () => Promise<R>;
  isComplete: (rsp: R) => boolean;
  madeProgress?: (rsp: R, prevRsp: R | null) => boolean;
  interval?: number;
  immediate?: boolean;
  timeout?: number;
  timeoutMsg?: (rsp: R) => string;
  onPing?: (rsp: R) => void;
};

export const PING_TIMEOUT = "PING_TIMEOUT";

export function ping<R>({
  request,
  isComplete,
  madeProgress,
  interval = 2500,
  immediate = true,
  timeout = 60000,
  timeoutMsg,
  onPing,
}: PingConfigT<R>): CancelablePromiseT<R> {
  let id: NodeJS.Timeout | undefined;
  const promise = new Promise<R>((resolve, reject) => {
    let count = immediate ? 0 : 1;
    let prevRsp: R | null = null;

    const makeRequest = (): Promise<void> =>
      request()
        .then((rsp: R) => {
          count += 1;
          if (onPing) onPing(rsp);
          if (isComplete(rsp)) {
            if (id) clearInterval(id);
            resolve(rsp);
          } else if (madeProgress && madeProgress(rsp, prevRsp)) {
            // If progress is made, start the timeout over.
            count = 0;
          } else if (count > timeout / interval) {
            // stop after one minute (or whatever the timeout is)
            if (id) clearInterval(id);
            const error: Error & { response?: R } = new Error(
              `${PING_TIMEOUT}${timeoutMsg ? `: ${timeoutMsg(rsp)}` : ""}`,
            );
            error.response = rsp;
            reject(error);
          }
          prevRsp = rsp;
        })
        .catch((err: Error) => {
          if (id) clearInterval(id);
          reject(err);
        });

    // eslint-disable-next-line @typescript-eslint/no-implied-eval, @typescript-eslint/no-misused-promises
    id = setInterval(makeRequest, interval);
    if (immediate) makeRequest();
  });

  // Overwrite the default `cancel()` with one that cancels both the promise and the setInterval
  const cancelablePromise = makeCancelable<R>(promise);
  const cancelPromise = cancelablePromise.cancel;
  cancelablePromise.cancel = (): void => {
    if (id) clearInterval(id);
    cancelPromise();
  };

  return cancelablePromise;
}

export function pingWithAbort<R>({
  request,
  isComplete,
  madeProgress,
  interval = 2500,
  immediate = true,
  timeout = 60000,
  timeoutMsg,
  onPing,
}: PingConfigT<R>): CancelablePromiseT<R> {
  let prevRsp: R | null = null;
  let count = immediate ? 0 : 1;
  let resolveFn: (value: R | PromiseLike<R>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let rejectFn: (reason?: any) => void;
  const controller = new AbortController();

  const makeRequest = async (): Promise<void> => {
    try {
      const rsp = await request();
      if (onPing) onPing(rsp);
      if (isComplete(rsp)) {
        controller.abort();
        resolveFn(rsp);
      } else if (madeProgress && madeProgress(rsp, prevRsp)) {
        // If progress is made, start the timeout over.
        controller.signal.addEventListener(
          "abort",
          () => {
            rejectFn(new Error("Request cancelled."));
          },
          { once: true },
        );
      } else if (count > timeout / interval) {
        // stop after one minute (or whatever the timeout is)
        controller.abort();
        const error: Error & { response?: R } = new Error(
          `${PING_TIMEOUT}${timeoutMsg ? `: ${timeoutMsg(rsp)}` : ""}`,
        );
        error.response = rsp;
        rejectFn(error);
      }
      prevRsp = rsp;
      count += 1;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.name === "AbortError") {
        rejectFn(new Error("Request cancelled."));
      } else {
        rejectFn(err);
      }
    }
  };

  const promise = new Promise<R>((resolve, reject) => {
    resolveFn = resolve;
    rejectFn = reject;

    // eslint-disable-next-line @typescript-eslint/no-implied-eval, @typescript-eslint/no-misused-promises
    const id = setInterval(makeRequest, interval);
    if (immediate) makeRequest();

    controller.signal.addEventListener(
      "abort",
      () => {
        clearInterval(id);
      },
      { once: true },
    );
  }) as CancelablePromiseT<R>;

  promise.cancel = () => {
    controller.abort();
  };

  return promise;
}
