import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { CapNotificationsHeader, NotificationsMeta } from "./containers/NotificationsHeader";
import { SideNavigation } from "../../tap/components/Navigation/SideNavigation";

export const CapNotificationsPage = page({
  mobile: React.lazy(() => import("./containers/NotificationsPage")),
  header: CapNotificationsHeader,
  meta: NotificationsMeta,
});

const TapNotifications = React.lazy(() => import("./containers/NotificationsPage"));

export const TapNotificationsPage = page({
  desktop: TapNotifications,
  mobile: TapNotifications,
  // @ts-expect-error - it's okay
  desktopSidenav: SideNavigation,
  header: TapHeader,
  meta: NotificationsMeta,
});
