import * as React from "react";
import { env, londonLink } from "@talentpair/env";
import { TAP_STATUS } from "@talentpair/entities/job";
import { Badge, BadgePropsT } from "./Badge";

const jobBadgeColor = {
  ACTIVE: "bg-green-500",
  PAUSED: "bg-amber-500",
  CLOSED: "bg-red-500",
};

export interface JobTpBadgePropsT extends Partial<BadgePropsT> {
  job: {
    id: number;
    tap_status: {
      id: number;
    };
  };
  orgId: number;
}
export const JobTpBadge = ({
  job: { id, tap_status },
  orgId,
  href,
  ...props
}: JobTpBadgePropsT): React.ReactElement => {
  const text =
    tap_status.id === TAP_STATUS.PAUSED
      ? "PAUSED"
      : tap_status.id === TAP_STATUS.CLOSED
      ? "CLOSED"
      : "ACTIVE";

  return (
    <Badge
      text={text}
      bgColor={jobBadgeColor[text]}
      data-testid="platform-badge"
      href={href != null ? href : env.londonUrl(londonLink.tapJobInfo(orgId, id))}
      {...props}
    />
  );
};
