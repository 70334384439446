import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { OrgBillingMeta } from "./containers/OrgBillingHeader";
import { SideNavigation } from "../components/Navigation/SideNavigation";

const OrgBilling = React.lazy(() => import("./containers/OrgBillingPage"));

export const OrgBillingPage = page({
  // @ts-expect-error - fix later
  mobile: OrgBilling,
  // @ts-expect-error - fix later
  desktop: OrgBilling,
  // @ts-expect-error - fix later
  desktopSidenav: SideNavigation,
  header: TapHeader,
  meta: OrgBillingMeta,
});
