import * as React from "react";
import clsx from "clsx";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge } from "@mui/material";
import { userService, NotificationCountStore } from "@talentpair/api";
import { londonLink } from "@talentpair/env";
import { MenuItem } from "@talentpair/quantic";
import { connect, DispatchT } from "@talentpair/redux";
import { RouteComponentProps } from "@talentpair/routing";
import { StateT } from "../../../store";
import NotificationAction from "../store/notificationActions";
import { ViewStateT } from "../store/notificationReducers";

type DispatchToPropsT = { refreshList: (value: ViewStateT) => void };
type PropsT = RouteComponentProps &
  DispatchToPropsT & {
    isTap?: boolean;
  };

const BaseNotificationsMenuItem = ({ refreshList, isTap = false }: PropsT): React.ReactElement => {
  const [stats, setStats] = React.useState(NotificationCountStore.getState());
  React.useEffect(
    () => NotificationCountStore.subscribe((count, hasNew) => setStats({ count, hasNew })),
    [],
  );
  const { count, hasNew } = stats;

  const href = isTap
    ? londonLink.tapNotifications(userService.orgId())
    : londonLink.capNotifications(userService.data?.candidate_id || 0);
  const active = location.pathname === href;
  return (
    <MenuItem
      data-testid="viewNotificationsButton"
      component="a"
      icon={
        <div className="pr1">
          <Badge
            max={9}
            badgeContent={count}
            classes={{ badge: clsx({ "bg-grey-600": !hasNew }) }}
            color="error"
          >
            <NotificationsIcon fontSize="medium" />
          </Badge>
        </div>
      }
      href={href}
      onClick={active ? () => refreshList("all") : undefined}
    >
      Notifications
    </MenuItem>
  );
};

const mapDispatchToProps = (dispatch: DispatchT): DispatchToPropsT => ({
  refreshList: (view: ViewStateT) => {
    dispatch(NotificationAction.setView(view));
  },
});

export const NotificationsMenuItem = connect<void, DispatchToPropsT, RouteComponentProps, StateT>(
  undefined,
  mapDispatchToProps,
  // eslint-disable-next-line @typescript-eslint/ban-types
)(BaseNotificationsMenuItem) as React.ComponentType<{}>;

export const _test = { mapDispatchToProps };
