import { RouteComponentProps } from "@talentpair/routing";
import { createCapHeader, createMeta } from "../../../components/hocs/createCapHeader";
import { StateT } from "../../../store";
import { getCandidateName } from "../../store/candidateSelectors";
// @ts-expect-error - REMOVE ME
import { paramLink } from "../../utils/page";

type TitlePropsT = { title: string };

const titleSelector = (
  state: StateT,
  { match }: RouteComponentProps<{ candidate: string }>,
): TitlePropsT => ({
  title: getCandidateName(state, +match.params.candidate),
});

export const PairListHeader = createCapHeader(titleSelector, paramLink.capPairList, true);
export const PairListMeta = createMeta(titleSelector);

export const _test = { titleSelector };
