import { RouteComponentProps } from "@talentpair/routing";
import { londonLink } from "@talentpair/env";
import { createCapHeader, createMeta } from "../../../components/hocs/createCapHeader";

const backFn = ({ match }: RouteComponentProps<{ candidate?: string }>): string =>
  match.params.candidate ? londonLink.capPairList(match.params.candidate) : "/";

const title = "Your profile";
export const CapDesiredNextRoleHeader = createCapHeader(title, backFn, true);
export const CapDesiredNextRoleMeta = createMeta(title);
