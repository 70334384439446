import { uniq } from "kyoto/utils/array";
import type { CursorPaginationT } from "@talentpair/types/misc";
import type {
  ActivityItemsActionT,
  ActivityLoadingT,
  ActivityReceiveLastFetchT,
  ActivityReceiveLatestT,
  ActivityReceiveListActionT,
} from "./activityActions";
import {
  ACTIVITY_LOADING,
  ACTIVITY_RECEIVE_ITEM,
  ACTIVITY_REMOVE_ITEM,
  ACTIVITY_RECEIVE_LAST_FETCH,
  ACTIVITY_RECEIVE_LATEST,
  ACTIVITY_RECEIVE_LIST,
} from "./activityActions";

export type SharedActivityDataReduxT = {
  items: number[];
  lastFetched: string;
  latestActivity: string;
  loading: boolean;
  pagination: CursorPaginationT;
};

export const initialState: SharedActivityDataReduxT = {
  items: [],
  loading: false,
  lastFetched: "",
  latestActivity: "",
  pagination: {
    next: null,
    previous: null,
    page_size: 0,
  },
};

function items(state: number[] = initialState.items, action: ActivityItemsActionT): number[] {
  switch (action.type) {
    case ACTIVITY_RECEIVE_ITEM:
      return uniq([...state, action.item.id]);

    case ACTIVITY_REMOVE_ITEM:
      return state.filter((id) => id !== action.itemId);

    case ACTIVITY_RECEIVE_LIST:
      return action.pagination.previous
        ? uniq([...state, ...action.results.map(({ id }) => id)])
        : action.results.map(({ id }) => id);

    default:
      return state;
  }
}

function lastFetched(
  state: string = initialState.lastFetched,
  action: ActivityReceiveLastFetchT,
): string {
  switch (action.type) {
    case ACTIVITY_RECEIVE_LAST_FETCH:
      return action.lastFetched;

    default:
      return state;
  }
}

function latestActivity(
  state: string = initialState.latestActivity,
  action: ActivityReceiveLastFetchT | ActivityReceiveLatestT,
): string {
  switch (action.type) {
    case ACTIVITY_RECEIVE_LAST_FETCH:
      return action.lastFetched;

    case ACTIVITY_RECEIVE_LATEST:
      return action.latestActivity;

    default:
      return state;
  }
}

function loading(state: boolean = initialState.loading, action: ActivityLoadingT): boolean {
  switch (action.type) {
    case ACTIVITY_LOADING:
      return action.loading;

    default:
      return state;
  }
}

function pagination(
  state: CursorPaginationT = initialState.pagination,
  action: ActivityReceiveListActionT,
): CursorPaginationT {
  switch (action.type) {
    case ACTIVITY_RECEIVE_LIST:
      return action.pagination;

    default:
      return state;
  }
}

export default {
  items,
  lastFetched,
  latestActivity,
  loading,
  pagination,
};
