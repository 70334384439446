import { matchPath, match as MatchType } from "@talentpair/routing";

export type MatchPathOptionsT = {
  path?: string;
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
};
export type RoutesT = Map<string, MatchPathOptionsT>;

export type MatchDataT = {
  appType: string;
  appSubtype: string | null | undefined;
  match: MatchType<Record<string, string | undefined>>;
};

export function determineRoute(routes: RoutesT, pathname: string): MatchDataT {
  let match;
  let app = "404";

  for (const [key, value] of routes) {
    match = matchPath(pathname, value);
    if (match) {
      app = key;
      break;
    }
  }

  const appParts = app.split("/");

  return {
    appType: appParts[0],
    appSubtype: appParts[1],
    match: match || { path: "404", params: {}, isExact: false, url: pathname },
  };
}
