import * as React from "react";
import { Media } from "@talentpair/quantic";
import { toTruthy } from "kyoto/utils/array";
import tapNavigation1 from "../assets/tap-navigation-ui-1.svg";
import tapNavigation2 from "../assets/tap-navigation-ui-2.svg";
import tapNavigation3 from "../assets/tap-navigation-ui-3.svg";
import Tooltip from "../ui/Tooltip";
import Tour from "../Tour";
import { BG_COLOR } from "../utils";

export default ({
  step,
  ...props
}: {
  step: number;
  children: React.ReactNode;
}): React.ReactElement => (
  <Media>
    {(isDesktop) => (
      <Tour
        tour="onboarding_tap_navigation_ui"
        component={Tooltip}
        doneText="Got it"
        componentProps={{
          fixed: true,
          placement: {
            mobile: "bottom",
            desktop: step === 0 ? "right" : step === 1 ? "right-start" : "bottom",
          },
        }}
        // There's only 2 steps in the mobile variant
        step={isDesktop ? step : Math.min(1, step)}
        slides={[
          {
            background: BG_COLOR.BLUE_2,
            img: tapNavigation1,
            text: `Use the ${isDesktop ? "sidenav" : "menu"} to navigate around Talentpair.`,
          },
          isDesktop
            ? {
                background: BG_COLOR.GREEN_2,
                img: tapNavigation2,
                text: "Find your candidates and their pipeline status conveniently in this section.",
              }
            : null,
          {
            background: BG_COLOR.YELLOW_2,
            img: tapNavigation3,
            text: "Use the search bar to navigate quickly to candidates and jobs.",
          },
        ].filter(toTruthy)}
        {...props}
      />
    )}
  </Media>
);
