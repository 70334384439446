import * as React from "react";

export default ({ color }: { color: string }): React.ReactElement => (
  <svg height="29" viewBox="0 0 47 29" width="47" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path id="b" d="M44.539 1L24.092 21 3 1z" />
      <filter id="a" height="260%" width="174.6%" x="-37.3%" y="-32.5%">
        <feMorphology in="SourceAlpha" radius="3.5" result="shadowSpreadOuter1" />
        <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.118716033 0"
        />
        <feMorphology in="SourceAlpha" radius="2.5" result="shadowSpreadOuter2" />
        <feOffset dy="6" in="shadowSpreadOuter2" result="shadowOffsetOuter2" />
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="5" />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0867017663 0"
        />
        <feMorphology in="SourceAlpha" radius=".5" result="shadowSpreadOuter3" />
        <feOffset dy="4" in="shadowSpreadOuter3" result="shadowOffsetOuter3" />
        <feGaussianBlur in="shadowOffsetOuter3" result="shadowBlurOuter3" stdDeviation="3" />
        <feColorMatrix
          in="shadowBlurOuter3"
          result="shadowMatrixOuter3"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.20142663 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="shadowMatrixOuter3" />
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill={color} xlinkHref="#b" />
    </g>
  </svg>
);
