import perryIcon from "@talentpair/assets/images/perry-192.png";
import { SmallUserT } from "@talentpair/types/entities/user";
import { dateFormat } from "@talentpair/datetime";

export type FirstLastNameT = {
  id: number;
  first_name?: null | string;
  last_name?: null | string;
};

export function displayFullName<
  User extends { first_name?: null | string; last_name?: null | string },
>({ first_name, last_name }: User, defaultVal?: unknown): string {
  if (first_name) {
    return last_name ? `${first_name} ${last_name}` : first_name;
  }

  // Explicit check to avoid iteration counter truthiness, e.g. users.map(displayFullName)
  return last_name || (typeof defaultVal === "string" ? defaultVal : "Unknown User");
}

export function loginStatus(
  last_active?: string | null | undefined,
  login_blocked?: boolean | null | undefined,
): string {
  // Explicity returning empty string until all data is loaded (in case redux only has partial user)
  if (last_active === undefined || login_blocked === undefined) return "";
  if (login_blocked) return "Not invited";
  if (!last_active) return "Invite pending";
  return `Last seen: ${dateFormat.fromNow(last_active)}`;
}

type NamedT = {
  name: string;
  [key: string]: unknown;
};

interface UserWithRoleAndCompanyT {
  role?: NamedT | null;
  company?: NamedT | null;
  organization?: NamedT | null;
}
export const displayRoleAndCompany = (user: UserWithRoleAndCompanyT): string => {
  const role = user.role && user.role.name;
  const company: string | null = user.organization
    ? user.organization.name
    : user.company
    ? user.company.name
    : null;
  if (company && role) return `${role} @ ${company}`;
  return company || role || "Unknown Role";
};

export const TalentpairUser: SmallUserT = {
  badness_reported: false,
  candidate: null,
  company: null,
  first_name: "Talentpair",
  flag: "",
  has_email: true,
  id: 199711,
  is_active: false,
  last_active: null,
  last_communication: "2019-09-12T09:27:39.026684-07:00",
  last_login: null,
  last_name: "",
  location: null,
  login_blocked: true,
  no_longer_employed: false,
  organization: {
    id: 2,
    name: "Talentpair",
  },
  profile_picture_medium: perryIcon,
  role: null,
  username: "luther",
  zetid: null,
};
