import * as React from "react";
import { auth, userService } from "@talentpair/api";
import { RouteProps } from "@talentpair/routing";
import { UserDataT } from "@talentpair/types/userService";
import { skipSignupRedirect } from "../../routeConfig";
import { LoginRedirect, HomeRedirect } from "../Redirects";
import { BaseRoute } from "./BaseRoute";

type CustomCheckT = (userData?: UserDataT) => boolean;
interface AuthRouteProps extends RouteProps {
  customCheck?: CustomCheckT | null;
  failureRedirect?: React.ComponentType<Record<string, unknown>> | null;
  loggedOutRedirect?: React.ComponentType<Record<string, unknown>> | null;
}
interface AuthRouteState {
  status: "pending" | "loggedOut" | "failure" | "success";
}

export class AuthRoute extends React.Component<AuthRouteProps, AuthRouteState> {
  static defaultProps = {
    customCheck: null,
    failureRedirect: HomeRedirect,
    // Allows you to decide if it should loggedOutRedirect on failure or not.
    loggedOutRedirect: LoginRedirect,
  };

  state: AuthRouteState = { status: "pending" };

  componentDidMount(): void {
    const { customCheck } = this.props;
    auth.isLoggedin(skipSignupRedirect).then(
      (userData) =>
        this.setState({
          status: !customCheck || customCheck(userData) ? "success" : "failure",
        }),
      ({ message }: Error) => {
        if (!message.includes("redirect handled")) this.setState({ status: "loggedOut" });
      }, // onRejected
    );
  }

  render(): React.ReactNode {
    const {
      customCheck,
      failureRedirect: FailureRedirect,
      loggedOutRedirect: LoggedOutRedirect,
      ...props
    } = this.props;
    const { status } = this.state;
    if (LoggedOutRedirect && status === "loggedOut") return <LoggedOutRedirect />;
    if (FailureRedirect && status === "failure") return <FailureRedirect />;
    if (status === "success") return <BaseRoute {...props} />;
    return null;
  }
}

const capUser: CustomCheckT = () => userService.hasCapAccess();
const tapUser: CustomCheckT = () => userService.hasTapAccess();
const recruitUser: CustomCheckT = () => userService.isRecruiter();
const qaUser: CustomCheckT = () => userService.isQaUser();
const isPlatformUser: CustomCheckT = () => !userService.isRecruiter();

export const CapAuthRoute: React.ComponentType<AuthRouteProps> = (props) => (
  <AuthRoute customCheck={capUser} {...props} />
);
export const TapAuthRoute: React.ComponentType<AuthRouteProps> = (props) => (
  <AuthRoute customCheck={tapUser} {...props} />
);
export const RecruitAuthRoute: React.ComponentType<AuthRouteProps> = (props) => (
  <AuthRoute customCheck={recruitUser} {...props} />
);
export const QaAuthRoute: React.ComponentType<AuthRouteProps> = (props) => (
  <AuthRoute customCheck={qaUser} {...props} />
);
export const PlatformAuthRoute: React.ComponentType<AuthRouteProps> = (props) => (
  <AuthRoute customCheck={isPlatformUser} {...props} />
);
