import { LinkT } from "@talentpair/types/entities/misc";
import { displayPhoneNumber, justDomain } from "../../utils/string";
import { ChoiceValT, FormikChoiceT } from "./Formik/choiceFieldTypes";

export const defaultParserFormatter = {
  // @ts-expect-error - eslint upgrade
  parse: <V, F>(f: F): V => f,
  // @ts-expect-error - eslint upgrade
  format: <V, F>(v: V): F => v,
};

// Don't transform if the value isn't set yet
export const numberToBooleanParserFormatter = {
  parse: (f: string | null): boolean | null => (f == null ? f : f === "1"),
  format: (v: boolean | null): string | null => {
    if (v == null) return v;
    return v ? "1" : "0";
  },
};

export const parseTrimmedText = (value: string): string => value.trimLeft();

export const createEmptyToNullParser =
  <V, F>(parse: (val: F) => V | null) =>
  (val: F): V | null => {
    const parsed = parse(val);
    return (parsed as unknown as string) === "" ? null : parsed;
  };

type IdChoiceT<ID extends ChoiceValT> = { id: ID } | { id: ID | null };
interface ParseFormatReturnT<ID extends ChoiceValT, V extends IdChoiceT<ID>> {
  parse: (id: ChoiceValT | null) => V | null;
  format: (choice: V | null) => ID | null;
}
export function createChoiceParserFormatter<ID extends number | string, V extends IdChoiceT<ID>>(
  choices: V[],
): ParseFormatReturnT<ID, V> {
  return {
    parse: (id): V | null =>
      !id ? null : choices.find((c) => c.id === id || c.id === Number(id)) || null,
    format: (choice): ID | null => (choice ? choice.id : null),
  };
}

type ParseT = (id: ChoiceValT[]) => FormikChoiceT[];
type FormatT = (choice: FormikChoiceT[]) => ChoiceValT[];
interface ChoiceListT {
  parse: ParseT;
  format: FormatT;
}
export const createChoiceListParserFormatter = (choices: FormikChoiceT[]): ChoiceListT => ({
  parse: (ids: ChoiceValT[]): FormikChoiceT[] => {
    const numericIds = ids.map(Number);
    return choices.filter(({ id }) => id !== null && numericIds.includes(+id));
  },
  format: (value: FormikChoiceT[]): ChoiceValT[] => value.map(({ id }) => (id ? +id : 0)),
});

interface InvertedChoiceListT {
  parse: FormatT;
  format: ParseT;
}
export const invert = ({ parse, format }: ChoiceListT): InvertedChoiceListT => ({
  parse: format,
  format: parse,
});

export const strToNumberParser = (val: string | null): number | null => (val == null ? val : +val);

export const linkParserFormatter = {
  parse: (url: string): { url: string } => ({ url }),
  format: (link: (LinkT | string) | null): string =>
    !link ? "" : typeof link === "string" ? link : link.url,
};

export const emailAddressParserFormatter = {
  parse: (address: string): { address: string } => ({ address }),
  format: (email: ({ address: string } | string) | null): string =>
    !email ? "" : typeof email === "string" ? email : email.address,
};

export const mentionParserFormatter = {
  parse: (v: string): string => (v.startsWith("@") ? v.slice(1) : v).toLowerCase(),
  format: (v: string): string => `@${v.replace(/[^\w]/g, "")}`,
};

// This regexp is used to determine if the phone number has been formatted previously, but now the user is editing only a single section of the number
// Using this prevents the cursor being reset to the the end of the field when the user is attempting to modify a sub-section
// Comprised of three core patterns:
//    `\(\d{3}\) \d{3}-\d{0,4}` -- user is editing the line number
//    `\(\d{3}\) \d{0,3}-\d{4}` -- user is editing the central office code
//    `\(\d{0,3}\) \d{3}-\d{4}` -- user is editing the area code
const formattedNumber =
  /^(?:\(\d{3}\) \d{3}-\d{0,4})|(?:\(\d{3}\) \d{0,3}-\d{4})|(?:\(\d{0,3}\) \d{3}-\d{4})$/g;
export const phoneNumberFormatter = (number?: string | null): string => {
  if (!number) return "";
  return number.match(formattedNumber) ? number : displayPhoneNumber(number);
};

export const phoneNumberParser = (value: string): string => value.replace(/[^\d+\-() ]/g, "");

export type ValueT = string | number;

// strips leading zeros but keeps negative and preserves a single leading zero if it precedes a decimal
export const stripLeadingZeros = (val: string): string => val.replace(/^(-)?0+(?!\.|$)/g, "$1");

// Currently just supporting integers
// Doesn't support number with cents. will treat cents like whole dollars i.e. $50.25 becomes $5025
export const parseNumber = (val: ValueT | null): ValueT => {
  if (val === "" || val === "-" || typeof val === "number") return val;
  const valid = val ? val.replace(/[^-\d]/g, "") : "";
  return valid !== "" ? parseInt(valid, 10) : "";
};

export const parseNumberOrNull = createEmptyToNullParser(parseNumber);

export const parseDecimal = (val: ValueT | null): ValueT => {
  if (val === "" || val === "-" || val === "0" || typeof val === "number") return val;
  const valid = stripLeadingZeros(val ? val.replace(/[^-\d.]/g, "") : "");
  return valid !== "" && !valid.endsWith(".") ? +valid : valid;
};
export const parseDecimalOrNull = createEmptyToNullParser(parseDecimal);

export const formatDecimal = (val: ValueT | null): string => `${parseDecimal(val)}`;

export const commafy = (val: ValueT | null): string => {
  const parsed = parseNumber(val);
  return typeof parsed === "number" ? parsed.toLocaleString("en-US") : parsed;
};

export const parseDomain = (url: string): string => justDomain(url) || "";
