import { createSelector } from "@talentpair/redux";
import { displayFullName } from "@talentpair/entities/user";
import type { CapCandidateDetailReduxT } from "@talentpair/types/cap/entities/candidate";
import "@talentpair/types/cap/entities/candidate";
import type { StateT } from "../../store";

export const getCandidate = ({ cap }: StateT, id: number): CapCandidateDetailReduxT | null =>
  cap.candidates[id] || null;

export const getCandidateName = createSelector([getCandidate], (candidate) =>
  candidate ? displayFullName(candidate.user, "Talentpair") : "",
);

export const profileActivated = createSelector(
  [getCandidate],
  (candidate) => candidate && candidate.cap_activated,
);

export const profileComplete = createSelector(
  [getCandidate],
  (candidate) => !!candidate && !!candidate.ready_to_send,
);

export const canSendCandidate = createSelector(
  [profileComplete, profileActivated],
  (complete, activated) => !!complete && !!activated,
);
