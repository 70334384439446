// @flow
import { displayFullName } from "@talentpair/entities/user";
import type { ContextRouter } from "@talentpair/routing";
import { createCapHeader, createMeta } from "../../../components/hocs/createCapHeader";
import type { StateT } from "../../../store";
import { getCandidate } from "../../store/candidateSelectors";
import { paramLink } from "../../utils/page";

const titleFn = (state: StateT, { match: { params } }: ContextRouter) => {
  const candidate = getCandidate(state, +params.candidate);
  return { title: candidate && candidate.owner ? displayFullName(candidate.owner) : "" };
};

export const CapUserActivityHeader = createCapHeader(titleFn, paramLink.capPairList);
export const CapUserActivityMeta = createMeta(titleFn);

export const _test = { titleFn };
