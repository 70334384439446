/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { mixpanelHelper } from "@talentpair/tracking";
import { shouldIgnoreInputEvent, shouldIgnoreInteractionEvent } from "../utils/misc";

export const ignoreFieldEvents = (e: KeyboardEvent): boolean => shouldIgnoreInputEvent(e);
export const ignoreFieldAndModalEvents = (e: KeyboardEvent): boolean =>
  shouldIgnoreInteractionEvent(e);

export type ModifierKey = Extract<keyof KeyboardEvent, "metaKey" | "ctrlKey" | "altKey">;
const modifierKeys: ModifierKey[] = ["metaKey", "ctrlKey", "altKey"];
export const ignoreModifierEvents = (e: KeyboardEvent): boolean => modifierKeys.some((k) => e[k]);

export const ignoreModifierAndFieldEvents = (e: KeyboardEvent): boolean =>
  ignoreModifierEvents(e) || ignoreFieldEvents(e);

export const ignoreAllEvents = (e: KeyboardEvent): boolean =>
  ignoreModifierEvents(e) || ignoreFieldAndModalEvents(e);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const ignoreWithoutModifiers = (modifiers: ModifierKey[]) => {
  const ignoredModifiers = modifierKeys.filter((k) => !modifiers.includes(k));
  return (e: KeyboardEvent): boolean =>
    !modifiers.some((k) => e[k]) || ignoredModifiers.some((k) => e[k]);
};
export type EventKey = string;
export type KeyHandler<R = unknown> = (event: KeyboardEvent) => R;
type EventHandlerConfig = {
  shouldIgnore: KeyHandler<boolean>;
  handler: KeyHandler;
};
type Handlers = Record<string, EventHandlerConfig[]>;

const eventHandlers = {
  keydown: {} as Handlers,
  keyup: {} as Handlers,
  keypress: {} as Handlers,
};
export type EventType = keyof typeof eventHandlers;

function handleEvent(e: KeyboardEvent, handlers: Handlers): boolean {
  if (!handlers[e.key]) return false;
  const handles = handlers[e.key].filter((h) => !h.shouldIgnore(e));
  if (!handles.length) return false;
  e.preventDefault();
  for (const { handler } of handles) handler(e);
  mixpanelHelper.track("Hotkey", { key: e.key, type: e.type });
  return true;
}

function onKeyDown(e: KeyboardEvent): void {
  handleEvent(e, eventHandlers.keydown);
}
function onKeyUp(e: KeyboardEvent): void {
  handleEvent(e, eventHandlers.keyup);
}
function onKeyPress(e: KeyboardEvent): void {
  handleEvent(e, eventHandlers.keypress);
}

document.addEventListener("keydown", onKeyDown);
document.addEventListener("keyup", onKeyUp);
document.addEventListener("keypress", onKeyPress);

const hotkeyService = {
  register(
    type: EventType,
    key: EventKey,
    handler: KeyHandler,
    shouldIgnore?: KeyHandler<boolean>,
  ): () => void {
    const handlers = eventHandlers[type];
    if (!handlers[key]) handlers[key] = [];
    handlers[key].push({
      handler,
      shouldIgnore: shouldIgnore || ignoreAllEvents,
    });
    return () => hotkeyService.unregister(type, key, handler);
  },

  unregister(type: EventType, key: EventKey, handler: KeyHandler): void {
    const handlers = eventHandlers[type];
    if (!handlers[key]) return;
    handlers[key] = handlers[key].filter((h) => h.handler !== handler);
    if (!handlers[key].length) {
      delete handlers[key];
    }
  },
};
export default hotkeyService;

export const _test = { onKeyDown, onKeyUp, onKeyPress, eventHandlers };
