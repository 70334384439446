import { Loader } from "@talentpair/quantic";
import { ReactElement, ReactNode, Suspense } from "react";
import { ErrorBoundary } from "./ErrorBoundary";

export function SuspenseWrapper({ children }: { children: ReactNode }): ReactElement {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader absolute />}>{children}</Suspense>
    </ErrorBoundary>
  );
}
