// @flow
import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { OrgSettingsMeta } from "./containers/OrgSettingsHeader";
import { SideNavigation } from "../components/Navigation/SideNavigation";

// $FlowFixMe
export const OrgSettings = React.lazy(() => import("./containers/OrgSettingsPage"));

export const OrgSettingsPage = page({
  desktop: OrgSettings,
  mobile: OrgSettings,
  desktopSidenav: SideNavigation,
  sidebarOne: OrgSettings,
  header: TapHeader,
  meta: OrgSettingsMeta,
});
