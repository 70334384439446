import * as React from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "@talentpair/routing";
import { AppBar, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Media, Placeholder, Sidebar } from "@talentpair/quantic";
import { SuspenseWrapper } from "@talentpair/sentry";
import { createCapHeader, HeaderProps } from "../hocs/createCapHeader";

const Outer = withStyles({
  container: {
    minHeight: 0,
    position: "relative",
  },
})(Grid);

const Panel = withStyles({
  item: {
    display: "flex",
    height: "calc(100vh - 56px)",
    position: "relative",
    marginTop: 56,
    "@media (min-width:0px) and (orientation: landscape)": {
      height: "calc(100vh - 48px)",
      marginTop: 48,
    },
    "@media (min-width:600px)": {
      height: "calc(100vh - 64px)",
      marginTop: 64,
    },
  },
})(Grid);

interface WrapperPropsT extends RouteComponentProps {
  desktop: boolean;
  children: React.ReactNode;
  header: React.ComponentType<HeaderProps>;
  meta?: React.ComponentType<RouteComponentProps>;
}
const Wrapper = ({
  desktop,
  children,
  header: Header,
  meta: Meta,
  history,
  location,
  match,
}: WrapperPropsT): React.ReactElement => (
  <Outer container direction="column" justifyContent="space-between" wrap="nowrap">
    <Helmet defaultTitle="Talentpair" titleTemplate="%s | Talentpair" />
    {Meta && <Meta history={history} location={location} match={match} />}
    <AppBar elevation={0}>
      <Header history={history} location={location} match={match} desktop={desktop} />
    </AppBar>
    <Panel item>{children}</Panel>
  </Outer>
);

type DesktopPropsT = RouteComponentProps & {
  desktop: boolean;
  main?: React.ComponentType<RouteComponentProps>;
  sidenav?: React.ComponentType<RouteComponentProps>;
  sidebarOne?: React.ComponentType<HeaderProps>;
  sidebarTwo?: React.ComponentType<HeaderProps>;
  header: React.ComponentType<HeaderProps>;
  meta?: React.ComponentType<RouteComponentProps>;
};
export const Desktop = ({
  main: Main,
  sidenav: Sidenav,
  sidebarOne: SidebarOne,
  sidebarTwo: SidebarTwo,
  header,
  meta,
  desktop,
  ...props
}: DesktopPropsT): React.ReactElement => (
  <Wrapper header={header} meta={meta} desktop={desktop} {...props}>
    {Sidenav ? <Sidenav {...props} /> : null}
    {SidebarOne || !Sidenav ? (
      <Sidebar>{SidebarOne ? <SidebarOne {...props} /> : <Placeholder />}</Sidebar>
    ) : null}
    {SidebarTwo && (
      <Sidebar>
        <SidebarTwo {...props} />
      </Sidebar>
    )}
    <div
      className={`relative overflow-auto ${SidebarTwo ? "col-6" : SidebarOne ? "col-9" : "col-12"}`}
    >
      <SuspenseWrapper>
        {Main && Main !== SidebarOne ? <Main {...props} /> : <Placeholder />}
      </SuspenseWrapper>
    </div>
  </Wrapper>
);
Desktop.defaultProps = { main: null, sidebarOne: null, sidebarTwo: null };

type MobilePropsT = RouteComponentProps & {
  desktop: boolean;
  main: React.ComponentType<RouteComponentProps>;
  header: React.ComponentType<HeaderProps>;
  meta?: React.ComponentType<RouteComponentProps>;
};
export const Mobile = ({
  main: Main,
  header,
  meta,
  desktop,
  ...props
}: MobilePropsT): React.ReactElement => (
  <Wrapper header={header} meta={meta} desktop={desktop} {...props}>
    <div className="col-12">
      <SuspenseWrapper>
        <Main {...props} />
      </SuspenseWrapper>
    </div>
  </Wrapper>
);

const defaultHeader = createCapHeader("Talentpair");

export type PageConfigT = {
  desktop?: React.ComponentType<RouteComponentProps>;
  mobile:
    | React.ComponentType<RouteComponentProps>
    | React.LazyExoticComponent<React.ComponentType<RouteComponentProps>>;
  desktopSidenav?: React.ComponentType<RouteComponentProps>;
  sidebarOne?: React.ComponentType<HeaderProps>;
  sidebarTwo?: React.ComponentType<HeaderProps>;
  header?: React.ComponentType<HeaderProps>;
  meta?: React.ComponentType<RouteComponentProps>;
};
export const page =
  ({ mobile, desktop, desktopSidenav, sidebarOne, sidebarTwo, header, meta }: PageConfigT) =>
  (props: RouteComponentProps): React.ReactElement =>
    (
      <Media>
        {(isDesktop: boolean) =>
          isDesktop && (!!desktop || !!sidebarOne || !!sidebarTwo || !!desktopSidenav) ? (
            <Desktop
              desktop={isDesktop}
              main={desktop}
              sidenav={desktopSidenav}
              sidebarOne={sidebarOne}
              sidebarTwo={sidebarTwo}
              header={header || defaultHeader}
              meta={meta}
              {...props}
            />
          ) : (
            <Mobile
              desktop={isDesktop}
              main={mobile}
              header={header || defaultHeader}
              meta={meta}
              {...props}
            />
          )
        }
      </Media>
    );

export type HeaderConfigT = {
  desktop: React.ComponentType<HeaderProps>;
  mobile: React.ComponentType<HeaderProps>;
};
export const header =
  ({ mobile: M, desktop: D }: HeaderConfigT) =>
  (props: HeaderProps): React.ReactElement =>
    <Media>{(isDesktop: boolean) => (isDesktop ? <D {...props} /> : <M {...props} />)}</Media>;

export const _test = { Wrapper, Outer, Panel };
