import * as React from "react";
import lodash from "lodash";
import { ProfilePictureUserT } from "@talentpair/types/entities/user";
import { ChoiceT } from "@talentpair/types/entities/misc";
import { UserAvatar, UserAvatarPropsT } from "kyoto/components/ui/Avatars";
import { displayFullName } from "@talentpair/entities/user";
import DataListItem, { DataListItemProps } from "./DataListItem";

export type UserListUserT = ProfilePictureUserT & {
  email?: string | null;
  role?: ChoiceT | null;
  company?: ChoiceT | null;
};

export type UserListItemProps<U extends UserListUserT> = DataListItemProps & {
  user: U;
  badge?: React.ReactNode | null;
  UserAvatarProps?: Omit<UserAvatarPropsT<U>, "user">;
};

export default function UserListItem<U extends UserListUserT>({
  badge,
  user,
  primary,
  secondary,
  ListItemTextProps,
  UserAvatarProps,
  ...props
}: UserListItemProps<U>): React.ReactElement {
  // Only contacts have "organization", so use that if available
  const company =
    lodash.get(user, ["organization", "name"]) || lodash.get(user, ["company", "name"]);
  return (
    <DataListItem
      ListItemTextProps={ListItemTextProps || badge ? { ...ListItemTextProps, badge } : undefined}
      avatar={<UserAvatar user={user} {...UserAvatarProps} />}
      primary={
        primary !== undefined ? primary : displayFullName(user, "email" in user ? user.email : null)
      }
      secondary={
        secondary !== undefined ? (
          secondary
        ) : user.role || company ? (
          <span>
            {user.role && <span className="block truncate">{user.role.name}</span>}
            {company && <span className="block truncate">{company}</span>}
          </span>
        ) : undefined
      }
      {...props}
    />
  );
}
