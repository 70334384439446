import * as React from "react";
import { Pane, Item } from "@talentpair/quantic";

export default function UploadFailPane({
  children,
}: React.PropsWithChildren<{ didParse: boolean }>): React.ReactElement {
  return (
    <Pane>
      <Item>
        <span aria-label="frowning face" role="img" style={{ fontSize: "6em" }}>
          🙁
        </span>
      </Item>
      {children}
    </Pane>
  );
}
