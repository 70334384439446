import { isValid, setHours, setMinutes, parseISO, isBefore } from "date-fns";
import lodash from "lodash";
import type { FormikChoiceT } from "kyoto/components/Forms/Formik/choiceFieldTypes";

export function timeDeltaInSeconds(since: number | string | Date): number {
  const timestamp =
    typeof since === "number"
      ? since
      : (typeof since === "string" ? parseISO(since) : since).getTime();
  return Math.ceil((Date.now() - timestamp) / 1000);
}

// Options for every 15 min increments
export const allTimes = lodash
  .range(0, 24)
  .reduce((acc: Array<{ id: string; name: string }>, h) => {
    ["00", "15", "30", "45"].forEach((m) => {
      acc.push({
        id: `${h.toString().padStart(2, "0")}:${m}`,
        name: `${h % 12 || 12}:${m} ${h < 12 ? "AM" : "PM"}`,
      });
    });
    return acc;
  }, []);

// Start allTimes at a certain value
export const times = (start?: string | null, increment_thirty?: boolean): FormikChoiceT[] => {
  const index = start ? allTimes.findIndex((t: { id: string; name: string }) => t.id === start) : 0;
  const list = index > 0 ? [...allTimes.slice(index), ...allTimes.slice(0, index)] : allTimes;
  return increment_thirty ? list.filter((choice) => !choice.id.endsWith("5")) : list;
};

// Default Start Input to start at 06:00
export const startTimes = times("06:00");
export const startTimesThirty = times("06:00", true);

export const convertDateAndTimeToDatetime = (
  date: Date | string,
  time: string | null,
): Date | null => {
  if (!date) return null;
  const transformedDate = typeof date === "string" ? parseISO(date) : date;
  if (!isValid(transformedDate)) return null;
  if (!time) return transformedDate;
  const [hours, minutes] = time.split(":");
  return setMinutes(setHours(transformedDate, +hours), +minutes);
};

// Convert date and start/end time into an ISO String
export const convertDateAndTimeToISOString = (date: Date | string, time: string | null): string => {
  const result = convertDateAndTimeToDatetime(date, time);
  if (!result) return "";
  return result.toISOString();
};

export const isDateInThePast = (date: string | Date): boolean =>
  isBefore(typeof date === "string" ? parseISO(date) : date, new Date());
