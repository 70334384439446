// For services that have a window object. Allows local host to be something other than 'localhost'.
// This file is picked up first in the webpack builds for web
export const londonQgHost = {
  local: `${location.hostname}:9003`,
  gojira: "gojira-qg.talentpair.com",
  gojira2: "gojira2-qg.talentpair.com",
  staging: "staging-qg.talentpair.com",
  production: "questgroups.talentpair.com",
};
export const londonTpHost = {
  local: `${location.hostname}:9004`,
  gojira: "gojira-app.talentpair.com",
  gojira2: "gojira2-app.talentpair.com",
  staging: "staging-app.talentpair.com",
  production: "app.talentpair.com",
};
export const gettysburgHost = {
  local: `${location.hostname}:9001`,
  gojira: "gojira.talentpair.com",
  gojira2: "gojira2.talentpair.com",
  staging: "staging-recruit.talentpair.com",
  production: "recruit.talentpair.com",
};
export const queenstownHost = {
  local: "localhost:9005",
  gojira: "jobs.gojira-app.talentpair.com",
  gojira2: "jobs.gojira2-app.talentpair.com",
  staging: "jobs.staging-app.talentpair.com",
  production: "jobs.talentpair.com",
};
export const pricingHost = {
  local: "localhost:9006",
  gojira: "emp.gojira-app.talentpair.com",
  gojira2: "emp.gojira2-app.talentpair.com",
  staging: "emp.staging-app.talentpair.com",
  production: "emp.talentpair.com",
};
export const referralHost = {
  local: "localhost:9005",
  gojira: "gojira-ref.talentpair.com",
  gojira2: "gojira-ref.talentpair.com",
  staging: "staging-ref.talentpair.com",
  production: "ref.talentpair.com",
};
export const perryaiHost = {
  local: "localhost:9009",
  gojira: "gojira-perryai.talentpair.com",
  gojira2: "gojira2-perryai.talentpair.com",
  staging: "staging-perryai.talentpair.com",
  production: "perryai.talentpair.com",
};
export const s3Host = {
  local: "talentpair-local.s3.amazonaws.com",
  gojira: "talentpair-gojira.s3.amazonaws.com",
  gojira2: "talentpair-gojira2.s3.amazonaws.com",
  staging: "talentpair-staging.s3.amazonaws.com",
  production: "talentpair-production.s3.amazonaws.com",
};
