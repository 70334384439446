import * as React from "react";

export interface FocusableChipListRenderPropsT<V> {
  setChipFocusIndex: (index: number | null) => void;
  chipFocusIndex: number | null;
  chipProps: {
    onKeyDown: (event: React.KeyboardEvent) => void;
    onKeyUp: (event: React.KeyboardEvent) => void;
    onFocus: (index: number) => void;
    onDelete: (index: number, value: V) => void;
    onBlur: () => void;
  };
}

export interface PropsT<V> {
  values: V[];
  onChipDelete: (index: number, value: V) => void;
  focusField?: (() => void) | null;
  render: (props: FocusableChipListRenderPropsT<V>) => React.ReactNode;
}

interface StateT {
  focusIndex: number | null;
}

export default class FocusableChipList<V> extends React.Component<PropsT<V>, StateT> {
  keyIsDown = false;

  static defaultProps = {
    focusField: null,
  };

  state: StateT = { focusIndex: null };

  setFocusIndex = (focusIndex: number | null): void => this.setState({ focusIndex });

  onChipKeyDown = (e: React.KeyboardEvent): void => {
    if (this.keyIsDown) return;

    const { focusIndex } = this.state;
    if (focusIndex == null) return;

    const { values, onChipDelete, focusField } = this.props;

    switch (e.key) {
      case "Backspace": {
        this.keyIsDown = true;
        onChipDelete(focusIndex, values[focusIndex]);
        if (values.length - 1 > focusIndex) return; // no need to adjust focus index. chip after removed one will now be focused.

        const newIndex = focusIndex - 1;
        if (newIndex >= 0) {
          this.setState({ focusIndex: newIndex });
        } else {
          this.setState({ focusIndex: null });
          if (focusField) focusField();
        }
        return;
      }

      case "ArrowLeft": {
        this.keyIsDown = true;
        if (focusIndex > 0) this.setState({ focusIndex: focusIndex - 1 });
        return;
      }

      case "ArrowRight": {
        this.keyIsDown = true;
        const newIndex = focusIndex + 1;
        if (newIndex >= values.length) {
          if (this.props.focusField) this.props.focusField();
        } else {
          this.setState({ focusIndex: newIndex });
        }
        break;
      }

      default:
    }
  };

  onChipKeyUp = (e: React.KeyboardEvent): void => {
    e.stopPropagation();
    e.preventDefault();
    this.keyIsDown = false;
  };

  onChipBlur = (): void => {
    this.keyIsDown = false;
    this.setState({ focusIndex: null });
  };

  render(): React.ReactNode {
    const { focusIndex } = this.state;
    const { render, onChipDelete } = this.props;

    return render({
      setChipFocusIndex: this.setFocusIndex,
      chipFocusIndex: focusIndex,
      chipProps: {
        onFocus: this.setFocusIndex,
        onKeyDown: this.onChipKeyDown,
        onKeyUp: this.onChipKeyUp,
        onDelete: onChipDelete,
        onBlur: this.onChipBlur,
      },
    });
  }
}
