import { ReactElement } from "react";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    borderRadius: "50%",
    display: "inline-flex",
    height: 10,
    width: 10,
  },
  local: { backgroundColor: "#c7c758" },
  gojira: { backgroundColor: "#aeca08" },
  gojira2: { backgroundColor: "#48bbda" },
  staging: { backgroundColor: "#e293ed" },
});

export function EnvIndicator(): ReactElement | null {
  const classes = useStyles();
  const envName = process.env.HOST;
  if (envName === "production") return null;
  return (
    <Tooltip title={`${process.env.TAG} (${envName})`}>
      <span className={clsx(classes.root, classes[envName])} />
    </Tooltip>
  );
}
