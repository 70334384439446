import * as React from "react";
import clsx from "clsx";
import { userService } from "@talentpair/api";
import { Link } from "@talentpair/routing";
import { Button, TextButtonLink } from "@talentpair/quantic";
import { londonLink } from "@talentpair/env";
import { CapCandidateDetailReduxT } from "@talentpair/types/cap/entities/candidate";
import { expectNever } from "kyoto/utils/types";
import {
  ColumnTitle,
  CompleteAvatar,
  WarningAvatar,
  PauseAvatar,
} from "kyoto/components/Profiles/editing/ui";
import { PROFILE_EVENT_DISPATCH } from "kyoto/components/Profiles/editing/profileEventDispatch";
import { SectionContainer, SectionText } from "kyoto/components/Profiles/components";
import { displayName, CapCandidateCompletenessFieldsT } from "../utils/completeness";

function displayReason(name: CapCandidateCompletenessFieldsT): string {
  switch (name) {
    case "first_name":
    case "last_name":
    case "email":
    case "classifications":
    case "primary_classification":
    case "seniority_score":
    case "role":
    case "location":
    case "interested_verticals":
    case "candidate_upload_resume":
    case "job_history":
    case "work_authorization":
      return displayName(name);
    case "top_skills":
      return `${displayName(name)} - pick at least 1 top skill`;
    case "top_platforms":
      return `${displayName(name)} - pick at least 1 top platform`;
    case "features":
      return `${displayName(name)} - please pick either B2B or B2C and one other experience`;
    default:
      expectNever(name);
      return "";
  }
}

function dataSectionScrollName(name: CapCandidateCompletenessFieldsT): string {
  switch (name) {
    case "first_name":
    case "last_name":
    case "role":
    case "candidate_upload_resume": // Resume is at the top of the page
    case "email":
      return "basic_info";
    case "classifications":
    case "primary_classification":
      return "field_of_work";
    case "seniority_score":
      return "seniority";
    case "location":
      return "location";
    case "top_skills":
      return "omnis";
    case "top_platforms":
      return "platforms";
    case "features":
      return "features";
    case "interested_verticals":
      return "interested_verticals";
    case "job_history":
      return "work_history";
    case "work_authorization":
      return "eligibility";
    default:
      expectNever(name);
      return "";
  }
}

type PropsT = {
  candidate: CapCandidateDetailReduxT;
  uploadResume?: null | (() => void);
  incompleteFields: null | CapCandidateCompletenessFieldsT[];
  className?: null | string;
  hideButton?: boolean;
  profileLinks?: boolean;
  reactivateCandidateAccount: (userId: number) => void;
};

export default function ProfileStatus({
  candidate: { id, cap_activated, resume, user },
  incompleteFields,
  reactivateCandidateAccount,
  uploadResume = null,
  className = undefined,
  hideButton = false,
  profileLinks = false,
}: PropsT): React.ReactElement {
  const profileComplete = !incompleteFields || !incompleteFields.length;
  const scrollToTargetAdjusted = (field: CapCandidateCompletenessFieldsT): void => {
    const dataSection = dataSectionScrollName(field);
    const element = document.querySelector(`[data-section=${dataSection}]`);
    if (element) {
      const headerOffset = 185;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;

      if (!field.startsWith("primary_") && !field.startsWith("top_"))
        PROFILE_EVENT_DISPATCH.dispatchEvent(
          new CustomEvent(`focus_${dataSection}`, { detail: { field } }),
        );

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };
  return userService.isDeactivated() ? (
    <SectionContainer className="border-none p3 bg-grey-100">
      <div className="flex items-center mb3">
        <PauseAvatar className="mr3" />
        <ColumnTitle>Status: Inactive</ColumnTitle>
      </div>
      <SectionText paragraph>
        Your account is inactive. To start matching with jobs, reactivate your account.
      </SectionText>
      <div className="center mb2 mt3">
        <Button
          color="primary"
          variant="contained"
          className="center"
          onClick={() => reactivateCandidateAccount(user.id)}
          data-testid="reactivateBtn"
        >
          Reactivate your account
        </Button>
      </div>
    </SectionContainer>
  ) : (
    <SectionContainer
      className={clsx("border-none p3 bg-grey-100", className, {
        "bg-amber-50": !profileComplete,
      })}
    >
      <div className="flex items-center mb3">
        {profileComplete ? <CompleteAvatar className="mr3" /> : <WarningAvatar className="mr3" />}
        {profileComplete ? (
          <ColumnTitle>Status: Complete</ColumnTitle>
        ) : (
          <ColumnTitle>Status: Incomplete</ColumnTitle>
        )}
      </div>
      {profileComplete ? (
        <SectionText paragraph>
          Your profile is complete. Start matching with jobs you like.
        </SectionText>
      ) : resume ? (
        <SectionText component="div" paragraph>
          {`${
            cap_activated
              ? "Finish your profile to receive jobs."
              : "You can't get matched with jobs yet."
          } Your profile is missing:`}
          <ul>
            {incompleteFields?.map(
              profileLinks
                ? (f) => (
                    <TextButtonLink
                      className="block"
                      style={{ textAlign: "initial", marginLeft: -20 }}
                      key={f}
                      onClick={() => {
                        scrollToTargetAdjusted(f);
                      }}
                    >
                      • {displayReason(f)}
                    </TextButtonLink>
                  )
                : (f) => (
                    <li key={f} style={{ textAlign: "initial", marginLeft: -20 }}>
                      • {displayReason(f)}
                    </li>
                  ),
            )}
          </ul>
        </SectionText>
      ) : (
        <>
          <SectionText component="div" paragraph>
            You can&apos;t get matched with jobs yet. Upload your resume to finish creating your
            profile.
          </SectionText>
          <SectionText component="div" paragraph>
            Your resume will be attached to your profile when shared with an employer.
          </SectionText>
        </>
      )}
      {!hideButton ? (
        <div className="center mb2 mt3">
          {!profileComplete && !resume && uploadResume && (
            <Button
              color="primary"
              variant="contained"
              data-testid="finishYourProfileButton"
              onClick={uploadResume}
            >
              Finish your profile
            </Button>
          )}
          {profileComplete && cap_activated && (
            <Button
              color="primary"
              variant="contained"
              data-testid="reviewYourJobsButton"
              // @ts-expect-error - it's okay
              component={Link}
              to={londonLink.capPairList(id)}
            >
              Review Your Jobs
            </Button>
          )}
        </div>
      ) : null}
    </SectionContainer>
  );
}
