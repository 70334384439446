import { Modal, PortalPropsT } from "@talentpair/portal";
import * as React from "react";
import track from "../../services/track";
import NavSearchForm from "./NavSearchForm";

class NavSearchModal extends React.Component<PortalPropsT> {
  componentDidMount(): void {
    track.navSearch({ action: "Modal Opened" });
  }

  render(): React.ReactNode {
    return (
      <Modal
        fullWidth
        responsive
        data-testid="navSearchModal"
        DialogContentProps={{ className: "p0" }}
        classes={{ container: "items-start" }}
        {...this.props}
      >
        <NavSearchForm onSubmit={this.props.onClose} />
      </Modal>
    );
  }
}

export default NavSearchModal;
