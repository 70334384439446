import { CardTravel } from "@mui/icons-material";
import { RingedAvatar, RingedAvatarProps } from "./RingedAvatar";

export type JobAvatarJobT = {
  organization?: null | { logo_url?: null | string; [key: string]: unknown };
  health?: 0 | 1 | 2;
  [key: string]: unknown;
};

export type JobAvatarProps = Pick<RingedAvatarProps, "size" | "ringed"> & {
  ringColor?: RingedAvatarProps["ringColor"];
  job?: undefined | null | JobAvatarJobT; // if no job passed in fallback to default job avatar
};

export function JobAvatar({ job, ringColor, ...props }: JobAvatarProps): React.ReactElement {
  const sharedProps: Omit<RingedAvatarProps, "fill"> = {
    ...props,
    shape: "rounded",
    color: "common.white",
    background: "secondary.600",
    ringColor:
      ringColor ||
      (job?.health == null
        ? "transparent"
        : job?.health === 0
        ? "red.500"
        : job?.health === 1
        ? "amber.500"
        : "primary.500"),
  };

  if (job?.organization?.logo_url)
    return (
      <RingedAvatar
        {...sharedProps}
        fill="image"
        src={job.organization.logo_url}
        alt="Job's company logo"
        // icon fallback
        Icon={CardTravel}
      />
    );

  return <RingedAvatar {...sharedProps} fill="icon" Icon={CardTravel} />;
}
