import * as React from "react";
import { NotificationCountStore } from "@talentpair/api";
import { connect, DispatchT, AnyActionT } from "@talentpair/redux";
import { StateT } from "../../../store";

/**
 * Dispatches an arbitrary action when the notification count updates.
 */
type DispatchToPropsT = { fetchNotificationCount: () => unknown };
// If you need to pass your action arguments, wrap it in a closure
type DispatchedOwnPropsT = {
  fetchAction: () => AnyActionT;
};
type OwnPropsT = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchAction: () => any;
};
type PropsT = DispatchToPropsT & OwnPropsT;

function NotificationCountWatcher({ fetchNotificationCount }: PropsT): null {
  React.useEffect(
    () => NotificationCountStore.subscribe(() => fetchNotificationCount),
    [fetchNotificationCount],
  );
  return null;
}

function mapDispatchToProps(
  dispatch: DispatchT,
  { fetchAction }: DispatchedOwnPropsT,
): DispatchToPropsT {
  return { fetchNotificationCount: () => dispatch(fetchAction()) };
}

function mapActionToProps(dispatch: DispatchT, { fetchAction }: OwnPropsT): DispatchToPropsT {
  return { fetchNotificationCount: () => fetchAction() };
}

export default connect<void, DispatchToPropsT, DispatchedOwnPropsT, StateT>(
  undefined,
  mapDispatchToProps,
)(NotificationCountWatcher);

export const NotificationCountWatcherNoDispatch = connect<
  void,
  DispatchToPropsT,
  OwnPropsT,
  StateT
>(
  undefined,
  mapActionToProps,
)(NotificationCountWatcher);

export const _test = {
  NotificationCountWatcher,
  mapDispatchToProps,
  mapActionToProps,
};
