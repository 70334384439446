import * as React from "react";

type OnChangeT = (file: FileList | null) => unknown;
interface FileInputProps {
  inputRef?: (el: HTMLInputElement | null) => void;
  name: string;
  onChange: OnChangeT;
  accept: string;
}

// This should be wrapped in some sort of label or <Button /> with a component prop of "label"
export class FileInput extends React.Component<FileInputProps> {
  ref: HTMLInputElement | null | undefined;

  static defaultProps = { inputRef: null };

  handleChange = (event: React.SyntheticEvent): unknown => {
    event.preventDefault();
    const { onChange } = this.props;
    return onChange(this.ref ? this.ref.files : null);
  };

  render(): React.ReactNode {
    const { inputRef, onChange, ...props } = this.props;
    return (
      <input
        className="absolute o0 overflow-hidden"
        onChange={this.handleChange}
        ref={(node) => {
          this.ref = node;
          if (inputRef) inputRef(node);
        }}
        style={{ height: 0.1, width: 0.1, zIndex: -1 }}
        type="file"
        {...props}
      />
    );
  }
}

const fileExtensions = [".doc", ".docx", ".htm", ".html", ".pdf", ".rtf", ".txt", ".xml"];
const imageExtensions = [".png", ".jpg", ".jpeg"];
const mimeTypes = [
  "application/msword",
  "application/pdf",
  "application/rtf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/html",
  "text/plain",
];
const imageMimeTypes = ["image/jpeg", "image/png"];

// For validating
export const validFile = (file: File): boolean =>
  mimeTypes.includes(file.type) ||
  fileExtensions.some((ext) => file.name.toLowerCase().endsWith(ext));

export const validImage = (file: File): boolean =>
  imageMimeTypes.includes(file.type) ||
  imageExtensions.some((ext) => file.name.toLowerCase().endsWith(ext));

type DocumentInputProps = Omit<FileInputProps, "accept">;

export const DocumentInput = (props: DocumentInputProps): React.ReactElement => (
  <FileInput accept={[...fileExtensions, ...mimeTypes].join(",")} {...props} />
);

export const ImageInput = (props: DocumentInputProps): React.ReactElement => (
  <FileInput accept={[...imageExtensions, ...imageMimeTypes].join(",")} {...props} />
);
