import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export function getShortTimeZone(date: Date = new Date()): string {
  return date.toLocaleDateString("en-US", { timeZoneName: "short" }).split(", ")[1];
}

export function shiftDateToTimeZone(date: Date | string, timeZone: string): Date {
  return utcToZonedTime(date, timeZone);
}

export function shiftDateFromTimeZone(date: Date | string, timeZone: string): Date {
  return zonedTimeToUtc(date, timeZone);
}
