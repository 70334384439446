import { mixpanelHelper, navigationData } from "@talentpair/tracking";
import { TrackT } from "@talentpair/types/misc";
import { PairActiveStateT, PairStateT } from "@talentpair/types/entities/pairs";
import { TourT } from "../components/Onboarding/utils";
import { routes } from "../routeConfig";

// Also passes in navigation data for the current page for every track event (same logic as Navigation events)
const track =
  <D>(event: string): TrackT<D> =>
  (data?): void =>
    mixpanelHelper.track(event, {
      ...navigationData(routes, location.pathname, location.search),
      ...data,
    });

const activityFeed: TrackT = track("Activity Feed");

const companyAdmin: TrackT = track("Company Admin");

type ABTestGroupDataT = {
  test_name: string;
  test_group: "A" | "B";
};

const aBTesting: TrackT<ABTestGroupDataT> = track("A/B Test Group");

interface ExternalLinkShapeT {
  href: string;
}
const externalLink: TrackT<ExternalLinkShapeT> = track("External Link");

const jobAdmin: TrackT = track("Job Admin");

const jobBoard: TrackT = track("Job Board");

interface OnboardingShapeT {
  tour: TourT;
  action: string;
}
const onboarding: TrackT<OnboardingShapeT> = track("Onboarding");

type StateT = PairActiveStateT | PairStateT;
export interface CapPairTrackDataT {
  state: StateT;
  candidate_state: StateT;
  job_inactive: boolean;
  sent_to_job: boolean;
}
export interface TapPairTrackDataT {
  state: StateT;
  candidate_state: StateT;
  job_state: StateT;
  tap_status: string;
  job_admin: boolean;
}
export type PairTrackDataT = CapPairTrackDataT | TapPairTrackDataT;
type PairShapeT = PairTrackDataT & {
  action: string;
  feed_type?: string;
  source?: string;
};
const pairAction: TrackT<PairShapeT> = track("Pair Action");

const pairList: TrackT = track("Pair List");
const profile: TrackT = track("Profile");
const profileEdit = (field: string, data?: Record<string, unknown>): void =>
  profile({ action: "Edit", ...data, field });
const tapProfile: TrackT = track("TAP Profile");

const resume: TrackT = track("Resume");

const referFriends: TrackT = track("Refer Friends");

const accountSettings: TrackT = track("Account Settings");
const inviteEmails: TrackT = track("Invite Emails");

// Add future allowable entity/types here
interface ShareShapeT {
  action: string;
  entity: "job";
  type: "link";
}
const share: TrackT<ShareShapeT> = track("Share");

const signup: TrackT = track("Signup");

const reonboarding: TrackT = track("Reonboarding");

const hotkey: TrackT<{ key: string }> = track("Hotkey");
const navSearch: TrackT = track("Nav Search");

type GreenhouseDataT =
  | { action: "Oauth" }
  | {
      action: "Connect Job";
      tpJobId: number;
      greenhouseJobId: number;
    }
  | {
      action: "Submit";
      resubmitted: boolean;
    };
const greenhouse: TrackT<GreenhouseDataT> = track("Greenhouse");

type TeleprompterInterviewQuestionsDataT = {
  action: "tap_generate_interview_questions";
  interview_type: string;
  interview_round: number;
};
type TeleprompterVoteDataT = {
  action: "Upvote" | "Downvote";
  step_name?: string;
  step_id?: number;
};
type TeleprompterResultDataT = {
  action: "Success" | "Error";
  step_name?: string;
};
type TeleprompterDataT =
  | TeleprompterInterviewQuestionsDataT
  | TeleprompterVoteDataT
  | TeleprompterResultDataT;
const teleprompter: TrackT<TeleprompterDataT> = track("Teleprompter");

export type MyDeskActions =
  | "Load desk"
  // jobs subtree (vNext)
  | "Select Jobs list"
  | "Select job"
  | "Select job pipeline"
  | "Select job pipeline candidate"
  // candidates subtree (vNext)
  | "Select Candidates list"
  | "Select candidate"
  | "Select candidate pipeline"
  | "Select candidate pipeline job"
  // pipeline subtree
  | "Select Job manage"
  | "Select Partner detail"
  | "Select Member detail"
  | "Select Pipeline stage"
  | "Select candidate"
  | "Select candidate job pair"

  // menu actions
  | "Open candidate item menu"
  | "Open candidate list menu"
  | "Open candidate detail menu"
  | "Open candidate job pair menu"
  | "Open job item menu"
  | "Open job list menu"
  | "Open job detail menu"
  | "Open job candidate pair menu"
  | "Open pair detail menu"
  | "Click candidate action"
  | "Click job action"
  | "Click pair item action"

  // detail pane actions
  | "Click pair detail action"
  | "Open resume tab"
  | "Open profile tab"
  | "Navigate up"
  | "Keyboard navigate up"
  | "Keyboard select child"
  | "Keyboard select previous"
  | "Keyboard select next"
  | "See new activity"
  | "Has new activity"
  | "No new activity";

// export type MyDeskActionButtonT =
//   | "Go to candidate"
//   | "Email candidate"
//   | "Send job"
//   | "Go to job"
//   | "Submit candidate"
//   | "Pass"
//   | "Schedule interview"
//   | "Create offer"
//   | "Create placement"
//   | "Remove pair"; // vNext

export type MyDeskData = {
  action: MyDeskActions;
  subdesk?: "jobs" | "candidates";
  // action_button?: MyDeskActionButtonT;
  pipeline_stage?: string;
  panelId?: string;
};

const myDesk: TrackT<MyDeskData> = track("MyDesk");

export type CalendarData = {
  action:
    | "Start editing interview" // TAP
    | "Edit interview" // TAP
    | "Delete interview" // TAP
    | "Download interview" // TAP
    | "Go to candidate" // TAP
    | "Go to team chat" // TAP
    | "Go to candidate chat" // TAP
    | "Go to job" // CAP
    | "Go to chat"; // CAP
};

const calendar: TrackT<CalendarData> = track("Calendar");

export default {
  base: track,
  calendar,
  activityFeed,
  companyAdmin,
  externalLink,
  jobAdmin,
  jobBoard,
  onboarding,
  reonboarding,
  pairAction,
  profile,
  profileEdit,
  resume,
  share,
  signup,
  hotkey,
  myDesk,
  navSearch,
  referFriends,
  tapProfile,
  pairList,
  greenhouse,
  accountSettings,
  inviteEmails,
  aBTesting,
  teleprompter,
};
