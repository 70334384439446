import * as React from "react";
import { connect, FormikContextType } from "formik";

/**
 * Exposes form state via the exported `formikState` variable.
 * Remember that FastFields do not sync their values until they are blurred.
 */
interface FormStateT<Values> {
  [key: string]: FormikContextType<Values>;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formikState: FormStateT<any> = {};

interface OwnPropsT {
  name: string;
}

interface PropsT<Values> extends OwnPropsT {
  formik: FormikContextType<Values>;
}

class Expose<Values> extends React.Component<PropsT<Values>> {
  constructor(props: PropsT<Values>) {
    super(props);
    formikState[props.name] = props.formik;
  }

  UNSAFE_componentWillReceiveProps({ name, formik }: PropsT<Values>): void {
    formikState[name] = formik;
  }

  componentWillUnmount(): void {
    delete formikState[this.props.name];
  }

  render(): React.ReactNode {
    return null;
  }
}

export default connect<OwnPropsT>(Expose);
