import * as React from "react";
import clsx from "clsx";
import { FormControlLabel, Theme, FormControlLabelProps, ComponentsOverrides } from "@mui/material";
import { withStyles } from "@mui/styles";

// Styles largely borrowed from button.
// Goal is to get behavior of Checkboxes and Radio inputs, but look of a button
const styles = (
  theme: Theme,
): ComponentsOverrides<Theme>["MuiFormControlLabel"] & {
  checked: Record<string, unknown>;
  focused: Record<string, unknown>;
} => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.grey[300],
    borderRadius: 0,
    borderWidth: 1,
    borderBottomWidth: 0,
    borderStyle: "solid",
    flex: "1 1 auto",
    justifyContent: "center",
    lineHeight: "1.4em",
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0,
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
      "&:first-child": {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      },
      "&:last-child": {
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        borderBottomWidth: 1,
      },
    },
    [theme.breakpoints.up("md")]: {
      borderBottomWidth: 1,
      borderRightWidth: 0,
      "&:first-child": {
        borderBottomLeftRadius: 100,
        borderTopLeftRadius: 100,
      },
      "&:last-child": {
        borderBottomRightRadius: 100,
        borderTopRightRadius: 100,
        borderRightWidth: 1,
      },
    },
  },
  label: {
    color: "inherit",
    fontWeight: "500",
    lineHeight: "inherit",
    fontSize: "0.875rem",
    padding: "6px 16px",
  },
  checked: {
    color: theme.palette.primary.contrastText,
    // @ts-expect-error - eslint upgrade
    backgroundColor: theme.palette.primary[500],
    "&.Mui-disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      "&.Mui-disabled": { backgroundColor: theme.palette.action.disabledBackground },
      "@media (hover: none)": { backgroundColor: theme.palette.primary.main },
    },
  },
  focused: {
    // @ts-expect-error - eslint upgrade
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    boxShadow: `0.5px 0 0 1.5px ${theme.palette.primary[500]}`,
  },
});

export type PropsT = {
  checked: boolean;
  classes: Pick<FormControlLabelProps, "classes"> & {
    checked: string;
    focused: string;
  };
  onBlur: (event: React.FocusEvent) => void;
  onFocus: (event: React.FocusEvent) => void;
} & FormControlLabelProps;

type StateT = { focused: boolean };

class PillButtonLabel extends React.Component<PropsT, StateT> {
  static defaultProps = { onBlur: null, onFocus: null };

  state = { focused: false };

  handleBlur = (event: React.FocusEvent): void => {
    this.setState({ focused: false });
    if (this.props.onBlur) this.props.onBlur(event);
  };

  handleFocus = (event: React.FocusEvent): void => {
    this.setState({ focused: true });
    if (this.props.onFocus) this.props.onFocus(event);
  };

  render(): React.ReactNode {
    const { checked, classes, onBlur, onFocus, ...props } = this.props;
    const { focused } = this.state;
    return (
      <FormControlLabel
        checked={checked}
        classes={{
          root: clsx(classes.root, {
            [classes.checked]: checked,
            [classes.focused]: focused,
          }),
          disabled: classes.disabled,
          label: classes.label,
        }}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        {...props}
      />
    );
  }
}

// @ts-expect-error - it's fine
export default withStyles(styles)(PillButtonLabel);

export const _test = { PillButtonLabel };
