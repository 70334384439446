import lodash from "lodash";
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from "@mui/material/colors";
import { Person, PermContactCalendar, NotInterested } from "@mui/icons-material";
import { ColorPropT } from "../../theme/colorProp";
import { RingedAvatar, RingedAvatarProps } from "./RingedAvatar";

// excludes `common` color palette and puts colors in order
const materialColors = {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
};

export const initials = (title: string): string =>
  lodash
    .words(title)
    .slice(0, 2)
    // Handles unicode characters, which word[0] does not
    .map((word) => {
      const codePoint = word.codePointAt(0);
      return codePoint ? String.fromCodePoint(codePoint) : "";
    })
    .join("")
    .toUpperCase();

const colorLevel = (level: "500" | "700"): string[] =>
  lodash.reduce(materialColors, (acc: string[], val) => [...acc, val[level]], []);

const userColors = [...colorLevel("500"), ...colorLevel("700")];

export const generateUserColor = (seed: number): string => userColors[seed % userColors.length];

export type UserAvatarUserT = {
  id?: number;
  first_name?: string;
  last_name?: string;
  profile_picture_medium?: string | null;
  last_active?: string | null;
  candidate?:
    | undefined
    | null
    | number
    | { id?: number; status?: { id: number }; [key: string]: unknown };
  no_longer_employed?: boolean;

  [key: string]: unknown;
};

export type UserAvatarProps = Pick<RingedAvatarProps, "size" | "ringed" | "className"> & {
  candidate?: boolean;
  user?: undefined | null | UserAvatarUserT; // if no candidate user passed in fallback to default candidate avatar
  ringColor?: RingedAvatarProps["ringColor"];
};

export function UserAvatar({
  user,
  candidate = !!user?.candidate,
  ringColor,
  ...props
}: UserAvatarProps): React.ReactElement {
  const dnc =
    // either this is a contact/recruiter that was marked no_longer_employed
    user?.no_longer_employed ||
    // or this is a candidate perhaps marked as do_not_contact
    (typeof user?.candidate === "object" && user.candidate?.status?.id === 10);
  const sharedProps: Omit<RingedAvatarProps, "fill"> = {
    ...props,
    shape: "circular",
    color: "common.white",
    background: dnc
      ? "error.main"
      : user?.id
      ? (generateUserColor(user.id) as ColorPropT)
      : candidate
      ? "blue.600"
      : "primary.400",
    ringColor: ringColor || (user?.last_active ? "primary" : "transparent"),
  };

  if (dnc) return <RingedAvatar {...sharedProps} fill="icon" Icon={NotInterested} />;

  const userInitials =
    user?.first_name || user?.last_name
      ? initials(`${user.first_name || ""} ${user.last_name || ""}`)
      : undefined;

  if (user?.profile_picture_medium)
    return (
      <RingedAvatar
        {...sharedProps}
        fill="image"
        src={user.profile_picture_medium}
        alt={userInitials}
        // prefer text initials fallback over icon fallback
        text={userInitials}
        Icon={userInitials ? undefined : candidate ? Person : PermContactCalendar}
      />
    );

  if (userInitials) return <RingedAvatar {...sharedProps} fill="text" text={userInitials} />;

  return (
    <RingedAvatar {...sharedProps} fill="icon" Icon={candidate ? Person : PermContactCalendar} />
  );
}
