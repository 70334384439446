import {
  CandidateDetailT,
  CandidateHealthT,
  CandidateProfileStatusT,
  CandidateT,
  PairCandidateDetailT,
  TinyCandidateT,
} from "@talentpair/types/entities/candidate";
import {
  JobHealthT,
  TapHealthT,
  JobT,
  SmallJobT,
  SmallJobWithVerticalsT,
} from "@talentpair/types/entities/job";
import {
  SmallMaxHireCandidateT,
  SmallMaxHireContactT,
  SmallMaxHireOrgT,
} from "@talentpair/types/entities/maxhire";
import { SmallOrgT } from "@talentpair/types/entities/org";
import { SmallContactT } from "@talentpair/types/entities/user";
import { EntityTypeT } from "@talentpair/types/misc";
import { SmallTeamT } from "@talentpair/types/entities/team";

const toPair = (id: number): string => `/pair/${id}`;
const toPairCandidate = (id: number): string => `/pair/${id}`;
const toPairJob = (id: number): string => `/pair/${id}/job`;
const toPairCapFeed = (id: number): string => `/pair/${id}/cap`;
const toPairTapFeed = (id: number): string => `/pair/${id}/tap`;
const toPairSharedFeed = (id: number): string => `/pair/${id}/shared`;
const toPairActivity = (id: number): string => `/pair/${id}/activity`;
const toPairPipeline = (id: number): string => `/pair/${id}/pipeline`;
const toPairAudit = (id: number): string => `/pair/${id}/audit`;
const toPairCommission = (id: number): string => `/pair/${id}/commission`;
const toJob = (id: number): string => `/job/${id}`;
const toJobPairs = (id: number): string => `${toJob(id)}/pairs`;
const toJobPipeline = (id: number): string => `${toJob(id)}/pipeline`;
const toJobActivity = (id: number): string => `${toJob(id)}/activity`;
const toJobPlatform = (id: number): string => `${toJob(id)}/platform`;
const toJobPayment = (id: number): string => `${toJob(id)}/payment`;

const toRecruiterDashboard = (id: number): string => `/dashboard/${id}`;
const toJobDashboard = (id: number, job: number): string => `/dashboard/${id}/?job=${job}`;
const toJobDashboardRecommendedCandidates = (id: number, job: number): string =>
  `/dashboard/${id}?job=${job}&list=recommended`;
const toJobDashboardApplicants = (id: number, job: number): string =>
  `/dashboard/${id}?job=${job}&list=applicants`;

const toRecruiterJobs = (id: number): string => `/${id}/jobs`;
const toRecruiterCandidates = (id: number): string => `/${id}/candidates`;
const toRecruiterDirectRecruits = (id: number): string => `/${id}/direct-recruits`;
const toRecruiterPayments = (id: number): string => `/${id}/payments`;

const toUserHome = (id: number): string => `/${id}`;
const toUserMaxHire = (id: number): string => `/${id}/maxhire`;
const toUserNotifications = (id: number): string => `/${id}/notifications`;
const toUserPipeline = (id: number): string => `/${id}/pipeline`;
const toUserActivity = (id: number): string => `/${id}/activity`;
const toUserPlatform = (id: number): string => `/${id}/platform`;
const toContactJobs = (id: number): string => `/${id}/contactjobs`;
const toTeam = (id: number): string => `/team/${id}`;
const toTeamJobs = (id: number): string => `${toTeam(id)}/jobs`;
const toTeamRecruiters = (id: number): string => `${toTeam(id)}/recruiters`;
const toTeamPayments = (id: number): string => `${toTeam(id)}/payments`;
const toTeamPipeline = (id: number): string => `${toTeam(id)}/pipeline`;
const toTeamActivity = (id: number): string => `${toTeam(id)}/activity`;
const toOrg = (id: number): string => `/organization/${id}`;
const toOrgJobs = (id: number): string => `${toOrg(id)}/jobs`;
const toOrgIntake = (id: number): string => `${toOrg(id)}/intake`;
const toOrgTAP = (id: number): string => `${toOrg(id)}/platform`;
const toOrgPayment = (id: number): string => `${toOrg(id)}/payment`;

const toNotificationInbox = (userId: number, bucket = "Inbox", view = "all"): string =>
  `/notifications/${userId}/?view=${view}&bucket=${bucket}`;

const toNotificationInboxItem = (
  userId: number,
  selected: number,
  bucket = "Inbox",
  view = "all",
): string => `${toNotificationInbox(userId, bucket, view)}&selected=${selected}&read=1`;

const toMaxhireCandidate = (id: number): string => `/candidate-inactive/${id}`;
const toMaxhireOrganization = (id: number): string => `/company-inactive/${id}`;
const toMaxhireContact = (id: number): string => `${toMaxhireOrganization(id)}?contact=true`;

const toProfile = (entityType: EntityTypeT, id: number): string => {
  switch (entityType) {
    case "candidate":
    case "contact":
      return `/${String(id)}`;

    case "maxhire_candidate":
      return toMaxhireCandidate(id);

    case "job":
      return toJob(id);

    case "organization":
      return toOrg(id);

    case "maxhire_organization":
      return toMaxhireOrganization(id);

    case "maxhire_contact":
      return toMaxhireContact(id);

    case "team":
      return toTeam(id);

    default:
      return "";
  }
};

type ProfileEntityT =
  | CandidateDetailT
  | CandidateT
  | TinyCandidateT
  | SmallMaxHireCandidateT
  | JobT
  | SmallJobT
  | SmallJobWithVerticalsT
  | SmallOrgT
  | SmallMaxHireOrgT
  | SmallContactT
  | SmallMaxHireContactT
  | JobHealthT
  | TapHealthT
  | CandidateHealthT
  | PairCandidateDetailT
  | CandidateProfileStatusT
  | SmallTeamT;

const toProfileForEntity = (entity: ProfileEntityT): string => {
  let id;

  switch (entity.type) {
    case "candidate":
      id = entity.user.id;
      break;

    case "team":
    case "job":
    case "contact":
    case "organization":
    case "maxhire_candidate":
      id = entity.id;
      break;

    case "maxhire_organization":
      id = entity.zetid;
      break;

    case "maxhire_contact":
      if (!entity.maxhire_organization) return "";
      id = entity.maxhire_organization.id;
      break;

    default:
      return "";
  }

  return toProfile(entity.type, id);
};

const toIntake = (entity: ProfileEntityT): string => {
  switch (entity.type) {
    case "candidate":
      return `/${entity.user.id}/intake`;

    case "job":
      return `${toJob(entity.id)}/intake`;

    default:
      return "";
  }
};

const toJobIntake = (id: number): string => `${toJob(id)}/intake`;
const toCandidateIntake = (id: number): string => `/${id}/intake`;
const toCandidateDocs = (id: number): string => `/${id}/docs`;
const toCandidatePairs = (id: number): string => `/${id}/pairs`;

const toSearchJobGoldenPairs = (id: number): string =>
  `/search?types=candidate,maxhire_candidate&job_pk=${id}&pairs=suggested`;
const toSearchCandidateGoldenPairs = (id: number): string =>
  `/search?types=job&candidate_pk=${id}&pairs=suggested`;

const toMyDeskPair = (
  ownerId: number,
  pipelineSlug: string,
  candidateId: number,
  pairId: number,
): string => `/desk/recruiter/${ownerId}/pipeline/${pipelineSlug}/${candidateId}/pairs/${pairId}`;

export const astralLink = {
  toJob,
  toJobIntake,
  toJobPairs,
  toJobPayment,
  toJobPipeline,
  toJobActivity,
  toJobPlatform,
  toRecruiterDashboard,
  toRecruiterCandidates,
  toRecruiterDirectRecruits,
  toRecruiterPayments,
  toJobDashboard,
  toJobDashboardRecommendedCandidates,
  toJobDashboardApplicants,
  toOrg,
  toOrgJobs,
  toOrgIntake,
  toOrgTAP,
  toOrgPayment,
  toPair,
  toTeam,
  toTeamJobs,
  toTeamRecruiters,
  toTeamPayments,
  toTeamPipeline,
  toTeamActivity,
  toPairCandidate,
  toPairJob,
  toPairCapFeed,
  toPairTapFeed,
  toPairSharedFeed,
  toPairActivity,
  toPairPipeline,
  toPairAudit,
  toPairCommission,
  toProfile,
  toProfileForEntity,
  toIntake,
  toCandidateIntake,
  toCandidatePairs,
  toCandidateDocs,
  toNotificationInbox,
  toNotificationInboxItem,
  toRecruiterJobs,
  toContactJobs,
  toUserHome,
  toUserMaxHire,
  toUserNotifications,
  toUserPipeline,
  toUserActivity,
  toUserPlatform,
  toSearchJobGoldenPairs,
  toSearchCandidateGoldenPairs,
  toMyDeskPair,
};

export const _test = { toMaxhireCandidate, toMaxhireOrganization, toMaxhireContact };
