import * as React from "react";
import ReactMedia, { SingleQueryProps, MediaQueryValue } from "react-media";
import { tpTheme } from "./theme/muiTheme";

interface MediaPropsT extends Omit<SingleQueryProps, "query"> {
  query?: MediaQueryValue;
}
export function Media({
  query = { minWidth: tpTheme.breakpoints.values.md },
  ...props
}: MediaPropsT): React.ReactElement {
  return <ReactMedia query={query} {...props} />;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = Record<string, any>;

export interface ResponsiveCmpPropsT {
  isDesktop: boolean;
}

interface ResponsivePropsT<P extends Props> {
  Desktop: React.ComponentType<ResponsiveCmpPropsT & P>;
  Mobile: React.ComponentType<ResponsiveCmpPropsT & P>;
}
export function Responsive<P extends Props>({
  Desktop,
  Mobile,
  ...props
}: ResponsivePropsT<P>): React.ReactElement {
  return (
    <Media>
      {(isDesktop: boolean): React.ReactNode =>
        isDesktop ? (
          // @ts-expect-error - eslint upgrade
          <Desktop {...props} isDesktop={isDesktop} />
        ) : (
          // @ts-expect-error - eslint upgrade
          <Mobile {...props} isDesktop={isDesktop} />
        )
      }
    </Media>
  );
}

type MediaContextT = {
  desktop: boolean;
};
const initialMediaContext = {
  // 896 is the equivalent number of pixels to our default 64em breakpoint
  desktop:
    (window.innerWidth || document.documentElement.clientWidth) >= tpTheme.breakpoints.values.md,
};
const MediaContext = React.createContext(initialMediaContext);

export function MediaContextProvider({
  children,
}: React.PropsWithChildren<Record<string, unknown>>): React.ReactElement {
  const [value, setValue] = React.useState(initialMediaContext);
  return (
    <Media onChange={(desktop) => setValue((v) => ({ ...v, desktop }))}>
      {() => <MediaContext.Provider value={value}>{children}</MediaContext.Provider>}
    </Media>
  );
}

export function useMediaContext(): MediaContextT {
  return React.useContext(MediaContext);
}
