// @flow
import type {
  JobCreateSuccessActionT,
  JobReceiveDetailActionT,
  JobLoadingListActionT,
} from "./jobActions";
import { JOB_CREATE_SUCCESS, JOB_RECEIVE_DETAIL, JOB_LOADING_LIST } from "./jobActions";

export type OrgJobsT = number[] & { loading: boolean };
export type OrgJobsReduxT = { [id: number]: OrgJobsT, loading: number[], ... };

export default function orgJobs(
  state: OrgJobsReduxT = { loading: [] },
  action: JobReceiveDetailActionT | JobCreateSuccessActionT | JobLoadingListActionT,
): OrgJobsReduxT {
  switch (action.type) {
    case JOB_LOADING_LIST: {
      if (action.loading) {
        if (state.loading.includes(action.orgId)) return state;
        return {
          ...state,
          [action.orgId]: state[action.orgId] || null,
          loading: [...state.loading, action.orgId],
        };
      }
      return {
        ...state,
        [action.orgId]: state[action.orgId] || [],
        loading: state.loading.filter((v) => v !== action.orgId),
      };
    }
    case JOB_RECEIVE_DETAIL: {
      const orgId = action.job?.organization?.id || 0;
      const jobs = orgId ? state[orgId] : null;
      if (!jobs || !jobs.includes(action.job.id))
        return {
          ...state,
          [orgId]: jobs ? [...jobs, action.job.id] : [action.job.id],
        };
      return state;
    }
    case JOB_CREATE_SUCCESS:
      return { ...state, [action.orgId]: [action.job.id, ...(state[action.orgId] || [])] };

    default:
      (action: empty);
      return state;
  }
}
