// @flow
import * as React from "react";
import { Grid, Tab, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Media, Typography } from "@talentpair/quantic";
import { londonLink } from "@talentpair/env";
import { SuspenseWrapper } from "@talentpair/sentry";
import { type CapPairReduxT } from "@talentpair/types/cap/entities/pair";
import { connect } from "@talentpair/redux";
import { getRole } from "@talentpair/entities/job";
import type { ContextRouter } from "@talentpair/routing";
import type { StateT } from "../../store";
import { BadgeTab, CenteredTabs, tabFixedPosition } from "../../components/layout";
import AstralLink from "../../components/AstralLink";
import ClosedBanner from "../../components/ClosedBanner";
import pairService from "../../services/pair";
import track from "../../services/track";
import NotificationCountWatcher from "../../shared-apps/Notifications/containers/NotificationCountWatcher";
import PairAction from "../store/pairActions";
import { getPair } from "../store/pairSelectors";
import PairListItem from "./PairListItem";

type TabPropsT = ContextRouter & { pair: ?CapPairReduxT, ... };

class PairDetailTabs extends React.Component<TabPropsT> {
  link = (event: SyntheticEvent<>, value: string) => {
    const { history, location } = this.props;
    if (value !== location.pathname) history.push(value);
  };

  track = (tab: string) => {
    const { pair } = this.props;
    if (pair) {
      track.pairAction({ action: "Change Tab", tab, ...pairService.capTrackData(pair) });
    }
  };

  render(): React.Node {
    const {
      location,
      match: { params },
      pair,
    } = this.props;
    const isMutualInterestOrBeyond = pairService.isMutualInterestOrBeyond(pair);
    return (
      <Media>
        {(matches: boolean) => (
          <Grid classes={{ container: tabFixedPosition("main1") }} container direction="column">
            {pairService.capPairClosed(pair) && !!pair && pair.am_state !== "PA" && (
              <Grid item>
                <ClosedBanner>This job is no longer accepting candidates.</ClosedBanner>
              </Grid>
            )}
            {matches && (
              <Grid item style={{ height: 69 }}>
                {pair && (
                  <PairListItem
                    pair={pair}
                    ListItemTextProps={{
                      primary: <AstralLink>{getRole(pair.job)}</AstralLink>,
                      timestamp: false,
                    }}
                  />
                )}
              </Grid>
            )}
            <Grid item classes={{ item: "col-12" }}>
              <CenteredTabs onChange={this.link} value={location.pathname}>
                <Tab
                  label="Job Details"
                  data-testid="jobDetailsTabBtn"
                  onClick={() => this.track("Job Details")}
                  value={londonLink.capPairDetail(+params.candidate, +params.pair)}
                />
                <BadgeTab
                  count={pair && pair.unread_notification_count}
                  label="Recruiter Chat"
                  data-testid="messagesTabBtn"
                  onClick={() => this.track("Messages")}
                  value={londonLink.capPairActivity(+params.candidate, +params.pair)}
                />
                {pair?.shared_feed_opened_at ? (
                  <BadgeTab
                    count={pair && pair.unread_shared_notification_count}
                    label="Company Chat"
                    data-testid="sharedMessagesTabBtn"
                    onClick={() => this.track("Shared Messages")}
                    value={londonLink.capSharedPairActivity(+params.candidate, +params.pair)}
                  />
                ) : isMutualInterestOrBeyond ? (
                  <Tooltip title="Company chat is unavailable for this job.">
                    <div className="flex-center px3" data-testid="sharedMessagesTabBtn">
                      <Typography
                        variant="overline"
                        color="grey.400"
                        style={{ fontSize: 14, fontWeight: 500, cursor: "default" }}
                      >
                        Company Chat{" "}
                      </Typography>
                      <Typography className="ml1">
                        <InfoIcon className="h5 grey-400" />
                      </Typography>
                    </div>
                  </Tooltip>
                ) : null}
              </CenteredTabs>
              {pair && (
                <NotificationCountWatcher
                  fetchAction={() => PairAction.fetchNotificationCount(pair.id)}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Media>
    );
  }
}

type StateToPropsT = { pair: ?CapPairReduxT, ... };
type OwnPropsT = ContextRouter & { ... };
type PropsT = StateToPropsT & OwnPropsT;

const mapStateToProps = (state: StateT, { match: { params } }: OwnPropsT): StateToPropsT => ({
  pair: getPair(state, +params.pair),
});

const styles = (pair: ?CapPairReduxT, matches: boolean) =>
  pairService.capPairClosed(pair)
    ? { paddingTop: matches ? 164 : 96 }
    : { paddingTop: matches ? 116 : 48 };

const Wrapper = (Cmp: React.ComponentType<ContextRouter>): React.ComponentType<OwnPropsT> =>
  connect<PropsT, OwnPropsT, StateToPropsT, _, StateT, _>(mapStateToProps)(
    ({ pair, ...props }: PropsT) => (
      <Media>
        {(matches: boolean) => (
          <div style={styles(pair, matches)}>
            <PairDetailTabs pair={pair} {...props} />
            <SuspenseWrapper>
              <Cmp {...props} />
            </SuspenseWrapper>
          </div>
        )}
      </Media>
    ),
  );
export default Wrapper;
