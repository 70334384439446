import { userService } from "@talentpair/api";
import { Section } from "@talentpair/quantic";
import { londonLink } from "@talentpair/env";
import { ErrorMessage } from "@talentpair/sentry";
import * as React from "react";
import { connect } from "@talentpair/redux";
import { Link, RouteComponentProps } from "@talentpair/routing";
import { MicroUserT } from "@talentpair/types/entities/user";
import { displayFullName } from "@talentpair/entities/user";
import { getCandidate } from "../cap/store/candidateSelectors";
// @ts-expect-error - eslint upgrade
import { getOrg } from "../tap/store/orgSelectors";
import { StateT } from "../store";

type StateToPropsT = {
  orgContact: MicroUserT | null;
  talentPartner: MicroUserT | null;
};
type OwnPropsT = RouteComponentProps<{ candidate?: string; org?: string }>;
type PropsT = StateToPropsT & OwnPropsT;

const Error404 = ({ orgContact, talentPartner }: PropsT): React.ReactElement => (
  <Section>
    <ErrorMessage subtitle="This item is no longer available.">
      Please choose a different item.{" "}
      {userService.isHm() &&
        `Contact your ${userService.isOrgAdmin() ? "Growth Partner" : "company admin"}${
          orgContact ? `, ${displayFullName(orgContact)},` : ""
        } if you think this item should be available.`}
      {userService.isCandidate() &&
        `Message your Growth Partner${
          talentPartner ? `, ${displayFullName(talentPartner)},` : ""
        } if you think this item should be available.`}
      <Link className="block" to="/">
        Home
      </Link>
      {userService.isRecruiter() ? null : (
        <Link
          className="block"
          to={
            userService.data?.candidate_id
              ? londonLink.capNotifications(userService.data?.candidate_id)
              : londonLink.tapNotifications(userService.orgId())
          }
        >
          Notifications
        </Link>
      )}
    </ErrorMessage>
  </Section>
);

const mapStateToProps = (state: StateT, { match: { params } }: OwnPropsT): StateToPropsT => {
  const candidate = params.candidate ? getCandidate(state, +params.candidate) : null;
  const org = params.org ? getOrg(state, +params.org) : null;
  return {
    orgContact: org && (userService.isOrgAdmin() ? org.primary_owner : org.contact_owner),
    talentPartner: candidate && candidate.owner,
  };
};

export default connect(mapStateToProps)(Error404);

export const _test = { Error404, mapStateToProps };
