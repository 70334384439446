import * as React from "react";
import { portal, PortalPropsT } from "@talentpair/portal";
import track from "../../services/track";
import OnboardingContext, { ValueT } from "./OnboardingContext";
import { CardConfigPropsT } from "./ui/Card";

interface BaseWatcherProps<P> extends Omit<CardConfigPropsT, "initialStep" | "setTourStep"> {
  children: React.ReactNode;
  component: React.ComponentType<P & PortalPropsT & CardConfigPropsT>;
  componentProps?: Partial<P>;
  step?: number;
}
export interface WatcherProps<P> extends BaseWatcherProps<P>, ValueT {}

class TourWatcher<P> extends React.Component<WatcherProps<P>> {
  target: HTMLElement | null | undefined;

  componentDidMount(): void {
    const { currentTour, registerTour, tour, step } = this.props;
    // Tell the context component that this tour is available in this render tree
    // Only un-stepped components or the first step should register the tour
    if (!step) registerTour(tour);
    // If our context provider tells us this Tour is up, render away!
    if (currentTour === tour && !step) this.addPortal();
  }

  componentDidUpdate(prevProps: WatcherProps<P>): void {
    if (this.props.currentTour !== prevProps.currentTour) {
      // active tour has changed... are we the current tour?
      // else were we the previous active tour?
      if (this.props.currentTour === this.props.tour) {
        // If this is a stepped tour then make sure our step here is the current one lest we add the portal from the wrong base location
        if (this.props.step == null || this.props.step === this.props.currentTourStep)
          this.addPortal();
      } else if (this.props.tour === prevProps.tour) {
        portal.remove(this.props.tour);
      }
    } else if (
      this.props.currentTourStep !== prevProps.currentTourStep &&
      this.props.currentTour === this.props.tour &&
      this.props.currentTourStep === this.props.step
    ) {
      this.addPortal(); // rerender
    }
  }

  componentWillUnmount(): void {
    const { tour, unregisterTour } = this.props;
    // If the target is removed, just remove the tooltip without marking it as done
    portal.remove(tour);
    unregisterTour(tour);
  }

  addPortal(): void {
    const {
      component: Cmp,
      markTourAsDone,
      setTourStep,
      tour,
      currentTourStep = 0,
      componentProps,
      doneText,
      slides,
    } = this.props;
    if (this.target) {
      portal.modal(Cmp, {
        ...componentProps,
        tour,
        initialStep: currentTourStep,
        key: tour,
        setTourStep,
        onExited: markTourAsDone(tour),
        // @ts-expect-error - it's fine
        target: this.target,
        doneText,
        slides,
      });
      track.onboarding({ tour, action: "Show" });
    }
  }

  render(): React.ReactNode {
    return (
      <div
        ref={(node) => {
          this.target = node;
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

interface TourProps<P> extends BaseWatcherProps<P> {
  showIf?: boolean;
}
function Tour<P>({ showIf = true, ...props }: TourProps<P>): React.ReactElement {
  return showIf ? (
    <OnboardingContext.Consumer>
      {(value) => <TourWatcher {...value} {...props} />}
    </OnboardingContext.Consumer>
  ) : (
    <>{props.children}</>
  );
}

export default Tour;
export const _test = { TourWatcher };
