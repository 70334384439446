import sanitize from "sanitize-filename";
import { env } from "@talentpair/env";
import { apiRequest, request, ResponseDataT } from "./apiRequest";

// We have more restrictions for file names to remove
export const sanitizeFilename = (filename: string): string =>
  sanitize(filename.replace(/[#%&]/g, ""));

export const fileNameToId = (filename: string, userId: number): string => {
  const ext = filename.split(".").pop() || "";
  return `${userId}.${ext}`;
};

export interface S3ConfigT {
  endpoint: string;
  file: string;
  params: Record<string, unknown> | null;
}

export interface S3UploadPostDataT {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
    acl: string;
  };
}

function postFileToS3<D = unknown>(
  file: File,
  { url, fields }: S3UploadPostDataT,
  appendAcl: boolean,
): Promise<ResponseDataT<D>> {
  const body = new FormData();

  // IMPORTANT: The order in which appending these keys matter or else s3 will break
  body.append("key", fields.key);
  body.append("AWSAccessKeyId", fields.AWSAccessKeyId);
  body.append("policy", fields.policy);
  body.append("signature", fields.signature);
  if (appendAcl) body.append("acl", fields.acl);
  body.append("file", file);

  return request(url, {
    method: "POST",
    body,
    credentials: "omit", // default, but we overwrite the default in our `request`. Don't want to send cookies to AWS
  });
}

const getSignedUploadPath = (
  filePath: string,
  fileName: string,
): Promise<ResponseDataT<S3UploadPostDataT>> =>
  apiRequest.post(`${filePath}/file-upload/?filename=${fileName}`);

function postFilePathToAPI<D = unknown>({
  endpoint,
  file,
  params,
}: S3ConfigT): Promise<ResponseDataT<D>> {
  return apiRequest.post(endpoint, {
    file,
    ...params,
  });
}

type OptionsT = {
  endpoint?: string | null;
  params?: Record<string, unknown>;
};

function upload<D = unknown>(
  file: File,
  filePath: string,
  options: OptionsT = {},
): Promise<ResponseDataT<D>> {
  const fileName = sanitizeFilename(file.name);
  const config = {
    endpoint: options.endpoint || `${filePath}/files/`,
    params: options.params || null,
  };

  return getSignedUploadPath(filePath, fileName).then(({ data }) =>
    postFileToS3(file, data, false).then(() =>
      postFilePathToAPI({
        ...config,
        file: data.fields.key.replace("media/", ""),
      }),
    ),
  );
}

function uploadPhoto(file: File, userId: number): Promise<string> {
  const fileName = fileNameToId(sanitizeFilename(file.name), userId);
  return apiRequest
    .post<S3UploadPostDataT>(`users/api/upload-profile-photo/`, { filename: fileName })
    .then(({ data }) =>
      postFileToS3(file, data, true).then(async () => {
        await apiRequest.post("users/api/set-profile-photo/", {
          signed_url: data.fields.key.replace("media/profile_pictures/", ""),
        });
        return data.fields.key.replace("media/profile_pictures/", "");
      }),
    );
}

function uploadLogo(file: File, orgId: number): Promise<string> {
  const fileName = fileNameToId(sanitizeFilename(file.name), orgId);
  return (
    apiRequest
      // get a url for us to upload to
      .post<S3UploadPostDataT>(`organizations/${orgId}/api/upload-logo/`, { filename: fileName })
      .then(({ data }) =>
        //  upload!
        postFileToS3(file, data, true).then(async () =>
          apiRequest
            // tell the BE we uploaded it!
            .post(`organizations/${orgId}/api/set-logo/`, {
              logo_url: data.fields.key.replace("media/org_logos/", ""),
            })
            // return full url for FE to use
            .then(() => env.s3Url(data.fields.key)),
        ),
      )
  );
}

export const s3 = { upload, uploadPhoto, uploadLogo };
export const _test = { postFileToS3, uploadPhoto, postFilePathToAPI };
