/* eslint-disable import/first */
// CSS
import "normalize.css";
import "@talentpair/assets/css/main.css";
import "@talentpair/assets/css/basscss";
import "@talentpair/assets/css/draft-js.css";

import { sentry } from "@talentpair/sentry";
import { createBrowserHistory } from "@talentpair/routing";
import ReactDOM from "react-dom";
import { Provider } from "@talentpair/redux";
import store from "./store";
import App from "./App";

const history = createBrowserHistory();

// Start this asap to catch any errors
sentry.init(history);

ReactDOM.render(
  // @ts-expect-error - store type difference in Action
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  // $FlowFixMe issue with ReactDOM types
  document.getElementById("react-app"),
);
