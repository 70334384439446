import { apiRequest, ResponseDataT } from "@talentpair/api";
import { setTpCookie } from "@talentpair/tracking";
import { queryStrToObj } from "@talentpair/utils";

export interface UtmParamsT {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;
}

const utmParamKeys: Array<keyof UtmParamsT> = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_term",
];

export function getUtmParams(url: string): Partial<UtmParamsT> | null {
  // pull any utm query params out of the given url
  const queryParams = queryStrToObj(url);

  const campaignKeywords: Array<keyof UtmParamsT> = utmParamKeys.filter((k) => !!queryParams[k]);

  if (!campaignKeywords.length) return null;

  return campaignKeywords.reduce<Partial<UtmParamsT>>((acc, keyword) => {
    acc[keyword] = queryParams[keyword];
    return acc;
  }, {});
}

export function saveUtmParams(params: Partial<UtmParamsT>): void {
  // store params in a cookie
  setTpCookie("UTM_PARAMS", JSON.stringify(params));
}

export function sendUtmParams(
  id: number,
  params: Partial<UtmParamsT>,
): Promise<ResponseDataT<void>> {
  // send utm params to BE
  return apiRequest.post(`users/${id}/events/source/`, params);
}
