// @flow
import { Switch } from "@talentpair/routing";
import { Placeholder } from "@talentpair/quantic";
import { CapAuthRoute } from "../components/hocs/AuthRoute";
import { header, page } from "../components/layout/responsive";
import type { HeaderPropsT } from "../components/hocs/createCapHeader";
import { routes } from "../routeConfig";
import { CapPairHeader, CapPairMeta } from "./containers/PairHeader";
import { CapPairActivity } from "./PairActivity";
import { CapPairDetail } from "./PairDetail";
import { CapApplicationDetail } from "./ApplicationDetail";
import { CapPairList, CapPairListTitle, CapPairListMeta } from "./PairList";

// Needed to make sure pair params are passed down
const constant = (Cmp) => (props: HeaderPropsT) =>
  (
    <Switch>
      <CapAuthRoute {...routes.get("CAP/PairList")} render={(p) => <Cmp {...props} {...p} />} />
      <CapAuthRoute {...routes.get("CAP/PairDetail")} render={(p) => <Cmp {...props} {...p} />} />
      <CapAuthRoute
        {...routes.get("CAP/ApplicationDetail")}
        render={(p) => <Cmp {...props} {...p} />}
      />
      <CapAuthRoute {...routes.get("CAP/PairActivity")} render={(p) => <Cmp {...props} {...p} />} />
      <CapAuthRoute
        {...routes.get("CAP/SharedPairActivity")}
        render={(p) => <Cmp {...props} {...p} />}
      />
    </Switch>
  );

const Meta = () => (
  <Switch>
    <CapAuthRoute {...routes.get("CAP/PairList")} component={CapPairListMeta} />
    <CapAuthRoute {...routes.get("CAP/PairDetail")} component={CapPairMeta} />
    <CapAuthRoute {...routes.get("CAP/ApplicationDetail")} component={CapPairMeta} />
    <CapAuthRoute {...routes.get("CAP/PairActivity")} component={CapPairMeta} />
    <CapAuthRoute {...routes.get("CAP/SharedPairActivity")} component={CapPairMeta} />
  </Switch>
);
const MobileHeader = (props: HeaderPropsT) => (
  <Switch>
    <CapAuthRoute
      {...routes.get("CAP/PairList")}
      render={(p) => <CapPairListTitle {...props} {...p} />}
    />
    <CapAuthRoute
      {...routes.get("CAP/PairDetail")}
      render={(p) => <CapPairHeader {...props} {...p} />}
    />
    <CapAuthRoute
      {...routes.get("CAP/ApplicationDetail")}
      render={(p) => <CapPairHeader {...props} {...p} />}
    />
    <CapAuthRoute
      {...routes.get("CAP/PairActivity")}
      render={(p) => <CapPairHeader {...props} {...p} />}
    />
    <CapAuthRoute
      {...routes.get("CAP/SharedPairActivity")}
      render={(p) => <CapPairHeader {...props} {...p} />}
    />
  </Switch>
);
const DesktopHeader = constant(CapPairListTitle);
const MobileMain = () => (
  <Switch>
    <CapAuthRoute {...routes.get("CAP/PairList")} component={CapPairList} />
    <CapAuthRoute {...routes.get("CAP/PairDetail")} component={CapPairDetail} />
    <CapAuthRoute {...routes.get("CAP/ApplicationDetail")} component={CapApplicationDetail} />
    <CapAuthRoute {...routes.get("CAP/PairActivity")} component={CapPairActivity} />
    <CapAuthRoute {...routes.get("CAP/SharedPairActivity")} component={CapPairActivity} />
  </Switch>
);
const DesktopMain = () => (
  <Switch>
    <CapAuthRoute {...routes.get("CAP/PairList")} component={Placeholder} />
    <CapAuthRoute {...routes.get("CAP/PairDetail")} component={CapPairDetail} />
    <CapAuthRoute {...routes.get("CAP/ApplicationDetail")} component={CapApplicationDetail} />
    <CapAuthRoute {...routes.get("CAP/PairActivity")} component={CapPairActivity} />
    <CapAuthRoute {...routes.get("CAP/SharedPairActivity")} component={CapPairActivity} />
  </Switch>
);
const SidebarOne = constant(CapPairList);

const CapPairRoutesHeader = header({
  desktop: DesktopHeader,
  mobile: MobileHeader,
});

export const CapPairRoutesPage = page({
  desktop: DesktopMain,
  mobile: MobileMain,
  sidebarOne: SidebarOne,
  header: CapPairRoutesHeader,
  meta: Meta,
});
