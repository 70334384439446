import * as React from "react";
import { withStyles } from "@mui/styles";
import { Stars } from "./StarSvg";

const spinnerStyle = {
  "@keyframes resume-processing-spinner": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(359deg)" },
  },
  spinner: {
    animationDuration: "2s",
    animationIterationCount: "infinite",
    animationName: "$resume-processing-spinner",
    animationTimingFunction: "linear",
    transformOrigin: "27.5px 27.5px",
  },
};
export const Spinner = withStyles(spinnerStyle)(
  ({ classes, ...props }: { classes: { spinner: string } }) => (
    <g id="processing" fill="none" fillRule="evenodd" {...props}>
      <circle cx="27.5" cy="27.5" r="27.5" fill="#ADBCDA" stroke="#815A91" strokeWidth="5" />
      <path
        className={classes.spinner}
        fill="#FFF"
        fillRule="nonzero"
        d="M27.463 43.855v3.705a1 1 0 0 1-1.707.707l-5.842-5.841a1 1 0 0 1 0-1.414l5.842-5.842a1 1 0 0 1 1.707.707v3.977c4.371-.225 7.622-1.908 9.895-5.034 2.976-4.093 3.272-8.45.87-13.39a2 2 0 0 1 3.598-1.749c3.035 6.241 2.63 12.177-1.233 17.491-3.067 4.217-7.495 6.439-13.13 6.683zm.074-32.71V7.44a1 1 0 0 1 1.707-.707l5.842 5.841a1 1 0 0 1 0 1.414l-5.842 5.842a1 1 0 0 1-1.707-.707v-3.977c-4.371.225-7.622 1.908-9.895 5.034-2.976 4.093-3.272 8.45-.87 13.39a2 2 0 0 1-3.598 1.749c-3.035-6.241-2.63-12.177 1.233-17.491 3.067-4.217 7.495-6.439 13.13-6.683z"
      />
    </g>
  ),
);

const successStyle = {
  "@keyframes resume-success-grow": {
    "0%": { opacity: 0, transform: "scale(0.5)" },
    "100%": { opacity: 1, transform: "scale(1)" },
  },
  success: {
    animationDuration: "0.5s",
    animationName: "$resume-success-grow",
    transformOrigin: "27.5px 27.5px",
  },
};
const SuccessCheck = withStyles(successStyle)(
  ({ classes, ...props }: { classes: { success: string } }) => (
    <g id="success" fill="none" fillRule="evenodd" {...props}>
      <circle cx="27.5" cy="27.5" r="27.5" fill="#7BDEB1" stroke="#815A91" strokeWidth="5" />
      <path
        className={classes.success}
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7"
        d="M39.399 21L24.897 35.872 16 26.991"
      />
    </g>
  ),
);

interface PropsT {
  state: "document" | "question" | "processing" | "fail" | "success";
}

export default ({ state }: PropsT): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="213" height="173">
    {state === "processing" && (
      <defs>
        <linearGradient id="processingGradient">
          <stop offset="0%" stopColor="#815A91">
            <animate attributeName="offset" values="-2; 1" dur="3s" repeatCount="indefinite" />
          </stop>
          <stop offset="50%" stopColor="#adbcda">
            <animate attributeName="offset" values="-1.5; 1.5" dur="3s" repeatCount="indefinite" />
          </stop>
          <stop offset="100%" stopColor="#815A91">
            <animate attributeName="offset" values="-1; 2" dur="3s" repeatCount="indefinite" />
          </stop>
        </linearGradient>
      </defs>
    )}
    <g fill="none" fillRule="evenodd">
      {state === "success" && <Stars />}
      <g id="document" transform="translate(67 28)">
        <path
          fill="#6FCFA5"
          d="M11 2h45.347a6 6 0 0 1 4.427 1.95l18.654 20.396A6 6 0 0 1 81 28.396V101a8 8 0 0 1-8 8H11a8 8 0 0 1-8-8V10a8 8 0 0 1 8-8z"
        />
        <path
          fill={state === "processing" ? "url(#processingGradient)" : "#815A91"}
          fillRule="nonzero"
          d="M56 6H9a3 3 0 0 0-3 3v94a3 3 0 0 0 3 3h66a3 3 0 0 0 3-3V29.322c0-.45-.06-.895-.178-1.322H60a4 4 0 0 1-4-4V6zM9 0h47.357a10 10 0 0 1 7.315 3.182l17.375 18.64a11 11 0 0 1 2.953 7.5V103a9 9 0 0 1-9 9H9a9 9 0 0 1-9-9V9a9 9 0 0 1 9-9zm11 52h44a3 3 0 0 1 0 6H20a3 3 0 0 1 0-6zm0-17h23a3 3 0 0 1 0 6H20a3 3 0 0 1 0-6zm0 34h44a3 3 0 0 1 0 6H20a3 3 0 0 1 0-6zm0 17h44a3 3 0 0 1 0 6H20a3 3 0 0 1 0-6z"
        />
        <rect width="48" height="6" x="7" y="7" fill="#7BDEB1" rx="3" />
        <rect width="70" height="6" x="7" y="99" fill="#62C297" rx="3" />
      </g>
      {state === "question" && (
        <g id="question" transform="translate(124 88)">
          <circle cx="27.5" cy="27.5" r="27.5" fill="#ADBCDA" stroke="#815A91" strokeWidth="5" />
          <path
            fill="#FFF"
            d="M18.965 22.019c.056-4.862 3.58-8.035 9.518-8.035 5.641 0 9.315 2.97 9.315 7.422 0 2.746-1.28 4.639-4.008 6.216-2.542 1.466-3.247 2.3-3.247 4.045v.872h-5.79l-.055-.742c-.352-3.173.575-4.843 3.155-6.327 2.523-1.485 3.172-2.264 3.172-3.897 0-1.503-1.224-2.542-2.968-2.542-1.782 0-3.006 1.188-3.062 2.988h-6.03zm8.795 19.593c-1.948 0-3.507-1.466-3.507-3.34 0-1.855 1.559-3.32 3.507-3.32 1.93 0 3.507 1.465 3.507 3.32 0 1.874-1.578 3.34-3.507 3.34z"
          />
        </g>
      )}
      {/*
      // @ts-expect-error - eslint upgrade */}
      {state === "processing" && <Spinner transform="translate(124 88)" />}
      {state === "fail" && (
        <g id="fail" transform="translate(124 88)">
          <circle cx="27.5" cy="27.5" r="27.5" fill="#D25786" stroke="#815A91" strokeWidth="5" />
          <path
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="7"
            d="M36.517 19L19 36.517M20 19l17.517 17.517"
          />
        </g>
      )}
      {/*
      // @ts-expect-error - eslint upgrade */}
      {state === "success" && <SuccessCheck transform="translate(124 88)" />}
    </g>
  </svg>
);
