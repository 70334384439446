// @flow
import * as React from "react";
import { page } from "../../components/layout/responsive";
import ProfileTabs from "../components/ProfileTabs";
import { CapProfileHeader, CapProfileMeta } from "../containers/ProfileHeader";

const CapProfilePreview = ProfileTabs(React.lazy(() => import("./containers/ProfilePreviewPage")));

export const CapProfilePreviewPage = page({
  mobile: CapProfilePreview,
  header: CapProfileHeader,
  meta: CapProfileMeta,
});
