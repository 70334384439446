import * as React from "react";
import clsx from "clsx";
import { NewTabLink } from "../NewTabLink";

type OnMouseDownT = (event: React.MouseEvent) => unknown;

export interface BadgePropsT {
  text: string;
  value?: string | number | null | undefined;
  className?: string;
  target?: string;
  href?: string | null;
  bgColor?: string;
  bgColorHex?: string;
  fgColor?: string;
  onMouseDown?: OnMouseDownT;
  "data-testid"?: string;
}

export const Badge = ({
  text,
  value,
  className = "",
  href = null,
  bgColor = "bg-green-500",
  fgColor = "white",
  onMouseDown,
  ...props
}: BadgePropsT): React.ReactElement => {
  const sharedClasses = "inline-block h6 rounded regular line-height-initial condensed-600 nowrap";
  const valueUi =
    value == null ? null : (
      <span
        className={clsx("inline-block bg-white", bgColor.replace("bg-", ""))}
        style={{
          margin: "1px 1px 1px 4px",
          padding: "0 2px 0 2px",
          borderRadius: "0 2px 2px 0",
          lineHeight: "13px",
        }}
      >
        {value}
      </span>
    );
  if (href)
    return (
      <NewTabLink
        {...props}
        onMouseDown={onMouseDown}
        href={href}
        className={clsx(sharedClasses, valueUi ? "pl1" : "px1", bgColor, fgColor, className)}
      >
        {text}
        {valueUi}
      </NewTabLink>
    );

  return (
    <span
      {...props}
      className={clsx(
        "text-decoration-none",
        sharedClasses,
        valueUi ? "pl1" : "px1",
        bgColor,
        fgColor,
        className,
      )}
    >
      {text}
      {valueUi}
    </span>
  );
};
