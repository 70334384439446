import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@talentpair/quantic";
import { portal } from "@talentpair/portal";
import Hotkey from "kyoto/components/Hotkey";

import NavSearchModal from "./NavSearchModal";

const openNavSearch = (): unknown => portal.modal(NavSearchModal);

export function NavSearchButton(): React.ReactElement {
  return (
    <>
      <Button
        variant="icon"
        icon={SearchIcon}
        size="lg"
        data-testid="navSearchButton"
        onClick={openNavSearch}
      />
      <Hotkey on="s" handler={openNavSearch} />
      <Hotkey on="/" handler={openNavSearch} />
    </>
  );
}
