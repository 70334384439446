import * as React from "react";
import InfoIcon from "@mui/icons-material/Info";

export default ({ children }: { children: React.ReactNode }): React.ReactElement => (
  <div
    className="px3 py2 white bg-grey-900 flex-none flex items-center h6 z1"
    style={{ height: 48 }}
  >
    <InfoIcon classes={{ root: "mr2" }} />
    {children}
  </div>
);
