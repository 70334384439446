import * as React from "react";
import { Link } from "@talentpair/routing";
import { Badge } from "@mui/material";
import { useSelector } from "@talentpair/redux";
import { londonLink } from "@talentpair/env";
import { userService } from "@talentpair/api";
import { UserAvatar, MenuItem } from "@talentpair/quantic";
import { displayFullName } from "@talentpair/entities/user";
// @ts-expect-error - REMOVE ME
import { getOrg } from "../../store/orgSelectors";
import NotificationCountWatcher from "../../../shared-apps/Notifications/containers/NotificationCountWatcher";
import ActivityAction from "../../../shared-apps/ActivityFeed/store/activityActions";
import UserActivityAction from "../../../shared-apps/ActivityFeed/store/userActivityActions";
import { getUserActivity } from "../../../shared-apps/ActivityFeed/store/userActivitySelectors";
import { DispatchT } from "../../../store";

export function AccountOwnerMenuItem({ orgId }: { orgId: number }): React.ReactElement | null {
  const { contact_owner, primary_owner, notifications_count } = useSelector((state) => {
    const org = getOrg(state, orgId);
    return {
      contact_owner: org?.contact_owner,
      primary_owner: org?.primary_owner,
      notifications_count:
        // @ts-expect-error - it's fine
        (org?.contact_owner && getUserActivity(state, org?.contact_owner.id)?.notificationCount) ||
        0,
    };
  });
  if (!userService.isOrgAdmin() || !primary_owner || !contact_owner) return null;

  return (
    <MenuItem
      data-testid="growthPartnerBtn"
      component={Link}
      icon={
        <div className="mln2 myn2 p2">
          <Badge overlap="circular" max={9} badgeContent={notifications_count} color="error">
            <UserAvatar user={primary_owner} ringed={false} />
          </Badge>
        </div>
      }
      to={londonLink.tapUserActivity(orgId)}
    >
      {displayFullName(primary_owner)} @ Talentpair
      <NotificationCountWatcher
        fetchAction={() => (dispatch: DispatchT) => {
          dispatch(UserActivityAction.fetchNotificationCount(contact_owner.id));
          dispatch(ActivityAction.fetchLatestActivity("user", contact_owner.id));
        }}
      />
    </MenuItem>
  );
}
