import * as React from "react";
import clsx from "clsx";
import { Badge, ListItemAvatar } from "@mui/material";
import { UserAvatar } from "kyoto/components/ui/Avatars";
import { ListItem, ListItemText, ListItemPropsT } from "kyoto/components/ui/List";
import { MicroUserT } from "@talentpair/types/entities/user";
import { displayFullName } from "@talentpair/entities/user";
import ActivityAction from "../shared-apps/ActivityFeed/store/activityActions";
import UserActivityAction from "../shared-apps/ActivityFeed/store/userActivityActions";
import NotificationCountWatcher from "../shared-apps/Notifications/containers/NotificationCountWatcher";
import { isCap } from "../routeConfig";
import { DispatchT } from "../store";

export type PropsT<D extends React.ElementType> = ListItemPropsT<D> & {
  owner: MicroUserT;
  timestamp?: string | null;
  notificationCount?: number | null;
  userActivityId?: number | null;
};

const AccountOwnerListItem = <D extends React.ElementType>({
  owner,
  className = undefined,
  timestamp = null,
  notificationCount = null,
  userActivityId = null,
  ...props
}: PropsT<D>): React.ReactElement => (
  <ListItem className={clsx("flex bg-white", className)} {...props}>
    <ListItemAvatar>
      <UserAvatar user={owner} />
    </ListItemAvatar>
    <ListItemText
      className="pr3"
      primary={displayFullName(owner)}
      secondary={`Growth ${isCap() ? "Partner" : "Executive"} @ Talentpair`}
      timestamp={timestamp}
    />
    {notificationCount ? (
      <Badge badgeContent={notificationCount} color="error">
        <span />
      </Badge>
    ) : null}
    {!!userActivityId && (
      <NotificationCountWatcher
        fetchAction={() => (dispatch: DispatchT) => {
          dispatch(UserActivityAction.fetchNotificationCount(userActivityId));
          dispatch(ActivityAction.fetchLatestActivity("user", userActivityId));
        }}
      />
    )}
  </ListItem>
);

export default AccountOwnerListItem;
