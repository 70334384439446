import { Avatar, AvatarProps } from "../../atoms/Avatar";
import { Ring, RingProps } from "../../atoms/Ring";

export type RingedAvatarProps = AvatarProps & {
  ringColor?: RingProps["color"];
  // `ringed` prop controls if the ring is visible
  // ringed === true means show the ring and use the ringColor prop
  // if ring is left undefined the ring will not be visible, but it will still be in the DOM and will add whitespace to the avatar
  // ringed === false means hide the ring completely along with any whitespace
  ringed?: boolean;
};

export function RingedAvatar({
  shape,
  ringed,
  ringColor = "transparent",
  ...props
}: RingedAvatarProps): React.ReactElement {
  const avatar = <Avatar {...props} shape={shape} />;
  return ringed === false ? (
    avatar
  ) : (
    <Ring shape={shape} color={ringed ? ringColor : "transparent"}>
      {avatar}
    </Ring>
  );
}
