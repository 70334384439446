// @flow
import { withRouter, type ContextRouter } from "@talentpair/routing";
import { SubmitButton, CancelButton } from "@talentpair/quantic";
import { londonLink } from "@talentpair/env";
import { Modal, type PortalPropsT } from "@talentpair/portal";
import { useFetcher } from "@talentpair/api";
import type { CapCandidateDetailReduxT } from "@talentpair/types/cap/entities/candidate";
import { fetchCompleteness } from "../utils/completeness";
import ProfileStatus from "./ProfileStatus";

type PropsT = PortalPropsT & ContextRouter & { candidate: CapCandidateDetailReduxT, ... };

const IncompleteProfileModal = ({
  candidate,
  history,
  match,
  location,
  staticContext,
  ...props
}: PropsT) => {
  const { rsp, loading } = useFetcher(() => fetchCompleteness(candidate.id));

  return (
    <Modal
      data-testid="incompleteProfileModal"
      title="Profile Incomplete"
      DialogContentProps={{ className: "p0" }}
      actions={
        <>
          <CancelButton onClick={props.onClose} />
          <SubmitButton
            onClick={() => {
              props.onClose();
              history.push(londonLink.capProfile(candidate.id));
            }}
          >
            Go to your profile
          </SubmitButton>
        </>
      }
      {...props}
    >
      <ProfileStatus
        candidate={candidate}
        className="mb3"
        hideButton
        incompleteFields={loading ? null : rsp}
      />
    </Modal>
  );
};

export default withRouter(IncompleteProfileModal);

export const _test = { IncompleteProfileModal };
