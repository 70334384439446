import * as React from "react";
import { Chip } from "@mui/material";
import { mergeClasses } from "../../utils/misc";

export type ChipVariantT = "default" | "warning" | "error";

export interface FocusableChipRenderPropsT<V> {
  key?: string | number;
  label: string;
  tabIndex?: number;
  value: V;
  classes?: Record<string, string>;
  onDelete?: (e: React.SyntheticEvent | React.KeyboardEvent) => void;
  onFocus: () => void;
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export type RenderFocusableChipT<V> = (props: FocusableChipRenderPropsT<V>) => React.ReactElement;

export interface FocusableChipPropsT<V> {
  index: number;
  label: string;
  focus: boolean;
  render?: RenderFocusableChipT<V> | null;
  Component?: React.ComponentType<FocusableChipRenderPropsT<V>>;
  value: V;
  onFocus: (index: number) => void;
  onDelete: (index: number, value: V) => void;
  disabled?: boolean;
  tabbable?: boolean;
  classes?: Record<string, string> | null;
  tabIndex?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default class FocusableChip<V> extends React.Component<FocusableChipPropsT<V>> {
  ref: { current: null | HTMLElement } = React.createRef();

  static defaultProps = {
    render: null,
    Component: Chip,
    disabled: false,
    tabbable: false,
  };

  componentDidUpdate({ focus }: FocusableChipPropsT<V>): void {
    if (
      this.props.focus &&
      !focus &&
      this.ref.current &&
      document.activeElement !== this.ref.current
    ) {
      this.ref.current.focus();
    }
  }

  onDelete = (e: React.SyntheticEvent | React.KeyboardEvent): void => {
    const { onDelete, index, value } = this.props;
    // Disable MUI keyboard delete functionality since FocusableChipList handles this insteaad.
    if (!("key" in e) || e.key !== "Backspace") onDelete(index, value);
  };

  onFocus = (): void => this.props.onFocus(this.props.index);

  render(): React.ReactNode {
    const {
      focus,
      render,
      Component = Chip,
      disabled,
      tabbable,
      onDelete,
      classes = null,
      onFocus,
      ...props
    } = this.props;
    const renderProps: FocusableChipRenderPropsT<V> = {
      ...props,
      classes: mergeClasses(disabled ? { root: "pointer-events-none" } : {}, classes),
      onDelete: disabled ? undefined : this.onDelete,
      onFocus: this.onFocus,
      ref: this.ref,
    };
    if (!tabbable) renderProps.tabIndex = -1;
    return render ? render(renderProps) : <Component {...renderProps} />;
  }
}
