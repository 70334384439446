import { ReactElement } from "react";
import { makeStyles } from "@mui/styles/";
import { MakeStyleProps } from "@talentpair/types/material-ui";

const useStyles = makeStyles({
  "@keyframes processing-ellipsis": {
    from: { width: 0 },
    to: { width: "1em" },
  },
  ellipsis: {
    position: "relative",
    "&:after": {
      animation: "$processing-ellipsis steps(4,end) 1s infinite",
      content: '"\\2026"',
      display: "inline-block",
      left: "100%",
      overflow: "hidden",
      position: "absolute",
      verticalAlign: "bottom",
    },
  },
});

export function AnimatedEllipsis(props: MakeStyleProps<typeof useStyles>): ReactElement {
  const classes = useStyles(props);
  return <span className={classes.ellipsis} />;
}
