import * as React from "react";
import {
  FastField,
  Field as SlowField,
  FieldConfig,
  FormikValues,
  FormikProps,
  FormikContextType,
  connect,
} from "formik";
import lodash from "lodash";
import { FormControlProps, FormHelperTextProps } from "@mui/material";
import { FieldPropsT, FieldBagT, SubmitOnChangeT } from "@talentpair/types/formik";
import { MarginT } from "@talentpair/types/misc";
import FieldLabel from "../components/FieldLabel";
import { EventObjectT, mergeStatus } from "../utils";
import { defaultParserFormatter } from "../parseFormat";
import { required } from "../validators";

interface AutoFieldPropsT<Value, FormValues extends FormikValues, Formatted = Value>
  extends Omit<FieldConfig, "render"> {
  fast?: boolean;
  render: (props: FieldPropsT<Value, Formatted, FormValues>) => React.ReactElement;
  submitOnChange?: SubmitOnChangeT<Value, FormValues> | null;
  submitOnBlur?: SubmitOnChangeT<Value, FormValues> | null;
  onSubmitError?: (formik: FormikProps<FormValues>) => unknown;
  required?: boolean;
  parse?: (v: Formatted) => Value;
  format?: (v: Value) => Formatted;
  updateOnlyOnBlur?: boolean;
  revertOnInvalid?: boolean;
}

export interface ConnectedAutoFieldPropsT<Value, FormValues, Formatted>
  extends AutoFieldPropsT<Value, FormValues, Formatted> {
  formik: FormikContextType<FormValues>;
}

interface FieldStateT<Value> {
  value: Value;
}

class UnconnectedAutoField<
  Value,
  FormValues extends FormikValues,
  Formatted = Value,
> extends React.Component<
  ConnectedAutoFieldPropsT<Value, FormValues, Formatted>,
  FieldStateT<Value>
> {
  prevVal: Value | null | undefined;

  focused = false;

  static defaultProps = {
    fast: false,
    required: false,
    submitOnChange: null,
    submitOnBlur: null,
    updateOnlyOnBlur: false,
    parse: defaultParserFormatter.parse,
    format: defaultParserFormatter.format,
  };

  constructor(props: ConnectedAutoFieldPropsT<Value, FormValues, Formatted>) {
    super(props);
    const {
      formik: { initialValues, values },
      name,
      updateOnlyOnBlur,
    } = props;
    // eslint-disable-next-line react/state-in-constructor
    if (updateOnlyOnBlur) this.state = { value: values[name] };
    // Initialize previous value with what was provided in initialValues
    // Might have to revisit this if we use enableReinitialize in the future
    if (this.prevVal === undefined && initialValues) this.prevVal = initialValues[name];
  }

  componentWillUnmount(): void {
    const { name, formik } = this.props;
    if (this.focused)
      this.onBlur({ target: { name, value: formik.values[name] }, persist: lodash.noop });
  }

  onError = (
    e: {
      status: number;
      data?: {
        [key: string]: string[];
      };
    } | null,
  ): void => {
    const { name, onSubmitError, formik } = this.props;
    if (!e || e.status !== 400 || !e.data) {
      if (onSubmitError) onSubmitError(formik);
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw e;
    }
    // Extract error data and associate it with the same key as the name of the form field. Although the
    // field name will usually match the name of the key returned in the error, there are cases where the
    // front end may have transformed the outgoing data when making the request, resulting in error keys
    // that don't match the field names, for example: https://goo.gl/NCty2R
    const errorKey = Object.keys(e.data)[0];
    const err = e.data[errorKey];
    formik.setFieldError(name, lodash.upperFirst(Array.isArray(err) ? err[0] : err));
    if (onSubmitError) onSubmitError(formik);
  };

  submitOnBlur = (): Promise<unknown> => {
    const { name, updateOnlyOnBlur, submitOnBlur, formik } = this.props;
    if (!submitOnBlur) return Promise.resolve();
    const { values } = formik;
    // wrapping submitOnBlur to ensure that the field's value has actually changed
    const val = updateOnlyOnBlur ? this.state.value : values[name];
    if (val === this.prevVal) return Promise.resolve();
    const prevVal = this.prevVal;
    this.prevVal = val;
    if (this.validate(val)) return Promise.resolve();

    return submitOnBlur({
      name,
      val,
      prevVal: prevVal != null ? prevVal : null,
      form: {
        ...formik,
        values: {
          ...values,
          [name]: val,
        },
      },
    }).catch(this.onError);
  };

  onBlur = (e: EventObjectT<Formatted>): Promise<unknown> => {
    const { updateOnlyOnBlur, submitOnBlur } = this.props;
    this.focused = false;
    if (updateOnlyOnBlur) this.onChange(e);
    if (submitOnBlur) return this.submitOnBlur();
    return Promise.resolve();
  };

  onFocus = (): void => {
    const { name, updateOnlyOnBlur, formik } = this.props;
    if (updateOnlyOnBlur && !this.focused) this.setState({ value: formik.values[name] });
    this.focused = true;
  };

  // this does both field.setFieldValue and submitOnChange functionality
  changeFieldValue = (name: string, val: Value): Promise<unknown> => {
    const { submitOnChange, formik, revertOnInvalid } = this.props;
    const prevVal = formik.values[name];
    const isInvalid = !!this.validate(val);
    formik.setFieldValue(name, val);
    // set field touched so that if validation says there an error then it'll show up
    // fixes issue where the field value can change by deleting a chip, but never focusing the field
    if (isInvalid) formik.setFieldTouched(name, true, false); // using false here to skip validation
    if (isInvalid && revertOnInvalid) formik.setFieldValue(name, prevVal, false); // skip validation to preserve error
    if (!submitOnChange || isInvalid) return Promise.resolve();
    return submitOnChange({
      name,
      val,
      prevVal,
      form: {
        ...formik,
        values: {
          ...formik.values,
          [name]: val,
        },
      },
    }).catch(this.onError);
  };

  /**
   * Handling onChange manually rather than using Formik's `field.onChange`. We
   * replicate parts of the default `field.onChange` functionality, and discard others.
   *
   * What we do:
   *    - Parse checkbox values into boolean
   *    - Update the field's value -- form.setFieldValue() -- this also runs all validators
   *    - Call submitOnChange
   *
   * What we don't bother doing that Formik does:
   *    - Parse number & range input values
   *    - Support pure string values rather than event-shaped objects as the onChange argument
   */
  onChange = ({
    target: { value, checked = false, type = "" },
  }: EventObjectT<Formatted>): Promise<unknown> => {
    const { name, parse, updateOnlyOnBlur } = this.props;
    const controlVal = type.includes("checkbox") ? !!checked : value;
    // @ts-expect-error - eslint upgrade
    const val = parse(controlVal);
    if (updateOnlyOnBlur && this.focused) {
      this.setState({ value: val });
      return Promise.resolve();
    }
    return this.changeFieldValue(name, val);
  };

  validate = (val: Value): string | void | Promise<string | void> =>
    (this.props.required && required(val)) || this.props.validate?.(val);

  render(): React.ReactNode {
    const {
      fast,
      render,
      submitOnChange,
      submitOnBlur,
      updateOnlyOnBlur,
      onSubmitError,
      revertOnInvalid,
      required: _required, // pulled off to prevent spreading onto the field component
      parse,
      format = defaultParserFormatter.format,
      validate,
      formik,
      ...props
    } = this.props;
    // FastField's value will get overwritten with the last value synced to context.formik when calling
    // `form.setFieldError()`. Since this syncing doesn't happen during `onChange()`, calling `setFieldError()` after
    // a change without triggering a sync (e.g. by blurring the field) will cause the value to get undesirably
    // overwritten. Therefore, don't use `FastField` when `submitOnChange` is provided.
    //
    // We don't really lose anything with this. `FastField` mostly (entirely?) benefits text fields by preventing the
    // form component tree from re-rendering with every keystroke. And we always use `submitOnBlur` with these fields
    // anyway, thus allowing us to still get the benefits of FastField when it really matters. Should an exception to
    // this ever arise (i.e. we want `submitOnChange` used with a text field), the performance hit of not using
    // `FastField` will probably be negligible anyway.
    //
    // Finally, perhaps this drawback of `FastField` will eventually get fixed:
    // https://github.com/jaredpalmer/formik/issues/509

    const FieldCmp = fast && !submitOnChange ? FastField : SlowField;

    return (
      <FieldCmp {...props} validate={this.validate}>
        {(fieldBag: FieldBagT<Value, Formatted, FormValues>): React.ReactNode => {
          const { field, form } = fieldBag;

          // text fields in large forms are still slow as heck, as every keystroke updates the formik state and rerenders the ENTIRE form again.
          // so we use updateOnlyOnBlur to trigger this field controlling the input state until the input has blurred
          const value = updateOnlyOnBlur && this.focused ? this.state.value : field.value;

          return render({
            field: {
              ...props,
              ...field,
              value: format(value),
              onChange: this.onChange,
              onBlur: (e: EventObjectT<Formatted>): Promise<unknown> => {
                // copying event as react reuses the same synthetic event, and we need this to persist through an asynchronous method
                const eCopy = {
                  ...e,
                  target: { ...e.target, name: field.name },
                };
                field.onBlur(eCopy);
                return this.onBlur(eCopy);
              },
              onFocus: this.onFocus,
            },
            form,
            submitVal: submitOnBlur ? this.submitOnBlur : undefined,
            setWarning: (msg: string | null | undefined) => {
              mergeStatus(form, { warnings: { [field.name]: msg } });
            },
          });
        }}
      </FieldCmp>
    );
  }
}

export const AutoField = connect(UnconnectedAutoField);

// Finding field errors may change. From README:
// NOTE: In Formik v0.12 / 1.0, a new meta prop may be be added to Field and FieldArray that will give you relevant
// metadata such as error & touch, which will save you from having to use Formik or lodash's getIn or checking if the
// path is defined on your own.
export interface UiFieldPropsT<Value, FormValues extends FormikValues, Formatted = Value>
  extends AutoFieldPropsT<Value, FormValues, Formatted> {
  disabled?: boolean;
  FormControlProps?: FormControlProps;
  FormHelperTextProps?: FormHelperTextProps;
  FormLabelProps?: Record<string, unknown>;
  fullWidth?: boolean;
  group?: boolean;
  helperText?: React.ReactNode;
  id?: string;
  InputLabelProps?: Record<string, unknown>;
  label?: React.ReactNode;
  required?: boolean;
  className?: undefined | string;
  classes?: Record<string, string>;
  style?: undefined | React.CSSProperties;
  margin?: MarginT;
  inlineLabel?: boolean;
  hasFocus?: boolean;
  customWarning?: string;
  showErrors?: boolean;
}

// Use this for fields where you have already defined a render prop
export type FieldWrapperPropsT<
  Value,
  Formatted,
  FormValues extends FormikValues = Record<string, unknown>,
> = Omit<UiFieldPropsT<Value, FormValues, Formatted>, "render">;

function Field<Value, FormValues extends FormikValues, Formatted = Value>({
  FormControlProps: formControlProps = {},
  FormHelperTextProps: formHelperTextProps = {},
  FormLabelProps: formLabelProps = {},
  InputLabelProps,
  disabled,
  fullWidth,
  group,
  helperText,
  label,
  className,
  margin,
  inlineLabel,
  render,
  hasFocus,
  customWarning,
  id,
  showErrors = true,
  ...props
}: UiFieldPropsT<Value, FormValues, Formatted>): React.ReactElement {
  return (
    <AutoField
      {...props}
      // @ts-expect-error - eslint upgrade
      render={(p: FieldPropsT<Value, Formatted, FormValues>): React.ReactNode => {
        const {
          form,
          field: { name },
        } = p;
        const field = { ...p.field, id: id || name, noValidate: true };
        const isTouched = lodash.get(form.touched, name) || !!form.submitCount;
        const error = isTouched ? lodash.get(form.errors, name) : null;
        const warning = isTouched && !error && lodash.get(form.status?.warnings || {}, name);

        return (
          <FieldLabel
            name={name}
            error={!showErrors ? null : error || warning || customWarning || null}
            disabled={disabled}
            group={group}
            fullWidth={fullWidth}
            helperText={helperText}
            label={label}
            className={className}
            margin={margin}
            inlineLabel={inlineLabel}
            hasFocus={hasFocus}
            touched={!!isTouched}
            required={props.required}
            FormHelperTextProps={formHelperTextProps}
            FormLabelProps={formLabelProps}
            InputLabelProps={InputLabelProps}
            {...formControlProps}
          >
            {render({ ...p, field })}
          </FieldLabel>
        );
      }}
    />
  );
}

export default Field;

export const _test = { UnconnectedAutoField };
