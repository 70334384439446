import * as React from "react";
import { FormikProps } from "formik";
import { diffObjects, preventFocusOverwrite } from "../../../utils/diff";

export interface PropsT<Values extends Record<string, unknown>> {
  initialValues: Values | null | undefined;
  formik: FormikProps<Values>;
}

// For autosaving forms, the true form values are living somewhere else in the state tree,
// being passed into the form. We don't want to use `enableReinitialize` though because it will
// wipe out any errors/warnings on the form. This allows us to reset the form values to the
// latest data from the server after autosaving without having to reset the entire form.
export default class Reinitialize<V extends Record<string, unknown>> extends React.Component<
  PropsT<V>
> {
  componentDidUpdate(prevProps: PropsT<V>): void {
    const { initialValues, formik } = this.props;
    if (!initialValues || !prevProps.initialValues) return;
    // To the next weary traveler that crosses this threshold: Woe unto you should you change this code!
    // Without this diffing of old and current initialValues autosaving forms will momentarily show old
    // values if it's rerendered while an edit is still in flight to the BE. This is because the initialValues
    // are pulled from redux which isn't updated until the edit request succeeds.
    const updated = preventFocusOverwrite(diffObjects(prevProps.initialValues, initialValues));
    Object.entries(updated).forEach(([field, value]) => formik.setFieldValue(field, value));
  }

  render(): React.ReactNode {
    return null;
  }
}
