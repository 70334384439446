import {
  differenceInCalendarDays,
  format,
  formatDistance,
  formatDistanceStrict,
  isSameYear,
  parseISO,
  isToday,
  isYesterday,
  isTomorrow,
} from "date-fns";

const january1st = (time: string): string => format(parseISO(time), "MMMM do");
const january2018 = (time: string): string => format(parseISO(time), "MMMM yyyy");
const january1st2018 = (time: string): string => format(parseISO(time), "MMMM do, yyyy");
const jan1st2018 = (time: string): string => format(parseISO(time), "MMM do, yyyy");
const jan1st = (time: string): string => format(parseISO(time), "MMM do");
const monday = (time: string): string => format(parseISO(time), "EEEE");
const mon = (time: string): string => format(parseISO(time), "EEE");
const t12pm = (time: string): string => format(parseISO(time), "h:mm aa").replace(":00", "");
const t2300 = (time: string): string => format(parseISO(time), "HH:mm");
const full = (time: string): string => `${january1st(time)} at ${t12pm(time)}`;
const fullWithYear = (time: string): string => `${january1st2018(time)} at ${t12pm(time)}`;
const shortenedWithTime = (time: string): string => format(parseISO(time), `MM/dd/yy p`);

const fullTimestamp = (time: string): string =>
  format(parseISO(time), "EEE, MMM d, yyyy, h:mm aa").replace(":00 ", " ");
const shortWithSlashes = (time: string): string => format(parseISO(time), "MM/dd/yyyy");
const shortDateFull = (time: string): string => `${shortWithSlashes(time)} ${t12pm(time)}`;

const january1MaybeYear = (time: string): string =>
  format(parseISO(time), `MMMM d${isSameYear(parseISO(time), new Date()) ? "" : " yyyy"}`);

const fromNow = (time: string, addSuffix = true): string =>
  formatDistance(parseISO(time), new Date(), { addSuffix });

// Follows formatting of moment-shortformat
function fromNowShort(time: string): string {
  const baseDate = new Date();
  const diff = differenceInCalendarDays(parseISO(time), baseDate);
  if (Number.isNaN(diff)) throw Error("Invalid Date");
  if (diff >= 7) return january1MaybeYear(time);
  const date = formatDistanceStrict(parseISO(time), baseDate, {
    addSuffix: true,
  }).replace(/ (seconds?|minutes?|hours?|days?|months?|years?)/g, (m, unit) =>
    /months?/.test(unit) ? "mo" : unit[0],
  );
  if (date.endsWith("s ago")) return "moments ago";
  return date;
}

function quanticRelative(time: string, ignoreTime = false): string {
  const now = new Date();
  const parsed = parseISO(time);
  // the following formats apply equally regardless of if the timestamp is in the future or in the past
  // if date is within today then return the timestamp, ex. 12:34 PM
  if (isToday(parsed)) return ignoreTime ? "Today" : format(parsed, "h:mm aa");
  if (isYesterday(parsed))
    return ignoreTime ? "Yesterday" : `Yesterday, ${format(parsed, "MMM dd")}`;
  if (isTomorrow(parsed)) return ignoreTime ? "Tomorrow" : `Tomorrow, ${format(parsed, "MMM dd")}`;
  if (isSameYear(parsed, now)) return format(parsed, "EEE, MMM dd");
  // if date in some future or past year then display date with year
  return format(parsed, "MMM dd, yyyy");
}

export const dateFormat = {
  january1st,
  january2018,
  jan1st2018,
  january1st2018,
  jan1st,
  monday,
  mon,
  t12pm,
  t2300,
  full,
  fullWithYear,
  fromNow,
  fromNowShort,
  january1MaybeYear,
  fullTimestamp,
  shortWithSlashes,
  shortDateFull,
  shortenedWithTime,
  quanticRelative,
};
