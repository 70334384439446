import { Link } from "@talentpair/routing";
import { Grid } from "@mui/material";
import { CancelButton, SubmitButton, Pane, Item, Title, Actions } from "@talentpair/quantic";
import { londonLink } from "@talentpair/env";
import { Modal, PortalPropsT } from "@talentpair/portal";
import activatedSvg from "../../img/candidate-profile-activated.svg";

type PropsT = PortalPropsT & { candidateId: number };

const ActivateProfileModal = ({ candidateId, onClose, ...props }: PropsT): React.ReactElement => (
  <Modal
    classes={{ paper: "md-col-12" }}
    onClose={onClose}
    data-testid="activateProfileModal"
    responsive
    title="Activate your profile"
    {...props}
  >
    <Pane>
      <Item>
        <img src={activatedSvg} alt="Success" />
      </Item>
      <Item>
        <Title paragraph> Go activate your profile to submit your profile to jobs you like.</Title>
        <Title paragraph>
          Remember to take a minute to review your profile. Confirm employers see the best you.
        </Title>
      </Item>
      <Actions>
        <Grid container justifyContent="space-around" spacing={3} wrap="nowrap">
          <Grid item>
            <CancelButton onClick={onClose} />
          </Grid>
          <Grid item>
            <SubmitButton
              component={Link}
              onClick={onClose}
              to={londonLink.capProfile(candidateId)}
            >
              Go to your profile
            </SubmitButton>
          </Grid>
        </Grid>
      </Actions>
    </Pane>
  </Modal>
);

export default ActivateProfileModal;
