// @flow
import { apiRequest, type ResponseDataT } from "@talentpair/api";
import { portal } from "@talentpair/portal";
import {
  type TapDetailJobT,
  type TapDetailJobAdminT,
  type TapJobReduxT,
} from "@talentpair/types/tap/entities/job";
import { type TapMicroUserT } from "@talentpair/types/tap/entities/user";
import lodash from "lodash";
import { listFieldUrl } from "kyoto/utils/misc";
import type { DispatchT, GetStateT } from "@talentpair/redux";
import track from "../../services/track";
import type { StateT } from "../../store";
import { getJob } from "./jobSelectors";
import UserAction from "./userActions";

export type ListItemT = {
  id: number,
  name?: ?string,
  ...
};
export type MaybeNewListItemT =
  | ListItemT
  | {
      id?: null,
      name: string,
      ...
    };

export const JOB_LOADING_LIST = "JOB_LOADING_LIST";
export type JobLoadingListActionT = {
  type: "JOB_LOADING_LIST",
  orgId: number,
  loading: boolean,
};

export const JOB_RECEIVE_DETAIL = "JOB_RECEIVE_DETAIL";
export type JobReceiveDetailActionT = {
  type: "JOB_RECEIVE_DETAIL",
  job: TapDetailJobT,
  ...
};

export const JOB_RECEIVE_ADMIN_DETAIL = "JOB_RECEIVE_ADMIN_DETAIL";
export type JobReceiveAdminDetailActionT = {
  type: "JOB_RECEIVE_ADMIN_DETAIL",
  job: TapDetailJobAdminT,
  ...
};

export const JOB_CREATE_SUCCESS = "JOB_CREATE_SUCCESS";
export type JobCreateSuccessActionT = {
  type: "JOB_CREATE_SUCCESS",
  orgId: number,
  job: TapDetailJobT,
  ...
};

export const JOB_UPDATE_SUCCESS = "JOB_UPDATE_SUCCESS";
export type JobUpdateSuccessActionT = {
  type: "JOB_UPDATE_SUCCESS",
  job: TapDetailJobT,
  ...
};

export const JOB_LIST_ITEM_UPDATE_SUCCESS = "JOB_LIST_ITEM_UPDATE_SUCCESS";
export type JobListItemUpdateSuccessActionT = {
  type: "JOB_LIST_ITEM_UPDATE_SUCCESS",
  jobId: number,
  field: string,
  item: MaybeNewListItemT | ListItemT,
  ...
};

export const JOB_LIST_ITEM_DELETE_SUCCESS = "JOB_LIST_ITEM_DELETE_SUCCESS";
export type JobDeleteSuccessActionT = {
  type: "JOB_LIST_ITEM_DELETE_SUCCESS",
  jobId: number,
  field: string,
  item: ListItemT,
  ...
};

export const JOB_RECEIVE_TEAM_MEMBER = "JOB_RECEIVE_TEAM_MEMBER";
export type JobReceiveTeamMemberActionT = {
  type: "JOB_RECEIVE_TEAM_MEMBER",
  jobId: number,
  member: TapMicroUserT,
  ...
};

export type JobReceiveActionT =
  | JobReceiveDetailActionT
  | JobReceiveAdminDetailActionT
  | JobCreateSuccessActionT
  | JobUpdateSuccessActionT
  | JobListItemUpdateSuccessActionT
  | JobDeleteSuccessActionT
  | JobReceiveTeamMemberActionT;

const updateSuccess = (job: $Shape<TapJobReduxT> & { id: number }) => ({
  type: JOB_UPDATE_SUCCESS,
  job,
});

const receiveDetail = (job: TapDetailJobT): JobReceiveDetailActionT => ({
  type: JOB_RECEIVE_DETAIL,
  job,
});

const receiveAdminDetail = (job: TapDetailJobAdminT): JobReceiveAdminDetailActionT => ({
  type: JOB_RECEIVE_ADMIN_DETAIL,
  job,
});

const fetchDetail =
  (jobId: number) =>
  (dispatch: DispatchT<JobReceiveDetailActionT>): Promise<void> =>
    apiRequest
      .getMemoized(`jobs-tap/${jobId}/`)
      .then(({ data }: ResponseDataT<TapDetailJobT>) => dispatch(receiveDetail(data)));

const fetchAdminDetail =
  (jobId: number) =>
  (dispatch: DispatchT<>, getState: () => StateT): Promise<?TapDetailJobAdminT> => {
    const state = getState();
    // If job is not in Redux yet, we must fetch it before fetching admin data. Otherwise, we'd end up
    // with a job in the Redux store that only has the admin data, thus breaking our components that
    // expect all jobs to be TapJobReduxT
    const job = getJob(state, jobId);
    const detailRequest =
      job && "classifications" in job
        ? Promise.resolve({})
        : apiRequest
            .getMemoized(`jobs-tap/${jobId}/`)
            .then(({ data }: ResponseDataT<TapDetailJobT>) => data);

    return Promise.all([
      detailRequest,
      apiRequest
        .getMemoized(`jobs-tap/${jobId}/admin/`)
        .then(({ data }: ResponseDataT<TapDetailJobAdminT>) => data),
    ]).then(([jobData, adminData]) => dispatch(receiveDetail({ ...jobData, ...adminData })));
  };

const fetchJobList =
  (orgId: number, userIdOrUrl: number | string) =>
  (dispatch: DispatchT<JobReceiveDetailActionT>): Promise<TapDetailJobT[]> => {
    dispatch({ type: JOB_LOADING_LIST, orgId, loading: true });
    return apiRequest
      .get(
        typeof userIdOrUrl === "number"
          ? `mydesk-tap/hc/${userIdOrUrl}/jobs/pipeline/`
          : userIdOrUrl,
      )
      .then((res) => {
        const { results, next } = res.data;
        for (const job of results) {
          dispatch(receiveDetail({ ...job, organization: { id: orgId } }));
        }
        if (next) return dispatch(fetchJobList(orgId, next));
        dispatch({ type: JOB_LOADING_LIST, orgId, loading: false });
        return Promise.resolve();
      });
  };

const createJob =
  (orgId: number, jobData: { ... }) =>
  (dispatch: DispatchT<JobCreateSuccessActionT>): Promise<TapDetailJobT> =>
    apiRequest.post("jobs-tap/", jobData).then(({ data: job }: ResponseDataT<TapDetailJobT>) => {
      dispatch({ type: JOB_CREATE_SUCCESS, orgId, job });
      return job;
    });

const updateRequest = (jobId: number, jobData: { ... }) =>
  apiRequest.patch(`jobs-tap/${jobId}/`, jobData);

const updateFields =
  (jobId: number, jobData: { salary_min?: number, salary_max?: number, ... }) =>
  (dispatch: DispatchT<JobUpdateSuccessActionT>): Promise<void> =>
    updateRequest(jobId, jobData).then(({ data }: ResponseDataT<TapDetailJobT>) => {
      // Salary fields are special. They're not included in the TapDetailJobT because only admins can see it.
      // But they're still patched the same as any property, so we we need to manually include them in our redux update.
      if (jobData.salary_min) data.salary_min = jobData.salary_min;
      if (jobData.salary_max) data.salary_max = jobData.salary_max;
      // prevent omnis from overwriting list in redux--as this will wipe out any platforms and features in the list too
      dispatch({ type: JOB_UPDATE_SUCCESS, job: lodash.omit(data, "omnis") });
    });

type NameT = {| name: ?(number | string) |};
type PkT = {| pk: ?(number | string) |};
type TransformT = (MaybeNewListItemT) => MaybeNewListItemT | NameT | PkT;

const listFieldConfig: {
  [string]: {
    post: TransformT,
    patch?: TransformT,
    ...
  },
  ...
} = {
  omnis: {
    patch: lodash.identity,
    post: ({ id, name, ...other }): NameT =>
      // $FlowFixMe
      typeof id === "number" && name ? { id, name, ...other } : { name: id || name, ...other },
  },
  platforms: {
    patch: lodash.identity,
    post: ({ id, ...other }) => ({ pk: id, ...other }),
  },
  features: {
    patch: lodash.identity,
    post: ({ id, ...other }) => ({ pk: id, ...other }),
  },
  verticals: {
    patch: lodash.identity,
    post: ({ id, ...other }) => ({ pk: id, ...other }),
  },
  classifications: {
    patch: lodash.identity,
    // $FlowFixMe
    post: ({ id, ...other }) => ({ pk: id, ...other }),
  },
  addresses: {
    // $FlowFixMe
    patch: ({ location, ...other }) => ({ ...other, location: location?.id }),
    // $FlowFixMe
    post: ({ location, ...other }) => ({ ...other, location: location?.id }),
  },
};

const updateListItemRequest = (
  jobId: number,
  field: string,
  item: MaybeNewListItemT,
  prevItem?: ?ListItemT,
): Promise<*> => {
  const transform = listFieldConfig[field];
  const endpoint = `jobs-tap/${jobId}/${listFieldUrl(field)}/`;
  // Need to handle delete on your own if you can't patch in certain cases (such as omnis)
  return prevItem && transform.patch
    ? apiRequest.patch(`${endpoint}${item.id || ""}/`, transform.patch(item))
    : apiRequest.post(endpoint, transform.post(item));
};

const updateListItemSuccess = (jobId: number, field: string, item: ListItemT) => ({
  type: JOB_LIST_ITEM_UPDATE_SUCCESS,
  jobId,
  field,
  item,
});

const updateListItem =
  (jobId: number, field: string, item: MaybeNewListItemT, prevItem?: ?ListItemT) =>
  (dispatch: DispatchT<JobListItemUpdateSuccessActionT>): Promise<*> =>
    updateListItemRequest(jobId, field, item, prevItem).then(({ data }: ResponseDataT<ListItemT>) =>
      dispatch(updateListItemSuccess(jobId, field, data)),
    );

const optimisticUpdateListItem =
  (jobId: number, field: string, item: ListItemT, prevItem?: ?ListItemT) =>
  (
    dispatch: DispatchT<JobListItemUpdateSuccessActionT>,
    getState: GetStateT<StateT>,
  ): Promise<*> => {
    const job = getJob(getState(), jobId);
    if (!job) return Promise.reject();

    dispatch(updateListItemSuccess(jobId, field, item));

    return updateListItemRequest(jobId, field, item, prevItem).catch((e) => {
      const oldItem = job[field].find((i) => i.id === item.id);
      if (oldItem) dispatch(updateListItemSuccess(jobId, field, oldItem));
      portal.snackbar({
        message: "Something went wrong. Unable to save new order.",
        type: "error",
      });
      return Promise.reject(e);
    });
  };

const deleteListItemRequest = (jobId: number, field: string, item: ListItemT) =>
  apiRequest.delete(`jobs-tap/${jobId}/${listFieldUrl(field)}/${item.id}/`);

const deleteListItem =
  (jobId: number, field: string, item: ListItemT) =>
  (dispatch: DispatchT<JobDeleteSuccessActionT>): Promise<*> =>
    deleteListItemRequest(jobId, field, item).then(() =>
      dispatch({ type: JOB_LIST_ITEM_DELETE_SUCCESS, jobId, field, item }),
    );

const optimisticDeleteListItem =
  (jobId: number, field: string, item: ListItemT) =>
  (dispatch: DispatchT<JobListItemUpdateSuccessActionT | JobDeleteSuccessActionT>): Promise<*> => {
    dispatch({ type: JOB_LIST_ITEM_DELETE_SUCCESS, jobId, field, item });
    return deleteListItemRequest(jobId, field, item).catch((e) => {
      dispatch(updateListItemSuccess(jobId, field, item));
      return Promise.reject(e);
    });
  };

const receiveTeamMember = (jobId: number, member: TapMicroUserT): JobReceiveTeamMemberActionT => ({
  type: JOB_RECEIVE_TEAM_MEMBER,
  jobId,
  member,
});

const setAdmin =
  (jobId: number, memberId: number, is_admin: boolean) =>
  (dispatch: DispatchT<JobReceiveTeamMemberActionT>) =>
    apiRequest
      .patch(`jobs-tap/${jobId}/hiring/${memberId}/`, { is_admin })
      .then(({ data }: ResponseDataT<TapMicroUserT>) => dispatch(receiveTeamMember(jobId, data)))
      .then(() => track.jobAdmin({ action: "Toggle Job Admin", value: is_admin }));

const addUserToJob = (orgId: number, jobId: number, userId: number) => (dispatch: DispatchT<>) =>
  apiRequest
    .post(`jobs-tap/${jobId}/hiring/`, { user: userId })
    .then(({ data }: ResponseDataT<TapMicroUserT>) =>
      Promise.all([
        dispatch(UserAction.fetchUserDetail(orgId, userId)),
        dispatch(receiveTeamMember(jobId, data)),
      ]),
    );

const removeUserFromJob =
  (orgId: number, jobId: number, userId: number, currentUser?: boolean = false) =>
  (dispatch: DispatchT<>) =>
    apiRequest
      .delete(`jobs-tap/${jobId}/hiring/${userId}/`)
      .then(() =>
        Promise.all([
          dispatch(UserAction.fetchUserDetail(orgId, userId)),
          !currentUser && dispatch(fetchDetail(jobId)),
        ]),
      );

const publishJob =
  (jobId: number, published: boolean) => (dispatch: DispatchT<>, getState: GetStateT<StateT>) => {
    const job = getJob(getState(), jobId);
    if (!job) return Promise.reject();
    return (
      published
        ? apiRequest.post(`jobs-tap/${jobId}/publish-to-job-board/`)
        : apiRequest.delete(`jobs-tap/${jobId}/publish-to-job-board/`)
    ).then(() => dispatch(updateSuccess({ ...job, org_board_published: published })));
  };

export default {
  fetchJobList,
  createJob,
  fetchAdminDetail,
  fetchDetail,
  receiveAdminDetail,
  receiveDetail,
  receiveTeamMember,
  removeUserFromJob,
  setAdmin,
  updateFields,
  updateListItem,
  optimisticUpdateListItem,
  deleteListItem,
  optimisticDeleteListItem,
  addUserToJob,
  updateSuccess,
  updateListItemSuccess,
  publishJob,
};

export const _test = {
  updateRequest,
  updateListItemRequest,
  listFieldConfig,
  deleteListItemRequest,
};
