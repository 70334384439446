type ParamT = number | string;

const jobBoard = (): string => "/";
const orgDetail = (org: ParamT): string => `/company/${org}`;
const orgAboutUs = (): string => `/about-us`;
const jobDetail = (job_id: ParamT, job_slug?: ParamT): string =>
  `/${job_id}${job_slug ? `/${job_slug}` : ""}`;

export const queenstownLink = {
  jobBoard,
  jobDetail,
  orgDetail,
  orgAboutUs,
};
