import * as React from "react";
import clsx from "clsx";
import lodash from "lodash";
import { grey } from "@mui/material/colors";
import { withStyles } from "@mui/styles";
import { Avatar, Chip, Grid, GridProps, SvgIcon } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { EducationT, JobHistoryT } from "@talentpair/types/entities/candidate";
import { LinkT, OmniT } from "@talentpair/types/entities/misc";
import { newTabLinkProps, Typography, TypographyProps, GenericElement } from "@talentpair/quantic";
import { dateFormat } from "@talentpair/datetime";
import { displayDegree, displayJobHistoryDateRange } from "../../utils/entities/candidate";
import { displayLocation } from "../../utils/entities/location";
import { externalLink } from "../../utils/string";
import RenderText from "../RenderText";
import { getUrlInfo } from "./Icons";

export const Wrapper = withStyles({
  root: { counterReset: "section" },
  // @ts-expect-error - eslint upgrade
})(({ classes, ...props }) => <div className={classes.root} {...props} />) as React.ComponentType<
  React.HTMLAttributes<HTMLDivElement>
>;

export const Header = ({
  children = null,
  title,
  ...props
}: {
  children?: React.ReactNode;
  title: React.ReactNode;
}): React.ReactElement => (
  <Grid
    classes={{ container: "border-bottom border-grey-300 py3" }}
    container
    justifyContent="space-between"
    wrap="nowrap"
    {...props}
  >
    <Grid classes={{ item: "flex-auto" }} item>
      <Typography noWrap variant="h2">
        {title}
      </Typography>
    </Grid>
    {children && <Grid item>{children}</Grid>}
  </Grid>
);

const Cell = withStyles({
  item: {
    fontSize: "0.875rem",
    padding: "12px 4px",
    fontWeight: 700,
    "&:first-of-type": { paddingLeft: 0 },
    "&:last-of-type": { paddingRight: 0 },
  },
})(Grid);
const TitleCell = withStyles({
  item: {
    color: grey[500],
    fontSize: "0.8125rem",
    textTransform: "uppercase",
  },
})(Cell);

const GridRow = withStyles({ container: { borderBottom: `1px solid ${grey[300]}` } })(Grid);

export interface RowPropsT extends Omit<GridProps, "title"> {
  title: React.ReactNode;
  data: React.ReactNode;
  cellProps?: unknown;
  fullWidth?: boolean;
}
export const Row = ({
  title,
  data,
  cellProps,
  className,
  fullWidth = false,
  ...props
}: RowPropsT): React.ReactElement => (
  <GridRow container wrap="nowrap" className={clsx(className, !fullWidth ? "p0" : "")} {...props}>
    {title ? (
      <TitleCell item xs={4} sm={3}>
        {title}
      </TitleCell>
    ) : null}
    <Cell item xs={8} sm={9} {...cellProps}>
      {data}
    </Cell>
  </GridRow>
);
const Body2 = <D extends GenericElement = "span">(
  props: Omit<TypographyProps<D>, "variant">,
): React.ReactElement => <Typography variant="body2" {...props} />;

export const SectionTitleNoNumber = withStyles({
  root: { textTransform: "uppercase" },
})((props: TypographyProps) => (
  <Typography variant="body2" bold paragraph gutterBottom {...props} />
));

export const SectionTitle = withStyles({
  root: {
    "&:before": {
      color: grey[500],
      content: "counter(section, decimal-leading-zero)",
      counterIncrement: "section",
      marginRight: "0.33rem",
    },
  },
})(SectionTitleNoNumber);

interface SectionContainerPropsT extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  isLast?: boolean;
}
export const SectionContainer = ({
  className = "",
  isLast = false,
  ...props
}: SectionContainerPropsT): React.ReactElement => (
  <div
    className={clsx("pt3", className, { "border-bottom border-grey-300 pb3": !isLast })}
    {...props}
  />
);

interface SectionPropsT extends Omit<SectionContainerPropsT, "title"> {
  children: React.ReactNode;
  title: React.ReactNode;
}
export const Section = ({ children, title, ...props }: SectionPropsT): React.ReactElement => (
  <SectionContainer {...props}>
    <SectionTitle>{title}</SectionTitle>
    {children}
  </SectionContainer>
);

export const SectionHeader = withStyles({ root: { fontWeight: 700, display: "block" } })(Body2);
export const SectionText = withStyles({ root: { color: grey[800], display: "block" } })(Body2);
export const SectionCopy = ({ text }: { text: string }): React.ReactElement => (
  <SectionText>
    <RenderText text={text} />
  </SectionText>
);

export const PrivateLabel = ({
  viewableBy = "you",
}: {
  viewableBy?: string;
}): React.ReactElement => (
  <span className="grey-600 h6 regular">
    <VisibilityOffIcon classes={{ root: "h4" }} style={{ verticalAlign: "text-bottom" }} /> Only
    viewable by {viewableBy}.
  </span>
);

export const BulletedText = ({ text }: { text: string }): React.ReactElement | null => {
  if (!text) return null;
  const parts: React.ReactNode[] = [];

  // Splitting on "* ", "- ", and "•". See test file for more robust examples.
  text.split(/((?:^[ \t]*(?:\* |- |•).*\n?)+)/m).forEach((match, i) => {
    const trimmed = match.trim();
    if (!trimmed) return;

    const isList = trimmed.startsWith("•") || trimmed.startsWith("- ") || trimmed.startsWith("* ");
    if (!isList) {
      // lodash.trimEnd() to remove ending \n since the <ul> will provide the needed margin
      parts.push(<SectionCopy key={i} text={trimmed} />); // eslint-disable-line react/no-array-index-key
      return;
    }

    parts.push(
      // eslint-disable-next-line react/no-array-index-key
      <ul key={i}>
        {trimmed.split("\n").map((line, n) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={n}>
            <SectionCopy text={line.trim().slice(1).trim()} />
          </li>
        ))}
      </ul>,
    );
  });

  // Return a fragment-wrapped array so that there is no risk of key collision
  return <>{parts}</>;
};

interface WorkHistoryPropsT extends React.HTMLAttributes<HTMLDivElement> {
  job: JobHistoryT;
}
export const WorkHistory = ({ job, ...props }: WorkHistoryPropsT): React.ReactElement => {
  const { company, description, location, role } = job;

  return (
    <div {...props}>
      <div className="mb2">
        <SectionHeader>{role ? role.name : "Untitled Job"}</SectionHeader>
        {(company || location) && (
          <SectionText>
            {company && company.name}
            {company && location && " - "}
            {location && displayLocation(location)}
          </SectionText>
        )}
        <SectionText>{displayJobHistoryDateRange(job)}</SectionText>
      </div>
      <BulletedText text={description} />
    </div>
  );
};

interface EducationPropsT extends React.HTMLAttributes<HTMLDivElement> {
  education: EducationT;
}
export const Education = ({ education, ...props }: EducationPropsT): React.ReactElement => {
  const { graduated, school } = education;
  return (
    <div {...props}>
      {school && <SectionHeader>{school.name}</SectionHeader>}
      <SectionText>{displayDegree(education)}</SectionText>
      {graduated && <SectionText>{dateFormat.january2018(graduated)}</SectionText>}
    </div>
  );
};

export const Url = withStyles({
  root: {
    backgroundColor: grey[200],
    margin: 4,
    textDecoration: "none",
    borderRadius: 16,
  },
  label: { fontWeight: 600 },
})(Chip);
export const UrlChips = ({
  links,
  track = lodash.noop,
}: {
  links: LinkT[];
  track?: (url: string) => unknown;
}): React.ReactElement => (
  <Grid container>
    {links.map(({ id, url }) => {
      const { color, icon, label } = getUrlInfo(url);
      const avatar = (
        <Avatar style={{ backgroundColor: color, margin: 0, width: 30, height: 30 }}>
          {icon ? (
            <SvgIcon htmlColor="white" style={{ width: 19, height: 19 }}>
              {icon}
            </SvgIcon>
          ) : (
            <LanguageIcon classes={{ root: "white" }} style={{ width: 24, height: 24 }} />
          )}
        </Avatar>
      );
      return (
        <Url
          avatar={avatar}
          component="a"
          {...newTabLinkProps}
          // @ts-expect-error 2769 - something weird with props here
          href={externalLink(url)}
          key={id}
          label={label}
          onClick={() => track(url)}
        />
      );
    })}
  </Grid>
);

export const ProfileChip = withStyles({
  root: {
    backgroundColor: grey[200],
    borderRadius: 0,
    fontSize: "0.75rem",
    height: 24,
    margin: 4,
  },
  label: {
    fontWeight: 600,
    paddingLeft: 9,
    paddingRight: 9,
    textTransform: "uppercase",
  },
})(Chip);

const MatchChip = withStyles({ root: { height: 22, margin: 1 } })(ProfileChip);

const MatchedSkillChip = ({ id, name }: { id: number; name: string }): React.ReactElement => (
  <div className="flex items-center bg-green-500 m1" style={{ height: 24 }} key={id}>
    <span className="white px2 bold" style={{ fontSize: 12 }}>
      Match
    </span>{" "}
    <MatchChip label={name} />
  </div>
);

export const MatchedSkillChips = ({ skills }: { skills: OmniT[] }): React.ReactElement => (
  <>
    {skills.map(({ id, name }) => (
      <MatchedSkillChip id={id} name={name} />
    ))}
  </>
);
export const UnmatchedSkillChips = ({ skills }: { skills: OmniT[] }): React.ReactElement => (
  <>
    {skills.map(({ id, name }) => (
      <ProfileChip key={id} label={name} />
    ))}
  </>
);

export const SkillChips = ({
  skills,
  matchIds,
  matchesInFront = false,
}: {
  skills: OmniT[];
  matchIds?: undefined | null | number[];
  matchesInFront?: boolean;
}): React.ReactElement => {
  // either render with matches pulled out in front
  if (matchesInFront && matchIds) {
    const [matched, unmatched] = lodash.partition(
      skills,
      (s) => s.canonical_id && matchIds.includes(s.canonical_id),
    );
    return (
      <Grid container>
        {matched.length ? <MatchedSkillChips skills={matched} /> : null}
        <UnmatchedSkillChips skills={unmatched} />
      </Grid>
    );
  }

  // or render with matches intermixed amongst unmatched skills
  return (
    <Grid container>
      {skills.map(({ id, name, canonical_id }) =>
        matchIds && matchIds.find((o) => o === canonical_id) ? (
          <MatchedSkillChip id={id} name={name} />
        ) : (
          <ProfileChip key={id} label={name} />
        ),
      )}
    </Grid>
  );
};
