/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as React from "react";
import { makeStyles } from "@mui/styles";
import { muiThemeVariables } from "@talentpair/quantic";
import { MakeStyleProps } from "@talentpair/types/material-ui";
import { mergeClasses } from "kyoto/utils/misc";
import TextInput, { TextInputProps } from "./TextInput";

export const ROW_HEIGHT = 19;

const useBaseTextareaStyles = makeStyles({
  multiline: {
    // @ts-expect-error - mui type doesn't match how jss handles !important
    padding: [`${muiThemeVariables.textInputPaddingY} 0`, "!important"],
  },
  // Can't put this in MuiTheme because MUI no longer exposes textarea component styles
  shadow: {
    boxSizing: "border-box",
    padding: `0 ${muiThemeVariables.textInputPaddingX}`,
  },
  rows2: { minHeight: ROW_HEIGHT * 2 },
  rows3: { minHeight: ROW_HEIGHT * 3 },
  rows4: { minHeight: ROW_HEIGHT * 4 },
});
export type TextAreaStylesProps = MakeStyleProps<typeof useBaseTextareaStyles>;

const useResizableTextareaStyles = makeStyles({
  multiline: {
    // @ts-expect-error - mui type doesn't match how jss handles !important
    padding: [0, "!important"],
  },
  inputMultiline: {
    resize: "vertical",
    padding: `${muiThemeVariables.textInputPaddingY} ${muiThemeVariables.textInputPaddingX}`,
  },
});
export type ResizableTextAreaStylesProps = MakeStyleProps<typeof useResizableTextareaStyles>;

export interface CustomInputProps
  extends Omit<TextInputProps, "classes" | "minRows" | "maxRows" | "onSubmit"> {
  rows?: number;
  minRows?: 2 | 3 | 4;
  maxRows?: number;
}

export interface TextareaProps extends CustomInputProps, TextAreaStylesProps {
  classes?: TextInputProps["classes"] & { textarea?: string };
}

export function Textarea({ rows, ...props }: TextareaProps): React.ReactElement {
  const { minRows } = props;
  const { shadow, rows2, rows3, rows4, ...classes } = useBaseTextareaStyles(props);
  return (
    <TextInput
      multiline
      {...props}
      minRows={rows || props.minRows}
      classes={classes}
      inputProps={{
        ...props.inputProps,
        ...(!rows
          ? {
              classes: {
                ...props.inputProps?.classes,
                shadow,
                root: minRows === 3 ? rows3 : minRows === 2 ? rows2 : rows4,
              },
              style: minRows
                ? {
                    ...props.inputProps?.style,
                    minHeight: minRows * ROW_HEIGHT,
                    lineHeight: "19px",
                  }
                : props.inputProps?.style,
            }
          : null),
      }}
    />
  );
}

export type ResizeableTextareaProps = TextareaProps & ResizableTextAreaStylesProps;
export function ResizableTextarea({
  rows,
  classes: classesOverride,
  ...props
}: ResizeableTextareaProps): React.ReactElement {
  const classes = useResizableTextareaStyles(props);
  return (
    <TextInput
      {...props}
      multiline
      classes={classesOverride ? mergeClasses(classesOverride, classes) : classes}
      minRows={rows || 4} // rows can never be falsey or this breaks the resizing functionality
    />
  );
}

export function NonResizableTextarea({
  classes = {},
  rows,
  ...props
}: TextareaProps): React.ReactElement {
  const noResizeClasses = { inputMultiline: "no-resize" };
  const resizableClasses = useResizableTextareaStyles(props);
  return (
    <TextInput
      {...props}
      multiline
      classes={mergeClasses(resizableClasses, noResizeClasses, classes || {})}
      minRows={4}
    />
  );
}
