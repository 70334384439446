import { Media } from "@talentpair/quantic";
import { londonLink } from "@talentpair/env";
import { SuspenseWrapper } from "@talentpair/sentry";
import * as React from "react";
import { RouteComponentProps } from "@talentpair/routing";
import { connect } from "@talentpair/redux";
import { CapCandidateDetailReduxT } from "@talentpair/types/cap/entities/candidate";
import { Grid, Tab } from "@mui/material";
import UserListItem from "kyoto/components/ui/UserListItem";
import { CenteredTabs, tabFixedPosition } from "../../components/layout";
import OnboardingCapProfile from "../../components/Onboarding/tours/OnboardingCapProfile";
import type { StateT } from "../../store";
import { getCandidate } from "../store/candidateSelectors";

type ContextRouter = RouteComponentProps<{ candidate: string }>;

type StateToPropsT = { candidate: null | CapCandidateDetailReduxT };
type OwnPropsT = ContextRouter;
type PropsT = StateToPropsT & OwnPropsT;

function BaseProfileTabs({
  location,
  history,
  match: { params },
  candidate,
}: PropsT): React.ReactElement {
  return (
    <Media>
      {(desktop: boolean) => (
        <Grid classes={{ container: tabFixedPosition("fullWidth") }} container direction="column">
          {desktop && (
            <Grid classes={{ item: "col-12 max-width-4 mx-auto" }} item style={{ height: 78 }}>
              {candidate && <UserListItem user={candidate.user} dense />}
            </Grid>
          )}
          {candidate && (
            <Grid item classes={{ item: "col-12" }}>
              <OnboardingCapProfile>
                <CenteredTabs
                  // @ts-expect-error - mismatch in event type, but type doesn't even matter here
                  onChange={(event: React.ChangeEvent<Record<string, unknown>>, value: string) => {
                    if (value !== location.pathname) history.push(value);
                  }}
                  value={location.pathname}
                >
                  <Tab
                    key="Edit"
                    label={desktop ? "Edit your profile" : "Edit"}
                    value={londonLink.capProfile(+params.candidate)}
                  />
                  <Tab
                    key="Preview"
                    label="Preview"
                    value={londonLink.capProfilePreview(+params.candidate)}
                  />
                  <Tab
                    key="Desired"
                    label={desktop ? "Desired next role" : "Desired"}
                    value={londonLink.capDesiredNextRole(+params.candidate)}
                  />
                </CenteredTabs>
              </OnboardingCapProfile>
            </Grid>
          )}
        </Grid>
      )}
    </Media>
  );
}

const mapStateToProps = (state: StateT, { match: { params } }: ContextRouter): StateToPropsT => ({
  candidate: getCandidate(state, +params.candidate),
});

const ProfileTabs = connect(mapStateToProps)(BaseProfileTabs);

export default (Cmp: React.ComponentType<ContextRouter>) =>
  (props: ContextRouter): React.ReactElement =>
    (
      <Media>
        {(desktop: boolean) => (
          <div style={{ paddingTop: desktop ? 127 : 48 }}>
            <ProfileTabs {...props} />
            <SuspenseWrapper>
              <Cmp {...props} />
            </SuspenseWrapper>
          </div>
        )}
      </Media>
    );

export const _test = { BaseProfileTabs };
