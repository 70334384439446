import * as React from "react";
import clsx from "clsx";
import { Badge } from "./Badge";

export interface OutOfBusinessBadgePropsT {
  className: string | null;
}

export const OutOfBusinessBadge = ({ className }: OutOfBusinessBadgePropsT): React.ReactElement => (
  <Badge
    text="OUT OF BUSINESS"
    bgColor="bg-amber-200"
    fgColor="black"
    className={clsx("bold", className)}
  />
);
