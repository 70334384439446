// @flow
import { displayFullName } from "@talentpair/entities/user";
import type { ContextRouter } from "@talentpair/routing";
import { createMeta } from "../../../components/TapHeader";
import type { StateT } from "../../../store";
import { getOrg } from "../../store/orgSelectors";

const titleFn = (state: StateT, { match: { params } }: ContextRouter) => {
  const org = getOrg(state, +params.org);
  return { title: org && org.primary_owner ? displayFullName(org.primary_owner) : "" };
};

export const UserActivityMeta = createMeta(titleFn);

export const _test = { titleFn };
