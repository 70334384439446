import { userService } from "@talentpair/api";
import { sharedConfig, RouteConfigT } from "@talentpair/env";
import { determineRoute, RoutesT } from "@talentpair/routing";

const config: RouteConfigT = [
  ...sharedConfig,

  // Referrals for candidates and companies
  [
    "REFERRAL/CandidateReferral",
    { path: "/referral/:referral_code/get-hired-using-talentpair", exact: true },
  ],
  [
    "REFERRAL/CompanyReferral",
    { path: "/referral/:referral_code/hire-with-talentpair", exact: true },
  ],
  [
    "REFERRAL/CompanySourcingReferral",
    { path: "/referral/:referral_code/hire-with-talentpair-sourcing", exact: true },
  ],
  [
    "REFERRAL/CompanyAccessReferral",
    { path: "/referral/:referral_code/hire-with-talentpair-access", exact: true },
  ],
  [
    "REFERRAL/CompanyPlatformReferral",
    { path: "/referral/:referral_code/hire-with-talentpair-platform", exact: true },
  ],
  // PUBLIC
  ["TP/CompanyLanding", { path: "/hire-smart", exact: true }],
  ["TP/AccountDeleteFeedback", { path: "/account-closed/:hash/", exact: false }],
  // CAP
  ["CAP/CreateProfile", { path: "/create-profile", exact: true }],
  ["CAP/RefreshProfile", { path: "/refresh-profile", exact: true }],
  ["CAP/NextSteps", { path: "/next-steps", exact: true }],
  ["CAP/PairList", { path: "/:candidate(\\d+)/pairs", exact: true }],
  ["CAP/PairDetail", { path: "/:candidate(\\d+)/pairs/:pair(\\d+)", exact: true }],
  [
    "CAP/ApplicationDetail",
    { path: "/:candidate(\\d+)/pairs/application/:pair(\\d+)", exact: true },
  ],
  ["CAP/PairActivity", { path: "/:candidate(\\d+)/pairs/:pair(\\d+)/messages", exact: true }],
  [
    "CAP/SharedPairActivity",
    { path: "/:candidate(\\d+)/pairs/:pair(\\d+)/shared-messages", exact: true },
  ],
  ["CAP/Profile", { path: "/:candidate(\\d+)/profile", exact: true }],
  ["CAP/ProfilePreview", { path: "/:candidate(\\d+)/profile/preview", exact: true }],
  ["CAP/DesiredNextRole", { path: "/:candidate(\\d+)/desired-next-role", exact: true }],
  ["CAP/UserActivity", { path: "/:candidate(\\d+)/messages", exact: true }],
  [
    "CAP/ApplicationSubmitted",
    { path: "/:candidate(\\d+)/applications/:application(\\d+)/", exact: true },
  ],
  ["CAP/ReferFriends", { path: "/refer", exact: true }],
  ["CAP/Settings", { path: "/:candidate(\\d+)/settings", exact: true }],
  ["CAP/Calendar", { path: "/:candidate(\\d+)/calendar", exact: true }],
  ["CAP/Notifications", { path: "/:candidate(\\d+)/notifications", exact: true }],

  // TAP
  ["TAP/CreateProfile", { path: "/employer-create-profile", exact: true }],
  ["TAP/OrgSettings", { path: "/org/:org(\\d+)/admin", exact: true }],
  ["TAP/ManageJobBoard", { path: "/org/:org(\\d+)/manage-job-board", exact: true }],
  ["TAP/OrgUserProfile", { path: "/org/:org(\\d+)/admin/:user(\\d+)", exact: true }],
  ["TAP/OrgProfile", { path: "/org/:org(\\d+)/profile", exact: true }],
  ["TAP/OrgReports", { path: "/org/:org(\\d+)/reports", exact: true }],
  ["TAP/Profile", { path: "/profile", exact: true }],
  ["TAP/UserActivity", { path: "/org/:org(\\d+)/messages", exact: true }],
  ["TAP/Settings", { path: "/org/:org(\\d+)/settings/:user(\\d+)", exact: true }],
  ["TAP/Calendar", { path: "/org/:org(\\d+)/calendar/:user(\\d+)", exact: true }],
  [
    "TAP/JobTeamProfile",
    { path: "/org/:org(\\d+)/jobs/:job(\\d+)/pipeline/manage/team/:member(\\d+)", exact: false },
  ],
  ["TAP/JobPipeline", { path: "/org/:org(\\d+)/jobs/:job(\\d+)/pipeline", exact: false }],
  ["TAP/JobDesk", { path: "/org/:org(\\d+)/jobs", exact: false }],
  ["TAP/CandidateDesk", { path: "/org/:org(\\d+)/candidates", exact: false }],
  ["TAP/Notifications", { path: "/org/:org(\\d+)/notifications", exact: true }],
  ["TAP/OrgBilling", { path: "/org/:org(\\d+)/billing", exact: true }],
  ["TAP/OrgChoosePricing", { path: "/employer-choose-pricing", exact: true }],
  ["TAP/OrgCheckout", { path: "/employer-checkout", exact: false }],

  // QA
  ["QA", { path: "/qa", exact: true }],
  ["Quantic", { path: "/quantic", exact: true }],
];

// A Map created with a 2D array: Array<[Route, MatchInfo]>
// assigning keys to each set of route props so we can use these in arrays of routes (see ./App.jsx for the arrays in question)
export const routes: RoutesT = new Map(config.map(([key, props]) => [key, { key, ...props }]));

export const getRoutePath = (routeStr: string): string => {
  const route = routes.get(routeStr);
  return route ? route.path || "" : "";
};

export const isCap = (): boolean => {
  const { appType } = determineRoute(routes, location.pathname);
  if (appType === "CAP") return true;
  if (appType === "TAP") return false;
  return userService.isCandidate();
};

export const getApp = (): "cap" | "tap" => (isCap() ? "cap" : "tap");

export const isCreateProfilePage = (): boolean => {
  const { appSubtype } = determineRoute(routes, location.pathname);
  return appSubtype === "CreateProfile";
};

export const isPublicJobs = (): boolean => {
  const { appType } = determineRoute(routes, location.pathname);
  return appType === "TP";
};

export const isNextSteps = (): boolean => {
  const { appSubtype } = determineRoute(routes, location.pathname);
  return appSubtype === "NextSteps";
};

export const skipSignupRedirect = (): boolean =>
  isPublicJobs() || isCreateProfilePage() || isNextSteps();
