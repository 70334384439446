import * as React from "react";
import { Link, LinkProps } from "@talentpair/routing";
import { withStyles } from "@mui/styles";
import {
  Grid,
  IconButton,
  IconButtonProps,
  List,
  ListProps,
  ListItem as MuiListItem,
  ListItemProps,
  ListItemText as MuiListItemText,
  ListItemTextProps,
  ListSubheader as MuiListSubheader,
  ListSubheaderProps,
  Theme,
} from "@mui/material";
import Timestamp from "../Timestamp";

/**
 * These are good defaults for most of our use cases (columns with Links, entity list items, etc.)
 * However, since they aren't necessarily great defaults for all use cases of the material-ui List
 * components, (especially menus) these aren't in the muiTheme. Use where needed, but you can default
 * to the base Material-UI components if you're doing something like a custom Menu.
 */
// Replicate `noWrap` prop for inner Typography components, since they don't currently let us pass props in.
const TruncatedListItemText = withStyles({
  root: {},
  primary: {
    fontWeight: 700,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  secondary: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
})(MuiListItemText);

export type ListItemTextPropsT = ListItemTextProps & {
  timestamp?: string | null;
  badge?: React.ReactNode | null;
};
export const ListItemText = ({
  primary,
  timestamp = null,
  badge = null,
  ...props
}: ListItemTextPropsT): React.ReactElement => (
  <TruncatedListItemText
    primary={
      timestamp || badge ? (
        <span className="flex items-center">
          <span className={`bold truncate ${badge ? "" : "flex-auto"}`}>{primary}</span>
          {!!badge && <span className="flex-auto mx2">{badge}</span>}
          {timestamp && <Timestamp date={timestamp} />}
        </span>
      ) : (
        primary
      )
    }
    {...props}
  />
);

const RenderLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <Link ref={ref} {...props} />
));

export type ListItemPropsT<D extends React.ElementType> = ListItemProps<D> & {
  to?: LinkProps["to"];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ListItem = React.forwardRef<any, ListItemPropsT<any>>((props, ref) => {
  if (props.to) {
    // @ts-expect-error - 2769- weird inference on type for button prop
    return <MuiListItem ref={ref} button component={RenderLink} {...props} />;
  }
  return <MuiListItem ref={ref} component="div" {...props} />;
});

export interface ListSubheaderPropsT extends ListSubheaderProps<"div"> {
  action?: React.ReactNode;
  children: React.ReactNode;
}
export const ListSubheader = ({
  action = null,
  children,
  ...props
}: ListSubheaderPropsT): React.ReactElement => (
  <MuiListSubheader component="div" disableSticky {...props}>
    <Grid alignItems="center" container justifyContent="space-between" wrap="nowrap">
      <Grid item>{children}</Grid>
      {action && <Grid item>{action}</Grid>}
    </Grid>
  </MuiListSubheader>
);

export const Subheader = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "0 16px",
    color: theme.palette.common.black,
    backgroundColor: theme.palette.grey[100],
    fontSize: 16,
    height: 44,
    lineHeight: 1,
  },
}))(MuiListSubheader);

export const ListSectionSubheader = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "0 12px",
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[100],
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    height: 32,
    lineHeight: 1,
  },
}))(MuiListSubheader);

// eslint-disable-next-line react/require-default-props
interface HeaderActionPropsT extends IconButtonProps {
  // eslint-disable-next-line react/require-default-props
  to?: LinkProps["to"];
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HeaderAction = React.forwardRef<any, HeaderActionPropsT>(
  ({ to, ...props }: HeaderActionPropsT, ref) => {
    const buttonProps = to ? { component: Link, to } : { component: "button" };
    return (
      <IconButton
        color="primary"
        style={{ marginRight: -12 }}
        {...buttonProps}
        {...props}
        ref={ref}
      />
    );
  },
);

// Because we usually overwrite "li" components for ListItems, remove the "ul" here as well.
export default (props: ListProps<"div">): React.ReactElement => (
  <List component="div" disablePadding {...props} />
);
