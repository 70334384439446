import * as React from "react";
import { AppBar, Toolbar } from "@mui/material";
import logo from "../img/logo-white.png";

export function TpHeader(): React.ReactElement {
  return (
    <AppBar elevation={0}>
      <Toolbar className="relative flex-center">
        <img src={logo} alt="Talentpair" style={{ marginBottom: -5, width: 100 }} />
      </Toolbar>
    </AppBar>
  );
}
