import { userService } from "@talentpair/api";
import { sentry } from "@talentpair/sentry";
import * as React from "react";

export type PropsT<R> = {
  pingIfLoggedOut?: boolean;
  interval?: number;
  request: () => Promise<R>;
};

export default class Ping<R> extends React.Component<PropsT<R>> {
  ping: NodeJS.Timeout | null = null;

  static defaultProps = { interval: 5000, pingIfLoggedOut: false };

  componentDidMount(): void {
    if (!this.ping) {
      this.startPing();
    }
  }

  componentWillUnmount(): void {
    this.cancel();
  }

  pingRequest = (): void => {
    // skip the ping if the window is not visible
    if (
      (userService.loggedin || this.props.pingIfLoggedOut) &&
      window.document.visibilityState === "visible"
    )
      this.props.request().catch(sentry.error);
  };

  cancel = (): void => {
    if (this.ping) {
      clearInterval(this.ping);
      this.ping = null;
    }
  };

  startPing = (): void => {
    this.cancel();
    // ping immediately
    this.pingRequest();
    // ... and schedule pings for every 10s in the future
    this.ping = setInterval(this.pingRequest, this.props.interval);
  };

  render(): null {
    return null;
  }
}
