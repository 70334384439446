// @flow
import { apiRequest, type ResponseDataT, s3 } from "@talentpair/api";
import { type OrgAddressT } from "@talentpair/types/entities/location";
import { type TapOrgT } from "@talentpair/types/tap/entities/org";
import { listFieldUrl } from "kyoto/utils/misc";
import type { DispatchT, GetStateT } from "@talentpair/redux";
import type { ItemT } from "@talentpair/types/misc";
import { getOrg } from "./orgSelectors";

type ObjT = { [string]: *, ... };
type MaybeNewListItemT = { id?: ?number, ... };

export const ORG_RECEIVE_DETAIL = "ORG_RECEIVE_DETAIL";
export type OrgReceiveDetailActionT = {
  type: "ORG_RECEIVE_DETAIL",
  orgId: number,
  org: TapOrgT,
  ...
};

export const ORG_UPDATE_SUCCESS = "ORG_UPDATE_SUCCESS";
export type OrgUpdateSuccessActionT = {
  type: "ORG_UPDATE_SUCCESS",
  orgId: number,
  org: TapOrgT,
  ...
};

export const ORG_LIST_ITEM_UPDATE_SUCCESS = "ORG_LIST_ITEM_UPDATE_SUCCESS";
export type OrgListItemUpdateSuccessActionT = {
  type: "ORG_LIST_ITEM_UPDATE_SUCCESS",
  orgId: number,
  field: string,
  item: ItemT,
  ...
};

export const ORG_ADDRESS_ITEM_UPDATE_SUCCESS = "ORG_ADDRESS_ITEM_UPDATE_SUCCESS";
export type OrgAddressItemUpdateSuccessActionT = {
  type: "ORG_ADDRESS_ITEM_UPDATE_SUCCESS",
  orgId: number,
  address: OrgAddressT,
  ...
};

export const ORG_LIST_ITEM_DELETE_SUCCESS = "ORG_LIST_ITEM_DELETE_SUCCESS";
export type OrgListItemDeleteSuccessActionT = {
  type: "ORG_LIST_ITEM_DELETE_SUCCESS",
  orgId: number,
  field: string,
  item: ItemT,
  ...
};

export type OrgDetailActionT =
  | OrgReceiveDetailActionT
  | OrgUpdateSuccessActionT
  | OrgListItemUpdateSuccessActionT
  | OrgAddressItemUpdateSuccessActionT
  | OrgListItemDeleteSuccessActionT;

const receiveDetail = (orgId: number, org: TapOrgT): OrgReceiveDetailActionT => ({
  type: ORG_RECEIVE_DETAIL,
  orgId,
  org,
});

const fetchDetail =
  (orgId: number) =>
  (dispatch: DispatchT<OrgReceiveDetailActionT>): Promise<TapOrgT> =>
    apiRequest
      .getMemoized(`organizations-tap/${orgId}/`)
      .then(({ data }: ResponseDataT<TapOrgT>) => {
        dispatch(receiveDetail(orgId, data));
        return data;
      });

const updateFields =
  (orgId: number, data: ObjT) =>
  (dispatch: DispatchT<>): Promise<void> =>
    apiRequest
      .patch(`organizations-tap/${orgId}/`, data)
      .then(({ data: org }: ResponseDataT<TapOrgT>) => {
        dispatch({ type: ORG_UPDATE_SUCCESS, orgId, org });
      });

const updateListItem =
  (orgId: number, field: string, item: MaybeNewListItemT) =>
  (dispatch: DispatchT<>): Promise<void> => {
    const request = item.id
      ? apiRequest.patch(
          `organizations-tap/${orgId}/${listFieldUrl(field)}/${item.id || ""}/`,
          item,
        )
      : apiRequest.post(`organizations-tap/${orgId}/${listFieldUrl(field)}/`, item);

    return request.then(({ data }: ResponseDataT<ItemT>) => {
      dispatch({ type: ORG_LIST_ITEM_UPDATE_SUCCESS, orgId, field, item: data });
    });
  };

const updateAddressItem =
  (orgId: number, address: OrgAddressT) =>
  (dispatch: DispatchT<>): Promise<void> => {
    const request = address.address.id
      ? apiRequest.patch(
          `organizations-tap/${orgId}/addresses/${address.address.id || ""}/`,
          address,
        )
      : apiRequest.post(`organizations-tap/${orgId}/addresses/`, address);

    return request.then(({ data }: ResponseDataT<OrgAddressT>) => {
      dispatch({ type: ORG_ADDRESS_ITEM_UPDATE_SUCCESS, orgId, address: data });
    });
  };

const optimisticDeleteListItem =
  (orgId: number, field: string, item: ItemT) =>
  (dispatch: DispatchT<>): Promise<*> => {
    dispatch({ type: ORG_LIST_ITEM_DELETE_SUCCESS, orgId, field, item });
    return apiRequest
      .delete(`organizations-tap/${orgId}/${listFieldUrl(field)}/${item.id}/`)
      .catch((e) => {
        dispatch({ type: ORG_LIST_ITEM_UPDATE_SUCCESS, orgId, field, val: item });
        return Promise.reject(e);
      });
  };

const uploadNewLogo =
  (orgId: number, file: File) =>
  (dispatch: DispatchT, getState: GetStateT): Promise<*> => {
    const org = getOrg(getState(), orgId);
    if (!org) return Promise.reject();
    return s3.uploadLogo(file, orgId).then((logo_url) => {
      dispatch({
        type: ORG_UPDATE_SUCCESS,
        orgId,
        org: {
          ...org,
          logo_url,
        },
      });
    });
  };

export default {
  receiveDetail,
  fetchDetail,
  updateFields,
  updateListItem,
  updateAddressItem,
  optimisticDeleteListItem,
  uploadNewLogo,
};
