import * as React from "react";
import { connect, FormikValues, FormikContextType } from "formik";
import { FormikContextProps } from "@talentpair/types/formik";
import { ButtonProps } from "@mui/material";
import { SubmitButton as BaseSubmitButton } from "@talentpair/quantic";

export function isDisabled<V extends FormikValues>({
  isSubmitting,
  isValidating,
  errors,
  status,
}: FormikContextType<V>): boolean {
  // `!!Object.keys(errors).length` is used for cases where formik's `form.isValid === true` and the `form.dirty === false` even though the form has field level errors (seen on required fields that have been focused and blurred but not changed)
  return (
    isSubmitting ||
    isValidating ||
    // block submit on any field-level errors
    !!Object.values(errors).filter(Boolean).length ||
    // email modals use warnings fetched from the BE and stored in the formik status object
    // block submit on hard warnings
    status?.warning?.some((w: { level?: string }) => w?.level === "hard")
  );
}

interface Props extends ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
}

// You can pass in your own custom disabled check in addition to the base form ones
function SubmitButton<V>({
  disabled = false,
  formik,
  ...props
}: Props & FormikContextProps<V>): React.ReactElement {
  return <BaseSubmitButton disabled={disabled || isDisabled(formik)} {...props} />;
}

export default connect<Props, unknown>(SubmitButton);
