import * as React from "react";
import { NavSearchResultT } from "@talentpair/types/navSearch";
import { TAP_STATUS } from "@talentpair/entities/job";
import { CandidateTpBadge } from "./CandidateTpBadge";
import { ContactTpBadge } from "./ContactTpBadge";
import { OrgBadges } from "./OrgBadges";
import { JobTpBadge } from "./JobTpBadge";
import { BadgePropsT } from "./Badge";

export interface SuggestionTpBadgeT extends Partial<BadgePropsT> {
  suggestion: NavSearchResultT;
}
export const SuggestionTpBadge: React.ComponentType<SuggestionTpBadgeT> = ({
  suggestion,
  ...props
}): React.ReactElement | null => {
  switch (suggestion.type) {
    case "candidate":
      return (
        <CandidateTpBadge
          href=""
          candidate={{
            id: suggestion.id,
            platform_status: suggestion.platform_status,
          }}
          {...props}
        />
      );
    case "contact":
      return (
        <ContactTpBadge
          href=""
          contact={{
            id: suggestion.id,
            login_blocked:
              suggestion.platform_status === "deleted" ||
              suggestion.platform_status === "deactivated",
            last_active: suggestion.platform_status?.startsWith("invited") ? null : "sometime",
          }}
          {...props}
        />
      );
    case "organization": {
      return (
        <OrgBadges
          noLink
          noWrap
          org={{
            ...suggestion,
            is_on_platform: suggestion?.platform_status === "active",
          }}
          {...props}
        />
      );
    }
    case "job": {
      let statusId = TAP_STATUS.ACTIVE;
      if (suggestion.platform_status === "closed") statusId = TAP_STATUS.CLOSED;
      else if (suggestion.platform_status === "paused") statusId = TAP_STATUS.PAUSED;
      return (
        <JobTpBadge
          href=""
          job={{
            id: suggestion.id,
            tap_status: { id: statusId },
          }}
          orgId={suggestion.extra_id || 0}
          {...props}
        />
      );
    }
    default:
      return null;
  }
};
