import { LocationT, OrgAddressT, OrgAddressWithIdT } from "@talentpair/types/entities/location";
import { toTruthy } from "../array";

export function displayLocation(
  { city, state, zip }: LocationT,
  includeZip: boolean | number = true,
): string {
  if (typeof includeZip === "number") includeZip = true;

  state = state ? `, ${state}` : "";
  zip = state && includeZip ? ` ${zip}` : "";
  return city ? `${city}${state}${zip}` : "";
}

export function displayLocations(
  locations: LocationT[],
  includeZip = true,
  separator = ", ",
): string {
  return locations.map((l) => displayLocation(l, includeZip)).join(separator);
}

// All permuations of addresses so far...
type AddressNoIdT =
  | {
      address_line_1: string | null;
      address_line_2: string | null;
      location: LocationT | null;
    }
  | { address_line_1: string; address_line_2: string; location: LocationT | null }
  | { address_line_1?: string; address_line_2?: string; location: LocationT | null };

export function displayAddressLines(address: Partial<AddressNoIdT>): string {
  return [address.address_line_1, address.address_line_2].filter(toTruthy).join(", ");
}

export function displayAddress(address: AddressNoIdT): string {
  return [displayAddressLines(address), address.location && displayLocation(address.location)]
    .filter(toTruthy)
    .join(", ");
}

export function displayMultipleAddresses(addresses: AddressNoIdT[], fallback = ""): string {
  return addresses.length > 1
    ? "Multiple locations"
    : addresses.length === 1 && addresses[0].location
    ? displayLocation(addresses[0].location, false)
    : fallback;
}

export function displayMultipleLocations(locations: LocationT[], fallback = ""): string {
  return locations.length > 1
    ? "Multiple locations"
    : locations.length === 1
    ? displayLocation(locations[0], false)
    : fallback;
}

export const orgAddressMapId = ({ address, is_primary }: OrgAddressT): OrgAddressWithIdT => ({
  address,
  is_primary,
  id: address.id,
});

export const sortOrgAddress = <A extends { is_primary: boolean }>(a: A, b: A): number =>
  a.is_primary ? -1 : b.is_primary ? 1 : 0;
