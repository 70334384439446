import * as React from "react";
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioProps,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps,
} from "@mui/material";
import { mergeClasses } from "../../../../utils/misc";
import Field from "../Field";
import { ChoiceFieldPropsT, ChoiceFieldWrapperPropsT } from "../choiceFieldTypes";
import PillButtonLabel from "./PillButtonLabel";

interface BaseRadioGroupPropsT<V, F> extends ChoiceFieldWrapperPropsT<V, F> {
  FormControlLabelProps?: Partial<FormControlLabelProps>;
  RadioProps?: Partial<RadioProps>;
  RadioGroupProps?: Partial<RadioGroupProps>;
  renderLabel?: (choice: V, checked: boolean) => React.ReactNode;
}

interface PropsT<V, F> extends BaseRadioGroupPropsT<V, F> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormControlLabelComponent?: React.ComponentType<any>;
}

const BaseRadioGroup = <V, F extends string | number | null>({
  choices,
  FormControlLabelComponent = FormControlLabel,
  FormControlLabelProps: formControlLabelProps,
  RadioProps: radioProps,
  RadioGroupProps: radioGroupProps,
  margin = "none",
  // @ts-expect-error - eslint upgrade
  renderLabel = (choice: V): React.ReactNode => choice.name,
  ...props
}: PropsT<V, F>): React.ReactElement => (
  <Field
    group
    margin={margin}
    {...props}
    render={({ field: { name, value, onChange } }: ChoiceFieldPropsT<V, F>) => (
      <MuiRadioGroup
        name={name}
        value={value != null ? String(value) : value}
        // @ts-expect-error - 2322 - different types for event handlers due to our Field wrapper
        onChange={onChange}
        {...radioGroupProps}
      >
        {choices.map((o) => {
          // sometimes the choice id is a number or a string, and sometimes the value is a number or a string
          // eslint-disable-next-line eqeqeq
          const checked = o.id == value;
          return (
            <FormControlLabelComponent
              key={o.id}
              control={<Radio {...radioProps} />}
              // @ts-expect-error - eslint upgrade
              label={renderLabel(o, checked)}
              checked={checked}
              value={o.id != null ? String(o.id) : o.id}
              disabled={o.disabled != null ? o.disabled : props.disabled || false}
              {...formControlLabelProps}
            />
          );
        })}
      </MuiRadioGroup>
    )}
  />
);

export const BorderedRadioGroup = <V, F extends string | number | null>({
  FormControlLabelProps: formControlLabelProps,
  choices,
  ...props
}: BaseRadioGroupPropsT<V, F>): React.ReactElement => {
  const defaultClasses = {
    root: "border border-grey-300 mx0 my2 pr2 py1 rounded bg-white",
    // Add margin to label so that if the label is multiple lines and therefore overtakes
    // the 24px height of the radio button, it still has some margin before the border
    label: "my2",
  };
  return (
    <BaseRadioGroup
      {...props}
      choices={choices}
      FormControlLabelProps={{
        ...formControlLabelProps,
        classes:
          formControlLabelProps && formControlLabelProps.classes
            ? mergeClasses(defaultClasses, formControlLabelProps.classes)
            : defaultClasses,
      }}
    />
  );
};

export interface RadioGroupPropsT<V, F> extends BaseRadioGroupPropsT<V, F> {
  pill?: boolean;
  style?: React.CSSProperties;
  renderLabel?: (choice: V, checked: boolean) => React.ReactNode;
}

const RadioGroup = <V, F extends string | number | null>({
  pill = false,
  choices,
  ...props
}: RadioGroupPropsT<V, F>): React.ReactElement =>
  pill ? (
    <BaseRadioGroup
      choices={choices}
      FormControlLabelComponent={PillButtonLabel}
      RadioProps={{ classes: { root: "hide" } }}
      RadioGroupProps={{ row: true }}
      {...props}
    />
  ) : (
    <BaseRadioGroup choices={choices} {...props} />
  );

export default RadioGroup;

export const _test = { BaseRadioGroup };
