/* eslint-disable quote-props */
import {
  createTheme,
  ThemeOptions,
  SimplePaletteColorOptions,
  Theme,
  lighten,
  darken,
  alpha,
  CSSInterpolation,
} from "@mui/material";
import { common, grey, red } from "@mui/material/colors";
import lodash from "lodash";
import { muiThemeVariables } from "./muiThemeVariables";
import { colors, TalentpairColorT } from "./colors";
import { typographyThemeOverride } from "../atoms/Typography";
import { spacingThemeOverride } from "./spacing";
import { TalentpairTheme } from "./types";
import { getContrastingTextColor } from "./getContrastingTextColor";

export type OuterThemeT = { palette: SimplePaletteColorOptions };

// pulls heavily from the augmentColor function in @mui/styles/createPalette.js
export function augmentColor(color: Partial<TalentpairColorT>): TalentpairColorT {
  if (!color[500] && !color.main)
    throw new Error("Cannot create a palette color without a main color or a '500' level color!");
  const main = (color.main || color[500]) as string; // we know from the check above that one of these must exist
  const light = color.light || color[300] || lighten(main, 0.2);
  const dark = color.dark || color[700] || darken(main, 0.2);
  return {
    ...color,
    main,
    light,
    dark,
    contrastText: color.contrastText || getContrastingTextColor(main) || common.white,
  } as TalentpairColorT;
}

declare module "@mui/material/styles" {
  interface Palette {
    triadic: Palette["primary"];
    red: Palette["primary"];
    amber: Palette["primary"];
    blue: Palette["primary"];
    misc: { activeListItem: string };
  }
  interface PaletteOptions {
    triadic: PaletteOptions["primary"];
    red: PaletteOptions["primary"];
    amber: PaletteOptions["primary"];
    blue: PaletteOptions["primary"];
    misc: { activeListItem: string };
  }
}

export const generateTheme = (
  base: Partial<TalentpairColorT>,
  overrides: Record<string, unknown> = {},
): TalentpairTheme => {
  const primary = augmentColor(base);
  const selectedOpacity = primary.contrastText === "#000" ? 1 : 0.125;
  return createTheme(
    lodash.merge(
      {} as ThemeOptions,
      {
        palette: {
          primary,
          secondary: colors.pink,
          triadic: colors.purple,
          misc: {
            activeListItem: alpha(primary.main, selectedOpacity),
          },
          common,
          red: colors.red,
          amber: colors.amber,
          blue: colors.blue,
          background: {
            default: common.white,
            paper: common.white,
          },
          info: {
            main: "#3e77ef",
            light: "#f0f5ff",
            dark: "#192f60",
            contrastText: common.white,
          },
          warning: {
            main: "#eb641e",
            light: "#fff4ec",
            dark: "#5e280c",
            contrastText: common.white,
          },
          error: {
            main: "#d9152c",
            light: "#fff0f4",
            dark: "#560813",
            contrastText: common.white,
          },
          success: {
            main: "#0b9d4b",
            light: "#edf9f0",
            dark: "#043f1d",
            contrastText: common.white,
          },
          action: {
            selectedOpacity,
          },
          getContrastText: getContrastingTextColor,
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1350,
            xl: 2025,
          },
        },
        spacing: spacingThemeOverride,
        typography: typographyThemeOverride,
        components: {
          MuiInputLabel: {
            styleOverrides: {
              root: {
                fontSize: "0.75rem",
                fontWeight: 700,
                marginBottom: 6.5,
                marginTop: 1.5,
                textTransform: "uppercase",
              },
              formControl: {
                position: "relative",
                transform: "none",
              },
              sizeSmall: {
                transform: "none",
              },
              shrink: {
                transform: "none",
              },
            },
            defaultProps: {
              required: false,
              shrink: true,
            },
          },
          MuiTabs: {
            defaultProps: {
              indicatorColor: "primary",
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: "primary",
                color: primary.contrastText,
              },
              colorDefault: {
                backgroundColor: common.white,
              },
            },
          },
          MuiAvatar: {
            // TODO: Remove these style overrides once all uses of Avatars go through the quantic Avatar(s)
            styleOverrides: {
              img: {
                objectFit: "contain",
                backgroundColor: "rgba(0,0,0,0)",
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                // Icon font size
                "& svg": {
                  fontSize: 20,
                },
              },
              contained: {
                "&:not(.MuiButton-containedPrimary):not(.Mui-disabled)": {
                  backgroundColor: common.white,
                  color: common.black,
                },
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                padding: "12px",
              },
            },
          },
          MuiCardContent: {
            styleOverrides: {
              root: {
                "@media (min-width:600px)": {
                  paddingLeft: 16,
                  paddingRight: 16,
                },
              },
            },
          },
          MuiCardHeader: {
            styleOverrides: {
              root: {
                "@media (min-width:600px)": {
                  paddingLeft: 16,
                  paddingRight: 16,
                },
              },
            },
          },
          MuiChip: {
            styleOverrides: {
              root: {
                fontSize: 14,
                // Pushes label & delete button to the sides of the chip when the chip width is not
                // dynamic. Otherwise they get centered in the middle of the chip.
                justifyContent: "space-between",
              },
              avatar: {
                color: common.white,
                marginLeft: 0,
              },
              deletable: {
                root: ({ theme }: { theme: Theme }) => {
                  const conditionalStyles: CSSInterpolation = {};
                  conditionalStyles["&:.MuiInput-focused"] = {
                    backgroundColor: grey[200],
                    "& .MuiChip-deleteIcon": {
                      color: primary.dark,
                      transition: theme.transitions.create(["color"]),
                    },
                    "& .MuiChip-deleteIcon:hover": {
                      color: darken(primary.dark, 0.2),
                      transition: theme.transitions.create(["color"]),
                    },
                  };
                  return { ...conditionalStyles };
                },
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                background: primary.main,
                color: common.white,
                padding: "12px 24px",
              },
            },
          },
          MuiDialogContent: {
            styleOverrides: {
              root: {
                padding: "18px 24px !important",
              },
              dividers: {
                borderTop: 0,
              },
            },
          },
          MuiDialogActions: {
            styleOverrides: {
              root: {
                padding: "12px",
              },
            },
          },
          MuiFormControl: {
            styleOverrides: {
              root: {
                // zIndex: "auto" overrides changes made in this MUI PR: https://github.com/mui-org/material-ui/pull/19547 which fixed a bug we didn't have
                zIndex: "auto",
                "&:first-of-type": {
                  marginTop: 0,
                },
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                fontSize: "0.75rem",
                fontWeight: 700,
                marginBottom: 6.5,
                marginTop: 1.5,
                lineHeight: "1",
                textTransform: "uppercase",
                "&.MuiInput-focused": {
                  color: primary.main,
                },
              },
            },
          },
          MuiInput: {
            styleOverrides: {
              formControl: {
                "label + &": {
                  marginTop: 0,
                },
              },
              underline: {
                "&:after": {
                  borderBottomColor: primary.main,
                },
                "&:before": {
                  borderBottomColor: grey[300],
                  bottom: -1,
                },
                "&.Mui-error:hover:not(.Mui-disabled):before": {
                  borderBottomColor: red[500],
                },
                "&:hover:not(.Mui-disabled):not(.Mui-error):before": {
                  borderBottomColor: primary.main,
                },
                "&.Mui-disabled:before": {
                  borderBottomColor: grey[300],
                  borderBottomWidth: 2,
                },
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                background: common.white,
                border: `1px solid ${grey[300]}`,
              },
              input: {
                paddingLeft: muiThemeVariables.textInputPaddingX,
                paddingRight: muiThemeVariables.textInputPaddingX,
                "&:not(.MuiInputBase-inputMultiline)": {
                  lineHeight: "19px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  height: "1.5rem",
                },
                "&.MuiInputBase-inputAdornedStart, &.MuiInputBase-inputAdornedEnd": {
                  flexGrow: 10,
                  width: "auto",
                },
              },
              inputSizeSmall: {
                paddingTop: 8,
              },
              inputMultiline: {
                boxSizing: "border-box",
                padding: `0 ${muiThemeVariables.textInputPaddingX}`,
              },
            },
          },
          MuiInputAdornment: {
            styleOverrides: {
              root: {
                background: grey[50],
                color: grey[600],
                maxHeight: "none",
                justifyContent: "center",
              },
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                paddingTop: 11,
                paddingBottom: 11,
              },
            },
          },
          MuiListItem: {
            styleOverrides: {
              gutters: {
                "@media (min-width:600px)": {
                  paddingLeft: 16,
                  paddingRight: 16,
                },
              },
            },
          },
          MuiListSubheader: {
            styleOverrides: {
              root: {
                fontSize: "1rem",
                fontWeight: 400,
              },
              gutters: {
                "@media (min-width:600px)": {
                  paddingLeft: 16,
                  paddingRight: 16,
                },
              },
            },
          },
          MuiSelect: {
            styleOverrides: {
              select: {
                "&.Mui-focused": {
                  background: common.white,
                },
                "&.MuiSelect-select": {
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: 40,
                  lineHeight: "40px",
                  paddingRight: 21,
                },
              },
            },
          },
          MuiSnackbar: {
            styleOverrides: {
              root: {
                "@media (min-width:900px)": {
                  maxWidth: "calc(100% - 36px)",
                },
              },
              anchorOriginBottomRight: {
                bottom: "80px !important",
                right: "16px !important",
              },
            },
          },
          MuiSnackbarContent: {
            styleOverrides: {
              root: {
                "@media (min-width:900px)": {
                  flexWrap: "nowrap",
                  maxWidth: "100%",
                },
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                textTransform: "uppercase",
                "&:hover": {
                  textDecoration: "none",
                },
              },
              fullWidth: {
                minWidth: 0,
                "@media (min-width:900px)": {
                  minWidth: 0,
                },
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              head: {
                fontWeight: 700,
                textTransform: "uppercase",
                fontSize: 13,
              },
            },
          },
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                textAlign: "center",
                maxWidth: "24rem",
              },
            },
          },
        },
      },
      overrides,
    ),
  ) as unknown as TalentpairTheme;
};

export const tpTheme = generateTheme(colors.green);
