import { EntityTypeT } from "@talentpair/types/misc";

type ParamT = number | string;

const publicJobList = (): string => "/public";
const publicJobDetail = (job: ParamT): string => `/public/${job}`;

const candidateReferral = (referral_code: string): string =>
  `/referral/${referral_code}/get-hired-using-talentpair`;

const companyReferral = (referral_code: string, plan?: string): string =>
  `/referral/${referral_code}/hire-with-talentpair${plan ? `-${plan}` : ""}`;

const authSignin = (): string => "/signin";
const authSignup = (): string => "/signup";
const authCandidateSignin = (): string => "/candidate-signin";
const authEmployerSignin = (): string => "/employer-signin";
const authCandidateSignup = (): string => "/candidate-signup";
const authEmployerSignup = (): string => "/employer-signup"; // Do not change this without also updating the hardcoded url in the pricing app
const authForgot = (): string => "/forgot-password";

const capCreateProfile = (): string => "/create-profile";
const capPairList = (candidate: ParamT): string => `/${candidate}/pairs`;
const capPairDetail = (candidate: ParamT, pair: ParamT): string =>
  `${capPairList(candidate)}/${pair}`;
const capApplicationDetail = (candidate: ParamT, pair: ParamT): string =>
  `${capPairList(candidate)}/application/${pair}`;
const capPairActivity = (candidate: ParamT, pair: ParamT): string =>
  `${capPairList(candidate)}/${pair}/messages`;
const capSharedPairActivity = (candidate: ParamT, pair: ParamT): string =>
  `${capPairList(candidate)}/${pair}/shared-messages`;
const capProfile = (candidate: ParamT): string => `/${candidate}/profile`;
const capProfilePreview = (candidate: ParamT): string => `/${candidate}/profile/preview`;
const capDesiredNextRole = (candidate: ParamT): string => `/${candidate}/desired-next-role`;
const capUserActivity = (candidate: ParamT): string => `/${candidate}/messages`;
const capReferFriends = (): string => "/refer";
const capSettings = (candidate: ParamT): string => `/${candidate}/settings`;
const capCalendar = (candidate: ParamT, highlight?: ParamT): string =>
  `/${candidate}/calendar${highlight ? `?highlight=${highlight}` : ""}`;

const tapCreateProfile = (): string => "/employer-create-profile";
const tapOrgSettings = (org: ParamT): string => `/org/${org}/admin`;
const tapOrgUserProfile = (org: ParamT, member: ParamT): string =>
  `${tapOrgSettings(org)}/${member}`;
const tapOrgProfile = (org: ParamT): string => `/org/${org}/profile`;
const tapReports = (org: ParamT): string => `/org/${org}/reports`;
const tapBilling = (org: ParamT): string => `/org/${org}/billing`;

const tapPricing = (): string => `/employer-choose-pricing`;

const tapJobList = (org: ParamT): string => `/org/${org}/jobs`;
const tapJobCreate = (org: ParamT): string => `${tapJobList(org)}/new`;
const tapJobPipeline = (org: ParamT, job: ParamT): string => `${tapJobList(org)}/${job}/pipeline`;
const tapJobPipelineBucket = (org: ParamT, job: ParamT, bucket: string): string =>
  `${tapJobList(org)}/${job}/pipeline/${bucket}/pairs`;
const tapJobInfo = (org: ParamT, job: ParamT): string => `${tapJobList(org)}/${job}/manage`;
const tapJobGreenhouse = (org: ParamT, job: ParamT): string =>
  `${tapJobInfo(org, job)}/integrations`;
const tapJobStatus = (org: ParamT, job: ParamT): string => `${tapJobInfo(org, job)}/status`;
const tapJobEdit = (org: ParamT, job: ParamT): string => `${tapJobInfo(org, job)}/edit`;
const tapJobTeamMembers = (org: ParamT, job: ParamT): string =>
  `${tapJobInfo(org, job)}/team/members`;

const tapJobTeamProfile = (org: ParamT, job: ParamT, member: ParamT): string =>
  `${tapJobTeamMembers(org, job)}/${member}`;
const tapJobGrowthPartnerProfile = (org: ParamT, job: ParamT, member: ParamT): string =>
  `${tapJobInfo(org, job)}/team/partners/${member}`;
const tapPairList = (org: ParamT, job: ParamT): string => `${tapJobPipeline(org, job)}/all/pairs`;
const tapPairDetail = (org: ParamT, job: ParamT, pair: ParamT): string =>
  `${tapPairList(org, job)}/${pair}`;
const tapPairCandidate = (org: ParamT, job: ParamT, pair: ParamT): string =>
  `${tapPairDetail(org, job, pair)}/view`;
const tapPairActivity = (org: ParamT, job: ParamT, pair: ParamT): string =>
  `${tapPairDetail(org, job, pair)}/messages`;
const tapSharedPairActivity = (org: ParamT, job: ParamT, pair: ParamT): string =>
  `${tapPairDetail(org, job, pair)}/shared-messages`;
const tapPairPipeline = (org: ParamT, job: ParamT, pair: ParamT): string =>
  `${tapPairDetail(org, job, pair)}/pipeline`;
const tapProfile = (): string => "/profile";
const tapUserActivity = (org: ParamT): string => `/org/${org}/messages`;
const tapSettings = (org: ParamT, user: ParamT): string => `/org/${org}/settings/${user}`;
const tapCalendar = (org: ParamT, user: ParamT, highlight?: ParamT): string =>
  `/org/${org}/calendar/${user}${highlight ? `?highlight=${highlight}` : ""}`;
const tapManageJobBoard = (org: ParamT): string => `/org/${org}/manage-job-board`;
const tapCandidates = (org: ParamT): string => `/org/${org}/candidates`;

const capNotifications = (candidate: ParamT): string => `/${candidate}/notifications`;
const tapNotifications = (org: ParamT): string => `/org/${org}/notifications`;
const qa = (): string => "/qa";

const toProfile = (entityType: EntityTypeT, id: ParamT, orgId?: ParamT | null): string => {
  switch (entityType) {
    case "candidate":
      return capProfile(id);
    case "job":
      return orgId ? tapJobInfo(orgId, id) : "";
    case "contact":
      return orgId ? tapOrgUserProfile(orgId, id) : "";
    case "organization":
      return tapOrgProfile(id);
    default:
      return "";
  }
};

export const londonLink = {
  publicJobList,
  publicJobDetail,
  candidateReferral,
  companyReferral,
  authSignin,
  authSignup,
  authCandidateSignin,
  authEmployerSignin,
  authCandidateSignup,
  authEmployerSignup,
  authForgot,
  capCreateProfile,
  capPairActivity,
  capSharedPairActivity,
  capPairDetail,
  capApplicationDetail,
  capPairList,
  capDesiredNextRole,
  capProfile,
  capProfilePreview,
  capUserActivity,
  capReferFriends,
  capSettings,
  capCalendar,
  tapCalendar,
  tapCreateProfile,
  tapOrgSettings,
  tapOrgUserProfile,
  tapReports,
  tapOrgProfile,
  tapJobCreate,
  tapJobGreenhouse,
  tapJobEdit,
  tapJobStatus,
  tapJobPipeline,
  tapJobPipelineBucket,
  tapJobInfo,
  tapJobList,
  tapJobTeamMembers,
  tapJobGrowthPartnerProfile,
  tapJobTeamProfile,
  tapPairCandidate,
  tapPairActivity,
  tapSharedPairActivity,
  tapPairPipeline,
  tapPairDetail,
  tapPairList,
  tapProfile,
  tapUserActivity,
  tapManageJobBoard,
  tapSettings,
  tapCandidates,
  capNotifications,
  tapNotifications,
  tapBilling,
  tapPricing,
  qa,
  toProfile,
};
