import { AutoCompleteJobT, TinyJobT } from "@talentpair/types/entities/job";
import { LocationT } from "@talentpair/types/entities/location";
import { MediumTagT } from "@talentpair/types/entities/tags";
import { NavSearchResultT } from "@talentpair/types/navSearch";
import { ChoiceT } from "@talentpair/types/entities/misc";
import { SmallTeamT } from "@talentpair/types/entities/team";
import {
  MicroUserWithTypeT,
  SmallUserWithEmailT,
  TinyUserWithEmailT,
  NanoUserT,
  MicroUserWithEmailT,
} from "@talentpair/types/entities/user";
import { apiRequest } from "./apiRequest";
import { userService } from "./user";

async function requestResults<V, P = Record<string, unknown>>(
  url: string,
  query: string,
  params?: P | null,
): Promise<V[]> {
  try {
    const { data } = await apiRequest.get<{ results: V[] } | V[]>(url, { q: query, ...params });
    return "results" in data ? data.results : data;
  } catch (e) {
    // TODO add logging
    return [];
  }
}

type EntityT =
  | "team"
  | "candidate"
  | "maxhire_candidate"
  | "organization"
  | "maxhire_organization"
  | "maxhire_contact"
  | "job"
  | "contact";

export interface LocationParamsT {
  no_area?: 0 | 1;
  only_area?: 0 | 1;
}
export interface NavSearchParamsT {
  types: EntityT[];
}
export interface TapNavSearchParamsT {
  types: Array<Extract<EntityT, "candidate" | "contact" | "job">>;
}
export type SendCandidateRecipientJobT = AutoCompleteJobT<SmallUserWithEmailT> & { type: "job" };
export type SendCandidateRecipientContactT = SmallUserWithEmailT & { type: "contact" };
export type SendCandidateRecipientT = SendCandidateRecipientJobT | SendCandidateRecipientContactT;

export const createQuery =
  <V>(entityName: string) =>
  (query: string, params?: Record<string, unknown>): Promise<V[]> =>
    query ? requestResults(`entities/${entityName}/`, query, params) : Promise.resolve([]);

export type RecruiterTeamT = MicroUserWithTypeT | SmallTeamT;

export const autocompleteFetcher = {
  navSearch: (query: string, params?: NavSearchParamsT): Promise<NavSearchResultT[]> =>
    query
      ? requestResults("search/navigational/", query, params)
      : apiRequest
          .get<NavSearchResultT[]>(`users/${userService.id()}/looks/`)
          .then(({ data }) => data),
  tapNavSearch: (query: string, params?: TapNavSearchParamsT): Promise<NavSearchResultT[]> =>
    query ? requestResults("search/tap/navigational/", query, params) : Promise.resolve([]),
  // TODO: add looks support for TAP users
  // : apiRequest
  //     .get<NavSearchResultT[]>(`users/${userService.id()}/looks/`)
  //     .then(({ data }) => data),
  locations: (query: string, params?: LocationParamsT): Promise<LocationT[]> =>
    query ? requestResults("intake/api/location/", query, params) : Promise.resolve([]),
  omnis: createQuery<ChoiceT>("omnis"),
  onering: createQuery<ChoiceT>("onering"),
  roles: createQuery<ChoiceT>("roles"),
  organizations: createQuery<ChoiceT>("organizations"),
  tags: createQuery<MediumTagT>("tags/candidate"),
  verticals: createQuery<ChoiceT>("verticals"),
  companies: createQuery<ChoiceT>("companies"),
  recruiters: createQuery<NanoUserT>("recruiters"),
  jobs: createQuery<TinyJobT>("jobs"),
  candidates: createQuery<TinyUserWithEmailT>("candidate-users"),
  recruitersTeams: createQuery<RecruiterTeamT>("recruiters-teams"),
  fieldsOfStudy: createQuery<ChoiceT>("field-of-study"),
  degrees: createQuery<ChoiceT>("degrees"),
  schools: createQuery<ChoiceT>("schools"),
  investors: createQuery<ChoiceT>("investors"),
  orgUsers: createQuery<SmallUserWithEmailT>("org-users"),
  users: createQuery<MicroUserWithEmailT>("users"),
  sendCandidateRecipients: createQuery<SendCandidateRecipientT>("send-candidate-recipients"),
};
