import * as React from "react";
import { page } from "../../components/Auth/Page";

// Currently we aren't using Formik for any of these pages because it seems to not work well
// with Chrome autofill behavior. Could be possible to move everything but the /signin forms
// to Formik, since the rest don't use autofill.
export const EmployerSigninPage = page(React.lazy(() => import("./EmployerSignin")));
export const EmployerSignupPage = page(React.lazy(() => import("./EmployerSignup")));
export const CandidateSigninPage = page(React.lazy(() => import("./CandidateSignin")));
export const CandidateSignupPage = page(React.lazy(() => import("./CandidateSignup")));
export const ForgotPage = page(React.lazy(() => import("./Forgot")));
export const InvitePage = page(React.lazy(() => import("./Invite")));
export const ResetPage = page(React.lazy(() => import("./Reset")));
export const SigninPage = page(React.lazy(() => import("./Signin")));
export const SignupPage = page(React.lazy(() => import("./Signup")));
