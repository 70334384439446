import * as React from "react";
import { userService, apiRequest, ResponseDataT, NotificationCountStore } from "@talentpair/api";
import { env } from "@talentpair/env";
import promptAppRefresh from "./promptAppRefresh";
import Poll from "./Poll";

export interface UrgentNotificationT {
  mail_sync_failed: boolean;
  supported_front_end_versions: string[];
  supported_london_versions: string[];
  has_new_notifications: boolean;
  notification_count: number;
  chore_count?: number;
}

function versionCheck({
  supported_front_end_versions,
  supported_london_versions,
}: UrgentNotificationT): boolean {
  const currentVersion = process.env.TAG;
  // Short circuit if there is no current version or we're on gojira2
  if (!currentVersion || process.env.HOST === "gojira2" || process.env.HOST === "local")
    return true;
  // Check if current version isn't valid
  const versions = env.isGettysburg() ? supported_front_end_versions : supported_london_versions;
  return !(!!versions.length && !versions.includes(currentVersion));
}

const NotificationPing = ({
  action,
  ...props
}: {
  action: (notification: UrgentNotificationT) => unknown;
}): React.ReactElement => (
  <Poll
    {...props}
    request={(timestamp) =>
      apiRequest
        .get<UrgentNotificationT>("notifications/urgent/")
        .then(({ data }: ResponseDataT<UrgentNotificationT>) => {
          const versionChanged = !versionCheck(data);
          if (versionChanged) promptAppRefresh();
          const newNotif = data.has_new_notifications && !NotificationCountStore.getHasNew();
          const countChanged =
            data[userService.isRecruiter() ? "chore_count" : "notification_count"] !==
            NotificationCountStore.getCount();
          action(data);
          return versionChanged || newNotif || countChanged ? new Date().toISOString() : timestamp;
        })
    }
  />
);

export default NotificationPing;
export const _test = { versionCheck };
