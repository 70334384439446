import * as React from "react";
import { Link, RouteComponentProps, withRouter } from "@talentpair/routing";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FeedbackIcon from "@mui/icons-material/Feedback";
import PaymentIcon from "@mui/icons-material/Payment";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { auth, userService } from "@talentpair/api";
import { londonLink } from "@talentpair/env";
import { portal } from "@talentpair/portal";
import { Menu, Button, MenuItem, MenuSection, UserAvatar } from "@talentpair/quantic";
import FeedbackModal from "kyoto/components/FeedbackModal";
import { NotificationsMenuItem } from "../../../shared-apps/Notifications/components/NotificationsMenuItem";
import { useOrgBillingAccessControls } from "../Billing/useOrgBillingAccessControls";
import { AccountOwnerMenuItem } from "./AccountOwnerMenuItem";

function BaseMenuNavigation({ match }: RouteComponentProps<{ org: string }>): React.ReactElement {
  const { canSeeBilling } = useOrgBillingAccessControls(false);
  const orgId = match.params.org ? +match.params.org : userService.orgId() || 0;
  const isRecruiter = userService.isRecruiter();
  return (
    <Menu
      button={<Button size="lg" variant="icon" icon={MenuIcon} data-testid="appNavigationMenu" />}
      minWidth={320}
    >
      {isRecruiter ? null : (
        <>
          <MenuItem
            data-testid="profileBtn"
            component={Link}
            icon={
              <div className="mxn2 myn2">
                <UserAvatar user={userService.data} />
              </div>
            }
            to={londonLink.tapOrgUserProfile(orgId, userService.id())}
          >
            Your profile
          </MenuItem>
          <NotificationsMenuItem />
        </>
      )}
      <MenuSection bordered={!isRecruiter}>
        <MenuItem
          data-testid="jobsBtn"
          component={Link}
          icon={WorkIcon}
          to={londonLink.tapJobList(orgId)}
        >
          Jobs
        </MenuItem>
        <MenuItem
          data-testid="candidatesBtn"
          component={Link}
          icon={PersonIcon}
          to={londonLink.tapCandidates(orgId)}
        >
          Candidates
        </MenuItem>
        {userService.isRecruiter() ? null : (
          <MenuItem
            data-testid="calendarBtn"
            component={Link}
            to={londonLink.tapCalendar(orgId, userService.data?.id || 0)}
            icon={CalendarMonthIcon}
          >
            Calendar
          </MenuItem>
        )}
        <MenuItem
          data-testid="reportsBtn"
          component={Link}
          icon={ShowChartIcon}
          to={londonLink.tapReports(orgId)}
        >
          Reports
        </MenuItem>
      </MenuSection>
      <MenuSection bordered>
        <MenuItem
          data-testid="companyProfileBtn"
          component={Link}
          icon={BusinessIcon}
          to={londonLink.tapOrgProfile(orgId)}
        >
          Company profile
        </MenuItem>
        {userService.isOrgAdmin() ? (
          <MenuItem
            data-testid="hiringTeamBtn"
            component={Link}
            icon={GroupsIcon}
            to={londonLink.tapOrgSettings(orgId)}
          >
            Hiring team
          </MenuItem>
        ) : null}
        <MenuItem
          data-testid="jobBoardBtn"
          component={Link}
          icon={ListAltIcon}
          to={londonLink.tapManageJobBoard(orgId)}
        >
          Job board
        </MenuItem>
      </MenuSection>
      <MenuSection bordered>
        {canSeeBilling ? (
          <MenuItem
            component={Link}
            data-testid="billingBtn"
            to={londonLink.tapBilling(orgId)}
            icon={PaymentIcon}
          >
            Billing
          </MenuItem>
        ) : null}
        <MenuItem
          data-testid="feedbackBtn"
          component="a"
          icon={FeedbackIcon}
          onClick={() => portal.modal(FeedbackModal)}
        >
          Feedback
        </MenuItem>
        <MenuItem
          component={Link}
          icon={SettingsIcon}
          data-testid="settingsBtn"
          to={londonLink.tapSettings(orgId, userService.data?.id || 0)}
        >
          Settings
        </MenuItem>
        <MenuItem
          data-testid="logoutBtn"
          component="a"
          icon={LogoutIcon}
          onClick={() => auth.logout()}
        >
          Log out
        </MenuItem>
      </MenuSection>
      <MenuSection bordered>
        <AccountOwnerMenuItem orgId={orgId} />
      </MenuSection>
    </Menu>
  );
}

export const MenuNavigation = withRouter(BaseMenuNavigation);
