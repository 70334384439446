import * as React from "react";
import { ControlledMenu, ControlledMenuProps } from "./ControlledMenu";

export type MenuProps = Omit<ControlledMenuProps, "open" | "onClose" | "onOpen"> & {
  onOpen?: () => unknown;
};

export function Menu(props: MenuProps): React.ReactElement {
  const [open, setOpen] = React.useState(false);
  return (
    <ControlledMenu
      {...props}
      open={open}
      onOpen={() => {
        setOpen(true);
        props.onOpen?.();
      }}
      onClose={() => setOpen(false)}
    />
  );
}
