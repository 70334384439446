import type { CapPairReduxT } from "@talentpair/types/cap/entities/pair";
// @ts-expect-error - REMOVE ME
import { mergeListDetail } from "../../tap/store/storeUtils";
import {
  CAP_PAIR_RECEIVE_DETAIL,
  CAP_PAIR_RECEIVE_LIST,
  CAP_PAIR_SET_STATE,
  CAP_PAIR_RECEIVE_NOTIFICATION_COUNTS,
  PairActionT,
} from "./pairActions";

export type CapPairsReduxT = Record<number, CapPairReduxT | null | undefined>;
export default function pairs(state: CapPairsReduxT = {}, action: PairActionT): CapPairsReduxT {
  switch (action.type) {
    case CAP_PAIR_RECEIVE_DETAIL:
      return { ...state, [action.pair.id]: { ...state[action.pair.id], ...action.pair } };

    case CAP_PAIR_RECEIVE_LIST:
      return Object.assign(
        {},
        state,
        ...action.pairs.map((newPair) => ({
          [newPair.id]: mergeListDetail(state, newPair),
        })),
      );

    // Have to duplicate below because of flow's inference of merge fn
    case CAP_PAIR_RECEIVE_NOTIFICATION_COUNTS:
      return Object.assign(
        {},
        state,
        ...action.pairs.map((newPair) => ({
          [newPair.id]: mergeListDetail(state, newPair),
        })),
      );

    case CAP_PAIR_SET_STATE: {
      const pair = state[action.pairId];
      if (!pair) return state;
      return {
        ...state,
        [action.pairId]: { ...pair, candidate_state: action.candidate_state },
      };
    }

    default:
      return state;
  }
}
