import * as React from "react";
import { astralLink, env } from "@talentpair/env";
import { Badge, BadgePropsT } from "./Badge";

export type IncompleteBadgeProps = {
  type: "job" | "candidate";
  id?: number;
} & Partial<BadgePropsT>;

export const IncompleteBadge = ({
  type,
  id,
  href,
  ...props
}: IncompleteBadgeProps): React.ReactElement => (
  <Badge
    {...props}
    text="INCOMPLETE"
    bgColor="bg-amber-700"
    fgColor="white"
    data-testid="incomplete-badge"
    href={
      href != null
        ? href
        : id
        ? env.gettysburgUrl(
            type === "job" ? astralLink.toJobIntake(id) : astralLink.toCandidateIntake(id),
          )
        : null
    }
  />
);
