// @flow
import lodash from "lodash";

type EntityT = {
  [string]: *,
  id: number,
  ...
};

export const mergeListDetail = <V: EntityT>(
  state: { [id: number]: ?EntityT, ... },
  newEntity: V,
): V => {
  const oldEntity = state[newEntity.id];
  return {
    // Lodash's `merge` will merge arrays. This `mergeWith` implementation overwrites the old array with the new one.
    // $FlowFixMe
    ...lodash.mergeWith({}, oldEntity, newEntity, (oldVal, newVal) =>
      Array.isArray(newVal) ? newVal : undefined,
    ),
  };
};
