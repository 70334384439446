import { ReactElement, ReactNode } from "react";
import { Typography } from "@talentpair/quantic";

export function ErrorMessage({
  subtitle,
  children,
}: {
  subtitle: string;
  children: ReactNode;
}): ReactElement {
  return (
    <>
      <Typography gutterBottom variant="h1">
        Sorry.
      </Typography>
      <Typography gutterBottom variant="subtitle">
        {subtitle}
      </Typography>
      <Typography variant="body2">{children}</Typography>
    </>
  );
}
