import * as React from "react";
import { StyleProps } from "@talentpair/types/component";
import { styled } from "@mui/material/styles";
import { colorProp, ColorPropT } from "../theme/colorProp";

export type RingProps = StyleProps & {
  shape?: "circular" | "rounded";
  color?: ColorPropT;
  children: React.ReactElement; // avatar or whatever to wrap
};
function UnstyledRing({ children, shape, color, ...props }: RingProps): React.ReactElement {
  return (
    <div {...props}>
      <div data-testid={color === "transparent" ? undefined : "ring"}>{children}</div>
    </div>
  );
}

export const Ring = styled(UnstyledRing, { skipSx: true })(
  ({ theme, shape = "circular", color = "primary" }) => ({
    // applying styles to nested div so that the wrapping div can be an anchor for further margin/styles
    "& > div": {
      margin: 4,
      border: `solid 2px ${colorProp(color, theme)}`,
      padding: 2,
      borderRadius: shape === "circular" ? "50%" : "calc(20% + 2px)",
      overflow: "hidden",
    },
  }),
) as React.ComponentType<RingProps>;
