import * as React from "react";
import { apiRequest, userService } from "@talentpair/api";
import { determineRoute, RouteComponentProps, withRouter } from "@talentpair/routing";
import { routes } from "../routeConfig";

// Note that `user` should be used for contacts
type TypeT = "candidate" | "user" | "job" | "organization";

function updateRecentlyViewed(type: TypeT, id?: string | undefined): void {
  if (!id || !userService.data) return;
  apiRequest.post(`users/${userService.id()}/looks/`, { id: +id, type });
}

function processLocationChange(props: RouteComponentProps, prevProps?: RouteComponentProps): void {
  if (!userService.isRecruiter()) return;

  const newPath = props.location.pathname;
  let prevRoute;

  if (prevProps) {
    const oldPath = prevProps.location.pathname;
    if (newPath === oldPath) return;
    prevRoute = determineRoute(routes, oldPath);
  }

  const prevParams: Record<string, string | undefined> = prevRoute ? prevRoute.match.params : {};
  const prevAppSubtype = prevRoute ? prevRoute.appSubtype : null;

  const {
    appType,
    appSubtype,
    match: { params },
  } = determineRoute(routes, newPath);

  if (appType === "CAP") {
    if (params.candidate !== prevParams.candidate) {
      updateRecentlyViewed("candidate", params.candidate);
    }
    return;
  }

  if (
    params.job !== prevParams.job || // If the job is the same bet we are moving from a JobTeamProfile page to one that isn't,
    // we want to hit the API for the job so that it moves to the top of the list
    (appSubtype !== "JobTeamProfile" && prevAppSubtype === "JobTeamProfile")
  ) {
    updateRecentlyViewed("job", params.job);
  }

  switch (appSubtype) {
    case "OrgProfile":
      updateRecentlyViewed("organization", params.org);
      break;

    case "OrgUserProfile":
      updateRecentlyViewed("user", params.user);
      break;

    case "JobTeamProfile":
      updateRecentlyViewed("user", params.member);
      break;

    default:
  }
}

class RecentlyViewed extends React.Component<RouteComponentProps> {
  componentDidMount(): void {
    processLocationChange(this.props);
  }

  componentDidUpdate(prevProps: RouteComponentProps): void {
    processLocationChange(this.props, prevProps);
  }

  render(): React.ReactNode {
    return null;
  }
}

export const _test = { updateRecentlyViewed, processLocationChange, RecentlyViewed };

export default withRouter(RecentlyViewed);
