import * as React from "react";
import clsx from "clsx";
import { Link, RouteComponentProps } from "@talentpair/routing";
import { Tooltip } from "@mui/material";
import { Button, ButtonProps, Box } from "@talentpair/quantic";

export type NavLinkBoxProps = React.PropsWithChildren<{
  active?: boolean;
  className?: string;
}>;
export function NavLinkBox({
  active,
  className,
  children,
  ...props
}: NavLinkBoxProps): React.ReactElement {
  return (
    <Box
      {...props}
      variant="center"
      color={active ? "common.black" : "grey.600"}
      background={active ? "primary.100" : undefined}
      className={clsx(active ? undefined : "hover-bg-grey-200 hover-grey-900", className)}
      aria-current={!!active}
    >
      {children}
    </Box>
  );
}

export type NavLinkProps = {
  title: string;
  icon: ButtonProps["icon"];
  to: string | (() => unknown);
  match: RouteComponentProps["match"];
};

export function NavLink({ title, icon, to, match, ...props }: NavLinkProps): React.ReactElement {
  let contents = null;
  let active = false;
  if (typeof to === "function") {
    contents = (
      <Button
        variant="icon"
        icon={icon}
        color="inherit"
        size="lg"
        style={{ borderRadius: 0, padding: 17 }}
        onClick={to}
      />
    );
  } else {
    active = match.url.startsWith(to);
    contents = (
      <Link className="color-inherit" to={to}>
        <Button
          variant="icon"
          icon={icon}
          color="inherit"
          size="lg"
          style={{ borderRadius: 0, padding: 17 }}
        />
      </Link>
    );
  }
  return (
    <Tooltip
      title={title}
      placement="right"
      PopperProps={{
        popperOptions: { strategy: "fixed" },
      }}
    >
      <div>
        <NavLinkBox {...props} active={active}>
          {contents}
        </NavLinkBox>
      </div>
    </Tooltip>
  );
}
