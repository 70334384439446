import * as React from "react";
import { RouteComponentProps } from "@talentpair/routing";
import { ReferralPage } from "../components/ReferralPage";

type ReferralPageProps = RouteComponentProps<{ referral_code?: string }>;

const CompanyReferral = React.lazy(() => import("./CompanyReferral"));
const CompanyLanding = React.lazy(() => import("./CompanyLanding"));

export default (props: ReferralPageProps): React.ReactElement => (
  <ReferralPage
    page={props.match.params.referral_code ? CompanyReferral : CompanyLanding}
    {...props}
  />
);
