import * as React from "react";
import { Router, Switch, Redirect, History } from "@talentpair/routing";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { tpTheme, MediaContextProvider } from "@talentpair/quantic";
import { env } from "@talentpair/env";
import { PortalRenderer } from "@talentpair/portal";
import { ErrorBoundary, SuspenseWrapper, withProfiler } from "@talentpair/sentry";
import { NavigationAnalytics } from "@talentpair/tracking";
import AppVersionPing from "kyoto/components/AppVersionPing";
import {
  EmployerSigninPage,
  EmployerSignupPage,
  CandidateSigninPage,
  CandidateSignupPage,
  ForgotPage,
  InvitePage,
  ResetPage,
  SigninPage,
  SignupPage,
} from "./auth/pages";
import RecentlyViewed from "./components/RecentlyViewed";
import {
  AuthRoute,
  CapAuthRoute,
  TapAuthRoute,
  RecruitAuthRoute,
  QaAuthRoute,
} from "./components/hocs/AuthRoute";
import { BaseRoute } from "./components/hocs/BaseRoute";
import OnboardingContext from "./components/Onboarding/OnboardingContext";
import { UserRedirect, PublicJobRedirect } from "./components/Redirects";
import { routes, skipSignupRedirect } from "./routeConfig";
import { AccountDeleteFeedbackPage } from "./public-apps/AccountDeleteFeedback";
import { CapNotificationsPage, TapNotificationsPage } from "./shared-apps/Notifications";
import NotificationPing from "./shared-apps/Notifications/containers/NotificationPing";
import NotificationsDesktop from "./shared-apps/Notifications/components/NotificationsDesktop";
import { QaPage } from "./shared-apps/Qa";
import { MpcLinkedinPixel } from "./cap/components/MpcLinkedinPixel";
// @ts-expect-error - eslint upgrade
import { CapPairRoutesPage } from "./cap/PairRoutes";
import { CapDesiredNextRolePage } from "./cap/DesiredNextRole";
// @ts-expect-error - eslint upgrade
import { CapProfilePage } from "./cap/Profile";
// @ts-expect-error - eslint upgrade
import { CapProfilePreviewPage } from "./cap/ProfilePreview";
// @ts-expect-error - eslint upgrade
import { CapUserActivityPage } from "./cap/UserActivity";
// @ts-expect-error - eslint upgrade
import { CapCreateProfilePage } from "./cap/CreateProfile";
import { RefreshProfilePage } from "./cap/RefreshProfile";
import { NextStepsPage } from "./cap/NextSteps";
// @ts-expect-error - eslint upgrade
import { CapApplicationSubmitted } from "./cap/ApplicationSubmitted";
// @ts-expect-error - eslint upgrade
import { CapReferFriendsPage } from "./cap/ReferFriends";
import { CapSettingsPage } from "./cap/CapSettings";
import { CapCalendarPage } from "./cap/CapCalendar";
// @ts-expect-error - eslint upgrade
import { TapCreateProfilePage } from "./tap/CreateProfile";
// @ts-expect-error - eslint upgrade
import { TapProfilePage } from "./tap/Profile";
// @ts-expect-error - eslint upgrade
import { TapUserActivityPage } from "./tap/UserActivity";
import { TapSettingsPage } from "./tap/TapSettings";
import { TapCalendarPage } from "./tap/TapCalendar";
import { OrgReportsPage } from "./tap/OrgReports";
import { OrgBillingPage } from "./tap/OrgBilling";
import { BillingRedirect } from "./tap/OrgBilling/components/BillingRedirect";
import { OrgPricingPage } from "./tap/OrgPricing";
import { OrgCheckoutPage } from "./tap/OrgCheckout";
// @ts-expect-error - eslint upgrade
import { OrgSettingsPage } from "./tap/OrgSettings";
// @ts-expect-error - eslint upgrade
import { OrgUserProfilePage } from "./tap/OrgUserProfile";
// @ts-expect-error - eslint upgrade
import { OrgProfilePage } from "./tap/OrgProfile";
import { ManageJobBoardPage } from "./tap/ManageJobBoard";
import { MyJobsPage } from "./tap/MyJobs";
import { MyCandidatesPage } from "./tap/MyCandidates";
import { CandidateReferralPage } from "./referral-pages/CandidateReferralPage";
import CompanyReferral from "./referral-pages/CompanyReferral";

/**
 * NOTES:
 * In general we try to have a one-to-one Route -> Page relationship.
 * In some cases though, scroll-positions on desktop get weird when the whole page reloads.
 * In order to help with that, sometimes a more complicated RoutePage component is needed.
 * To do this, choose a top-level route, pass `exact={false}` and give it a RoutePage
 * (see "./tap/PairRoutes.jsx" for an example of how to do this)
 */
const authRoutes = (): React.ReactElement[] => [
  <BaseRoute
    {...routes.get("AUTH/Signin")}
    component={env.isLondonQg() ? EmployerSigninPage : SigninPage}
  />,
  <BaseRoute {...routes.get("AUTH/Signup")} component={SignupPage} />,
  <BaseRoute {...routes.get("AUTH/EmployerSignin")} component={EmployerSigninPage} />,
  <BaseRoute {...routes.get("AUTH/CandidateSignin")} component={CandidateSigninPage} />,
  <BaseRoute {...routes.get("AUTH/CandidateSignup")} component={CandidateSignupPage} />,
  <BaseRoute {...routes.get("AUTH/EmployerSignup")} component={EmployerSignupPage} />,
  <BaseRoute {...routes.get("AUTH/Forgot")} component={ForgotPage} />,
  <BaseRoute {...routes.get("AUTH/Reset")} component={ResetPage} />,
  <BaseRoute {...routes.get("AUTH/Invite")} component={InvitePage} />,
  <BaseRoute {...routes.get("REFERRAL/CandidateReferral")} component={CandidateReferralPage} />,
  <BaseRoute {...routes.get("REFERRAL/CompanyReferral")} component={CompanyReferral} />,
  <BaseRoute {...routes.get("REFERRAL/CompanySourcingReferral")} component={CompanyReferral} />,
  <BaseRoute {...routes.get("REFERRAL/CompanyAccessReferral")} component={CompanyReferral} />,
  <BaseRoute {...routes.get("REFERRAL/CompanyPlatformReferral")} component={CompanyReferral} />,
  <BaseRoute {...routes.get("TP/CompanyLanding")} component={CompanyReferral} />,
];

// returning array because react-router's Switch component cannot handle Routes inside fragments
const capRoutes = (): React.ReactElement[] => [
  <CapAuthRoute {...routes.get("CAP/CreateProfile")} component={CapCreateProfilePage} />,
  <CapAuthRoute {...routes.get("CAP/RefreshProfile")} component={RefreshProfilePage} />,
  <CapAuthRoute {...routes.get("CAP/PairList")} exact={false} component={CapPairRoutesPage} />, // Handles it's own sub-routes for scrolling consistency
  <CapAuthRoute {...routes.get("CAP/Profile")} component={CapProfilePage} />,
  <CapAuthRoute {...routes.get("CAP/ProfilePreview")} component={CapProfilePreviewPage} />,
  <CapAuthRoute {...routes.get("CAP/DesiredNextRole")} component={CapDesiredNextRolePage} />,
  <CapAuthRoute {...routes.get("CAP/UserActivity")} component={CapUserActivityPage} />,
  <CapAuthRoute {...routes.get("CAP/ApplicationSubmitted")} component={CapApplicationSubmitted} />,
  <CapAuthRoute {...routes.get("CAP/ReferFriends")} component={CapReferFriendsPage} />,
  <CapAuthRoute {...routes.get("CAP/Settings")} component={CapSettingsPage} />,
  <CapAuthRoute {...routes.get("CAP/Calendar")} component={CapCalendarPage} />,
  <CapAuthRoute {...routes.get("CAP/NextSteps")} component={NextStepsPage} />,
  <CapAuthRoute {...routes.get("CAP/Notifications")} component={CapNotificationsPage} />,
];

// returning array because react-router's Switch component cannot handle Routes inside fragments
const tapRoutes = (): React.ReactElement[] => [
  <TapAuthRoute {...routes.get("TAP/CreateProfile")} component={TapCreateProfilePage} />,
  <TapAuthRoute {...routes.get("TAP/OrgSettings")} component={OrgSettingsPage} />,
  <TapAuthRoute {...routes.get("TAP/OrgUserProfile")} component={OrgUserProfilePage} />,
  <TapAuthRoute {...routes.get("TAP/OrgProfile")} component={OrgProfilePage} />,
  <TapAuthRoute {...routes.get("TAP/OrgReports")} component={OrgReportsPage} />,
  <TapAuthRoute {...routes.get("TAP/ManageJobBoard")} component={ManageJobBoardPage} />,
  <TapAuthRoute {...routes.get("TAP/Profile")} component={TapProfilePage} />,
  <TapAuthRoute {...routes.get("TAP/UserActivity")} component={TapUserActivityPage} />,
  <TapAuthRoute {...routes.get("TAP/Calendar")} component={TapCalendarPage} />,
  <TapAuthRoute {...routes.get("TAP/Settings")} component={TapSettingsPage} />,
  <TapAuthRoute {...routes.get("TAP/JobDesk")} component={MyJobsPage} />,
  <TapAuthRoute {...routes.get("TAP/CandidateDesk")} component={MyCandidatesPage} />,
  <TapAuthRoute {...routes.get("TAP/Notifications")} component={TapNotificationsPage} />,
  <TapAuthRoute {...routes.get("TAP/OrgChoosePricing")} component={OrgPricingPage} />,
  <TapAuthRoute {...routes.get("TAP/OrgCheckout")} component={OrgCheckoutPage} />,
  <TapAuthRoute {...routes.get("TAP/OrgBilling")} component={OrgBillingPage} />,
  <BillingRedirect />,
];

const publicRoutes = (): React.ReactElement[] => [
  <BaseRoute
    key="public-redirect"
    path="/public/:slug(\\D+)?"
    exact={false}
    component={PublicJobRedirect}
  />,
  <BaseRoute {...routes.get("TP/AccountDeleteFeedback")} component={AccountDeleteFeedbackPage} />,
];

const App = ({ history }: { history: History }): React.ReactElement => (
  <Router history={history}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={tpTheme}>
        <ErrorBoundary>
          <OnboardingContext.Provider>
            <MediaContextProvider>
              <CssBaseline />
              <SuspenseWrapper>
                <Switch>
                  {/* PUBLIC */}
                  {publicRoutes()}

                  <AuthRoute {...routes.get("Redirect")} component={UserRedirect} />
                  <Redirect from="/login" to="/signin" />
                  <QaAuthRoute {...routes.get("QA")} component={QaPage} />

                  {/* AUTH */}
                  {authRoutes()}

                  {/* CAP */}
                  {capRoutes()}

                  {/* TAP */}
                  {tapRoutes()}

                  {/* USER SPECIFIC/GENERIC */}
                  <BaseRoute component={UserRedirect} />
                </Switch>
              </SuspenseWrapper>
              <TapAuthRoute
                component={RecentlyViewed}
                failureRedirect={null}
                loggedOutRedirect={null}
              />
              <RecruitAuthRoute
                component={NotificationsDesktop}
                failureRedirect={null}
                loggedOutRedirect={null}
              />
              <CapAuthRoute
                component={MpcLinkedinPixel}
                failureRedirect={null}
                loggedOutRedirect={null}
              />
              <NotificationPing />
              <AppVersionPing app="london" />
              <NavigationAnalytics routes={routes} skipSignupRedirect={skipSignupRedirect} />
              <PortalRenderer />
            </MediaContextProvider>
          </OnboardingContext.Provider>
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  </Router>
);

export default withProfiler(App);
