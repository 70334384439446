import { sentry } from "@talentpair/sentry";
import lodash from "lodash";

const encodeParam = (k: unknown, v: unknown): string =>
  `${encodeURIComponent(lodash.toString(k))}=${encodeURIComponent(lodash.toString(v))}`;

const encodeParamArrayRepeatedKeys = (key: unknown, values: unknown[]): string =>
  values.map((value) => encodeParam(key, value)).join("&");

const encodeParamArrayLegacy = (key: unknown, values: unknown[]): string =>
  `${encodeURIComponent(lodash.toString(key))}=${values
    .map(lodash.toString)
    .map(encodeURIComponent)
    .join(",")}`;

export function objToQueryStr<D = Record<string, unknown>>(
  obj: D,
  useRepeatedKeys = false,
): string {
  if (useRepeatedKeys) sentry.warning(new Error("Found use of useRepeatedKeys argument!"));
  const encodeParamArray = useRepeatedKeys ? encodeParamArrayRepeatedKeys : encodeParamArrayLegacy;

  const queryStr = Object.entries(obj)
    .map(([key, value]) =>
      Array.isArray(value) ? encodeParamArray(key, value) : encodeParam(key, value),
    )
    .join("&");

  return queryStr ? `?${queryStr}` : "";
}

export function queryStrToObj<Params extends string = string>(
  str: string,
): Partial<Record<Params, string>> {
  const queryStr = str.split("?")[1];
  return queryStr
    ? queryStr
        .split("&")
        .map((param) => param.split("=").map(decodeURIComponent))
        .reduce((acc, pair) => ({ ...acc, [pair[0]]: pair[1] }), {})
    : {};
}

export function getQueryParam<F extends string = string>(field: F): string | null {
  return queryStrToObj<F>(location.search)[field] || null;
}

export function getFlyoutReferer(): string {
  // check if we're inside a flyout and get referrer if so
  try {
    // have to wrap this check against window.frameElement in try/catch block as it can throw in certain older browsers
    if (window.frameElement) return document.referrer; // this will probably not work in cross-origin situations (where the flyout domain differs from the parent page domain)
  } catch (err) {
    sentry.error(err as Error);
  }
  return "";
}
