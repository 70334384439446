import { userService, dynamicPoll } from "@talentpair/api";
import { sentry } from "@talentpair/sentry";
import * as React from "react";

export type PropsT = {
  pollIfLoggedOut?: boolean;
  min_delay?: number;
  max_delay?: number;
  request: (timestamp: string) => Promise<string>;
};

export default class Poll extends React.Component<PropsT> {
  ac: AbortController | null = null;

  componentDidMount(): void {
    if (!this.ac) {
      this.startPoll();
    }
  }

  componentWillUnmount(): void {
    this.cancel();
  }

  poll = (timestamp: string): Promise<string> => {
    // skip the request if the window is not visible
    if (
      (userService.loggedin || this.props.pollIfLoggedOut) &&
      window.document.visibilityState === "visible"
    )
      return this.props.request(timestamp).catch((err) => {
        sentry.error(err);
        return timestamp;
      });

    return Promise.resolve(timestamp);
  };

  cancel = (): void => {
    if (this.ac) {
      this.ac.abort();
      this.ac = null;
    }
  };

  startPoll = (): void => {
    this.cancel();
    this.ac = dynamicPoll({
      min_delay_ms: this.props.min_delay || 3000,
      max_delay_ms: this.props.max_delay || 15000,
      poll: this.poll,
    });
  };

  render(): null {
    return null;
  }
}
