import { combineReducers } from "@talentpair/redux";
import candidates, { CandidatesReduxT } from "./candidateReducers";
import candidatePairs, { CandidatePairsReduxT } from "./candidatePairReducers";
import pairActivity, { PairActivityReduxT } from "./pairActivityReducers";
import pairFilter, { PairFilterReduxT } from "./pairFilterReducers";
import pairs, { CapPairsReduxT } from "./pairReducers";
import { UpcomingInterviewsReducer, UpcomingInterviewsState } from "./upcomingInterviewsStore";

export type CapStateT = {
  candidates: CandidatesReduxT;
  candidatePairs: CandidatePairsReduxT;
  pairActivity: PairActivityReduxT;
  pairFilter: PairFilterReduxT;
  pairs: CapPairsReduxT;
  upcomingInterviews: UpcomingInterviewsState;
};

export default combineReducers({
  candidates,
  candidatePairs,
  pairFilter,
  pairActivity,
  pairs,
  upcomingInterviews: UpcomingInterviewsReducer,
});
