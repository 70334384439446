// @flow
import { createSelector } from "@talentpair/redux";
import type { PairActiveStateT } from "@talentpair/types/entities/pairs";
import type { TapPairReduxT } from "@talentpair/types/tap/entities/pair";
import type { StateT } from "../../store";

// Detail
export const getPair = ({ tap }: StateT, id: number): ?TapPairReduxT => tap.pairs[id];

export const getHMState = createSelector<StateT, number, PairActiveStateT, _>([getPair], (pair) =>
  pair ? pair.job_state : "PR",
);

// List
export const getPairs = ({ tap }: StateT) => tap.pairs;
