// @flow
import { londonLink } from "@talentpair/env";
import { userService } from "@talentpair/api";
import type { ContextRouter } from "@talentpair/routing";
import { createCapHeader, createMeta } from "../../components/hocs/createCapHeader";

const backFn = ({ match }: ContextRouter) =>
  !userService.isDeactivated()
    ? match.params.candidate
      ? londonLink.capPairList(match.params.candidate)
      : "/"
    : "";

const title = "Your profile";
export const CapProfileHeader = createCapHeader(title, backFn, true);
export const CapProfileMeta = createMeta(title);
