// @flow
import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { OrgProfileMeta } from "./containers/OrgProfileHeader";
import { SideNavigation } from "../components/Navigation/SideNavigation";

const OrgProfile = React.lazy(() => import("./containers/OrgProfilePage"));

export const OrgProfilePage = page({
  mobile: OrgProfile,
  desktop: OrgProfile,
  desktopSidenav: SideNavigation,
  header: TapHeader,
  meta: OrgProfileMeta,
});
