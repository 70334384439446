import * as React from "react";
import { page } from "../../components/layout/responsive";
import { SettingsHeader, SettingsMeta } from "./containers/CapSettingsHeader";

export const CapSettingsPage = page({
  // @ts-expect-error - hmmm
  mobile: React.lazy(() => import("./containers/CapSettingsPage")),
  header: SettingsHeader,
  meta: SettingsMeta,
});
