// @flow
import { apiRequest, type ResponseDataT } from "@talentpair/api";
import type { DispatchT } from "@talentpair/redux";
import { type TapDetailJobAdminT } from "@talentpair/types/tap/entities/job";
import type { PairActiveStateT } from "@talentpair/types/entities/pairs";
import type { TapPairDetailT } from "@talentpair/types/tap/entities/pair";
import type { StateT } from "../../store";
import ActivityAction from "../../shared-apps/ActivityFeed/store/activityActions";
import JobAction from "./jobActions";
import { isAdmin } from "./jobSelectors";

export const TAP_PAIR_RECEIVE_DETAIL = "TAP_PAIR_RECEIVE_DETAIL";
export const TAP_PAIR_RECEIVE_ADMIN_DETAIL = "TAP_PAIR_RECEIVE_ADMIN_DETAIL";
export const TAP_PAIR_SET_STATE = "TAP_PAIR_SET_STATE";

type PairReceiveDetailActionT = {
  type: "TAP_PAIR_RECEIVE_DETAIL",
  pair: TapPairDetailT,
  ...
};
type PairSetStateT = {
  type: "TAP_PAIR_SET_STATE",
  pairId: number,
  job_state: PairActiveStateT,
  ...
};
type PairReceiveAdminActionT = {
  type: "TAP_PAIR_RECEIVE_ADMIN_DETAIL",
  pair: TapPairDetailT,
  ...
};

export type PairActionT = PairReceiveDetailActionT | PairReceiveAdminActionT | PairSetStateT;

const receiveDetail = (pair: TapPairDetailT) => ({ type: TAP_PAIR_RECEIVE_DETAIL, pair });
const recievePairAdmin = (pair: TapPairDetailT) => ({ type: TAP_PAIR_RECEIVE_ADMIN_DETAIL, pair });

const fetchDetail = (pairId: number) => (dispatch: DispatchT<PairReceiveDetailActionT>) =>
  apiRequest
    .getMemoized(`pairs-tap/${pairId}/mydesk/`)
    .then(({ data }: ResponseDataT<TapPairDetailT>) => {
      dispatch(receiveDetail(data));
      return data;
    });

const bootstrapPairDetail = (pairId: number, jobId: number) => (dispatch: DispatchT<>) =>
  Promise.all([dispatch(fetchDetail(pairId)), dispatch(JobAction.fetchDetail(jobId))]);

const fetchPairAdmin =
  (pairId: number, jobId: number, userId: number) =>
  (dispatch: DispatchT<>, getState: () => StateT): Promise<?TapDetailJobAdminT> => {
    const state = getState();
    if (!isAdmin(state, jobId)(userId)) return Promise.resolve();

    return apiRequest
      .getMemoized(`pairs-tap/${pairId}/admin/`)
      .then(({ data }: ResponseDataT<TapDetailJobAdminT>) =>
        Promise.resolve(dispatch(recievePairAdmin(data))),
      );
  };

const setState = (pairId: number, job_state: PairActiveStateT): PairSetStateT => ({
  type: TAP_PAIR_SET_STATE,
  pairId,
  job_state,
});

const saveState =
  (pair: number, endpoint: string, state: PairActiveStateT, payload: { ... } = {}) =>
  (dispatch: DispatchT<>) =>
    apiRequest.post(`pairs-tap/${pair}/${endpoint}/`, payload).then(() => {
      dispatch(setState(pair, state));
      dispatch(ActivityAction.fetchFirstPage("pair_tap", pair));
    });

const saveInterest = (pairId: number) => (dispatch: (*) => Promise<*>) =>
  dispatch(saveState(pairId, "interests", "HI"));

const savePass = (pairId: number, feedback?: ?{ ... }) => (dispatch: (*) => Promise<*>) =>
  dispatch(saveState(pairId, "passes", "PA", feedback || {}));
// Recruiter Only
const saveReset = (id: number) => (dispatch: DispatchT<>) =>
  apiRequest.patch(`pairs/${id}/`, { job_state: "PR" }).then(() => {
    dispatch(setState(id, "PR"));
    dispatch(ActivityAction.fetchFirstPage("pair_tap", id));
  });
const updateNotes = (id: number, notes: { ... }) => (dispatch: DispatchT<>) => {
  apiRequest.patch(`pairs/${id}/`, notes).then(({ data }) => {
    dispatch(receiveDetail(data));
  });
};

const setPairNotificationCount =
  (pairId: number, feedType: "pair_tap" | "pair_shared", count: number) =>
  (dispatch: DispatchT<>, getState: () => StateT) => {
    const pair = getState().tap.pairs[pairId];
    if (pair) {
      dispatch(
        receiveDetail({
          ...pair,
          [feedType === "pair_tap"
            ? "unread_notification_count"
            : "unread_shared_notification_count"]: count,
        }),
      );
    }
  };

export default {
  fetchDetail,
  fetchPairAdmin,
  bootstrapPairDetail,
  receiveDetail,
  saveInterest,
  savePass,
  saveReset,
  saveState,
  setState,
  updateNotes,
  setPairNotificationCount,
};
