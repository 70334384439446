// @flow
import * as React from "react";
import { SuspenseWrapper } from "@talentpair/sentry";

const Cmp = React.lazy(() => import("./containers/ApplicationDetailPage"));

export const CapApplicationDetail = (props: *) => (
  <SuspenseWrapper>
    <Cmp {...props} />
  </SuspenseWrapper>
);

/* PAGE HANDLED BY ../PairRoutes.jsx */
