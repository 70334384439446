import { format, parseISO } from "@talentpair/datetime";
import lodash from "lodash";
import { createSelector } from "@talentpair/redux";
import { PlatformNotificationT } from "@talentpair/types/notifications";
import { CursorPaginationT } from "@talentpair/types/misc";
import { StateT } from "../../../store";
import { ItemsStateT, ViewStateT } from "./notificationReducers";
import { getActivity } from "../../ActivityFeed/store/activitySelectors";

export const getIds = ({ notifications }: StateT): number[] => notifications.ids;
export const getItems = ({ notifications }: StateT): ItemsStateT => notifications.items;
export const getLoading = ({ notifications }: StateT): boolean => notifications.loading;
export const getPagination = ({ notifications }: StateT): CursorPaginationT =>
  notifications.pagination;
export const getView = ({ notifications }: StateT): ViewStateT => notifications.view;

export const getNextLink = createSelector([getPagination], (pagination) => pagination.next);

export const getNotificationFeed = createSelector(
  [getView, getIds, getItems, getActivity],
  (view, ids, items, activityItems): PlatformNotificationT[] =>
    ids
      .map((id) => items[id])
      .filter((item) => view === "all" || !item.read)
      .map(
        (notification): PlatformNotificationT => ({
          ...notification,
          // @ts-expect-error - activity type is inferred as the wrong type
          activity: activityItems[notification.activity],
        }),
      ),
);

export const getNotificationFeedByDay = createSelector(
  [getNotificationFeed],
  (notifications): [string, PlatformNotificationT[]][] =>
    lodash
      .sortBy<[string, PlatformNotificationT[]]>(
        Object.entries(
          lodash.groupBy<PlatformNotificationT>(notifications, (notification) =>
            format(parseISO(notification.created), "yyyy-MM-dd"),
          ),
        ),
        (entry: [string, PlatformNotificationT[]]) => entry[0],
      )
      .reverse()
      .map(([label, notificationList]: [string, PlatformNotificationT[]]) => [
        label,
        lodash.sortBy(notificationList, (notification) => notification.created).reverse(),
      ]),
);
