import { ErrorBoundary as SentryErrorBoundary, showReportDialog } from "@sentry/react";
import { ErrorBoundaryProps as SentryErrorBoundaryProps } from "@sentry/react/types/errorboundary";
import { Section, TextButtonLink } from "@talentpair/quantic";
import { ReactElement, ReactNode } from "react";
import { ErrorMessage } from "./ErrorMessage";

export function SubmitCrashReportButton(): React.ReactElement {
  return (
    <TextButtonLink onClick={() => process.env.REACT_APP_SENTRY_ID && showReportDialog()}>
      Submit a crash report
    </TextButtonLink>
  );
}

interface Error500PropsT {
  children?: ReactNode;
}
export const Error500 = ({ children = null }: Error500PropsT): ReactElement => (
  <Section>
    <ErrorMessage subtitle="We are having an issue.">
      Try refreshing the page or clicking something else in the app. {children}
      <SubmitCrashReportButton />
    </ErrorMessage>
  </Section>
);

export interface ErrorBoundaryProps extends SentryErrorBoundaryProps {
  children: ReactNode;
}

export function ErrorBoundary({
  fallback = <Error500 />,
  ...props
}: ErrorBoundaryProps): ReactElement {
  return <SentryErrorBoundary fallback={fallback} {...props} />;
}
