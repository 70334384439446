// @flow
import * as React from "react";
import { header, page } from "../../components/layout/responsive";
import { CapPairList, CapPairListTitle } from "../PairList";
import { CapUserActivityHeader, CapUserActivityMeta } from "./containers/UserActivityHeader";

// $FlowFixMe
export const CapUserActivity = React.lazy(() => import("./containers/UserActivityPage"));

export const CapUserActivityTitle = header({
  desktop: CapPairListTitle,
  mobile: CapUserActivityHeader,
});

export const CapUserActivityPage = page({
  desktop: CapUserActivity,
  mobile: CapUserActivity,
  sidebarOne: CapPairList,
  header: CapUserActivityTitle,
  meta: CapUserActivityMeta,
});
