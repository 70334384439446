import lodash from "lodash";
import { apiRequest } from "@talentpair/api";
import { timeDeltaInSeconds } from "@talentpair/datetime";
import { createSlice, PayloadAction, DispatchT } from "@talentpair/redux";
import { UpcomingInterviewT } from "@talentpair/types/entities/pipeline";

export type UpcomingInterviewsState = {
  user: number;
  list: UpcomingInterviewT[];
  lastLoaded: string | null;
  loading: boolean;
};

// HACK: If we upgrade redux-toolkit we can delete this outer stare business
type OuterState = {
  tap: { upcomingInterviews: UpcomingInterviewsState };
};

type GetState = () => OuterState;

function intialInterviewsState(user: number): UpcomingInterviewsState {
  return {
    user,
    list: [],
    lastLoaded: null,
    loading: false,
  };
}

function pickUpcomingInterviewValues(data: Record<string, unknown>): UpcomingInterviewT {
  return lodash.pick(data, [
    "id",
    "candidate",
    "job",
    "interviewer",
    "start_time",
    "end_time",
    "type",
    "round",
    "where",
    "graph_id",
    "pair",
  ]) as UpcomingInterviewT;
}

const { actions, reducer } = createSlice({
  name: "upcomingInterviews",
  initialState: intialInterviewsState(0),
  reducers: {
    setUser(state, action: PayloadAction<number>) {
      if (state.user !== action.payload) {
        state.list = [];
        state.lastLoaded = null;
      }
      state.user = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    receiveList(state, action: PayloadAction<UpcomingInterviewT[]>) {
      state.list = action.payload;
      state.lastLoaded = new Date().toISOString();
      state.loading = false;
    },
    addInterview(state, action: PayloadAction<UpcomingInterviewT>) {
      // picking off properties so a larger interview object can still be used to update this state
      state.list.push(pickUpcomingInterviewValues(action.payload));
      state.list = lodash.sortBy(state.list, ["start_time"]);
    },
    replaceInterview(state, action: PayloadAction<UpcomingInterviewT>) {
      const index = state.list.findIndex((i) => i.id === action.payload.id);
      if (index >= 0) {
        // picking off properties so a larger interview object can still be used to update this state
        state.list[index] = {
          ...pickUpcomingInterviewValues(action.payload),
          pair: state.list[index].pair, // keeping the pair, as the interview CRUD API serializers don't include the pair
        };
        state.list = lodash.sortBy(state.list, ["start_time"]);
      }
    },
    deleteInterview(state, action: PayloadAction<number>) {
      const index = state.list.findIndex((i) => i.id === action.payload);
      if (index >= 0) {
        state.list = state.list.filter((i) => i.id !== action.payload);
      }
    },
  },
  // TODO: Could be using nice slice selectors like this from v2 (upgrade redux-toolkit, possible other deps)
  // selectors: {
  //   getCalendar: (state) => state,
  //   getUser: (state) => state.user,
  // },
});

export function getCalendar(state: OuterState): UpcomingInterviewsState {
  return state.tap.upcomingInterviews;
}

export function getInterview(state: OuterState, id: number): UpcomingInterviewT | undefined {
  return getCalendar(state).list.find((i) => i.id === id);
}

const fetchInterviews =
  (userId?: number) =>
  (dispatch: DispatchT, getState: GetState): Promise<null | UpcomingInterviewT[]> => {
    const { user, lastLoaded, loading } = getCalendar(getState());
    if (
      userId &&
      userId === user &&
      (loading || (lastLoaded && timeDeltaInSeconds(lastLoaded) < 60))
    ) {
      return Promise.resolve(null);
    }
    if (userId !== user) dispatch(actions.setLoading(true));
    return apiRequest
      .get<UpcomingInterviewT[]>(`users/${user}/upcoming-interviews/`)
      .then(({ data }) => {
        dispatch(actions.receiveList(data));
        return data;
      });
  };

const setUser =
  (userId: number) =>
  (dispatch: DispatchT): Promise<unknown> => {
    dispatch(actions.setUser(userId));
    return dispatch(fetchInterviews(userId));
  };

const createInterview =
  (values: Record<string, unknown>) =>
  (dispatch: DispatchT, getState: GetState): Promise<unknown> =>
    apiRequest.post<UpcomingInterviewT>("interviews/", values).then(({ data }) => {
      const { user } = getCalendar(getState());
      // Recruiter or another TAP user can make interviews for others
      if (user && data.interviewer.id === user) {
        // just refetch upcoming interviews so we get the pair data too
        dispatch(fetchInterviews());
      }
    });

const updateInterview =
  (interviewId: number, values: Record<string, unknown>) =>
  (dispatch: DispatchT, getState: GetState): Promise<unknown> =>
    apiRequest.patch<UpcomingInterviewT>(`interviews/${interviewId}/`, values).then(({ data }) => {
      const { user } = getCalendar(getState());
      // Recruiter or another TAP user can make interviews for others
      if (user && data.interviewer.id === user) {
        dispatch(actions.replaceInterview(data));
      }
    });

const deleteInterview =
  (interviewId: number) =>
  (dispatch: DispatchT): Promise<unknown> =>
    apiRequest.delete<UpcomingInterviewT>(`interviews/${interviewId}/`).then(() => {
      // Safe enough to always fire since it only deletes if ids match
      dispatch(actions.deleteInterview(interviewId));
    });

export const UpcomingInterviewsReducer = reducer;
export const UpcomingInterviewsActions = {
  ...actions,
  fetchInterviews,
  setUser,
  createInterview,
  updateInterview,
  deleteInterview,
};
