import {
  isToday,
  isPast,
  isValid,
  differenceInMinutes,
  parseISO,
  differenceInHours,
  differenceInCalendarDays,
} from "@talentpair/datetime";

export function hasValue(value: unknown): boolean {
  if (Array.isArray(value)) return !!value.length;
  if (value == null || (typeof value === "string" && value.trim() === "")) return false;
  return true;
}

export function isNotFalsy(value: unknown): boolean {
  return Array.isArray(value) ? !!value.length : !!value;
}

export function required(value: unknown): string | undefined {
  return hasValue(value) ? undefined : "This field is required.";
}

type ValidatorT<V> = (value: V) => string | undefined;

export function requiredAnd<V>(validator?: ValidatorT<V>): ValidatorT<V> {
  return (value: V): string | undefined => required(value) || validator?.(value);
}

export function isPositiveNumber(value: string): string | undefined {
  if (!value) return undefined;

  const number = value.replace(/,/g, "");
  return number && Number(number) >= 0 ? undefined : "Must be a positive number.";
}

export function isGreaterThanZero(value: string | number): string | undefined {
  if (value === "") return undefined;

  const number = typeof value === "string" ? Number(value.replace(/,/g, "") || 0) : value;
  return number > 0 ? undefined : "Must be greater than zero.";
}

export function isOverTenThousand(value: string | number): string | undefined {
  if (value === "" || value === null) return undefined;

  const number = typeof value === "string" ? Number(value.replace(/,/g, "") || 0) : value;
  return number > 10000 ? undefined : `Must be greater than $10,000.`;
}

export function isBetweenValues(lowest: number, highest: number) {
  return (value: string | number): string | undefined => {
    if (value === "") return undefined;

    const number = typeof value === "string" ? Number(value.replace(/,/g, "") || 0) : value;
    return number >= lowest && number <= highest
      ? undefined
      : `Must be between ${lowest} and ${highest}.`;
  };
}

export const isBetween1And100 = isBetweenValues(1, 100);

export function isPhoneNumber(value: string): string | undefined {
  if (!value) return undefined;

  value = value.trim().replace(/[^\d]/g, "");
  if (value.startsWith("1")) value = value.slice(1);

  return value.length === 10 ? undefined : "Phone numbers must be 10 digits.";
}

export function isEmail(value: string): string | undefined {
  if (!value) return undefined;
  return value.trim().search(/^[^@\s]+@[^@\s]+\.[^@\s]+$/) === 0
    ? undefined
    : "Not a valid email address.";
}

export function isDate(value: string | Date): string | undefined {
  const transformedVal = typeof value === "string" ? parseISO(value) : value;
  if (transformedVal && !isValid(transformedVal)) return "Invalid Date. Format: yyyy-mm-dd";
  return undefined;
}

export function isWithinNextXDaysOf(
  value: string | Date,
  startDate: string | Date | number,
  days: number,
): string | undefined {
  const transformedVal = typeof value === "string" ? parseISO(value) : value;
  const transformedStartDate = typeof startDate === "string" ? parseISO(startDate) : startDate;
  const dayDiff = differenceInCalendarDays(transformedVal, transformedStartDate);

  if (
    !transformedVal ||
    !transformedStartDate ||
    !isValid(transformedVal) ||
    !isValid(transformedStartDate)
  )
    return "Invalid Date. Format: yyyy-mm-dd";
  if (dayDiff > days || dayDiff < 0) return "Date must be within the next 14 days";
  return undefined;
}

export function isTime(value: string | Date): string | undefined {
  const transformedVal = typeof value === "string" ? parseISO(value) : value;
  if (transformedVal && !isValid(transformedVal)) return "Invalid Time. Format: HH:mm";
  return undefined;
}

export function isDateInThePast(value: string | Date): string | undefined {
  const err = isDate(value);
  if (err) return err;
  const transformedVal = typeof value === "string" ? parseISO(value) : value;
  return differenceInHours(transformedVal, new Date()) >= 1
    ? "Date must be in the past."
    : undefined;
}

export function isFullDateInTheFuture(value: string | Date): string | undefined {
  const err = isDate(value);
  if (err) return err;
  const transformedVal = typeof value === "string" ? parseISO(value) : value;
  return differenceInMinutes(transformedVal, new Date()) < 0
    ? "Date must be in the future."
    : undefined;
}

export function isDateTodayOrInPast(value: string | Date): string | undefined {
  const err = isDate(value);
  if (err) return err;
  const transformedVal = typeof value === "string" ? parseISO(value) : value;
  return isToday(transformedVal) || isPast(transformedVal)
    ? undefined
    : "Date must be today or a date in the past.";
}

export function endTimeIsAfterStart(startTime: string, endTime: string): string | undefined {
  const parsedStart = parseInt(startTime.replace(":", ""), 10);
  const parsedEnd = parseInt(endTime.replace(":", ""), 10);
  return parsedEnd < parsedStart ? "Starting time must be earlier than the end time." : undefined;
}

const colorRegex = /^#[a-f0-9]{6}$/i;
export function isHexColor(value: string): string | undefined {
  if (!value || value.length !== 7 || !colorRegex.test(value))
    return "Must be a hex color value, ex '#ffaa99'";
  return undefined;
}

const orgSubdomainRegex = /^(?:\w|-)+$/i;
export function orgSubdomain(value: string): string | undefined {
  if (!value || value.length <= 3) return "Subdomain must be at four characters or longer.";
  if (!orgSubdomainRegex.test(value))
    return "Subdomain cannot contain spaces or special characters.";
  return undefined;
}
