import * as React from "react";

export function isElementInViewport(el: HTMLElement): boolean {
  // return true if the element is wholly contained within the viewport
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function useScrollIntoView<E extends HTMLElement = HTMLElement>(
  selected: unknown,
  position: ScrollLogicalPosition = "center",
): React.RefObject<E> {
  const el = React.useRef<E>(null);
  React.useEffect(() => {
    // if selected element is not completely visible: scroll it into view
    if (selected && el.current && !isElementInViewport(el.current)) {
      el.current.scrollIntoView({ behavior: "smooth", block: position });
    }
  }, [selected, position]);
  return el;
}
