import * as React from "react";
import { page } from "../../components/layout/responsive";
import { TapHeader } from "../../components/TapHeader";
import { CalendarMeta } from "./containers/TapCalendarHeader";
import { SideNavigation } from "../components/Navigation/SideNavigation";

const TapCalendar = React.lazy(() => import("./containers/TapCalendarPage"));

export const TapCalendarPage = page({
  // @ts-expect-error - hmmm
  desktop: TapCalendar,
  // @ts-expect-error - hmmm
  mobile: TapCalendar,
  // @ts-expect-error - hmmm
  desktopSidenav: SideNavigation,
  header: TapHeader,
  meta: CalendarMeta,
});
