import * as React from "react";
import { Badge, BadgeProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colors } from "@talentpair/quantic";

const useBadgeStyle = makeStyles({
  badge: {
    zIndex: "auto",
    color: "#fff",
    backgroundColor: colors.red[600],
  },
});

export default function ListItemNotificationBadge(
  props: Omit<BadgeProps, "color">,
): React.ReactElement {
  const classes = useBadgeStyle(props);
  return <Badge {...props} classes={classes} />;
}
