class Focus {
  private currentFocus: string | null = null;

  constructor() {
    document.addEventListener("focus", this, true);
    document.addEventListener("blur", this, true);
  }

  handleEvent(e: FocusEvent): void {
    if (e.type === "blur") {
      this.currentFocus = null;
    } else if (e.target && "name" in e.target) {
      const { name } = e.target;
      this.currentFocus = name || null;
    }
  }

  getCurrentFocus(): string | null {
    return this.currentFocus;
  }
}

const focus = new Focus();

export default focus;
