// @flow
import type { TapPairReduxT } from "@talentpair/types/tap/entities/pair";
import { sortCandidateData } from "../../utils/misc";
import {
  TAP_PAIR_RECEIVE_DETAIL,
  TAP_PAIR_RECEIVE_ADMIN_DETAIL,
  TAP_PAIR_SET_STATE,
  type PairActionT,
} from "./pairActions";
import { mergeListDetail } from "./storeUtils";

export type TapPairsReduxT = { [id: number]: ?TapPairReduxT, ... };

export default function pairs(state: TapPairsReduxT = {}, action: PairActionT): TapPairsReduxT {
  switch (action.type) {
    case TAP_PAIR_RECEIVE_DETAIL: {
      const newData = mergeListDetail(state, action.pair);
      newData.candidate = sortCandidateData(newData.candidate);
      return {
        ...state,
        [action.pair.id]: newData,
      };
    }

    case TAP_PAIR_RECEIVE_ADMIN_DETAIL: {
      const oldPair = state[action.pair.id];
      const newData = {
        ...oldPair,
        ...action.pair,
        candidate: oldPair
          ? { ...oldPair.candidate, ...action.pair.candidate }
          : action.pair.candidate,
      };
      return {
        ...state,
        [action.pair.id]: newData,
      };
    }

    case TAP_PAIR_SET_STATE:
      return {
        ...state,
        [action.pairId]: { ...state[action.pairId], job_state: action.job_state },
      };

    default:
      (action: empty);
      return state;
  }
}
