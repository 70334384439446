import { apiRequest } from "@talentpair/api";
import { expectNever } from "kyoto/utils/types";

export type CapCandidateCompletenessFieldsT =
  | "first_name"
  | "last_name"
  | "candidate_upload_resume"
  | "job_history"
  | "work_authorization"
  | "classifications"
  | "primary_classification"
  | "location"
  | "role"
  | "top_skills"
  | "top_platforms"
  | "features"
  | "interested_verticals"
  // following are delivered, but are only used if user is a recruiter looking at candidate profile on CAP
  | "seniority_score"
  | "email";

const ignoredFields = ["email", "seniority_score"];

export function fetchCompleteness(candidateId: number): Promise<CapCandidateCompletenessFieldsT[]> {
  return apiRequest
    .post<{ incomplete_fields: CapCandidateCompletenessFieldsT[] }>(
      `candidates-cap/${candidateId}/completeness/`,
    )
    .then(({ data }) =>
      data.incomplete_fields.filter(
        // filter out fields the user can't do anything about
        (f) => !ignoredFields.includes(f),
      ),
    );
}

export function displayName(name: CapCandidateCompletenessFieldsT): string {
  switch (name) {
    case "first_name":
      return "First Name";
    case "last_name":
      return "Last Name";
    case "email":
      return "Email";
    case "classifications":
      return "Field of Work";
    case "primary_classification":
      return "Primary Field of Work";
    case "seniority_score":
      return "Seniority";
    case "role":
      return "Current Job Title";
    case "location":
      return "Location";
    case "top_skills":
      return "Skills";
    case "top_platforms":
      return "Platforms";
    case "features":
      return "Experiences";
    case "interested_verticals":
      return "Industry Experience";
    case "candidate_upload_resume":
      return "Resume";
    case "job_history":
      return "Work History";
    case "work_authorization":
      return "Employment Eligibility";
    default:
      expectNever(name);
      return "";
  }
}
